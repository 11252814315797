<template>
	<div class="row">
		<div class="col-12">
			<h2>{{ $t('invoice.assignation_transaction') }}</h2>
		</div>

		<div class="col-6">
			{{ date }}
			<p>{{ bridge_transaction.bridgetransaction_description }}</p>
			<h4>{{ amount }}</h4>
			{{ bridge_transaction.bridge_account.bridge_bank.bridgebank_name }} - {{ bridge_transaction.bridge_account.bridgeaccount_name }}<br>
			{{ bridge_transaction.bridge_account.bridgeaccount_iban }}
		</div>
		<div class="col-6">
			<div class="form-group">
				<label for="memo">{{ $t('invoice.memo') }}</label>
				<input name="memo" type="text" class="form-control" v-model="memo">
			</div>
			<div class="form-group d-flex">
				<label class="mr-2">{{ $t('invoice.flux_fournisseur') }}</label><b-form-checkbox name="flux_client" switch v-model="flux_client">{{ $t('invoice.flux_client') }}</b-form-checkbox>
			</div>
			<form @submit.prevent="search">
				<div class="form-group">
					<label for="search_value">{{ $t('invoice.search_invoice_tiers') }}</label>
					<div class="input-group">
						<input name="search_value" type="text" class="form-control" v-model="search_value">
						<div class="input-group-append">
							<b-button variant="primary" @click="search">{{ $t("global.rechercher") }}</b-button>
						</div>
					</div>
				</div>
			</form>
			<LoadingSpinner v-if="loading_invoices" />
			<div v-else>
				<label for="invoices">{{ $t('invoice.titre_facture') }}</label>
				<i class="d-block" v-if="invoices.length == 0">{{ $t('invoice.invoices_search_empty') }}</i>
				<b-form-checkbox
					v-for="(invoice, key) in invoices"
					class="custom-control custom-checkbox mt-2"
					v-model="invoice.checked"
					name="invoices"
					:key="key"
				>
					<router-link :to="{ name: 'tiersFiche', params: { tiers_id: invoice.tiers_id }}">{{ invoice.tiers_rs }}</router-link><br>
					<font-awesome-icon v-if="invoice.checkdeposit_id" role="button" :icon="['far', 'money-check-edit-alt']" v-b-tooltip.hover="$t('check.check_deposit')"/>
					<font-awesome-icon v-if="invoice.payment_id" role="button" :icon="['far', 'file-invoice']" v-b-tooltip.hover="$t('payment.payment')"/>
					<a href="" class="" @click.prevent="quickPreview(invoice)">
					    {{ invoice.num }} <font-awesome-icon :icon="['fas', 'eye']" />
	                </a> - {{ invoice.date }}<br>
					{{ invoice.amount }} <template v-if="invoice.balance">- {{ $t('invoice.invoice_balance') }} : {{ invoice.balance }}</template>
				</b-form-checkbox>
			</div>
			<div class="text-center mt-3">
				<b-button variant="primary" :disabled="!can_save" @click="checkForm">{{ $t('invoice.associer') }} <font-awesome-icon v-if="processing" :icon="['fal', 'spinner']" pulse /></b-button>
			</div>
		</div>

        <b-modal size="xl" ref="modelPreview" hide-footer @hidden="pdf_url = null; base64 = null">
            <template v-slot:modal-title>
                {{ $t("action.previsualiser") }}
            </template>

            <iframe v-if="pdf_url != null" :src="pdf_url" height="1000px" width="100%" style="position:relative"></iframe>
            <iframe v-else-if="base64 != null" :src="'data:application/pdf;base64,' + base64" height="1000px" width="100%" style="position:relative"></iframe>
            <div v-else>
                <LoadingSpinner />
            </div>
        </b-modal>
	</div>
</template>

<script type="text/javascript">
    import Invoice from "@/mixins/Invoice.js"
    import OpenBank from "@/mixins/OpenBank.js"
    import Shutter from "@/mixins/Shutter.js"
    import Payment from "@/mixins/Payment.js"

	export default {
		name: 'AssignationTransaction',
		mixins: [Invoice, OpenBank, Shutter, Payment],
		props: ['bridge_transaction'],
		data () {
			return {
				amount: '',
				date: '',
				memo: '',
				flux_client: true,
				search_value: '',
				invoices: [],
				loading_invoices: false,
				processing: false,
				pdf_url: null,
				base64: null
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				this.loading_invoices = true
				this.flux_client = this.bridge_transaction.bridgetransaction_amount > 0
				this.amount = await this.priceFormat(this.bridge_transaction.bridgetransaction_amount, this.bridge_transaction.bridgetransaction_currency, true)
				this.date = this.formatDate(this.bridge_transaction.bridgetransaction_date)

				const invoices = await this.getPotentialsInvoicesByBridgeTransaction(this.bridge_transaction.bridgetransaction_id)
				for(let i in invoices) {
					invoices[i].amount = await this.priceFormat(invoices[i].amount/100, invoices[i].currency, true)
					invoices[i].balance = invoices[i].balance ? await this.priceFormat(invoices[i].balance/100, invoices[i].currency, true) : null
					invoices[i].date = this.formatDate(invoices[i].date)
					invoices[i].checked = false
				}
				this.invoices = invoices
				this.loading_invoices = false
			},

			async search() {
				this.loading_invoices = true
				const invoices = await this.searchInvoiceBridgeTransaction(!this.flux_client, this.search_value);
				for(let i in invoices) {
					invoices[i].amount = await this.priceFormat(invoices[i].amount/100, invoices[i].currency, true)
					invoices[i].balance = invoices[i].balance ? await this.priceFormat(invoices[i].balance/100, invoices[i].currency, true) : null
					invoices[i].date = this.formatDate(invoices[i].date)
					invoices[i].checked = false
				}
				this.invoices = invoices
				this.loading_invoices = false
			},

			async checkForm() {
				this.processing = true
				const invoices = this.invoices.filter(invoice => invoice.checked)
				if(invoices.length == 0) {
					this.processing = false
					return false
				}

				const invoices_ids = invoices.filter(invoice => invoice.id).map(invoice => invoice.id)
				const checkdeposit_ids = invoices.filter(invoice =>  invoice.checkdeposit_id).map(invoice => invoice.checkdeposit_id)
				const payment_ids = invoices.filter(invoice =>  invoice.payment_id).map(invoice => invoice.payment_id)

				if(checkdeposit_ids.length > 1 || (checkdeposit_ids.length == 1 && invoices_ids.length > 0)) {
					this.processing = false
					return false
				}

				const checkdeposit_id = checkdeposit_ids.length == 0 ? '' : checkdeposit_ids[0]
				const payment_id = payment_ids.length == 0 ? '' : payment_ids[0]

				await this.assignBridgeTransaction(this.bridge_transaction.bridgetransaction_id, !this.flux_client, invoices_ids, checkdeposit_id, payment_id, this.memo)
				this.processing = false
				this.ok()
				this.shutterPanel().close('assignation-flow-transaction')
			},

            async quickPreview(invoice) {
            	this.pdf_url = null
            	this.base64 = null
            	
                this.$refs.modelPreview.show()
            	if(invoice.pdf_url) {
            		this.pdf_url = invoice.pdf_url
            	}
            	else if(invoice.checkdeposit_id) {
            		let checkdeposit_ids = {checkdeposit_ids: invoice.checkdeposit_id.toString()}
            		let retour = await this.printCheckDeposit(checkdeposit_ids, true)
            		if(retour) {
	                    this.base64 = retour
	                }
            	}
            	else {
	                let retour = await this.pdfInvoice([invoice.id], "", true)
	                if(retour) {
	                    this.base64 = retour
	                }
            	}
            }
		},

		computed: {
			can_save() {
				return this.invoices.filter(invoice => invoice.checked).length > 0
			}
		},

		components: {
			LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35')
		}
	}

</script>