<template>
	<div class="box">
		<h3>{{ $t('monte.congelation.liste_protocoles') }}</h3>
		<p>{{ $t('monte.congelation.protocole_ordre_explication') }}</p>
		<div class="row pr-5 pl-2">
			<div class="col-6">{{ $t('monte.congelation.protocole_label') }}</div>
			<div class="col-3">{{ $t('monte.congelation.protocole_code') }}</div>
			<div class="col-1">{{ $t('monte.congelation.protocole_archiver') }}</div>
			<div class="text-right col-2">
				<b-button variant="primary" @click.prevent="openAddProtocole">{{ $t('global.ajouter') }} <font-awesome-icon :icon="['fal', 'plus']" /></b-button>
			</div>
		</div>
		<LoadingSpinner v-if="loading" />
		<draggable v-else class="list-group list-group-left" :list="protocoles" v-bind="dragOptions" group="colonne" @update="debouncedOrder">
			<div v-for="protocole in protocoles" class="list-group-item align-items-center" :key="protocole.protocole_id">
				<div class="form-row align-items-center">
					<div class="col-6">{{ protocole.protocole_label }}</div>
					<div class="col-3">{{ protocole.protocole_code }}</div>
					<div class="col-1">
						<font-awesome-icon class="text-success" v-if="protocole.protocole_archive" :icon="['fal', 'check-circle']" />
						<font-awesome-icon class="text-danger" v-else :icon="['fal', 'times-circle']" />
					</div>
					<div class="text-right col-2">
						<b-button variant="secondary" @click.prevent="openEditProtocole(protocole)">{{ $t('global.modifier') }} <font-awesome-icon :icon="['fal', 'angle-right']" /></b-button>
						<b-button variant="secondary" class="ml-auto order-md-last"><font-awesome-icon :icon="['fal', 'sort-alt']" /></b-button>
					</div>
			    </div>
			</div>
		</draggable>

		<b-modal ref="modal-protocole" hide-footer size="xl">
			<template v-if="!form.protocole_id" v-slot:modal-title>
				{{ $t('monte.congelation.add_protocole') }}
			</template>
			<template v-else v-slot:modal-title>
				{{ $t('monte.congelation.edit_protocole') }}
			</template>

			<div v-if="form_message !== ''" class="col-12 alert alert-danger">{{ form_message }}</div>

			<div class="row">
				<div class="col-12 form-group">
					<label for="protocole_label">{{ $t("monte.congelation.protocole_label") }} *</label>
					<b-input class="form-control" type="text" v-model="form.protocole_label" id="protocole_label" />
				</div>
				<div class="col-6 form-group">
					<label for="protocole_color" class="col-form-label">{{ $t("monte.congelation.protocole_color") }} *</label>
					<b-form-input type="color" id="protocole_color" v-model="form.protocole_color"></b-form-input>
				</div>
				<div class="col-6 form-group">
					<label for="protocole_color_police" class="col-form-label">{{ $t("monte.congelation.protocole_color_police") }} *</label>
					<b-form-input type="color" id="protocole_color_police" v-model="form.protocole_color_police"></b-form-input>
				</div>
				<div class="col-6 form-group">
					<label for="protocole_code">{{ $t("monte.congelation.protocole_code") }} *</label>
					<b-input class="form-control" type="text" v-model="form.protocole_code" id="protocole_code" />
				</div>
				<div class="col-6 form-group">
					<label for="protocole_start">{{ $t("monte.congelation.protocole_start") }}</label>
					<b-input class="form-control" type="number" v-model="form.protocole_start" id="protocole_start" />
				</div>
				<div class="col-6 form-group">
					<label for="protocole_before">{{ $t("monte.congelation.protocole_before") }}</label>
					<b-input class="form-control" type="number" v-model="form.protocole_before" id="protocole_before" />
				</div>
				<div class="col-6 form-group">
					<label for="protocole_after">{{ $t("monte.congelation.protocole_after") }}</label>
					<b-input class="form-control" type="number" v-model="form.protocole_after" id="protocole_after" />
				</div>
				<div class="col-6 form-group">
					<label for="protocole_archive">{{ $t("monte.congelation.protocole_archiver") }}</label>
					<b-form-checkbox v-model="form.protocole_archive" id="protocole_archive" />
				</div>
			</div>
			<table class="table table-hover table-sm mb-0">
				<tr>
					<td>{{ $t('monte.congelation.acte_type') }}</td>
					<td>{{ $t('monte.congelation.delay') }}</td>
					<td class="text-right"><b-button @click.prevent="addActeType"><font-awesome-icon :icon="['fal', 'plus']" /></b-button></td>
				</tr>
				<tr v-for="(acte_type, index) in protocole_actes_types" :key="index">
					<td>
						<e-select
							v-model="acte_type.acte_type"
							id="actestype_id"
							track-by="actestype_id"
							label="actestype_label"
							:placeholder="$t('acte.selectionnez_acte')"
							:selectedLabel="$t('global.selected_label')"
							:options="actes_types"
							:searchable="true"
							:allow-empty="false"
							:show-labels="false"
							:group-select="false"
						>
							<template slot="noOptions">{{ $t('global.list_empty') }}</template>
						</e-select>
					</td>
					<td><b-input class="form-control" type="number" v-model="acte_type.delay" /></td>
					<td class="text-right"><b-button @click.prevent="removeActeType"><font-awesome-icon :icon="['fal', 'trash-alt']" /></b-button></td>
				</tr>
			</table>

			<div class="text-center">
				<a class="btn btn-primary mt-3" href="#" @click.prevent="checkForm">{{ $t('global.enregistrer') }} <font-awesome-icon v-if="processing" :icon="['fal', 'spinner']" pulse /><font-awesome-icon v-else :icon="['fal', 'save']" /></a>
			</div>
		</b-modal>
	</div>
</template>

<script type="text/javascript">
	import Congelation from "@/mixins/Congelation.js"
    import draggable from 'vuedraggable'
    import _debounce from 'lodash/debounce'

	export default {
		name: 'Protocoles',
		mixins: [Congelation],
		data () {
			return {
				loading: false,
				protocoles: [],
				protocole_actes_types: [],
				actes_types: [],
				processing: false,
				form: {
					protocole_id: null,
					protocole_label: '',
					protocole_color: '#fff',
					protocole_color_police: '#000',
					protocole_code: '',
					protocole_start: 0,
					protocole_before: 0,
					protocole_after: 0,
					protocole_archive: false,
					protocole_order: 999
				},
                dragOptions: {
                    animation: 200
                },
                debouncedOrder: _debounce(this.updateOrder, 500),
				form_message: ''
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				this.loading = true
				this.protocoles = await this.getAllProtocoles()
				this.actes_types = await this.getActeTypeTest()
				this.loading = false
			},

			openAddProtocole() {
				this.form = {
					protocole_id: null,
					protocole_label: '',
					protocole_color: '#fff',
					protocole_color_police: '#000',
					protocole_code: '',
					protocole_start: 0,
					protocole_before: 0,
					protocole_after: 0,
					protocole_archive: false
				}
				this.protocole_actes_types = []
				this.$refs['modal-protocole'].show()
			},

			openEditProtocole(protocole) {
				this.form = protocole
				this.protocole_actes_types = protocole.actes_types.map(a => ({
					acte_type: this.actes_types.find(type => type.actestype_id == a.protocoleactetype_actetype),
					delay: a.protocoleactetype_delay
				}))

				this.$refs['modal-protocole'].show()
			},

			addActeType() {
				this.protocole_actes_types.push({
					acte_type: null,
					delay: 0
				})
			},

			removeActeType(acte_type) {
				this.protocole_actes_types.splice(this.protocole_actes_types.findIndex(at => at == acte_type), 1)
			},

			async checkForm() {
				this.form_message = ''
				this.processing = true

				if(this.form.protocole_code == '') {
					this.form_message = this.getTrad("monte.congelation.protocole_code_vide")
					this.processing = false
					return false
				}

				if(this.form.protocole_label == '') {
					this.form_message = this.getTrad("monte.congelation.protocole_label_vide")
					this.processing = false
					return false
				}

				const actes_types = this.protocole_actes_types.map(a => ({acte_type: a.acte_type.actestype_id, delay: a.delay}))
				if(this.form.protocole_id) {
					await this.editProtocole(this.form.protocole_id, this.form, actes_types)
				}
				else {
					await this.createProtocole(this.form, actes_types)
				}
				this.processing = false
				this.$refs['modal-protocole'].hide()
				this.init_component()
			},

			async updateOrder() {
				const orders = {}
				this.protocoles.forEach((protocole, index) => {
					orders[index] = protocole.protocole_id
				})
				await this.saveProtocoleOrder(orders)
				this.successToast('toast.info_save_succes')
			}
		},

		components: {
            LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
            draggable 
		}
	}

</script>