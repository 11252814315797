<template>
	<div class="row">
		<div v-if="lot_local" class="col-12 mb-2">
			<h3>{{ $t('monte.congelation.controle_qualite') }} : {{ lot_local.horse.horse_nom }} - {{ lot_local.semencelot_quantieme }}</h3>
		</div>

		<div class="col-6 form-group">
			<label for="date">{{ $t("global.date") }} *</label>
            <e-datepicker id="date" v-model="form.date" />
		</div>
		<div class="col-6 form-group">
			<label for="localisation">{{ $t("monte.congelation.cuve_stockage") }} *</label>
			<e-select
				v-model="form.localisation"
				id="stockage"
				track-by="full_name"
				label="full_name"
				:placeholder="$t('monte.stock_semence.rechercher_categorie')"
				:selectedLabel="$t('global.selected_label')"
				:options="all_localisations"
				:searchable="true"
				:show-labels="false"
				:allow-empty="true"
				:sortable="false"
				@input="changeStockageStallion(stallion.horse)"
			>
				<template slot="option" slot-scope="props"><span :style="`padding-left: ${25 * (props.option.indent || 0) }px;`">{{ props.option.full_name }}</span></template>
				</e-select>
		</div>
		<div class="col-6 form-group">
			<label for="nb_paillettes">{{ $t("monte.congelation.nb_paillettes") }} *</label>
			<input class="form-control" type="text" v-model="form.nb_paillettes" id="nb_paillettes">
		</div>
		<div class="col-6 form-group">
			<label for="qualite">{{ $t("monte.congelation.qualite") }}</label>
			<input class="form-control" type="text" v-model="form.qualite" id="qualite">
		</div>
		<div class="col-6 form-group">
			<label for="motilite">{{ $t("monte.congelation.motilite") }}</label>
			<input class="form-control" type="text" v-model="form.motilite" id="motilite">
		</div>
		<div class="col-6 form-group">
			<label for="survie">{{ $t("monte.congelation.survie") }}</label>
			<input class="form-control" type="text" v-model="form.survie" id="survie">
		</div>
		<div class="col-6 form-group">
			<label for="comptage">{{ $t("monte.congelation.comptage") }}</label>
			<input class="form-control" type="text" v-model="form.comptage" id="comptage">
		</div>
		<div class="col-6 form-group">
			<label for="statut">{{ $t("monte.congelation.statut") }} *</label>
			<e-select
				v-model="form.statut"
				id="statut"
				:placeholder="$t('monte.congelation.selectionnez_statut')"
				:selectedLabel="$t('global.selected_label')"
				:options="all_statut"
				:searchable="true"
				:allow-empty="false"
				:show-labels="false"
			>
				<template slot="option" slot-scope="{ option }">{{ $t('monte.congelation.statuts.'+option) }}</template>
				<template slot="singleLabel" slot-scope="{ option }">{{ $t('monte.congelation.statuts.'+option) }}</template>
				<template slot="noOptions">{{ $t('global.list_empty') }}</template>
			</e-select>
		</div>
		<div class="col-12 form-group">
			<label for="commentaire">{{ $t("monte.congelation.commentaire") }}</label>
			<input class="form-control" type="text" v-model="form.commentaire" id="commentaire">
		</div>

		<div class="col-12 text-center">
			<a class="btn btn-primary mt-3" href="#" @click.prevent="checkForm">{{ $t('global.enregistrer') }} <font-awesome-icon v-if="processing" :icon="['fal', 'spinner']" pulse /><font-awesome-icon v-else :icon="['fal', 'save']" /></a>
		</div>
	</div>
</template>

<script type="text/javascript">
	import Congelation from "@/mixins/Congelation.js"
	import StockSemence from "@/mixins/StockSemence.js"
	import Shutter from '@/mixins/Shutter.js'

	export default {
		name: 'AddControlLot',
		mixins: [Congelation, StockSemence, Shutter],
		props: ['lot', 'emplacement'],
		data () {
			return {
				form: {
					date: new Date(),
					localisation: null,
					nb_paillettes: 1,
					qualite: '',
					motilite: '',
					survie: '',
					comptage: '',
					commentaire: '',
					statut: null
				},
				all_statut: ['apte', 'inapte', 'doute', 'detruit'],
				processing: false,
				all_localisations: [],
				lot_local: null
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				const all_localisations = await this.getLocalisationSemenceFullName()
				if(this.lot) {
					this.lot_local = this.lot
				}
				else if (this.emplacement) {
					this.lot_local = this.emplacement.lot
				}

				if(this.lot_local.prestockage_id) {
					this.all_localisations = all_localisations.filter(loc => loc.semencelocalisation_id == this.lot_local.prestockage_id && !loc.semencelocalisation_transport && loc.semencelocalisation_is_floor)
				}
				else {
					const localisations = await this.getLocalisationsByLot(this.lot_local.semencelot_id)
					this.all_localisations = all_localisations.filter(loc => localisations.includes(loc.semencelocalisation_id) && !loc.semencelocalisation_transport && loc.semencelocalisation_is_floor)
				}

				if(this.emplacement) {
					this.form.localisation = this.all_localisations.find(loc => loc.semencelocalisation_id == this.emplacement.emplacementcongelation_localisation)
				}
				else {
					this.form.localisation = this.all_localisations[0]
				}
				this.form.statut = this.all_statut[0]
			},

			async checkForm() {
				this.processing = true
				let params = {...this.form, localisation: this.form.localisation.semencelocalisation_id}
				await this.createControlLot(this.lot_local.semencelot_id, params)
				this.processing = false
				this.ok()
			}
		}
	}

</script>