<template>
    <main>
        <HeaderTab :title="$t('global.titre_licence_management')" />
        <div id="content">
            <div class="container-fluid">
                <div class="row ">
                    <div class="col-12">
                        <div class="box">
                            <form @submit.prevent="() => false">
                                <div class="row align-items-end" >
                                    <div class="col">
                                        <label>Rechercher une adresse e-mail <font-awesome-icon v-if="spinner" :icon="['fal', 'circle-notch']" spin/></label>
                                        <b-form-input v-model="email" :placeholder="$t('compte.email')" type="email"></b-form-input>
                                    </div>
                                </div>
                                <div 
                                    v-for="(licence, key) in licences"
                                    :key="'licence_'+key"
                                >
                                    <br><hr><br>
                                    <div class="row" >
                                        <label class="col">Licence {{ key }}
											<span v-if="licence.main" style="color: green;">(Utilisateur principal)</span>
										</label>
                                    </div>
                                    <div class="row">
										<!-- Gamme -->
                                        <div class="col-6">
                                            <e-select
                                                v-model="licence.gamme"
                                                id="licence_gamme"
                                                track-by="gamme_id"
                                                label="gamme_label"
                                                placeholder="Choisir une gamme"
                                                :options="all_gammes"
                                                :searchable="true"
                                                :allow-empty="false"
                                                :loading="loading"
                                            />
                                        </div>
										<!-- Options -->
                                        <div class="col-5">
                                            <e-select
                                                v-model="licence.options"
                                                id="licence_option"
                                                track-by="option_id"
                                                label="option_label"
                                                placeholder="Choisir des options"
                                                :options="all_options"
                                                :searchable="true"
                                                :allow-empty="true"
                                                :loading="loading"
                                                :multiple="true"
                                            />
                                        </div>
                                        <div class="col-1">
                                            <b-button @click.prevent="validForm(key, licence)" :disabled="loading" variant="primary">
                                                <font-awesome-icon v-if="spinner" :icon="['fal', 'circle-notch']" spin/>
                                                Assigner
                                            </b-button>
                                        </div>
                                    </div>

									<!-- Suppléments -->
									<div class="row mt-1">
										<div class="col-6">
											<label>Suppléments</label>
										</div>

										<div class="col-6">
											<label>Nom de la licence</label>
										</div>

										<div class="col-6">
											<e-select
												v-model="selected_supplement"
												:options="all_supplements"
												track-by="supplement_id"
												label="supplement_label_formatted"
												placeholder="Ajouter un supplément"
												reset-after
												@select="addSupplement(licence, $event)"
											/>
										</div>

										<div class="col-6">
											<b-form-input v-model="licence.name" placeholder="Nom de la licence" type="text"></b-form-input>
										</div>

										<div class="col-6">
											<b-badge
												v-for="(supplement_licence, idx) in licence.supplements"
												:key="idx"
												class="ml-1 btn"
												@click="deleteSupplement(licence, supplement_licence.supplement_id)"
											>
												{{ supplement_licence.supplement_label }} {{ $t(`user_management.supplement_types.${supplement_licence.supplement_type}`) }}
												<font-awesome-icon :icon="['fal', 'times']"></font-awesome-icon>
											</b-badge>
										</div>
									</div>

									<!-- Options espace client -->
									<template v-if="ca_config && ca_available_config">
										<div class="row mt-3">
											<div class="col">
												<label>Configuration espace client dédié</label>
											</div>
										</div>
										<div class="row">
											<div class="col">
												<b-form-checkbox
													name="horse-auto-creation"
													v-model="ca_config.arion.horse_autocreate"
												>
													Permettre la création automatique des chevaux à réception des performances Arion
												</b-form-checkbox>
											</div>
										</div>
										<div class="row mt-3">
											<div class="col">
												<label>Onglets de la fiche cheval</label>
											</div>
										</div>
										<div class="row">
											<div class="col-6" v-if="ca_available_config && ca_available_config.available_tabs">
												<e-select
													:options="ca_available_config.available_tabs.horseFiche"
													placeholder="Ajouter un onglet"
                                                	reset-after
													@select="addCaTab('horseFiche', $event)"
												>
													<template slot="singleLabel" slot-scope="{ option }">{{ $t(`user_management.ca_tabs.horseFiche.${option}`) }}</template>
													<template slot="option" slot-scope="{ option }">{{ $t(`user_management.ca_tabs.horseFiche.${option}`) }}</template>
												</e-select>
											</div>
											<div class="col-6" v-if="ca_config.tabs">
												<b-badge
													v-for="tab in ca_config.tabs.horseFiche"
													:key="`horsefiche_${tab}`"
													class="ml-1 btn"
													@click="deleteCaTab('horseFiche', tab)"
												>
													{{ $t(`user_management.ca_tabs.horseFiche.${tab}`) }}
													<font-awesome-icon :icon="['fal', 'times']"></font-awesome-icon>
												</b-badge>
											</div>
										</div>
									</template>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>


<script type="text/javascript">
import User from "@/mixins/User.js"
import _debounce from 'lodash/debounce'
import _defaultsDeep from 'lodash/defaultsDeep'

export default {
    name: "LicenceManagement",
    mixins: [User],
    data () {
        return {
			email: '',
			licences: [],
			loading: true,
			spinner: false,
			all_gammes: [],
			all_options: [],
			all_supplements: [],
			ca_available_config: null,
			ca_config: null,
			selected_supplement: null,
			licence_name: ''
        }
	},
	created() {
		this.debouncedSearch = _debounce(this.searchEmail, 1000)
	},
    mounted() {
        this.getGammesData()
        this.getOptionsData()
        this.getSupplementsData()
		this.getCaAvailableConfigs()
    },
    methods: {
        async getGammesData() {
            this.all_gammes = await this.getGammes()
        },
        async getOptionsData() {
            this.all_options = await this.getOptions()
		},
        async getSupplementsData() {
			this.all_supplements = await this.getSupplements()
			this.all_supplements.forEach(supplement => {
				supplement.supplement_label_formatted = `${supplement.supplement_label} ${this.$t(`user_management.supplement_types.${supplement.supplement_type}`)}`
			})
		},
		async getCaAvailableConfigs() {
			this.ca_available_config = await this.getCaConfig()
		},
		async getClientAreaConfig(licence) {
			let temp = null
			try {
				temp = await this.getUserCaConfig(licence)
			} catch (e) {
				this.ca_config = null
				return
			} 
			this.ca_config = _defaultsDeep(temp, {
				arion: {
					horse_autocreate: true
				}
			})
						
		},
		async searchEmail() {
			this.getUserGamme(this.email).then(result => {
				let retour = result.retour || null
				if(!retour) throw 'No available result'

				// Pour la licence principale, on va chercher les éventuelles infos d'espace client
				const main_licence = Object.keys(retour)[0]
	            this.getClientAreaConfig(main_licence)

				Object.keys(retour).forEach(licence_key => {
					retour[licence_key].gamme = retour[licence_key].gamme[0]
				})

				this.licences = retour
				this.loading = false

			}).finally(() => {
				this.spinner = false
			})
		},
        async validForm(key, licence) {
            if(!this.email) return
            this.loading = true
            this.spinner = true

            const gamme_id = licence.gamme.gamme_id
			const options_ids = licence.options.map(option => option.option_id)
			const supplements_ids = licence.supplements.map(supplement => supplement.supplement_id)

            let tab_promises = []

            tab_promises.push(this.setLicenceGamme(this.email, key, gamme_id))
            tab_promises.push(this.setLicenceOptions(this.email, key, options_ids))
            tab_promises.push(this.setLicenceSupplements(this.email, key, supplements_ids))
            tab_promises.push(this.saveLicenceName(licence.id, licence.name))
            // if(this.ca_config.raw)  tab_promises.push(this.setUserCaConfig(key, 'raw', 'arion', JSON.stringify(this.ca_config.raw.arion))) // arion que pour les monceaux
            if(this.ca_config && this.ca_config.tabs) tab_promises.push(this.setUserCaConfig(key, 'tabs', 'horseFiche', JSON.stringify(this.ca_config.tabs.horseFiche)))

            Promise.all(tab_promises)
                .then(() => {
                    this.successToast("toast.info_modif_succes")
                })
                .catch((e) => {
                    this.failureToast("toast.info_modif_failed")
                }).finally(() => {
                    this.loading = false
                    this.spinner = false
                })
		},
		addSupplement(licence, supplement) {
			licence.supplements.push(supplement)
		},
		deleteSupplement(licence, supplement_id) {
			let index = licence.supplements.findIndex((s) => (s.supplement_id === supplement_id))
			licence.supplements.splice(index, 1)
		},
		addCaTab(type, tab) {
			this.ca_config.tabs[type].push(tab)
		},
		deleteCaTab(type, tab) {
			const idx = this.ca_config.tabs[type].indexOf(tab)
			this.ca_config.tabs[type].splice(idx, 1)
		},
    },
    watch: {
        'email' (val) {
            if(this.regex_email.test(String(val).toLowerCase())){
                this.loading = true
				this.spinner = true
				this.debouncedSearch()
            }
		},
    },
    components: {
        HeaderTab: () => import('@/components/HeaderTab'),
    }
}
</script>