<template>
    <div>
        <div class="box">
            <h4 class="mb-3"><font-awesome-icon :icon="['fal', 'tools']" />  {{ $t("compta.export.parametrage") }}</h4>
            <div class="row">
                <div class="col-md-10">
                    <label>{{ $t("compta.export.licence_key") }} </label>
                    <input type="text" class="form-control" v-model="licence">
                </div>
                <div class="text-center mt-4">
                    <button class="btn btn-primary rounded-pill" @click="searchLicence">{{ $t("global.rechercher") }} <font-awesome-icon :icon="['fal', 'search']" /></button>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <b-form-checkbox
                        id="no_licence_key"
                        v-model="no_licence_key"
                    >
                        {{ $t("compta.export.no_licence_key") }}
                    </b-form-checkbox>
                </div>
            </div>
            <div v-if="no_licence_key || search_licence" class="form-group">
                <label>{{ $t("compta.export.type_export") }}</label>
                <b-form-select
                    class="custom-select"
                    v-model="selected_type"
                    >
                    <option value="0" default disabled>{{ $t('compta.export.select_type') }}</option>
                    <option v-for="type in types" :key="type" :value="type">
                       {{ $t("compta.export."+type) }}
                    </option>
                </b-form-select>
            </div>
            <div v-if="!no_licence_key && search_licence && selected_type != 0" class="form-group row">
                <div class="col-md-6">
                    <label>{{ $t("compta.export.type_export_perso") }}</label>
                    <b-form-select
                        class="custom-select"
                        v-model="selected_type_perso"
                        >
                        <option value="new" default>{{ $t('compta.export.new') }}</option>
                        <option v-for="(type, index) in types_perso" :key="index" :value="index+1">
                           {{ type.export_label }}
                        </option>
                    </b-form-select>
                </div>
                <div class="col-md-6">
                    <label>{{ $t("compta.export.label") }} </label>
                    <input type="text" class="form-control" v-model="label">
                </div>
            </div>
            <div v-if="no_licence_key || search_licence && selected_type != 0" class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label>{{ $t("compta.export.format_date") }}</label>
                        <b-form-select 
                            class="custom-select"
                            v-model="selected_date"
                            >
                            <option value="0" default disabled>{{ $t('compta.export.select_date') }}</option>
                            <option v-for="date in dates" :key="date" :value="date">
                               {{ date }}
                            </option>
                        </b-form-select>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label>{{ $t("compta.export.separateur_colonnes") }}</label>
                        <b-form-select 
                            class="custom-select"
                            v-model="selected_sep"
                            >
                            <option value="0" default disabled>{{ $t('compta.export.select_separateur') }}</option>
                            <option v-for="separateur in separateurs" :key="separateur" :value="separateur">
                               {{ separateur }}
                            </option>
                        </b-form-select>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label>{{ $t("compta.export.format_nombre") }} <i v-b-tooltip.hover.right title="Vide = Pas de nombre pour débit/crédit, .00 = Séparateur point + x décimales, ,00 = séparateur virgule + x décimales"><font-awesome-icon :icon="['fas', 'info-circle']" /></i></label>
                        <input v-model="selected_format_numbers" class="form-control">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label>{{ $t("compta.export.format_export") }}</label>
                        <b-form-select class="custom-select" v-model="selected_export_extension">
                            <option value="csv">CSV</option>
                            <option value="xls">XLS</option>
                            <option value="txt">TXT</option>
                        </b-form-select>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="">&nbsp;</label>
                        <b-form-checkbox id="export_display_debit_credit" v-model="export_display_debit_credit">
                            {{ $t("compta.export.affichage_debit_credit") }} <i v-b-tooltip.hover.right title="Affichage du 0.00 pour débit/crédit ou vide si pas coché"><font-awesome-icon :icon="['fas', 'info-circle']" /></i>
                        </b-form-checkbox>
                        <label for="">&nbsp;</label>
                        <b-form-checkbox id="export_group_article" v-model="export_group_article">
                            {{ $t("compta.export.group_article") }}
                        </b-form-checkbox>
                        <label for="">&nbsp;</label>
                        <b-form-checkbox id="export_group_compte" v-model="export_group_compte">
                            {{ $t("compta.export.group_compte") }}
                        </b-form-checkbox>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label>{{ $t("compta.export.enclosure") }} <i v-b-tooltip.hover.right title="Caractère pour englober les chaines de caractères"><font-awesome-icon :icon="['fas', 'info-circle']" /></i></label>
                        <input v-model="selected_enclosure" class="form-control">
                    </div>
                </div>
            </div>
        </div>
        <div v-if="export_cle" class="box">
            <h4 class="mb-3"><font-awesome-icon :icon="['fal', 'line-columns']" /> {{ $t("compta.export.gestion_colonnes") }}</h4>
            <div class="row">
                <div class="col-6">
                    <draggable class="list-group" group="colonne" :list="all_dispo_columns" v-bind="dragOptionsAllColumns">
                        <div class="list-group-item  d-flex align-items-center" v-for="element in all_dispo_columns" :key="element.label">
                                {{ $t(element.label) }}
                            <div class="btn btn-secondary ml-auto" @click="select_column(element)">
                                <font-awesome-icon :icon="['fal', 'long-arrow-right']" />
                            </div>
                        </div>
                    </draggable>
                </div>
                <div class="col-6">
                    <draggable class="list-group" group="colonne" :list="selected_columns" v-bind="dragOptionsSelectedColumns" >
                        <div class="list-group-item d-flex align-items-center" v-for="(element, index) in selected_columns" :key="element.label">
                            <div class="badge badge-pill badge-secondary mr-2">{{ index }}</div>
                            {{ $t(element.label) }}
                            <div class="ml-auto" v-if="element.type == 'text' || element.type == 'number'">
                                <label for="">Longueur</label> <i v-b-tooltip.hover.right title="Laisser vide si pas nécessaire"><font-awesome-icon :icon="['fas', 'info-circle']" /></i>
                                <input type="text" class="form-control" v-model="element.params.longueur">
                            </div>
                            <div class="ml-auto" v-if="element.type == 'text' || element.type == 'number'">
                                <label for="">Alignement</label> <i v-b-tooltip.hover.right title="Ajouter des espaces à gauche ou droite pour combler en fonction de la longueur choisie"><font-awesome-icon :icon="['fas', 'info-circle']" /></i>
                                <select class="form-control" v-model="element.params.alignement">
                                    <option value="">--</option>
                                    <option value="STR_PAD_LEFT">Combler le vide à gauche</option>
                                    <option value="STR_PAD_RIGHT">Combler le vide à droite</option>
                                </select>
                            </div>
                            <div class="btn btn-secondary ml-auto">
                                <font-awesome-icon :icon="['fal', 'sort-alt']" />
                            </div>
                        </div>
                    </draggable>
                </div>
            </div>
        </div>
        <div class="text-center mb-4">
            <button class="btn btn-primary rounded-pill" @click="saveData">{{ $t("global.enregistrer") }} <font-awesome-icon :icon="['fal', 'save']" /></button>
        </div>
    </div>
</template>

<script type="text/javascript">
    import draggable from 'vuedraggable'
    import Accounting from "@/mixins/Accounting.js"

    export default {
        name: "ExportConfig",
        mixins: [Accounting],
        data () {
            return {
                selected_format_numbers: "",
                export_display_debit_credit: false,
                licence: "",
                no_licence_key: false,
                search_licence: false,
                selected_enclosure: '"',
                export: {},
                selected_export: {},
                export_perso: {},
                selected_perso: [],
                export_manylines: true,
                types: [],
                selected_type: 0,
                types_perso: [],
                selected_type_perso: "new",
                perso_cle: "new",
                dates: [],
                selected_date: 0,
                separateurs: [],
                selected_sep: 0,
                export_cle: false,
                all_dispo_columns: [],
                selected_columns: [],
                label: "",
                selected_export_extension: "csv",
                export_group_article: false,
                export_group_compte: false
            }
        },
        mounted() {
            this.init_component()
        },
        methods: {
            async init_component() {
                this.export = await this.getConfig('export_config')
                for(let type in this.export){
                    this.types.push(type)
                }
            },

            async searchLicence(){
                this.export_perso = await this.getExportPerso(this.licence);
                this.search_licence = true
            },

            async loadExportPerso(){
                if(this.no_licence_key){
                    this.selected_export = this.export[this.export_cle][0][0]
                }
                else{
                    this.types_perso = this.export_perso[this.export_cle][1]
                }
                
                this.loadColumnsConfig()
            },

            async loadColumnsConfig(){
                if(this.no_licence_key){
                    this.selected_export = this.export[this.export_cle][0][0]
                    this.export_display_debit_credit = this.selected_export.export_display_debit_credit
                    this.export_group_article = this.selected_export.export_group_article
                    this.export_group_compte = this.selected_export.export_group_compte
                    this.selected_columns = JSON.parse(this.selected_export.export_colonnes).first_line
                }
                else{
                    if(this.perso_cle === "new"){
                        this.selected_export = {}
                        this.selected_columns = []
                        this.label = ""
                        this.selected_export.export_manylines = this.export[this.export_cle][-1][0].export_manylines
                        this.selected_export.export_separateur = this.export[this.export_cle][-1][0].export_separateur
                        this.selected_export.export_enclosure = this.export[this.export_cle][-1][0].export_enclosure

                        this.export_display_debit_credit = this.export[this.export_cle][-1][0].export_display_debit_credit
                        this.export_group_article = this.export[this.export_cle][-1][0].export_group_article
                        this.export_group_compte = this.export[this.export_cle][-1][0].export_group_compte
                    }
                    else{
                        let key = this.perso_cle - 1;
                        this.selected_export = this.export_perso[this.export_cle][1][key]
                        this.export_display_debit_credit = this.selected_export.export_display_debit_credit
                        this.export_group_article = this.selected_export.export_group_article
                        this.export_group_compte = this.selected_export.export_group_compte
                        

                        this.selected_columns = JSON.parse(this.selected_export.export_colonnes).first_line
                        this.label = this.selected_export.export_label
                    }
                }

                let config = JSON.parse(this.export[this.export_cle][-1][0].export_colonnes).first_line

                this.all_dispo_columns = config.filter(col => {
                    let has_column = false
                    this.selected_columns.forEach(c => {
                        if(col.colonne === c.colonne)
                            has_column = true
                    })
                    return !has_column
                })
                this.selected_columns.forEach(col => {
                    if(col.colonne === "date"){
                        this.selected_date = col.format
                    }
                    else if(col.type === "number"){
                        this.selected_format_numbers = col.format
                    }
                })
                config.forEach(col => {
                    if(col.colonne === "date"){
                        this.dates = col.format
                    }
                })

                this.selected_enclosure = this.selected_export.export_enclosure
                this.selected_sep = this.selected_export.export_separateur
                this.selected_export_extension = this.selected_export.export_extension
                this.separateurs = JSON.parse(this.export[this.export_cle][-1][0].export_separateur)
            },

            select_column(col){
                const index = this.all_dispo_columns.indexOf(col)
                this.all_dispo_columns.splice(index, 1)
                this.selected_columns.push(col)
            },

            async saveData(){
                for(let col in this.selected_columns){
                    this.selected_columns[col].order = col
                    if(this.selected_columns[col].type == "date"){
                        this.selected_columns[col].format = this.selected_date
                    }
                    else if(this.selected_columns[col].type == "number"){
                        this.selected_columns[col].format = this.selected_format_numbers
                    }
                    else
                    {
                        this.selected_columns[col].format = ""
                    }
                }


                let user = this.licence
                let many = this.selected_export.export_manylines
                if(this.no_licence_key){
                    user = ""
                }

                let params = {
                    export_manylines: many,
                    export_code: this.export_cle,
                    export_label: this.no_licence_key ? this.selected_export.export_label : this.label,
                    export_separateur: this.selected_sep,
                    export_display_debit_credit: this.export_display_debit_credit,
                    export_group_article: this.export_group_article,
                    export_group_compte: this.export_group_compte,
                    export_enclosure: this.selected_enclosure,
                    export_extension: this.selected_export_extension,
                    export_colonnes: {
                        first_line: this.selected_columns,
                        second_line: []
                    },
                    user_licence_key: user
                }

                // création d'un export
                if(!this.no_licence_key && this.perso_cle === "new"){
                    let res = await this.createExport(params);
                    if(res) this.success()
                }
                else{
                    let res = await this.updateExport(this.selected_export.export_id, params);
                    if(res) this.success()
                }
            },

            async success(){
                this.successToast("toast.info_modif_succes")
                this.licence =  ""
                this.no_licence_key = false
                this.search_licence = false
                this.selected_export = {}
                this.export_perso = {}
                this.selected_perso = []
                this.export_manylines = true
                this.selected_type = 0
                this.types_perso =  []
                this.selected_type_perso = "new"
                this.perso_cle = "new"
                this.dates =  []
                this.selected_date = 0
                this.separateurs = []
                this.selected_sep = 0
                this.export_cle =  false
                this.all_dispo_columns = []
                this.selected_columns = []
                this.selected_format_numbers = ""
                this.export_display_debit_credit = false
                this.export_group_article = false
                this.export_group_compte = false
                this.label = "",
                this.selected_export_extension = "csv"
            }
        },
        computed: {
            dragOptionsAllColumns() {
                return {
                    sort: false
                }
            },
            dragOptionsSelectedColumns() {
                return {
                    animation: 200
                }
            },
        },
        watch: {
            'selected_type' (val) {
                if(val === 0)
                    return false
                this.export_cle = val
                this.loadExportPerso()
            },

            'selected_type_perso' (val) {
                if(val === 0)
                    return false
                this.perso_cle = val
                this.loadColumnsConfig()
            }
        },
        components: {
            draggable
        }
    }


</script>