<template>
	<div>
		<h3>{{ lot.semencelot_quantieme }}</h3>
		<CustomTable
            id_table="semence_lot_emplacement"
            ref="table"
            :items="emplacements"
            :busy.sync="table_busy"
            primaryKey="emplacementcongelation_id"
            :hide_if_empty="true"
            :hrefsRoutes="config_table_hrefs"
            :columsAddBegin="['preview']"
            :externSlotColumns="['preview']"
        >
            <template v-slot:[`custom-slot-cell(preview)`]="{ data }">
                <a href="" class="" @click.prevent="setUpEmplacementDetail(data)">
                    <font-awesome-icon :icon="['fas', 'eye']" />
                </a>
            </template>
   		</CustomTable>

		<b-modal ref="modal-stock" hide-footer>
			<template v-slot:modal-title>
				{{ $t('monte.congelation.inventaire') }}
			</template>

			 <template slot="default">
			 	<div class="row">
					<div class="col-12 form-group">
						<label for="qte">{{ $t("monte.congelation.qte") }} *</label>
						<input class="form-control" type="number" v-model="qte" id="qte">
					</div>
					<div class="col-12 form-group">
						<label for="commentaire">{{ $t("monte.congelation.commentaire") }} *</label>
						<input class="form-control" type="text" v-model="commentaire" id="commentaire">
					</div>
					<div class="col-12 text-center">
			            <b-button variant="primary" @click="confirmInventaire">
			                <font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse class="mr-1"/>
			                <font-awesome-icon v-else :icon="['fal', 'save']" class="mr-1"/>{{ $t('global.sauvegarder') }}
			            </b-button>
					</div>
			 	</div>
	        </template>
		</b-modal>

		<b-modal ref="modal-destroy" hide-footer>
			<template v-slot:modal-title>
				{{ $t('monte.congelation.confirm_destroy_emplacements') }}
			</template>

			 <template slot="default">
			 	<div class="row">
					<div class="col-12 form-group">
						<label for="commentaire">{{ $t("monte.congelation.commentaire") }} *</label>
						<input class="form-control" type="text" v-model="commentaire" id="commentaire">
					</div>
					<div class="col-12 text-center">
			            <b-button variant="primary" @click="confirmDestroy">
			                <font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse class="mr-1"/>
			                <font-awesome-icon v-else :icon="['fal', 'trash-alt']" class="mr-1"/>{{ $t('action.detruire') }}
			            </b-button>
					</div>
			 	</div>
	        </template>
		</b-modal>

		<b-modal ref="modal-assign" hide-footer>
			<template v-slot:modal-title>
				{{ $t('monte.congelation.assign_emplacements') }}
			</template>

			 <template slot="default">
			 	<div class="row">
					<div class="col-12 form-group">
						<label for="tiers">{{ $t("tiers.tiers") }} *</label>
						<SearchTiers 
							id="tiers"
							:tiers_selected.sync="tiers_assigned"
						/>
					</div>
					<div class="col-12 form-group">
						<label for="date">{{ $t("monte.date") }} *</label>
						<e-datepicker id="date" v-model="date" mode="date"></e-datepicker>
					</div>
					<div class="col-12 text-center">
			            <b-button variant="primary" @click="confirmAssignEmplacement">
			                <font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse class="mr-1"/>
			                <font-awesome-icon v-else :icon="['far', 'user']" class="mr-1"/>{{ $t('action.attribuer') }}
			            </b-button>
					</div>
			 	</div>
	        </template>
		</b-modal>

		<b-modal ref="modal-split" hide-footer>
			<template v-slot:modal-title>
				{{ $t('monte.congelation.split_stock') }}
			</template>

			 <template slot="default">
			 	<div class="row">
					<div class="col-12 form-group">
						<label for="qte">{{ $t("monte.congelation.qte") }} *</label>
						<input class="form-control" type="number" v-model="qte" id="qte">
					</div>
					<div class="col-12 form-group">
						<label for="commentaire">{{ $t("monte.congelation.commentaire") }}</label>
						<input class="form-control" type="text" v-model="commentaire" id="commentaire">
					</div>
					<div class="col-12 form-group">
						<label for="date">{{ $t("monte.date") }} *</label>
						<e-datepicker id="date" v-model="date" mode="date"></e-datepicker>
					</div>
					<div class="col-12 form-group">
                   		<label for="stockage">{{ $t("monte.stock_semence.localisation") }} *</label>
						<e-select
							v-model="localisation_selected"
							id="stockage"
							track-by="full_name"
							label="full_name"
							:placeholder="$t('monte.stock_semence.rechercher_categorie')"
							:selectedLabel="$t('global.selected_label')"
							:options="all_localisations"
							:searchable="true"
							:show-labels="false"
							:allow-empty="true"
							:sortable="false"
						>
							<template slot="option" slot-scope="props"><span :style="`padding-left: ${25 * (props.option.indent || 0) }px;`">{{ props.option.full_name }}</span></template>
						</e-select>
					</div>
					<div class="col-12 text-center">
			            <b-button variant="primary" @click="confirmSplit">
			                <font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse class="mr-1"/>
			                <font-awesome-icon v-else :icon="['far', 'layer-plus']" class="mr-1"/>{{ $t('monte.congelation.split') }}
			            </b-button>
					</div>
			 	</div>
	        </template>
		</b-modal>
	</div>
</template>

<script type="text/javascript">
	import Congelation from "@/mixins/Congelation.js"
	import StockSemence from "@/mixins/StockSemence.js"
	import Navigation from "@/mixins/Navigation.js"
	import ShutterCongelation from "@/mixins/shutters-manager/Congelation.js"
	import _sortBy from "lodash/sortBy"

	export default {
		name: 'EmplacementLot',
		mixins: [Congelation, StockSemence, Navigation, ShutterCongelation],
		props: ['lot'],
		data () {
			return {
				config_table_hrefs: {
					'proprietaire.tiers_rs': {
						routeUniqueName: 'tiersFiche',
						routeName: 'tiersFiche',
						params: {
							tiers_id: 'proprietaire.tiers_id'
						}
					}
				},
				events_tab: {
					'TableAction::goToInventaireEmplacement': this.openInventaire,
					'TableAction::goToDestroyEmplacements': this.openConfirmDestroy,
					'TableAction::goToAssignEmplacements': this.openAssign,
					'TableAction::goToSplitEmplacement': this.openSplit,
					'TableAction::goToControlEmplacement': this.setUpAddControlEmplacement,
					'TableAction::goToAddEmplacement': () => {
						this.setUpAddEmplacement(this.lot)
					}
				},
				table_busy: false,
				emplacements: [],
				edit_emplacement: null,
				processing: false,
				qte: 0,
				commentaire: '',
				emplacements_selected: [],
				tiers_assigned: null,
				date: null,
				all_localisations: [],
				localisation_selected: null
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				this.table_busy = true
				this.emplacements = await this.getEmplacementsByLot(this.lot.semencelot_id)
				this.table_busy = false
			},

			reload() {
				this.shutterPanel().close('add-control-lot')
				this.shutterPanel().close('emplacement-detail')
				this.shutterPanel().close('add-emplacement')
				this.$refs.table.unselectAll()
				this.init_component()
				this.ok()
			},

			openInventaire(emplacement) {
				this.edit_emplacement = emplacement
				this.qte = emplacement.emplacementcongelation_qte
				this.commentaire = ''
				this.$refs['modal-stock'].show()
			},

			async confirmInventaire() {
				if(!this.qte) 
					return false
				this.processing = true
				await this.inventaireEmplacement(this.edit_emplacement.emplacementcongelation_id, this.edit_emplacement.emplacementcongelation_qte, this.qte, this.commentaire)
				this.processing = false
				this.$refs['modal-stock'].hide()
				this.reload()
			},

			openConfirmDestroy(emplacements) {
				this.emplacements_selected = emplacements.map(empl => empl.emplacementcongelation_id)
				this.commentaire = ''
				this.$refs['modal-destroy'].show()
			},

			async confirmDestroy() {
				this.processing = true
				const floors_ids = await this.destroyEmplacements(this.emplacements_selected, this.commentaire)
				this.processing = false
				this.$refs['modal-destroy'].hide()
				this.reload()
				if(floors_ids.length > 0) {
					this.setUpDeleteFloors(floors_ids)
				}
			},

			openAssign(emplacements) {
				this.emplacements_selected = emplacements.map(empl => empl.emplacementcongelation_id)
				this.tiers_assigned = null
				this.date = new Date()
				this.$refs['modal-assign'].show()
			},

			async confirmAssignEmplacement() {
				if(!this.tiers_assigned || !this.date) 
					return false

				this.processing = true
				await this.assignEmplacements(this.emplacements_selected, this.tiers_assigned.tiers_id, this.date)
				this.processing = false
				this.$refs['modal-assign'].hide()
				this.reload()
			},

			async openSplit(emplacement) {
				this.edit_emplacement = emplacement
				this.qte = emplacement.emplacementcongelation_qte
				this.commentaire = ''
				this.localisation_selected = null
				this.date = new Date()
				const all_localisations = await this.getLocalisationSemenceFullName()
				this.all_localisations = _sortBy(all_localisations.filter(loc => !loc.semencelocalisation_transport && loc.semencelocalisation_is_floor), ['parent_name', 'semencelocalisation_order'])
				this.$refs['modal-split'].show()
			},

			async confirmSplit() {
				if(this.qte > this.edit_emplacement.emplacementcongelation_qte || !this.localisation_selected || !this.date) 
					return false

				this.processing = true
				const floors_ids = await this.splitEmplacement(this.edit_emplacement.emplacementcongelation_id, this.qte, this.localisation_selected.semencelocalisation_id, this.date, this.commentaire)
				this.processing = false
				this.$refs['modal-split'].hide()
				this.reload()
				if(floors_ids.length > 0) {
					this.setUpDeleteFloors(floors_ids)
				}
			},

			focus_shutter() {
				this.shutterPanel().goTo('emplacement-lot')
			}
		},

		components: {
			CustomTable : () => import('GroomyRoot/components/Table/CustomTable'),
			SearchTiers : () => import('@/components/Contract/SearchTiers')
		}
	}

</script>