<template>
	<div class="row">
		<div class="col-12">
			<table class="table b-table table-striped table-hover table-borderless">
				<thead class="sticky">
					<tr>
						<th>{{ $t('monte.stock_semence.localisation') }}</th>
						<th>{{ $t('monte.congelation.supprimer') }}</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="floor in floors" :key="floor.semencelocalisation_id">
						<td>{{ floor.full_name }}</td>
						<td><b-form-checkbox v-model="floor.delete"/></td>
					</tr>
				</tbody>
			</table>
		</div>
		<div class="col-12 text-center">
            <b-button variant="primary" @click="confirm_delete">
                <font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse class="mr-1"/>
                <font-awesome-icon v-else :icon="['far', 'trash']" class="mr-1"/>{{ $t('global.supprimer') }}
            </b-button>
            <b-button variant="secondary" @click="close" class="ml-2">
            	<font-awesome-icon :icon="['far', 'times']" class="mr-1"/>{{ $t('modal.general.annuler') }}
            </b-button>
		</div>
	</div>
</template>

<script type="text/javascript">
	import Congelation from "@/mixins/Congelation.js"
	import StockSemence from "@/mixins/StockSemence.js"
	import Shutter from '@/mixins/Shutter.js'

	export default {
		name: 'DeleteFloors',
		mixins: [Congelation, StockSemence, Shutter],
		props: ['floors_ids'],
		data () {
			return {
				floors: [],
				processing: false
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				const all_localisations = await this.getLocalisationSemenceFullName()
				this.floors = all_localisations.filter(loc => this.floors_ids.includes(loc.semencelocalisation_id)).map(loc => ({...loc, delete: true}))
			},

			close() {
				this.ok()
				this.shutterPanel().close('delete-floors')
			},

			async confirm_delete() {
				this.processing = true

				const floors_delete = this.floors.filter(floor => floor.delete).map(floor => floor.semencelocalisation_id)
				if(floors_delete.length > 0) {
					await this.deleteFloors(floors_delete)
				}

				this.processing = false
				this.successToast('toast.info_save_succes')
				this.close()
			}
		}
	}

</script>