<template>
	<div class="row">
		<div class="col-12">
			<b-alert v-if="!has_campagne" variant="warning" show>
				{{ $t('monte.congelation.no_campagne') }}
			</b-alert>
		</div>

		<div class="col-12 form-group">
       		<label for="lot">{{ $t("monte.congelation.lot") }} *</label>
			<e-select
				v-model="lot_selected"
				id="lot"
				track-by="semencelot_id"
				label="semencelot_quantieme"
				:placeholder="$t('monte.congelation.selectionner_lot')"
				:selectedLabel="$t('global.selected_label')"
				:options="lots"
				:searchable="true"
				:show-labels="false"
				:allow-empty="true"
				:sortable="false"
			/>
		</div>
		<div v-if="lot_selected && lot_selected.semencelot_id == 0" class="col-6 form-group">
			<label for="date_lot">{{ $t("monte.congelation.date_lot") }} *</label>
			<e-datepicker id="date_lot" v-model="lot.semencelot_date" mode="date"></e-datepicker>
		</div>
		<div v-if="lot_selected && lot_selected.semencelot_id == 0" class="col-6 form-group">
			<label for="quantieme">{{ $t("monte.congelation.semencelot_quantieme") }} *</label>
			<input class="form-control" type="text" v-model="lot.semencelot_quantieme" id="quantieme">
		</div>
		<div class="col-6 form-group">
			<label for="tiers">{{ $t("tiers.tiers") }}</label>
			<SearchTiers 
				id="tiers"
				:tiers_selected.sync="tiers"
			/>
		</div>
		<div class="col-6 form-group">
			<label for="date">{{ $t("monte.date") }} *</label>
			<e-datepicker id="date" v-model="date" mode="date"></e-datepicker>
		</div>
		<div class="col-6 form-group">
       		<label for="stockage">{{ $t("monte.stock_semence.localisation") }} *</label>
			<e-select
				v-model="localisation_selected"
				id="stockage"
				track-by="full_name"
				label="full_name"
				:placeholder="$t('monte.stock_semence.rechercher_categorie')"
				:selectedLabel="$t('global.selected_label')"
				:options="all_localisations"
				:searchable="true"
				:show-labels="false"
				:allow-empty="true"
				:sortable="false"
			>
				<template slot="option" slot-scope="props"><span :style="`padding-left: ${25 * (props.option.indent || 0) }px;`">{{ props.option.full_name }}</span></template>
			</e-select>
		</div>
		<div class="col-6 form-group">
			<label for="qte">{{ $t("monte.congelation.qte") }} *</label>
			<input class="form-control" type="number" v-model="qte" id="qte">
		</div>
		<div class="col-6 form-group">
			<label for="commentaire">{{ $t("monte.congelation.commentaire") }}</label>
			<input class="form-control" type="text" v-model="commentaire" id="commentaire">
		</div>
		<div class="col-6 form-group">
			<label for="norme_lock">{{ $t("monte.congelation.norme_lock") }}</label>
			<b-form-checkbox v-model="norme_lock" id="norme_lock" />
		</div>
		<div class="col-12 text-center">
			<a class="btn btn-primary mt-3" href="#" @click.prevent="checkForm">{{ $t('global.ajouter') }} <font-awesome-icon v-if="processing" :icon="['fal', 'spinner']" pulse /><font-awesome-icon v-else :icon="['fal', 'save']" /></a>
		</div>
	</div>
</template>

<script type="text/javascript">
	import Congelation from "@/mixins/Congelation.js"
	import StockSemence from "@/mixins/StockSemence.js"
	import Shutter from '@/mixins/Shutter.js'

	export default {
		name: 'ImportPaillettes',
		mixins: [Congelation, StockSemence, Shutter],
		props: ['horse_id'],
		data () {
			return {
				tiers: null,
				date: new Date(),
				localisation_selected: null,
				all_localisations: [],
				qte: 1,
				commentaire: '',
				norme_lock: true,
				lots: [],
				lot_selected: null,
				lot: {
					semencelot_date: new Date(),
					semencelot_quantieme: ''
				},
				processing: false,
				has_campagne: true
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				this.lots = await this.getLotsByStallion(this.horse_id)
				this.lots.push({
					semencelot_id: 0,
					semencelot_quantieme: this.getTrad('monte.congelation.ajouter_lot')
				})
				const all_localisations = await this.getLocalisationSemenceFullName()
				this.all_localisations = all_localisations.filter(loc => !loc.semencelocalisation_transport && loc.semencelocalisation_is_floor)
			},

			async checkForm() {
				if(!this.lot_selected || !this.date || !this.qte || !this.localisation_selected) {
					return false
				}
				this.processing = true
				let lot_id = this.lot_selected.semencelot_id
				if(lot_id == 0) {
					this.lot.semencelot_qte = this.qte
					const lot = await this.createLotByStallion(this.horse_id, this.lot)
					lot_id = lot.semencelot_id
				}
				const tiers_id = this.tiers ? this.tiers.tiers_id : null
				await this.addEmplacement(lot_id, this.qte, this.commentaire, null, this.localisation_selected.semencelocalisation_id, this.date, this.norme_lock, tiers_id)
				this.processing = false
				this.ok()
				this.shutterPanel().close('import-paillettes')
			}
		},

		watch: {
			'lot.semencelot_date'(val) {
				if(val) {
					this.checkCampagne(this.horse_id, val.toDateInputValue()).then(res => {
						this.has_campagne = res
					})
				}
			},
			'lot_selected.semencelot_id'(val) {
				if(val == 0) {
					this.checkCampagne(this.horse_id, this.lot.semencelot_date.toDateInputValue()).then(res => {
						this.has_campagne = res
					})
				}
				else {
					this.has_campagne = true
				}
			}
		},

		components: {
			SearchTiers : () => import('@/components/Contract/SearchTiers')
		}
	}

</script>