export default {
   "horse":{
      0:[
         {
            "name":"ajouter",
            "action":"goToAddHorse",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"importer",
            "action":"goToImportHorses",
            "spin":false,
            "icon":["fal","upload"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"ajouter_acte",
            "action":"goToAddActe",
            "spin":false,
            "icon":["far","tasks"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"ajouter_ordonnance",
            "action":"goToAddOrdonnance",
            "spin":false,
            "icon":["far","file-medical-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"ajouter_note",
            "action":"goToAddHorseNote",
            "spin":false,
            "icon":["far","sticky-note"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"print_infos_horse",
            "action":"goToPrintInfosHorse",
            "spin":false,
            "icon":["far","file-pdf"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"manage_categories",
            "action":"goToManageCategories",
            "spin":false,
            "icon":["fal","tools"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"ajouter_mouvement_entree",
            "action":"goToAddMouvementEntree",
            "spin":false,
            "icon":["fal","door-open"],
            "className":"btn-secondary text-dark text-left",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":"ajouter_mouvement"
         },
         {
            "name":"ajouter_mouvement_sortie",
            "action":"goToAddMouvementSortie",
            "spin":false,
            "icon":["fal","sign-out-alt"],
            "className":"btn-secondary text-dark text-left",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":"ajouter_mouvement"
         },
         {
            "name":"ajouter_mouvement_interne",
            "action":"goToAddMouvementInterne",
            "spin":false,
            "icon":["fal","farm"],
            "className":"btn-secondary text-dark text-left",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":"ajouter_mouvement"
         },
         {
            "name":"add_pension",
            "action":"goToAddPension",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"manage_qualifs",
            "action":"goToManageQualifsHorse",
            "spin":false,
            "icon":["fal","tools"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name": "archive_horse",
            "action": "goToArchiveHorse",
            "spin": false,
            "icon": ["far", "trash-alt"],
            "className": "btn-secondary text-dark text-left",
            "modal_options": {
               "text_title": "horse.archivage",
               "text_question": "horse.confirmation_archivage",
               "text_message": "horse.message_archivage"
            },
            "condition": null,
            "rights": "MVP",
            "dropdown_name": "supprimer"
         },
         {
            "name":"supprimer",
            "action":"goToDeleteHorses",
            "spin":false,
            "icon":["far","trash-alt"],
            "className":"btn-secondary text-dark text-left",
            "modal_options":{
               "text_title":"horse.suppression",
               "text_question":"horse.confirmation_suppression",
               "text_message":"general.avertissement_suppression"
            },
            "condition":null,
            "rights":"MVP",
            "dropdown_name":"supprimer"
         },
      ],
      2:[
         {
            "name":"ajouter_acte",
            "action":"goToAddActe",
            "spin":false,
            "icon":["far","tasks"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"ajouter_mouvement_entree",
            "action":"goToAddMouvementEntree",
            "spin":false,
            "icon":["fal","door-open"],
            "className":"btn-secondary text-dark text-left",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":"ajouter_mouvement"
         },
         {
            "name":"ajouter_mouvement_sortie",
            "action":"goToAddMouvementSortie",
            "spin":false,
            "icon":["fal","sign-out-alt"],
            "className":"btn-secondary text-dark text-left",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":"ajouter_mouvement"
         },
         {
            "name":"ajouter_mouvement_interne",
            "action":"goToAddMouvementInterne",
            "spin":false,
            "icon":["fal","farm"],
            "className":"btn-secondary text-dark text-left",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":"ajouter_mouvement"
         },
         {
            "name":"ajouter_ordonnance",
            "action":"goToAddOrdonnance",
            "spin":false,
            "icon":["far","file-medical-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"print_infos_horse",
            "action":"goToPrintInfosHorse",
            "spin":false,
            "icon":["far","file-pdf"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"manage_categories",
            "action":"goToManageCategories",
            "spin":false,
            "icon":["fal","tools"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"add_pension",
            "action":"goToAddPension",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"manage_qualifs",
            "action":"goToManageQualifsHorse",
            "spin":false,
            "icon":["fal","tools"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"supprimer",
            "action":"goToDeleteHorses",
            "spin":false,
            "icon":["far","trash-alt"],
            "className":"btn-secondary text-dark text-left",
            "modal_options":{
               "text_title":"horse.suppression",
               "text_question":"horse.confirmation_suppression",
               "text_message":"general.avertissement_suppression"
            },
            "condition":null,
            "rights":"MVP",
            "dropdown_name":"supprimer"
         },
         {
            "name": "archive_horse",
            "action": "goToArchiveHorse",
            "spin": false,
            "icon": ["far", "trash-alt"],
            "className": "btn-secondary text-dark text-left",
            "modal_options": {
               "text_title": "horse.archivage",
               "text_question": "horse.confirmation_archivage",
               "text_message": "horse.message_archivage"
            },
            "condition": null,
            "rights": "MVP",
            "dropdown_name":"supprimer"
         }
      ]
   },
   "horse_archive":{
      0:[
         {
            "name":"",
            "action":"",
            "spin":false,
            "icon":[],
            "className":"d-none",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"unarchive_horse",
            "action":"goToUnArchiveHorse",
            "spin":false,
            "icon":["far", "trash-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"supprimer",
            "action":"goToDeleteHorses",
            "spin":false,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":{
               "text_title":"horse.suppression",
               "text_question":"horse.confirmation_suppression",
               "text_message":"general.avertissement_suppression"
            },
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"unarchive_horse",
            "action":"goToUnArchiveHorse",
            "spin":false,
            "icon":["far", "trash-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"supprimer",
            "action":"goToDeleteHorses",
            "spin":false,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":{
               "text_title":"horse.suppression",
               "text_question":"horse.confirmation_suppression",
               "text_message":"general.avertissement_suppression"
            },
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "articles_courses":{
      0:[
         {
            "name":"",
            "action":"",
            "spin":false,
            "icon":[],
            "className":"d-none",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"valider",
            "action":"goToValideArticleCourse",
            "spin":false,
            "icon":["far","check"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"abandonner",
            "action":"goToAbandonFacturationAValider",
            "spin":true,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"valider",
            "action":"goToValideArticleCourse",
            "spin":false,
            "icon":["far","check"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"abandonner",
            "action":"goToAbandonFacturationAValider",
            "spin":true,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "mouvements":{
      0:[
         {
            "name":"ajouter",
            "action":"goToAddMouvement",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"modifier",
            "action":"goToEditMouvement",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"supprimer",
            "action":"goToDeleteMouvement",
            "spin":false,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":{
               "text_title":"mouvement.suppression",
               "text_question":"mouvement.confirmation_suppression",
               "text_message":"general.avertissement_suppression"
            },
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "mouvements_horse":{
      0:[
         {
            "name":"ajouter",
            "action":"goToAddMouvement",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"modifier",
            "action":"goToEditMouvement",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"supprimer",
            "action":"goToDeleteMouvement",
            "spin":false,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":{
               "text_title":"mouvement.suppression",
               "text_question":"mouvement.confirmation_suppression",
               "text_message":"general.avertissement_suppression"
            },
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "horse_planning":{
      0:[
         {
            "name":"ajouter",
            "action":"goToAddActe",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"ajouter_mouvement_interne",
            "action":"goToAddMouvementInternePlanning",
            "spin":false,
            "icon":["fal","farm"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":[
               'isInLocalisationResidence'
            ],
            "rights":"MVP",
            "dropdown_name":null
         },
      ],
      1:[
         {
            "name":"modifier",
            "action":"goToEditActe",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"replanifier",
            "action":"goToReplanActe",
            "spin":false,
            "icon":["far","calendar-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"valider",
            "action":"goToValidActe",
            "spin":false,
            "icon":["far","check"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"supprimer",
            "action":"goToDeleteActe",
            "spin":false,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":{
               "text_title":"acte.suppression",
               "text_question":"acte.confirmation_suppression",
               "text_message":"general.avertissement_suppression"
            },
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"ajouter_note",
            "action":"goToAddActNote",
            "spin":false,
            "icon":["far","sticky-note"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"analyse",
            "action":"goToAnalyse",
            "spin":false,
            "icon":["fal","file-medical"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"dupliquer",
            "action":"goToDupliqueActe",
            "spin":false,
            "icon":["far","clone"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"print_acts",
            "action":"goToPrintActs",
            "spin":false,
            "icon":["far","file-pdf"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"modifier",
            "action":"goToEditActe",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"replanifier",
            "action":"goToReplanActe",
            "spin":false,
            "icon":["far","calendar-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"valider",
            "action":"goToValidActe",
            "spin":false,
            "icon":["far","check"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"supprimer",
            "action":"goToDeleteActe",
            "spin":false,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":{
               "text_title":"acte.suppression",
               "text_question":"acte.confirmation_suppression",
               "text_message":"general.avertissement_suppression"
            },
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"modifier_lot",
            "action":"goToEditActeLot",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":[
               "needSameActe"
            ],
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"analyse",
            "action":"goToAnalyse",
            "spin":false,
            "icon":["fal","file-medical"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"print_acts",
            "action":"goToPrintActs",
            "spin":false,
            "icon":["far","file-pdf"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "horse_all_actes":{
      0:[
         {
            "name":"ajouter",
            "action":"goToAddActe",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"modifier",
            "action":"goToEditActe",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"supprimer",
            "action":"goToDeleteActe",
            "spin":false,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":{
               "text_title":"acte.suppression",
               "text_question":"acte.confirmation_suppression",
               "text_message":"general.avertissement_suppression"
            },
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"replanifier",
            "action":"goToReplanActeFromHorse",
            "spin":false,
            "icon":["far","calendar-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":["actsNotValidated"],
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"valider",
            "action":"goToValidActeFromHorse",
            "spin":false,
            "icon":["far","check"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":["actsNotValidated"],
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"ajouter_note",
            "action":"goToAddActNote",
            "spin":false,
            "icon":["far","sticky-note"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"analyse",
            "action":"goToAnalyse",
            "spin":false,
            "icon":["fal","file-medical"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"dupliquer",
            "action":"goToDupliqueActe",
            "spin":false,
            "icon":["far","clone"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"ajouter_ordonnance",
            "action":"goToAddOrdonnanceFromAct",
            "spin":false,
            "icon":["far","file-medical-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"print_acts",
            "action":"goToPrintActs",
            "spin":false,
            "icon":["far","file-pdf"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"modifier",
            "action":"goToEditActe",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"supprimer",
            "action":"goToDeleteActe",
            "spin":false,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":{
               "text_title":"acte.suppression",
               "text_question":"acte.confirmation_suppression",
               "text_message":"general.avertissement_suppression"
            },
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"replanifier",
            "action":"goToReplanActeFromHorse",
            "spin":false,
            "icon":["far","calendar-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":["actsNotValidated"],
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"valider",
            "action":"goToValidActeFromHorse",
            "spin":false,
            "icon":["far","check"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":["actsNotValidated"],
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"modifier_lot",
            "action":"goToEditActeLot",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":[
               "needSameActe"
            ],
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"analyse",
            "action":"goToAnalyse",
            "spin":false,
            "icon":["fal","file-medical"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"ajouter_ordonnance",
            "action":"goToAddOrdonnanceFromAct",
            "spin":false,
            "icon":["far","file-medical-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"print_acts",
            "action":"goToPrintActs",
            "spin":false,
            "icon":["far","file-pdf"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "horse_actes":{
      0:[
         {
            "name":"ajouter",
            "action":"goToAddActe",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"modifier",
            "action":"goToEditActe",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"supprimer",
            "action":"goToDeleteActe",
            "spin":false,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":{
               "text_title":"acte.suppression",
               "text_question":"acte.confirmation_suppression",
               "text_message":"general.avertissement_suppression"
            },
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"ajouter_note",
            "action":"goToAddActNote",
            "spin":false,
            "icon":["far","sticky-note"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"analyse",
            "action":"goToAnalyse",
            "spin":false,
            "icon":["fal","file-medical"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"dupliquer",
            "action":"goToDupliqueActe",
            "spin":false,
            "icon":["far","clone"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"ajouter_ordonnance",
            "action":"goToAddOrdonnanceFromAct",
            "spin":false,
            "icon":["far","file-medical-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"modifier",
            "action":"goToEditActe",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"supprimer",
            "action":"goToDeleteActe",
            "spin":false,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":{
               "text_title":"acte.suppression",
               "text_question":"acte.confirmation_suppression",
               "text_message":"general.avertissement_suppression"
            },
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"modifier_lot",
            "action":"goToEditActeLot",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":[
               "needSameActe"
            ],
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"analyse",
            "action":"goToAnalyse",
            "spin":false,
            "icon":["fal","file-medical"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"ajouter_ordonnance",
            "action":"goToAddOrdonnanceFromAct",
            "spin":false,
            "icon":["far","file-medical-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "liste_actes":{
      0:[
         {
            "name":"ajouter",
            "action":"goToAddActe",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"modifier",
            "action":"goToEditActe",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"supprimer",
            "action":"goToDeleteActe",
            "spin":false,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":{
               "text_title":"acte.suppression",
               "text_question":"acte.confirmation_suppression",
               "text_message":"general.avertissement_suppression"
            },
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"ajouter_note",
            "action":"goToAddActNote",
            "spin":false,
            "icon":["far","sticky-note"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"analyse",
            "action":"goToAnalyse",
            "spin":false,
            "icon":["fal","file-medical"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"dupliquer",
            "action":"goToDupliqueActe",
            "spin":false,
            "icon":["far","clone"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"ajouter_ordonnance",
            "action":"goToAddOrdonnanceFromAct",
            "spin":false,
            "icon":["far","file-medical-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"print_acts",
            "action":"goToPrintActs",
            "spin":false,
            "icon":["far","file-pdf"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"modifier",
            "action":"goToEditActe",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"supprimer",
            "action":"goToDeleteActe",
            "spin":false,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":{
               "text_title":"acte.suppression",
               "text_question":"acte.confirmation_suppression",
               "text_message":"general.avertissement_suppression"
            },
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"modifier_lot",
            "action":"goToEditActeLot",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":[
               "needSameActe"
            ],
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"analyse",
            "action":"goToAnalyse",
            "spin":false,
            "icon":["fal","file-medical"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"ajouter_ordonnance",
            "action":"goToAddOrdonnanceFromAct",
            "spin":false,
            "icon":["far","file-medical-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"print_acts",
            "action":"goToPrintActs",
            "spin":false,
            "icon":["far","file-pdf"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "planning":{
      0:[
         {
            "name":"ajouter",
            "action":"goToAddActe",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"modifier",
            "action":"goToEditActe",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"supprimer",
            "action":"goToDeleteActe",
            "spin":false,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":{
               "text_title":"acte.suppression",
               "text_question":"acte.confirmation_suppression",
               "text_message":"general.avertissement_suppression"
            },
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"replanifier",
            "action":"goToReplanActe",
            "spin":false,
            "icon":["far","calendar-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"valider",
            "action":"goToValidActe",
            "spin":false,
            "icon":["far","check"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"ajouter_note",
            "action":"goToAddActNote",
            "spin":false,
            "icon":["far","sticky-note"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"analyse",
            "action":"goToAnalyse",
            "spin":false,
            "icon":["fal","file-medical"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"dupliquer",
            "action":"goToDupliqueActe",
            "spin":false,
            "icon":["far","clone"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"print_acts",
            "action":"goToPrintActs",
            "spin":false,
            "icon":["far","file-pdf"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"modifier",
            "action":"goToEditActe",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"supprimer",
            "action":"goToDeleteActe",
            "spin":false,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":{
               "text_title":"acte.suppression",
               "text_question":"acte.confirmation_suppression",
               "text_message":"general.avertissement_suppression"
            },
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"replanifier",
            "action":"goToReplanActe",
            "spin":false,
            "icon":["far","calendar-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"valider",
            "action":"goToValidActe",
            "spin":false,
            "icon":["far","check"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"analyse",
            "action":"goToAnalyse",
            "spin":false,
            "icon":["fal","file-medical"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"modifier_lot",
            "action":"goToEditActeLot",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":[
               "needSameActe"
            ],
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"print_acts",
            "action":"goToPrintActs",
            "spin":false,
            "icon":["far","file-pdf"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "acte_type":{
      0:[
         {
            "name":"ajouter",
            "action":"goToAddActeType",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"modifier",
            "action":"goToEditActeType",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"supprimer",
            "action":"goToDeleteActesTypes",
            "spin":true,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":{
               "text_title":"actetype.suppression",
               "text_question":"actetype.confirmation_suppression",
               "text_message":"general.avertissement_suppression"
            },
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"choisir_operateur",
            "action":"goToEditOperateurActeType",
            "spin":false,
            "icon":["far","user"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"edit_maxdelay",
            "action":"goToEditMaxdelay",
            "spin":false,
            "icon":["far","cogs"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"supprimer",
            "action":"goToDeleteActesTypes",
            "spin":true,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":{
               "text_title":"actetype.suppression",
               "text_question":"actetype.confirmation_suppression",
               "text_message":"general.avertissement_suppression"
            },
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"edit_maxdelay",
            "action":"goToEditMaxdelay",
            "spin":false,
            "icon":["far","cogs"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "ordonnances":{
      0:[
         {
            "name":"ajouter",
            "action":"goToAddOrdonnance",
            "spin":false,
            "icon":["far","file-medical-alt"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"modifier",
            "action":"goToEditOrdonnance",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"voir_document_ordonnance",
            "action":"goToViewDocumentOrdonnance",
            "spin":true,
            "icon":["far","file-medical-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"supprimer",
            "action":"goToDeleteDocumentOrdonnance",
            "spin":false,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":{
               "text_title":"ordonnance.suppression",
               "text_question":"ordonnance.confirmation_suppression",
               "text_message":"general.avertissement_suppression"
            },
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"supprimer",
            "action":"goToDeleteDocumentOrdonnance",
            "spin":false,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":{
               "text_title":"ordonnance.suppression",
               "text_question":"ordonnance.confirmation_suppression",
               "text_message":"general.avertissement_suppression"
            },
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "horse_ordonnances":{
      0:[
         {
            "name":"ajouter",
            "action":"goToAddOrdonnance",
            "spin":false,
            "icon":["far","file-medical-alt"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"modifier",
            "action":"goToEditOrdonnance",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"voir_document_ordonnance",
            "action":"goToViewDocumentOrdonnance",
            "spin":true,
            "icon":["far","file-medical-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"supprimer",
            "action":"goToDeleteDocumentOrdonnance",
            "spin":false,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":{
               "text_title":"ordonnance.suppression",
               "text_question":"ordonnance.confirmation_suppression",
               "text_message":"general.avertissement_suppression"
            },
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"supprimer",
            "action":"goToDeleteDocumentOrdonnance",
            "spin":false,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":{
               "text_title":"ordonnance.suppression",
               "text_question":"ordonnance.confirmation_suppression",
               "text_message":"general.avertissement_suppression"
            },
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "articles":{
      0:[
         {
            "name":"ajouter",
            "action":"goToAddArticle",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"importer",
            "action":"goToImportArticles",
            "spin":false,
            "icon":["fal","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"modifier",
            "action":"goToEditArticle",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"supprimer",
            "action":"goToDeleteArticle",
            "spin":false,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":{
               "text_title":"article.suppression",
               "text_question":"article.confirmation_suppression",
               "text_message":"general.avertissement_suppression"
            },
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"modifier_tva",
            "action":"goToEditArticleTva",
            "spin":false,
            "icon":["far","percent"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"modifier_compte_comptable",
            "action":"goToEditArticleCompteComptable",
            "spin":false,
            "icon":["far","percent"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"supprimer",
            "action":"goToDeleteArticle",
            "spin":false,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":{
               "text_title":"article.suppression",
               "text_question":"article.confirmation_suppression",
               "text_message":"general.avertissement_suppression"
            },
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"modifier_tva",
            "action":"goToEditArticleTva",
            "spin":false,
            "icon":["far","percent"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"modifier_compte_comptable",
            "action":"goToEditArticleCompteComptable",
            "spin":false,
            "icon":["far","percent"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "tiers":{
      0:[
         {
            "name":"ajouter",
            "action":"goToAddTiers",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"btn_export_csv",
            "action":"goToExportTiers",
            "spin":true,
            "icon":["fal","download"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"importer",
            "action":"goToImportTiers",
            "spin":false,
            "icon":["fal","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"modifier",
            "action":"goToEditTiers",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"print_infos_tiers",
            "action":"goToPrintInfosTiers",
            "spin":false,
            "icon":["far","file-pdf"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"manage_qualifs",
            "action":"goToManageQualifsTiers",
            "spin":false,
            "icon":["fal","tools"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"envoyer",
            "action":"goToSendMailTiers",
            "spin":false,
            "icon":["far","paper-plane"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"archive_tiers",
            "action":"goToArchiveTiers",
            "spin":false,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"extrait_compte",
            "action":"goToPrintExtraitCompte",
            "spin":false,
            "icon":["far","list-ul"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"print_infos_tiers",
            "action":"goToPrintInfosTiers",
            "spin":false,
            "icon":["far","file-pdf"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"manage_qualifs",
            "action":"goToManageQualifsTiers",
            "spin":false,
            "icon":["fal","tools"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"envoyer",
            "action":"goToSendMailTiers",
            "spin":false,
            "icon":["far","paper-plane"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"archive_tiers",
            "action":"goToArchiveTiers",
            "spin":false,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"extrait_compte",
            "action":"goToPrintExtraitCompte",
            "spin":false,
            "icon":["far","list-ul"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "accounting":{
      0:[
         {
            "name":"ajouter",
            "action":"goToAddComptabilite",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"modifier",
            "action":"goToEditComptabilite",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"regenerer_compte_client",
            "action":"goToRegenerateClientAccount",
            "spin":true,
            "icon":["far","repeat-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"regenerer_compte_client_fournisseur",
            "action":"goToRegenerateClientAccountSupplier",
            "spin":true,
            "icon":["far","repeat-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition": [
               "checkIfAccountingAccountSupplier"
            ],
            "rights":"FOUROCR",
            "dropdown_name":null
         },
         {
            "name":"dupliquer",
            "action":"goToDuplicateAccountingPlan",
            "spin":false,
            "icon":["far","clone"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"archive",
            "action":"goToArchiveAccounting",
            "spin":false,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"archive",
            "action":"goToArchiveAccounting",
            "spin":false,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "accounting_account":{
      0:[
         {
            "name":"ajouter",
            "action":"goToAddAccountingAccount",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"importer",
            "action":"goToImportAccountingAccount",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"modifier",
            "action":"goToEditAccountingAccount",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "bons":{
      0:[
         {
            "name":"ligne",
            "action":"goToAddInvoiceDetail",
            "spin":false,
            "icon":["far","layer-plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"modifier",
            "action":"goToAddInvoiceDetail",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"annuler",
            "action":"goToCancelInvoiceDetail",
            "spin":false,
            "icon":["far","history"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"previsualiser",
            "action":"goToPreviewInvoice",
            "spin":true,
            "icon":["far","file-pdf"],
            "className":"btn-secondary text-dark text-left",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":"previsualiser"
         },
         {
            "name":"previsualiser_avec_modele",
            "action":"goToPreviewInvoiceWithModel",
            "spin":true,
            "icon":["far","file-pdf"],
            "className":"btn-secondary text-dark text-left",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":"previsualiser"
         },
         {
            "name":"facturer",
            "action":"goToAddInvoice",
            "spin":false,
            "icon":["far","file-invoice"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"copy",
            "action":"goToCopyInvoiceDetail",
            "spin":false,
            "icon":["far","copy"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"recalcul_tva",
            "action":"goToRecheckVat",
            "spin":false,
            "icon":["far","file-invoice"],
            "className":"btn-secondary",
            "modal_options":{
               "text_title":"invoice.bons.recalcul_tva",
               "text_question":"invoice.bons.confirmation_recalcul",
               "text_message":"invoice.bons.avertissement_recalcul",
               "text_button_cancel":"invoice.bons.recalcul_button"
            },
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"edit_ht",
            "action":"goToEditHT",
            "spin":false,
            "icon":["fal","tags"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"annuler",
            "action":"goToCancelInvoiceDetail",
            "spin":false,
            "icon":["far","history"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"previsualiser",
            "action":"goToPreviewInvoice",
            "spin":true,
            "icon":["far","file-pdf"],
            "className":"btn-secondary text-dark text-left",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":"previsualiser"
         },
         {
            "name":"previsualiser_avec_modele",
            "action":"goToPreviewInvoiceWithModel",
            "spin":true,
            "icon":["far","file-pdf"],
            "className":"btn-secondary text-dark text-left",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":"previsualiser"
         },
         {
            "name":"facturer",
            "action":"goToAddInvoice",
            "spin":false,
            "icon":["far","file-invoice"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"recalcul_tva",
            "action":"goToRecheckVat",
            "spin":false,
            "icon":["far","file-invoice"],
            "className":"btn-secondary",
            "modal_options":{
               "text_title":"invoice.bons.recalcul_tva",
               "text_question":"invoice.bons.confirmation_recalcul",
               "text_message":"invoice.bons.avertissement_recalcul",
               "text_button_cancel":"invoice.bons.recalcul_button"
            },
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"reorganiser",
            "action":"goToReorganizeInvoiceDetails",
            "spin":false,
            "icon":["far","sort"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"change_tiers",
            "action":"goToChangeTiersBaf",
            "spin":false,
            "icon":["far","random"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"edit_ht",
            "action":"goToEditHT",
            "spin":false,
            "icon":["fal","tags"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "bonsv2":{
      1:[
         {
            "name":"modifier",
            "action":"goToAddInvoiceDetail",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"annuler",
            "action":"goToCancelInvoiceDetail",
            "spin":false,
            "icon":["far","history"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"previsualiser",
            "action":"goToPreviewInvoice",
            "spin":true,
            "icon":["far","file-pdf"],
            "className":"btn-secondary text-dark text-left",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":"previsualiser"
         },
         {
            "name":"previsualiser_avec_modele",
            "action":"goToPreviewInvoiceWithModel",
            "spin":true,
            "icon":["far","file-pdf"],
            "className":"btn-secondary text-dark text-left",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":"previsualiser"
         },
         {
            "name":"facturer",
            "action":"goToAddInvoice",
            "spin":false,
            "icon":["far","file-invoice"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"copy",
            "action":"goToCopyInvoiceDetail",
            "spin":false,
            "icon":["far","copy"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"recalcul_tva",
            "action":"goToRecheckVat",
            "spin":false,
            "icon":["far","file-invoice"],
            "className":"btn-secondary",
            "modal_options":{
               "text_title":"invoice.bons.recalcul_tva",
               "text_question":"invoice.bons.confirmation_recalcul",
               "text_message":"invoice.bons.avertissement_recalcul",
               "text_button_cancel":"invoice.bons.recalcul_button"
            },
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"annuler",
            "action":"goToCancelInvoiceDetail",
            "spin":false,
            "icon":["far","history"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"previsualiser",
            "action":"goToPreviewInvoice",
            "spin":true,
            "icon":["far","file-pdf"],
            "className":"btn-secondary text-dark text-left",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":"previsualiser"
         },
         {
            "name":"previsualiser_avec_modele",
            "action":"goToPreviewInvoiceWithModel",
            "spin":true,
            "icon":["far","file-pdf"],
            "className":"btn-secondary text-dark text-left",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":"previsualiser"
         },
         {
            "name":"facturer",
            "action":"goToAddInvoice",
            "spin":false,
            "icon":["far","file-invoice"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"recalcul_tva",
            "action":"goToRecheckVat",
            "spin":false,
            "icon":["far","file-invoice"],
            "className":"btn-secondary",
            "modal_options":{
               "text_title":"invoice.bons.recalcul_tva",
               "text_question":"invoice.bons.confirmation_recalcul",
               "text_message":"invoice.bons.avertissement_recalcul",
               "text_button_cancel":"invoice.bons.recalcul_button"
            },
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"reorganiser",
            "action":"goToReorganizeInvoiceDetails",
            "spin":false,
            "icon":["far","sort"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"change_tiers",
            "action":"goToChangeTiersBaf",
            "spin":false,
            "icon":["far","random"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "actes_type_articles":{
      0:[
         {
            "name":"",
            "action":"",
            "spin":false,
            "icon":[],
            "className":"d-none",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"modifier",
            "action":"goToEditActeTypeArticle",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "carnet_adresse":{
      0:[
         {
            "name":"ajouter",
            "action":"goToAddLieu",
            "spin":false,
            "icon":["fal","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"choisir_lieu",
            "action":"goToSelectLieu",
            "spin":false,
            "icon":["fal","check"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"modifier",
            "action":"goToEditLieu",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"supprimer",
            "action":"goToDeleteLieu",
            "spin":false,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":{
               "text_title":"lieu.confirm_suppression",
               "text_question":"lieu.question_suppression_lieu",
               "text_message":"general.avertissement_suppression"
            },
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"archive",
            "action":"goToArchiveLieu",
            "spin":false,
            "icon":["far","archive"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"set_default",
            "action":"goToSetDefaultLieu",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"supprimer",
            "action":"goToDeleteLieu",
            "spin":false,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":{
               "text_title":"lieu.confirm_suppression",
               "text_question":"lieu.question_suppression_lieu",
               "text_message":"general.avertissement_suppression"
            },
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"archive",
            "action":"goToArchiveLieu",
            "spin":false,
            "icon":["far","archive"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "tiers_horse_horse":{
      0:[
         {
            "name":"ajouter",
            "action":"goToAddTiersHorseHorse",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"modifier",
            "action":"goToEditTiersHorseHorse",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"fiche_depart",
            "action":"goToPdfTiersHorseHorse",
            "spin":false,
            "icon":["far","file-pdf"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"ajouter_tiers_secondaire",
            "action":"goToAddTiersHorseSecondaires",
            "spin":false,
            "icon":["fal","user-tie"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"transferer_autre_tiers",
            "action":"goToTransferHorsesOtherTiers",
            "spin":false,
            "icon":["far","exchange-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"ajout_contrat",
            "action":"goToAddContract",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":["IsMare"],
            "rights":"MVP",
            "dropdown_name":null
         }, 
         {
            "name":"add_pension",
            "action":"goToAddPensionFromTiers",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
      ],
      2:[
         {
            "name":"fiche_depart",
            "action":"goToPdfTiersHorseHorse",
            "spin":false,
            "icon":["far","file-pdf"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"ajouter_tiers_secondaire",
            "action":"goToAddTiersHorseSecondaires",
            "spin":false,
            "icon":["fal","user-tie"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"transferer_autre_tiers",
            "action":"goToTransferHorsesOtherTiers",
            "spin":false,
            "icon":["far","exchange-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"add_pension",
            "action":"goToAddPensionFromTiers",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
      ]
   },
   "tiers_horse_online":{
      0:[
         {
            "name":"ajouter",
            "action":"goToAddTiersHorseHorse",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"modifier",
            "action":"goToEditTiersHorseHorse",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"fiche_depart",
            "action":"goToPdfTiersHorseHorse",
            "spin":false,
            "icon":["far","file-pdf"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"ajouter_tiers_secondaire",
            "action":"goToAddTiersHorseSecondaires",
            "spin":false,
            "icon":["fal","user-tie"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"transferer_autre_tiers",
            "action":"goToTransferHorsesOtherTiers",
            "spin":false,
            "icon":["far","exchange-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"ajout_contrat",
            "action":"goToAddContract",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":["IsMare"],
            "rights":"MVP",
            "dropdown_name":null
         }, 
         {
            "name":"add_pension",
            "action":"goToAddPensionFromTiers",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
      ],
      2:[
         {
            "name":"fiche_depart",
            "action":"goToPdfTiersHorseHorse",
            "spin":false,
            "icon":["far","file-pdf"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"ajouter_tiers_secondaire",
            "action":"goToAddTiersHorseSecondaires",
            "spin":false,
            "icon":["fal","user-tie"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"transferer_autre_tiers",
            "action":"goToTransferHorsesOtherTiers",
            "spin":false,
            "icon":["far","exchange-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"add_pension",
            "action":"goToAddPensionFromTiers",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
      ]
   },
   "tiers_horse_tiers":{
      0:[
         {
            "name":"ajouter",
            "action":"goToAddTiersHorseTiers",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"modifier",
            "action":"goToEditTiersHorseTiers",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"ajout_baf",
            "action":"goToAddBAF",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2: [
         {
            "name":"ajout_baf",
            "action":"goToAddBAF",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "tiers_horse_tiers_no_saillie":{
      0:[
         {
            "name":"ajouter",
            "action":"goToAddTiersHorseTiers",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"modifier",
            "action":"goToEditTiersHorseTiers",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"ajout_baf",
            "action":"goToAddBAF",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2: [
         {
            "name":"ajout_baf",
            "action":"goToAddBAF",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "tiers_invoices":{
      0:[
         {
            "name":"",
            "action":"",
            "spin":false,
            "icon":[],
            "className":"d-none",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"imprimer",
            "action":"goToPdfInvoice",
            "spin":false,
            "icon":["far","file-pdf"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"imprimer_avec_acompte",
            "action":"goToPdfInvoiceWithAdvancedPayment",
            "spin":true,
            "icon":["far","file-pdf"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"envoyer",
            "action":"goToSendInvoice",
            "spin":false,
            "icon":["far","paper-plane"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"reglement",
            "action":"goToAddPayment",
            "spin":false,
            "icon":["far","money-bill-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"export_comptable",
            "action":"goToExportComptable",
            "spin":true,
            "icon":["far","receipt"],
            "className":"btn-secondary text-dark text-left",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":"operation"
         },
         {
            "name":"avoir",
            "action":"goToModalFactureAvoir",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"accounted",
            "action":"goToAccounted",
            "spin":true,
            "icon":["fal","check"],
            "className":"btn-secondary text-dark text-left",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":"operation"
         },
         {
            "name":"no_print",
            "action":"goToNoPrint",
            "spin":true,
            "icon":["fal","times-circle"],
            "className":"btn-secondary text-dark text-left",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":"operation"
         },
         {
            "name":"telecharger",
            "action":"goToDownloadPdfInvoice",
            "spin":true,
            "icon":["fas","download"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"duplicate",
            "action":"goToDuplicateInvoice",
            "spin":true,
            "icon":["far","copy"],
            "className":"btn-secondary text-dark text-left",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":"operation"
         },
         {
            "name":"supprimer",
            "action":"goToDeleteInvoice",
            "spin":false,
            "icon":["far","trash-alt"],
            "className":"btn-secondary text-dark text-left",
            "modal_options":{
               "text_title":"invoice.suppression",
               "text_question":"invoice.confirmation_suppression",
               "text_message":"general.avertissement_suppression"
            },
            "condition":null,
            "rights":"MVP",
            "dropdown_name":"operation"
         }
      ],
      2:[
         {
            "name":"imprimer",
            "action":"goToPdfInvoice",
            "spin":false,
            "icon":["far","file-pdf"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"imprimer_avec_acompte",
            "action":"goToPdfInvoiceWithAdvancedPayment",
            "spin":true,
            "icon":["far","file-pdf"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"envoyer",
            "action":"goToSendInvoice",
            "spin":false,
            "icon":["far","paper-plane"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"reglement",
            "action":"goToAddPayment",
            "spin":false,
            "icon":["far","money-bill-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"export_comptable",
            "action":"goToExportComptable",
            "spin":false,
            "icon":["far","receipt"],
            "className":"btn-secondary text-dark text-left",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":"operation"
         },
         {
            "name":"avoir",
            "action":"goToModalFactureAvoir",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"accounted",
            "action":"goToAccounted",
            "spin":false,
            "icon":["fal","check"],
            "className":"btn-secondary text-dark text-left",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":"operation"
         },
         {
            "name":"no_print",
            "action":"goToNoPrint",
            "spin":false,
            "icon":["fal","times-circle"],
            "className":"btn-secondary text-dark text-left",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":"operation"
         },
         {
            "name":"telecharger",
            "action":"goToDownloadPdfInvoice",
            "spin":true,
            "icon":["fas","download"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"supprimer",
            "action":"goToDeleteInvoice",
            "spin":false,
            "icon":["far","trash-alt"],
            "className":"btn-secondary text-dark text-left",
            "modal_options":{
               "text_title":"invoice.suppression",
               "text_question":"invoice.confirmation_suppression",
               "text_message":"general.avertissement_suppression"
            },
            "condition":null,
            "rights":"MVP",
            "dropdown_name":"operation"
         }
      ]
   },
   "facturation_a_valider":{
      0:[
         {
            "name":"",
            "action":"",
            "spin":false,
            "icon":[],
            "className":"d-none",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"valider",
            "action":"goToValideFacturationAValider",
            "spin":true,
            "icon":["far","check"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"valider_bis",
            "action":"goToValideFacturationAValider",
            "spin":true,
            "icon":["far","check"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"abandonner",
            "action":"goToAbandonFacturationAValider",
            "spin":true,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":{
               "text_title":"invoice.bons.confirmation_abandonner",
               "text_question":"invoice.bons.question_abandonner",
               "text_message":"general.avertissement_suppression"
            },
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"valider",
            "action":"goToValideFacturationAValider",
            "spin":true,
            "icon":["far","check"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"valider_bis",
            "action":"goToValideFacturationAValider",
            "spin":true,
            "icon":["far","check"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"abandonner",
            "action":"goToAbandonFacturationAValider",
            "spin":true,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":{
               "text_title":"invoice.bons.confirmation_abandonner",
               "text_question":"invoice.bons.question_abandonner",
               "text_message":"general.avertissement_suppression"
            },
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "model":{
      0:[
         {
            "name":"ajouter",
            "action":"goToAddModel",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"modifier",
            "action":"goToEditModel",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"archive_model",
            "action":"goToArchiveModel",
            "spin":false,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"dupliquer",
            "action":"goToDuplicateCourrier",
            "spin":false,
            "icon":["far","copy"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"",
            "action":"",
            "spin":false,
            "icon":[],
            "className":"d-none",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"archive_model",
            "action":"goToArchiveModel",
            "spin":false,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "tiers_horse_historique_horse":{
      0:[
         {
            "name":"",
            "action":"",
            "spin":false,
            "icon":[],
            "className":"d-none",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[]
   },
   "tiers_horse_historique_tiers":{
      0:[
         {
            "name":"",
            "action":"",
            "spin":false,
            "icon":[],
            "className":"d-none",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"supprimer",
            "action":"goToDeleteTiersHorseHistoriqueTiers",
            "spin":false,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":{
               "text_title":"tiers_horse_historique_tiers.suppression",
               "text_question":"tiers_horse_historique_tiers.confirmation_suppression",
               "text_message":"general.avertissement_suppression"
            },
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"supprimer",
            "action":"goToDeleteMultipleTiersHorseHistoriqueTiers",
            "spin":false,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":{
               "text_title":"tiers_horse_historique_tiers.suppression",
               "text_question":"tiers_horse_historique_tiers.confirmation_suppression_multiple",
               "text_message":"general.avertissement_suppression"
            },
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "pension_type":{
      0:[
         {
            "name":"ajouter",
            "action":"goToAddPensionType",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"modifier",
            "action":"goToEditPensionType",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "horse_pension":{
      0:[
         {
            "name":"ajouter",
            "action":"goToAddHorsePension",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"modifier",
            "action":"goToEditHorsePension",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"supprimer",
            "action":"goToDeleteHorsePension",
            "spin":false,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":{
               "text_title":"horse_pension.suppression",
               "text_question":"horse_pension.confirmation_suppression",
               "text_message":"general.avertissement_suppression"
            },
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "horse_pension_historique":{
      0:[
         {
            "name":"",
            "action":"",
            "spin":false,
            "icon":[],
            "className":"d-none",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"supprimer",
            "action":"goToDeleteHorsePensionHistorique",
            "spin":false,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":{
               "text_title":"horse_pension_historique.suppression",
               "text_question":"horse_pension_historique.confirmation_suppression",
               "text_message":"general.avertissement_suppression"
            },
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"modifier",
            "action":"goToEditEndHorsePension",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "impayes":{
      0:[
         {
            "name":"",
            "action":"",
            "spin":false,
            "icon":[],
            "className":"d-none",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"reglement",
            "action":"goToAddPayment",
            "spin":false,
            "icon":["far","money-bill-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"envoyer",
            "action":"goToSendInvoice",
            "spin":true,
            "icon":["far","paper-plane"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"print_infos_tiers",
            "action":"goToPrintInfos",
            "spin":false,
            "icon":["far","file-pdf"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"imprimer_facture",
            "action":"goToPdfInvoice",
            "spin":false,
            "icon":["far","file-pdf"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"relance_manuelle",
            "action":"goToSetRelanceManuelle",
            "spin":false,
            "icon":["far","redo"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"invoice_saillie",
            "action":"goToModifyInvoiceSaillie",
            "spin":false,
            "icon":["fal", "venus-mars"],
            "className":"btn-secondary text-dark text-left",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":"operation"
         },
         {
            "name":"solder_avoir",
            "action":"goToSoldeAvoir",
            "spin":false,
            "icon":["far","credit-card"],
            "className":"btn-secondary text-dark text-left",
            "condition":null,
            "rights":"MVP",
            "dropdown_name":"operation",
            "modal_options":{
               "text_title":"solde_avoir.tilte",
               "text_question":"solde_avoir.confirmation_1",
               "text_message":"solde_avoir.confirmation_2"
            }
         }
      ],
      2:[
         {
            "name":"reglement",
            "action":"goToAddPayment",
            "spin":false,
            "icon":["far","money-bill-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"imprimer",
            "action":"goToPdfInvoice",
            "spin":false,
            "icon":["far","file-pdf"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"envoyer",
            "action":"goToSendInvoice",
            "spin":true,
            "icon":["far","paper-plane"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"print_infos_tiers",
            "action":"goToPrintInfos",
            "spin":false,
            "icon":["far","file-pdf"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"relance_manuelle",
            "action":"goToSetRelanceManuelle",
            "spin":false,
            "icon":["far","redo"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"invoice_saillie",
            "action":"goToModifyInvoiceSaillie",
            "spin":false,
            "icon":["fal", "venus-mars"],
            "className":"btn-secondary text-dark text-left",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":"operation"
         }
      ]
   },
   "tiers_impayes":{
      1:[
         {
            "name":"relance_manuelle",
            "action":"goToSetRelanceAllManuelle",
            "spin":false,
            "icon":["far","redo"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"envoyer",
            "action":"goToSendAllInvoice",
            "spin":true,
            "icon":["far","paper-plane"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"print_infos_tiers",
            "action":"goToPrintInfos",
            "spin":false,
            "icon":["far","file-pdf"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
      ],
      2:[
         {
            "name":"relance_manuelle",
            "action":"goToSetRelanceAllManuelle",
            "spin":false,
            "icon":["far","redo"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"envoyer",
            "action":"goToSendAllInvoice",
            "spin":true,
            "icon":["far","paper-plane"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"print_infos_tiers",
            "action":"goToPrintInfos",
            "spin":false,
            "icon":["far","file-pdf"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
      ]
   },
   "check_deposit_configuration":{
      0:[
         {
            "name":"ajouter",
            "action":"goToAddCheckDepositConfiguration",
            "spin":false,
            "icon":["far","money-check-edit-alt"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"ajouter",
            "action":"goToAddCheckDepositConfiguration",
            "spin":false,
            "icon":["far","money-check-edit-alt"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"modifier",
            "action":"goToEditCheckDepositConfiguration",
            "spin":false,
            "icon":["far","money-check-edit-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"supprimer",
            "action":"goToDeleteCheckDepositConfiguration",
            "spin":false,
            "icon":["far","trash"],
            "className":"btn-secondary",
            "modal_options":{
               "text_title":"check_deposit_configuration.suppression",
               "text_question":"check_deposit_configuration.confirmation_suppression",
               "text_message":"general.avertissement_suppression"
            },
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"ajouter",
            "action":"goToAddCheckDepositConfiguration",
            "spin":false,
            "icon":["far","money-check-edit-alt"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "check_deposit":{
      0:[
         {
            "name":"ajouter",
            "action":"goToAddCheckDeposit",
            "spin":false,
            "icon":["far","money-check-edit-alt"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"ajouter",
            "action":"goToAddCheckDeposit",
            "spin":false,
            "icon":["far","money-check-edit-alt"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"encaisser",
            "action":"goToCashCheckDeposit",
            "spin":false,
            "icon":["far","money-check-edit-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"supprimer",
            "action":"goToDeleteCheckDeposit",
            "spin":false,
            "icon":["far","trash"],
            "className":"btn-secondary",
            "modal_options":{
               "text_title":"check_deposit.suppression",
               "text_question":"check_deposit.confirmation_suppression",
               "text_message":"general.avertissement_suppression"
            },
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"imprimer",
            "action":"goToPrintCheckDeposit",
            "spin":false,
            "icon":["far","print"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"ajouter",
            "action":"goToAddCheckDeposit",
            "spin":false,
            "icon":["far","money-check-edit-alt"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"encaisser",
            "action":"goToCashCheckDeposit",
            "spin":false,
            "icon":["far","money-check-edit-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"supprimer",
            "action":"goToDeleteCheckDeposit",
            "spin":false,
            "icon":["far","trash"],
            "className":"btn-secondary",
            "modal_options":{
               "text_title":"check_deposit.suppression",
               "text_question":"check_deposit.confirmation_suppression",
               "text_message":"general.avertissement_suppression"
            },
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"imprimer",
            "action":"goToPrintCheckDeposit",
            "spin":false,
            "icon":["far","print"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "payment":{
      0:[
         {
            "name":"",
            "action":"",
            "spin":false,
            "icon":[],
            "className":"d-none",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"impayes",
            "action":"goToUnpaidPayment",
            "spin":true,
            "icon":["far","hands-usd"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"annuler",
            "action":"goToCancelPayment",
            "spin":true,
            "icon":["far","trash"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"export_reglement",
            "action":"goToExportPayment",
            "spin":true,
            "icon":["far","receipt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"modifier",
            "action":"goToEditPayment",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"accounted",
            "action":"goToAccountedPayment",
            "spin":false,
            "icon":["fal","check"],
            "className":"btn-secondary text-dark text-left",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":"operation"
         }
      ],
      2:[
         {
            "name":"impayes",
            "action":"goToUnpaidPayment",
            "spin":true,
            "icon":["far","hands-usd"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"annuler",
            "action":"goToCancelPayment",
            "spin":true,
            "icon":["far","trash"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"export_reglement",
            "action":"goToExportPayment",
            "spin":true,
            "icon":["far","receipt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"accounted",
            "action":"goToAccountedPayment",
            "spin":false,
            "icon":["fal","check"],
            "className":"btn-secondary text-dark text-left",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":"operation"
         }
      ]
   },
   "registry_history":{
      0:[
         {
            "name":"",
            "action":"",
            "spin":false,
            "icon":[],
            "className":"d-none",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"voir",
            "action":"goToSeeRegistry",
            "spin":true,
            "icon":["fas","eye"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"telecharger",
            "action":"goToDownloadRegistry",
            "spin":true,
            "icon":["fas","download"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"telecharger",
            "action":"goToDownloadRegistry",
            "spin":true,
            "icon":["fas","download"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "export_perso_config":{
      0:[
         {
            "name":"",
            "action":"",
            "spin":false,
            "icon":[],
            "className":"d-none",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"selectionner",
            "action":"goToSaveExport",
            "spin":false,
            "icon":["far","check"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "localisation":{
      0:[
         {
            "name":"ajouter",
            "action":"goToAddLocalisation",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"ajouter",
            "action":"goToAddLocalisation",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"modifier",
            "action":"goToEditLocalisation",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"supprimer",
            "action":"goToDeleteLocalisation",
            "spin":false,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":{
               "text_title":"localisation.suppression",
               "text_question":"localisation.confirmation_suppression"
            },
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"supprimer",
            "action":"goToDeleteLocalisation",
            "spin":false,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":{
               "text_title":"localisation.suppression",
               "text_question":"localisation.confirmation_suppression"
            },
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "acte_type_admin":{
      0:[
         {
            "name":"ajouter",
            "action":"goToAddActeType",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"modifier",
            "action":"goToEditActeType",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"supprimer",
            "action":"goToDeleteActesTypes",
            "spin":true,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":{
               "text_title":"actetype.suppression",
               "text_question":"actetype.confirmation_suppression",
               "text_message":"general.avertissement_suppression"
            },
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"choisir_operateur",
            "action":"goToEditOperateurActeType",
            "spin":false,
            "icon":["far","user"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"edit_maxdelay",
            "action":"goToEditMaxdelay",
            "spin":false,
            "icon":["far","cogs"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"supprimer",
            "action":"goToDeleteActesTypes",
            "spin":true,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":{
               "text_title":"actetype.suppression",
               "text_question":"actetype.confirmation_suppression",
               "text_message":"general.avertissement_suppression"
            },
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"edit_maxdelay",
            "action":"goToEditMaxdelay",
            "spin":false,
            "icon":["far","cogs"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "addressed_invoices_to_pay":{
      0:[
         {
            "name":"online_paiement",
            "action":"goToSelectInvoiceToPayAndExec",
            "spin":false,
            "icon":["far","credit-card"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"imprimer",
            "action":"goToPdfInvoice",
            "spin":true,
            "icon":["far","file-pdf"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"online_paiement",
            "action":"goToPayOnline",
            "spin":false,
            "icon":["far","credit-card"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"imprimer",
            "action":"goToPdfInvoice",
            "spin":true,
            "icon":["far","file-pdf"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"online_paiement",
            "action":"goToPayOnline",
            "spin":false,
            "icon":["far","credit-card"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "addressed_invoices_sold":{
      0:[
         {
            "name":"",
            "action":"",
            "spin":false,
            "icon":[],
            "className":"d-none",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"imprimer",
            "action":"goToPdfInvoice",
            "spin":false,
            "icon":["far","file-pdf"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"imprimer",
            "action":"goToPdfInvoice",
            "spin":false,
            "icon":["far","file-pdf"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "contacts":{
      0:[
         {
            "name":"ajouter",
            "action":"goToAddContact",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"ajouter",
            "action":"goToAddContact",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"modifier",
            "action":"goToEditContact",
            "spin":false,
            "icon":["far","user"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"supprimer",
            "action":"goToDeleteContact",
            "spin":false,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"ajouter",
            "action":"goToAddContact",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"supprimer",
            "action":"goToDeleteContact",
            "spin":false,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "addressed_payment":{
      0:[
         {
            "name":"",
            "action":"",
            "spin":false,
            "icon":[],
            "className":"d-none",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "trigger_entity":{
      0:[
         {
            "name":"ajouter",
            "action":"goToAddTrigger",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"modifier",
            "action":"goToEditTrigger",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"supprimer",
            "action":"goToDeleteTrigger",
            "spin":false,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":{
               "text_title":"trigger.suppression",
               "text_question":"trigger.confirmation_suppression",
               "text_message":"general.avertissement_suppression"
            },
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"supprimer",
            "action":"goToDeleteTrigger",
            "spin":false,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":{
               "text_title":"trigger.suppression",
               "text_question":"trigger.confirmation_suppression_many",
               "text_message":"general.avertissement_suppression"
            },
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "season_mare_stallion":{
      0:[
         {
            "name":"ajouter",
            "action":"goToAddSeasonMareStallion",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"ajouter",
            "action":"goToAddSeasonMareStallion",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         // {
         //    "name":"modifier",
         //    "action":"goToEditSeasonMareStallion",
         //    "spin":false,
         //    "icon":["far","user"],
         //    "className":"btn-secondary",
         //    "modal_options":null,
         //    "condition":null,
         //    "rights":"MVP",
         //    "dropdown_name":null
         // },
         {
            "name":"archive_unarchive",
            "action":"goToArchiveCouple",
            "spin":false,
            "icon":["far","archive"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"supprimer",
            "action":"goToDeleteSeasonMareStallion",
            "spin":false,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":{
               "text_title":"season_mare_stallion.suppression",
               "text_question":"season_mare_stallion.confirmation_suppression",
               "text_message":"season_mare_stallion.avertissement_suppression"
            },
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"ajouter",
            "action":"goToAddSeasonMareStallion",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"archive_unarchive",
            "action":"goToArchiveCouple",
            "spin":false,
            "icon":["far","archive"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"supprimer",
            "action":"goToDeleteSeasonMareStallion",
            "spin":false,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":{
               "text_title":"season_mare_stallion.suppression",
               "text_question":"season_mare_stallion.confirmation_suppression",
               "text_message":"season_mare_stallion.avertissement_suppression"
            },
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "season":{
      0:[
         {
            "name":"ajouter",
            "action":"goToAddSeason",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"ajouter",
            "action":"goToAddSeason",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"modifier",
            "action":"goToEditSeason",
            "spin":false,
            "icon":["far","user"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"supprimer",
            "action":"goToDeleteSeason",
            "spin":false,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":{
               "text_title":"season.suppression",
               "text_question":"season.confirmation_suppression",
               "text_message":"general.avertissement_suppression"
            },
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"dupliquer",
            "action":"goToDuplicateSeason",
            "spin":false,
            "icon":["far","copy"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"ajouter",
            "action":"goToAddSeason",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "horse_actes_gyneco":{
      0:[
         {
            "name":"ajouter",
            "action":"goToAddActe",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"echographie",
            "action":"goToAddActeEcho",
            "spin":false,
            "icon":["far","waveform-path"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":[
               "isNotBellowMd"
            ],
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"echographie",
            "action":"goToAddActeEcho",
            "spin":false,
            "icon":["cf","echographie"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":[
               "isBellowMd"
            ],
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"dg",
            "action":"goToAddActeDG",
            "spin":false,
            "icon":["far","notes-medical"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":[
               "isNotBellowMd"
            ],
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"dg",
            "action":"goToAddActeDG",
            "spin":false,
            "icon":["cf","dg"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":[
               "isBellowMd"
            ],
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"modifier",
            "action":"goToEditActe",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"replanifier",
            "action":"goToReplanActe",
            "spin":false,
            "icon":["far","calendar-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":[
               "actionFilterActNeedUndone"
            ],
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"valider",
            "action":"goToValidActe",
            "spin":false,
            "icon":["far","check"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":[
               "actionFilterActNeedUndone"
            ],
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"supprimer",
            "action":"goToDeleteActe",
            "spin":false,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":{
               "text_title":"acte.suppression",
               "text_question":"acte.confirmation_suppression",
               "text_message":"general.avertissement_suppression"
            },
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"ajouter_note",
            "action":"goToAddActNote",
            "spin":false,
            "icon":["far","sticky-note"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"dupliquer",
            "action":"goToDupliqueActe",
            "spin":false,
            "icon":["far","clone"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"modifier",
            "action":"goToEditActe",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"replanifier",
            "action":"goToReplanActe",
            "spin":false,
            "icon":["far","calendar-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":[
               "actionFilterActNeedUndone"
            ],
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"valider",
            "action":"goToValidActe",
            "spin":false,
            "icon":["far","check"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":[
               "actionFilterActNeedUndone"
            ],
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"supprimer",
            "action":"goToDeleteActe",
            "spin":false,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":{
               "text_title":"acte.suppression",
               "text_question":"acte.confirmation_suppression",
               "text_message":"general.avertissement_suppression"
            },
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"modifier_lot",
            "action":"goToEditActeLot",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":[
               "needSameActe"
            ],
            "rights":"MVP",
            "dropdown_name":null,
         }
      ]
   },
   "media":{
      0:[
         {
            "name":"ajouter",
            "action":"goToAddMedia",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"modifier",
            "action":"goToEditMedia",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"voir",
            "action":"goToViewMedia",
            "spin":false,
            "icon":["fas","eye"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"supprimer",
            "action":"goToDeleteMedia",
            "spin":false,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":{
               "text_title":"media.suppression",
               "text_question":"media.confirmation_suppression"
            },
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"supprimer",
            "action":"goToDeleteMedia",
            "spin":false,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":{
               "text_title":"media.suppression",
               "text_question":"media.confirmation_suppression"
            },
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "mouvements_residence":{
      0:[
         {
            "name":"ajouter",
            "action":"goToAddHorse",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"ajouter_acte",
            "action":"goToAddActe",
            "spin":false,
            "icon":["far","tasks"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"ajouter_mouvement",
            "action":"goToAddMouvement",
            "spin":false,
            "icon":["far","route"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"ajouter_ordonnance",
            "action":"goToAddOrdonnance",
            "spin":false,
            "icon":["far","file-medical-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"supprimer",
            "action":"goToDeleteHorses",
            "spin":false,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":{
               "text_title":"horse.suppression",
               "text_question":"horse.confirmation_suppression",
               "text_message":"general.avertissement_suppression"
            },
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"ajouter_note",
            "action":"goToAddHorseNote",
            "spin":false,
            "icon":["far","sticky-note"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"print_infos_horse",
            "action":"goToPrintInfosHorse",
            "spin":false,
            "icon":["far","file-pdf"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"add_pension",
            "action":"goToAddPension",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"manage_categories",
            "action":"goToManageCategories",
            "spin":false,
            "icon":["fal","tools"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"ajouter_acte",
            "action":"goToAddActe",
            "spin":false,
            "icon":["far","tasks"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"ajouter_mouvement",
            "action":"goToAddMouvement",
            "spin":false,
            "icon":["far","route"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"ajouter_ordonnance",
            "action":"goToAddOrdonnance",
            "spin":false,
            "icon":["far","file-medical-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"supprimer",
            "action":"goToDeleteHorses",
            "spin":false,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":{
               "text_title":"horse.suppression",
               "text_question":"horse.confirmation_suppression",
               "text_message":"general.avertissement_suppression"
            },
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"print_infos_horse",
            "action":"goToPrintInfosHorse",
            "spin":false,
            "icon":["far","file-pdf"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"add_pension",
            "action":"goToAddPension",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"manage_categories",
            "action":"goToManageCategories",
            "spin":false,
            "icon":["fal","tools"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "contract_config":{
      0:[
         {
            "name":"ajouter",
            "action":"goToAddContractConfig",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"ajouter",
            "action":"goToAddContractConfig",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"modifier",
            "action":"goToEditContractConfig",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"supprimer",
            "action":"goToDeleteContractConfig",
            "spin":false,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "contract_config_type_monte":{
      0:[
         {
            "name":"ajouter",
            "action":"goToAddContractConfigTypeMonte",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"ajouter",
            "action":"goToAddContractConfigTypeMonte",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"modifier",
            "action":"goToEditContractConfigTypeMonte",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         // {
         //    "name":"supprimer",
         //    "action":"goToDeleteContractConfigTypeMonte",
         //    "spin":false,
         //    "icon":["far","trash-alt"],
         //    "className":"btn-secondary",
         //    "modal_options":{
         //       "text_title":"contract_config_type_monte.suppression",
         //       "text_question":"contract_config_type_monte.confirmation_suppression",
         //       "text_message":"general.avertissement_suppression"
         //    },
         //    "condition":null,
         //    "rights":"MVP",
         //    "dropdown_name":null
         // },
         {
            "name":"archive_unarchive",
            "action":"goToArchiveContractConfigTypeMonte",
            "spin":false,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":{
               "text_title":"contract_config_type_monte.archive",
               "text_question":"contract_config_type_monte.confirmation_archive",
               "text_message":"contract_config_type_monte.message_archive"
            },
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "contract":{
      0:[
         {
            "name":"ajouter",
            "action":"goToAddContract",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"export_global",
            "action":"goToExportContract",
            "spin":false,
            "icon":["fas","download"],
            "className":"btn-primary",
            "modal_options":{
               "text_title":"export_global.title",
               "text_question":"export_global.question",
               "text_message":"export_global.message"
            },
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"ajouter",
            "action":"goToAddContract",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"imprimer",
            "action":"goToPrintContract",
            "spin":true,
            "icon":["far","file-pdf"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"envoyer",
            "action":"goToSendContract",
            "spin":true,
            "icon":["far","paper-plane"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"modifier",
            "action":"goToEditContract",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"courrier",
            "action":"goToCourrierContract",
            "spin":true,
            "icon":["far","file-pdf"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"modifier_rapidement",
            "action":"goToFastEditContract",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"mark_as_sended",
            "action":"goToMarkContractAsSended",
            "spin":false,
            "icon":["fal","check"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"edit_qualif",
            "action":"goToEditQualificationTiers",
            "spin":false,
            "icon":["far","user"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"export_global",
            "action":"goToExportContract",
            "spin":false,
            "icon":["fas","download"],
            "className":"btn-primary",
            "modal_options":{
               "text_title":"export_global.title",
               "text_question":"export_global.question",
               "text_message":"export_global.message"
            },
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"reindexer",
            "action":"goToReindexContract",
            "spin":false,
            "icon":["fas","atom-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"act_dg_lot",
            "action":"goToActDgLotContract",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"relance_centre",
            "action":"goToPrintRelanceContract",
            "spin":true,
            "icon":["far","file-pdf"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":[
               "isInLieuMonte"
            ],
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"supprimer",
            "action":"goToDeleteContract",
            "spin":true,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":{
               "text_title":"contract.suppression",
               "text_question":"contract.confirmation_suppression",
               "text_message":"general.avertissement_suppression"
            },
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"envoyer",
            "action":"goToSendContract",
            "spin":true,
            "icon":["far","paper-plane"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"courrier",
            "action":"goToCourrierContract",
            "spin":true,
            "icon":["far","file-pdf"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"imprimer",
            "action":"goToPrintContract",
            "spin":true,
            "icon":["far","file-pdf"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"mark_as_sended",
            "action":"goToMarkContractAsSended",
            "spin":false,
            "icon":["fal","check"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"edit_qualif",
            "action":"goToEditQualificationTiers",
            "spin":false,
            "icon":["far","user"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"ajouter",
            "action":"goToAddContract",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"export_global",
            "action":"goToExportContract",
            "spin":false,
            "icon":["fas","download"],
            "className":"btn-primary",
            "modal_options":{
               "text_title":"export_global.title",
               "text_question":"export_global.question",
               "text_message":"export_global.message"
            },
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"reindexer",
            "action":"goToReindexContract",
            "spin":false,
            "icon":["fas","atom-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"act_dg_lot",
            "action":"goToActDgLotContract",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"relance_centre",
            "action":"goToPrintRelanceContract",
            "spin":true,
            "icon":["far","file-pdf"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":[
               "isInLieuMonte"
            ],
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"supprimer",
            "action":"goToDeleteContract",
            "spin":true,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":{
               "text_title":"contract.multiple_suppression",
               "text_question":"contract.confirmation_multiple_suppression",
               "text_message":"general.avertissement_suppression"
            },
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "contract_tiers":{
      0:[
         {
            "name":"ajouter",
            "action":"goToAddContract",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"ajouter_lot",
            "action":"goToAddContractLot",
            "spin":false,
            "icon":["far","layer-plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"export_global",
            "action":"goToExportContract",
            "spin":false,
            "icon":["fas","download"],
            "className":"btn-primary",
            "modal_options":{
               "text_title":"export_global.title",
               "text_question":"export_global.question",
               "text_message":"export_global.message"
            },
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"ajouter",
            "action":"goToAddContract",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"ajouter_lot",
            "action":"goToAddContractLot",
            "spin":false,
            "icon":["far","layer-plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"modifier",
            "action":"goToEditContract",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"courrier",
            "action":"goToCourrierContract",
            "spin":true,
            "icon":["far","file-pdf"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"export_global",
            "action":"goToExportContract",
            "spin":false,
            "icon":["fas","download"],
            "className":"btn-primary",
            "modal_options":{
               "text_title":"export_global.title",
               "text_question":"export_global.question",
               "text_message":"export_global.message"
            },
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"imprimer",
            "action":"goToPrintContract",
            "spin":true,
            "icon":["far","file-pdf"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"envoyer",
            "action":"goToSendContract",
            "spin":true,
            "icon":["far","paper-plane"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"modifier_rapidement",
            "action":"goToFastEditContract",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"mark_as_sended",
            "action":"goToMarkContractAsSended",
            "spin":false,
            "icon":["fal","check"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"edit_qualif",
            "action":"goToEditQualificationTiers",
            "spin":false,
            "icon":["far","user"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"act_dg_lot",
            "action":"goToActDgLotContract",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"reindexer",
            "action":"goToReindexContract",
            "spin":false,
            "icon":["fas","atom-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"relance_centre",
            "action":"goToPrintRelanceContract",
            "spin":true,
            "icon":["far","file-pdf"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"supprimer",
            "action":"goToDeleteContract",
            "spin":false,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":{
               "text_title":"contract.suppression",
               "text_question":"contract.confirmation_suppression",
               "text_message":"general.avertissement_suppression"
            },
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"courrier",
            "action":"goToCourrierContract",
            "spin":true,
            "icon":["far","file-pdf"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"ajouter",
            "action":"goToAddContract",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"ajouter_lot",
            "action":"goToAddContractLot",
            "spin":false,
            "icon":["far","layer-plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"export_global",
            "action":"goToExportContract",
            "spin":false,
            "icon":["fas","download"],
            "className":"btn-primary",
            "modal_options":{
               "text_title":"export_global.title",
               "text_question":"export_global.question",
               "text_message":"export_global.message"
            },
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"envoyer",
            "action":"goToSendContract",
            "spin":true,
            "icon":["far","paper-plane"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"imprimer",
            "action":"goToPrintContract",
            "spin":true,
            "icon":["far","file-pdf"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"imprimer_lot",
            "action":"goToPrintContractLot",
            "spin":true,
            "icon":["far","file-pdf"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"mark_as_sended",
            "action":"goToMarkContractAsSended",
            "spin":false,
            "icon":["fal","check"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"edit_qualif",
            "action":"goToEditQualificationTiers",
            "spin":false,
            "icon":["far","user"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"act_dg_lot",
            "action":"goToActDgLotContract",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"reindexer",
            "action":"goToReindexContract",
            "spin":false,
            "icon":["fas","atom-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"relance_centre",
            "action":"goToPrintRelanceContract",
            "spin":true,
            "icon":["far","file-pdf"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"supprimer",
            "action":"goToDeleteContract",
            "spin":false,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":{
               "text_title":"contract.multiple_suppression",
               "text_question":"contract.confirmation_multiple_suppression",
               "text_message":"general.avertissement_suppression"
            },
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "horse_syndic_details":{
      0:[
         {
            "name":"ajouter",
            "action":"goToAddSyndicParts",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"modifier",
            "action":"goToEditPart",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"attribuer_part",
            "action":"gotToAssignParts",
            "spin":false,
            "icon":["fal","chart-pie-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"facturer",
            "action":"goToInvoicePart",
            "spin":false,
            "icon":["far","file-invoice"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"modifier",
            "action":"goToEditMultiPart",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"facturer",
            "action":"goToInvoicePart",
            "spin":false,
            "icon":["far","file-invoice"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "contract_avenant_articles_a_valider":{
      0:[
         {
            "name":"",
            "action":"",
            "spin":false,
            "icon":[],
            "className":"d-none",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"valider",
            "action":"goToValideFacturationContractAValider",
            "spin":true,
            "icon":["far","check"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"valider",
            "action":"goToValideFacturationContractAValider",
            "spin":true,
            "icon":["far","check"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "qualif_config":{
      0:[
         {
            "name":"ajouter",
            "action":"goToAddQualification",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"modifier",
            "action":"goToEditQualification",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"supprimer",
            "action":"goToDeleteQualification",
            "spin":false,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":{
               "text_title":"qualification.suppression",
               "text_question":"qualification.confirmation_suppression",
               "text_message":"qualification.avertissement_suppression"
            },
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"supprimer",
            "action":"goToDeleteQualification",
            "spin":false,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":{
               "text_title":"qualification.suppression",
               "text_question":"qualification.confirmation_suppression_many",
               "text_message":"qualification.avertissement_suppression"
            },
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "qualif_fiche":{
      0:[
         {
            "name":"ajouter",
            "action":"goToAddQualificationToFiche",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"modifier",
            "action":"goToEditQualificationToFiche",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"supprimer",
            "action":"goToDeleteQualificationToFiche",
            "spin":false,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":{
               "text_title":"qualification.suppression",
               "text_question":"qualification.confirmation_suppression",
               "text_message":"general.avertissement_suppression"
            },
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"supprimer",
            "action":"goToDeleteQualificationToFiche",
            "spin":false,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":{
               "text_title":"qualification.suppression",
               "text_question":"qualification.confirmation_suppression_many",
               "text_message":"general.avertissement_suppression"
            },
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "tiers_payment_current_account":{
      0:[
         {
            "name":"reglement",
            "action":"goToAddPayment",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"reglement",
            "action":"goToAddPayment",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"reglement",
            "action":"goToAddPayment",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "qualif_horse":{
      0:[
         {
            "name":"ajouter",
            "action":"goToAddHorse",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"ajouter_acte",
            "action":"goToAddActe",
            "spin":false,
            "icon":["far","tasks"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"ajouter_mouvement",
            "action":"goToAddMouvement",
            "spin":false,
            "icon":["far","route"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"ajouter_ordonnance",
            "action":"goToAddOrdonnance",
            "spin":false,
            "icon":["far","file-medical-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"supprimer",
            "action":"goToDeleteHorses",
            "spin":false,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":{
               "text_title":"horse.suppression",
               "text_question":"horse.confirmation_suppression",
               "text_message":"general.avertissement_suppression"
            },
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"ajouter_note",
            "action":"goToAddHorseNote",
            "spin":false,
            "icon":["far","sticky-note"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"print_infos_horse",
            "action":"goToPrintInfosHorse",
            "spin":false,
            "icon":["far","file-pdf"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"ajouter_acte",
            "action":"goToAddActe",
            "spin":false,
            "icon":["far","tasks"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"ajouter_mouvement",
            "action":"goToAddMouvement",
            "spin":false,
            "icon":["far","route"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"ajouter_ordonnance",
            "action":"goToAddOrdonnance",
            "spin":false,
            "icon":["far","file-medical-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"supprimer",
            "action":"goToDeleteHorses",
            "spin":false,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":{
               "text_title":"horse.suppression",
               "text_question":"horse.confirmation_suppression",
               "text_message":"general.avertissement_suppression"
            },
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"print_infos_horse",
            "action":"goToPrintInfosHorse",
            "spin":false,
            "icon":["far","file-pdf"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "contract_sign_a_valider":{
      0:[
         {
            "name":"",
            "action":"",
            "spin":false,
            "icon":[],
            "className":"d-none",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"valider",
            "action":"goToValideFacturationAValider",
            "spin":true,
            "icon":["far","check"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"valider",
            "action":"goToValideFacturationAValider",
            "spin":true,
            "icon":["far","check"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"valider",
            "action":"goToValideFacturationAValider",
            "spin":true,
            "icon":["far","check"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"valider",
            "action":"goToValideFacturationAValider",
            "spin":true,
            "icon":["far","check"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "contract_saillie_a_valider":{
      0:[
         {
            "name":"",
            "action":"",
            "spin":false,
            "icon":[],
            "className":"d-none",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"valider",
            "action":"goToValideFacturationAValider",
            "spin":true,
            "icon":["far","check"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"valider",
            "action":"goToValideFacturationAValider",
            "spin":true,
            "icon":["far","check"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"valider",
            "action":"goToValideFacturationAValider",
            "spin":true,
            "icon":["far","check"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"valider",
            "action":"goToValideFacturationAValider",
            "spin":true,
            "icon":["far","check"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "contract_octobre_a_valider":{
      0:[
         {
            "name":"",
            "action":"",
            "spin":false,
            "icon":[],
            "className":"d-none",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"valider",
            "action":"goToValideFacturationAValider",
            "spin":true,
            "icon":["far","check"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"valider",
            "action":"goToValideFacturationAValider",
            "spin":true,
            "icon":["far","check"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"valider",
            "action":"goToValideFacturationAValider",
            "spin":true,
            "icon":["far","check"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"valider",
            "action":"goToValideFacturationAValider",
            "spin":true,
            "icon":["far","check"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "contract_poulain_a_valider":{
      0:[
         {
            "name":"",
            "action":"",
            "spin":false,
            "icon":[],
            "className":"d-none",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"valider",
            "action":"goToValideFacturationAValider",
            "spin":true,
            "icon":["far","check"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"valider",
            "action":"goToValideFacturationAValider",
            "spin":true,
            "icon":["far","check"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"valider",
            "action":"goToValideFacturationAValider",
            "spin":true,
            "icon":["far","check"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"valider",
            "action":"goToValideFacturationAValider",
            "spin":true,
            "icon":["far","check"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "carnet_adresse_ro":{
      0:[
         {
            "name":"ajouter",
            "action":"goToAddLieu",
            "spin":false,
            "icon":["fal","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"modifier",
            "action":"goToEditLieu",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"supprimer",
            "action":"goToDeleteLieu",
            "spin":false,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":{
               "text_title":"lieu.confirm_suppression",
               "text_question":"lieu.question_suppression_lieu",
               "text_message":"general.avertissement_suppression"
            },
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"archive",
            "action":"goToArchiveLieu",
            "spin":false,
            "icon":["far","archive"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"set_default",
            "action":"goToSetDefaultLieu",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"supprimer",
            "action":"goToDeleteLieu",
            "spin":false,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":{
               "text_title":"lieu.confirm_suppression",
               "text_question":"lieu.question_suppression_lieu",
               "text_message":"general.avertissement_suppression"
            },
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"archive",
            "action":"goToEditLieu",
            "spin":false,
            "icon":["far","archive"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "tiers_payment":{
      0:[
         {
            "name":"",
            "action":"",
            "spin":false,
            "icon":[],
            "className":"d-none",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"impayes",
            "action":"goToUnpaidPayment",
            "spin":true,
            "icon":["far","hands-usd"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"modifier",
            "action":"goToEditPayment",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"annuler",
            "action":"goToCancelPayment",
            "spin":true,
            "icon":["far","trash"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"impayes",
            "action":"goToUnpaidPayment",
            "spin":true,
            "icon":["far","hands-usd"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"annuler",
            "action":"goToCancelPayment",
            "spin":true,
            "icon":["far","trash"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "contract_config_modeles":{
      0:[
         {
            "name":"ajouter",
            "action":"goToAddContractConfigModel",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"ajouter",
            "action":"goToAddContractConfigModel",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"modifier",
            "action":"goToEditContractConfigModel",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"print_blank_contract",
            "action":"goToPrintBlankContract",
            "spin":true,
            "icon":["far","file-pdf"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"supprimer",
            "action":"goToDeleteModeleContractConfig",
            "spin":false,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":{
               "text_title":"contract_config_modele.suppression",
               "text_question":"contract_config_modele.confirmation_suppression",
               "text_message":"general.avertissement_suppression"
            },
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "intra_location":{
      0:[
         {
            "name":"localisation",
            "action":"goToAddIntraLocation",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"reorganiser",
            "action":"goToReorganizeParent",
            "spin":false,
            "icon":["far","sort"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"modifier",
            "action":"goToEditIntraLocation",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition": [
               "checkIfIntraLocalation"
            ],
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"supprimer",
            "action":"goToDeleteIntraLocation",
            "spin":false,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":{
               "text_title":"lieu.confirm_suppression",
               "text_question":"lieu.question_suppression",
               "text_message":"lieu.avertissement_suppression"
            },
            "condition": [
               "checkIfIntraLocalation"
            ],
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"reorganiser",
            "action":"goToReorganizeChildren",
            "spin":false,
            "icon":["far","sort"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition": [
               "checkIfIntraLocalation"
            ],
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "tiers_suppliers_invoices":{
      0:[
         {
            "name":"ajouter",
            "action":"goToAddSupplierInvoice",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"modifier",
            "action":"goToEditSupplierInvoice",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"details",
            "action":"goToSeeSupplierInvoiceDetails",
            "spin":false,
            "icon":["fas","eye"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"supprimer",
            "action":"goToDeleteSupplierInvoice",
            "spin":true,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "avenant_articles":{
      0:[
         {
            "name":"ajouter",
            "action":"goToAddArticlesToAvenant",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"recalculer",
            "action":"goToRecalculArticlesAvenant",
            "spin":false,
            "icon":["far","calculator-alt"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"importer",
            "action":"goToImportInvoiceDetail",
            "spin":false,
            "icon":["far","file-import"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"etat_facturation",
            "action":"goToEtatFacturation",
            "spin":false,
            "icon":["fas","eye"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "horse_actes_clientarea":{
      0:[
         {
            "name":"",
            "action":"",
            "spin":false,
            "icon":[],
            "className":"d-none",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"notify",
            "action":"goToNotifyAct",
            "spin":false,
            "icon":["far","bell"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"notify",
            "action":"goToNotifyAct",
            "spin":false,
            "icon":["far","bell"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "acte_type_ca":{
      0:[
         {
            "name":"",
            "action":"",
            "spin":false,
            "icon":[],
            "className":"d-none",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"modifier",
            "action":"goToEditActeTypeClientArea",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"modifier",
            "action":"goToEditActeTypeClientArea",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "payment_methods":{
      0:[
         {
            "name":"ajouter",
            "action":"goToAddPaymentMethod",
            "spin":false,
            "icon":["far","credit-card"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"modifier",
            "action":"goToEditPaymentMethod",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "note_liste":{
      0:[
         {
            "name":"ajouter",
            "action":"goToAddNote",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"modifier",
            "action":"goToEditNote",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"supprimer",
            "action":"goToDeleteNote",
            "spin":false,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":{
               "text_title":"note.suppression",
               "text_question":"note.confirmation_suppression",
               "text_message":"general.avertissement_suppression"
            },
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"partager",
            "action":"goToShareNote",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"envoyer",
            "action":"goToSendNote",
            "spin":false,
            "icon":["far","paper-plane"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"supprimer",
            "action":"goToDeleteNote",
            "spin":false,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":{
               "text_title":"note.suppression",
               "text_question":"note.confirmation_suppression",
               "text_message":"general.avertissement_suppression"
            },
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"envoyer",
            "action":"goToSendNote",
            "spin":false,
            "icon":["far","paper-plane"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "horse_note_liste":{
      0:[
         {
            "name":"ajouter",
            "action":"goToAddHorseFicheNote",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"modifier",
            "action":"goToEditNote",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"supprimer",
            "action":"goToDeleteNote",
            "spin":false,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":{
               "text_title":"note.suppression",
               "text_question":"note.confirmation_suppression",
               "text_message":"general.avertissement_suppression"
            },
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"partager",
            "action":"goToShareNote",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"envoyer",
            "action":"goToSendNote",
            "spin":false,
            "icon":["far","paper-plane"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"supprimer",
            "action":"goToDeleteNote",
            "spin":false,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":{
               "text_title":"note.suppression",
               "text_question":"note.confirmation_suppression",
               "text_message":"general.avertissement_suppression"
            },
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"partager",
            "action":"goToShareNote",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"envoyer",
            "action":"goToSendNote",
            "spin":false,
            "icon":["far","paper-plane"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "liste_courrier_type":{
      0:[
         {
            "name":"ajouter",
            "action":"goToAddCourrier",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"modifier",
            "action":"goToEditCourrier",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"supprimer",
            "action":"goToDeleteCourrier",
            "spin":true,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"dupliquer",
            "action":"goToDuplicateCourrier",
            "spin":false,
            "icon":["far","copy"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"supprimer",
            "action":"goToDeleteCourrier",
            "spin":true,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "liste_courrier_contrat":{
      0:[
         {
            "name":"ajouter",
            "action":"goToAddCourrier",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"modifier",
            "action":"goToEditCourrier",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"imprimer",
            "action":"goToPdfCourrier",
            "spin":true,
            "icon":["far","file-pdf"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"supprimer",
            "action":"goToDeleteCourrierFiche",
            "spin":true,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"supprimer",
            "action":"goToDeleteCourrierFiche",
            "spin":true,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "liste_courrier_type_fiche":{
      0:[
         {
            "name":"ajouter",
            "action":"goToAddCourrier",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"modifier",
            "action":"goToEditCourrier",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"imprimer",
            "action":"goToPdfCourrier",
            "spin":true,
            "icon":["far","file-pdf"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"signer",
            "action":"goToSignCourrier",
            "spin":true,
            "icon":["far","signature"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"supprimer",
            "action":"goToDeleteCourrier",
            "spin":true,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"supprimer",
            "action":"goToDeleteCourrier",
            "spin":true,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "tiers_suppliers_invoices_details":{
      0:[
         {
            "name":"ajouter",
            "action":"goToAddSupplierInvoiceDetails",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"modifier",
            "action":"goToEditSupplierInvoiceDetails",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "choix_horse":{
      0:[
         {
            "name":"",
            "action":"",
            "spin":false,
            "icon":[],
            "className":"d-none",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"valider",
            "action":"goToSelectHorses",
            "spin":false,
            "icon":["far","check"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"valider",
            "action":"goToSelectHorses",
            "spin":false,
            "icon":["far","check"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "contract_monte_resa":{
      0:[
         {
            "name":"ajouter",
            "action":"goToAddMonteResa",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"modifier",
            "action":"goToEditMonteResa",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"supprimer",
            "action":"goToDeleteResa",
            "spin":false,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"supprimer",
            "action":"goToDeleteResa",
            "spin":false,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "monte_resa_commande":{
      0:[
         {
            "name":"ajouter",
            "action":"goToAddResa",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"creer_bon_sortie",
            "action":"goToBonSortieResa",
            "spin":false,
            "icon":["fas","inbox-out"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"modifier",
            "action":"goToEditResa",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"supprimer",
            "action":"goToDeleteMonteResa",
            "spin":false,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":{
               "text_title":"monte_reservation.suppression_title",
               "text_question":"monte_reservation.suppression_confirmation",
               "text_message":"general.avertissement_suppression"
            },
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"modifier_centre",
            "action":"goToEditCentre",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"creer_bon_sortie",
            "action":"goToBonSortieResa",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"modifier_centre",
            "action":"goToEditCentre",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"supprimer",
            "action":"goToDeleteMonteResa",
            "spin":false,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":{
               "text_title":"monte_reservation.suppression_title",
               "text_question":"monte_reservation.suppression_confirmation",
               "text_message":"general.avertissement_suppression"
            },
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "monte_resa_bon":{
      0:[
         {
            "name":"ajouter",
            "action":"goToAddResa",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"notifier_centre_stockage",
            "action":"goToNotifyExpediteur",
            "spin":false,
            "icon":["fal","envelope"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"confirmer_expedie",
            "action":"goToAckExpedition",
            "spin":false,
            "icon":["fad","truck"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"modifier_centre",
            "action":"goToEditCentre",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"modifier",
            "action":"goToEditResa",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"notifier_centre_stockage",
            "action":"goToNotifyExpediteur",
            "spin":false,
            "icon":["fal","envelope"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"confirmer_expedie",
            "action":"goToAckExpedition",
            "spin":false,
            "icon":["fad","truck"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"modifier_centre",
            "action":"goToEditCentre",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "monte_resa_suivi":{
      0:[
         {
            "name":"ajouter",
            "action":"goToAddResa",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"confirmer_livraison",
            "action":"goToAckDelivered",
            "spin":false,
            "icon":["fas","shipping-fast"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"modifier_centre",
            "action":"goToEditCentre",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"modifier",
            "action":"goToEditResaBis",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"non_livree",
            "action":"goToAckNotDelivered",
            "spin":true,
            "icon":["far","dolly-empty"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
      ],
      2:[
         {
            "name":"confirmer_livraison",
            "action":"goToAckDelivered",
            "spin":true,
            "icon":["fas","shipping-fast"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"modifier_centre",
            "action":"goToEditCentre",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"non_livree",
            "action":"goToAckNotDelivered",
            "spin":true,
            "icon":["far","dolly-empty"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
      ]
   },
   "monte_resa_delivered":{
      0:[
         {
            "name":"ajouter",
            "action":"goToAddResa",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"modifier",
            "action":"goToEditResaBis",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"modifier_centre",
            "action":"goToEditCentre",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"modifier_commande",
            "action":"goToEditMonteOrder",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"non_livree",
            "action":"goToAckNotDelivered",
            "spin":true,
            "icon":["far","dolly-empty"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"modifier_centre",
            "action":"goToEditCentre",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "monte_resa_not_delivered":{
      0:[
         {
            "name":"ajouter",
            "action":"goToAddResa",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"modifier",
            "action":"goToEditResaBis",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"modifier_centre",
            "action":"goToEditCentre",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"modifier_commande",
            "action":"goToEditMonteOrder",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"confirmer_livraison",
            "action":"goToAckDelivered",
            "spin":true,
            "icon":["fas","shipping-fast"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
      ],
      2:[
         {
            "name":"modifier_centre",
            "action":"goToEditCentre",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "horse_actes_result":{
      0:[
         {
            "name":"ajouter_acte",
            "action":"goToAddActe",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"modifier",
            "action":"goToEditResult",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"ajouter_acte",
            "action":"goToAddActe",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"ajouter_note",
            "action":"goToAddResultNote",
            "spin":false,
            "icon":["far","sticky-note"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"modifier",
            "action":"goToEditResult",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"ajouter_acte",
            "action":"goToAddActe",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"ajouter_note",
            "action":"goToAddResultNote",
            "spin":false,
            "icon":["far","sticky-note"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "horse_actes_result_global":{
      0:[
         {
            "name":"",
            "action":"",
            "spin":false,
            "icon":[],
            "className":"d-none",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"modifier",
            "action":"goToEditResult",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"modifier",
            "action":"goToEditResult",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "accounting_payment_account":{
      0:[
         {
            "name":"ajouter",
            "action":"goToAddAccountingPaymentAccount",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"modifier",
            "action":"goToEditAccountingPaymentAccount",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "tiers_horse_tiers_secondaires":{
      0:[
         {
            "name":"ajouter",
            "action":"goToAddTiersSecondaires",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"modifier",
            "action":"goToEditTiersSecondaires",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"supprimer",
            "action":"goToDeleteTiersSecondaires",
            "spin":false,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"supprimer",
            "action":"goToDeleteTiersSecondaires",
            "spin":false,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "bons_doublons":{
      0:[
         {
            "name":"",
            "action":"",
            "spin":false,
            "icon":[],
            "className":"d-none",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"annuler",
            "action":"goToCancelInvoiceDetail",
            "spin":false,
            "icon":["far","history"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"annuler",
            "action":"goToCancelInvoiceDetail",
            "spin":false,
            "icon":["far","history"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"dedoublonner",
            "action":"goToDedoublonnerInvoiceDetail",
            "spin":false,
            "icon":["far","history"],
            "className":"btn-secondary",
            "modal_options":{
               "text_title":"invoice.bons.dedoublonner",
               "text_question":"invoice.bons.confirmation_dedoublonner",
               "text_message":"invoice.bons.avertissement_dedoublonner",
               "text_button_cancel":"invoice.bons.cancel_button"
            },
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "acte_to_delete":{
      0:[
         {
            "name":"",
            "action":"",
            "spin":false,
            "icon":[],
            "className":"d-none",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"supprimer",
            "action":"goToDeleteActeTrigger",
            "spin":true,
            "icon":["far","trash-alt"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"supprimer",
            "action":"goToDeleteActeTrigger",
            "spin":true,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"supprimer",
            "action":"goToDeleteActeTrigger",
            "spin":true,
            "icon":["far","trash-alt"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"supprimer",
            "action":"goToDeleteActeTrigger",
            "spin":true,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "tiers_relations":{
      0:[
         {
            "name":"ajouter",
            "action":"goToAddRelations",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"modifier",
            "action":"goToEditRelations",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"supprimer",
            "action":"goToDeleteRelations",
            "spin":false,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"supprimer",
            "action":"goToDeleteRelations",
            "spin":false,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "note_notify_ca":{
      0:[
         {
            "name":"",
            "action":"",
            "spin":false,
            "icon":[],
            "className":"d-none",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"notify",
            "action":"goToNotifyNote",
            "spin":false,
            "icon":["far","bell"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"notify",
            "action":"goToNotifyNote",
            "spin":false,
            "icon":["far","bell"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "declaration_sanitaire":{
      0:[
         {
            "name":"",
            "action":"",
            "spin":false,
            "icon":[],
            "className":"d-none",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"envoyer",
            "action":"goToSendSanitaire",
            "spin":true,
            "icon":["fal","upload"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"envoyer",
            "action":"goToSendSanitaire",
            "spin":true,
            "icon":["fal","upload"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "jumenterie":{
      0:[
         {
            "name":"add_cross",
            "action":"goToAddCross",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"print_infos_horse",
            "action":"goToPrintInfosHorseJumenterie",
            "spin":false,
            "icon":["far","file-pdf"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"imprimer_suivi",
            "action":"goToPdfSuivi",
            "spin":false,
            "icon":["far","file-pdf"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"act_dg_lot",
            "action":"goToActDgLot",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-secondary text-dark text-left",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":"ajouter_acte"
         },
         {
            "name":"changer_num_dps",
            "action":"goToChangeDpsNum",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"archive",
            "action":"goToArchiveCouple",
            "spin":false,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options": {
               "text_title": "gynecologie.archivage",
               "text_question": "gynecologie.confirmation_archivage",
               "text_message": "gynecologie.message_archivage"
            },            
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"envoyer",
            "action":"goToSendDg",
            "spin":true,
            "icon":["far","paper-plane"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"ajouter_acte_donneuse",
            "action":"goToAddActeDonneuse",
            "spin":false,
            "icon":["far","tasks"],
            "className":"btn-secondary text-dark text-left",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":"ajouter_acte"
         },
         {
            "name":"ajouter_acte_porteuse",
            "action":"goToAddActePorteuse",
            "spin":false,
            "icon":["far","tasks"],
            "className":"btn-secondary text-dark text-left",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":"ajouter_acte"
         },
         {
            "name":"interroger_dps",
            "action":"goToAskDPS",
            "spin":false,
            "icon":["fal","baby"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"print_infos_horse",
            "action":"goToPrintInfosHorseJumenterie",
            "spin":false,
            "icon":["far","file-pdf"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"imprimer_suivi",
            "action":"goToPdfSuivi",
            "spin":false,
            "icon":["far","file-pdf"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"act_dg_lot",
            "action":"goToActDgLot",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-secondary text-dark text-left",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":"ajouter_acte"
         },
         {
            "name":"archive",
            "action":"goToArchiveCouple",
            "spin":false,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options": {
               "text_title": "gynecologie.archivage",
               "text_question": "gynecologie.confirmation_archivage",
               "text_message": "gynecologie.message_archivage"
            },            
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"envoyer",
            "action":"goToSendDg",
            "spin":true,
            "icon":["far","paper-plane"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"ajouter_acte_donneuse",
            "action":"goToAddActeDonneuse",
            "spin":false,
            "icon":["far","tasks"],
            "className":"btn-secondary text-dark text-left",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":"ajouter_acte"
         },
         {
            "name":"ajouter_acte_porteuse",
            "action":"goToAddActePorteuse",
            "spin":false,
            "icon":["far","tasks"],
            "className":"btn-secondary text-dark text-left",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":"ajouter_acte"
         },
         {
            "name":"interroger_dps",
            "action":"goToAskDPS",
            "spin":false,
            "icon":["fal","baby"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "tiers_archive":{
      0:[
         {
            "name":"",
            "action":"",
            "spin":false,
            "icon":[],
            "className":"d-none",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"unarchive_tiers",
            "action":"goToUnarchiveTiers",
            "spin":false,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"unarchive_tiers",
            "action":"goToUnarchiveTiers",
            "spin":false,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "contract_client_area":{
      0:[
         {
            "name":"ajouter_contract",
            "action":"goToAddContract",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary btn-lg",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"imprimer",
            "action":"goToPrintContract",
            "spin":false,
            "icon":["fas","download"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"ajouter_contract",
            "action":"goToAddContract",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary btn-lg",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "unacknowledged_horses_client_area":{
      0:[
         {
            "name":"",
            "action":"",
            "spin":false,
            "icon":[],
            "className":"d-none",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"valider",
            "action":"goToValidHorse",
            "spin":true,
            "icon":["far","check"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"valider",
            "action":"goToValidHorse",
            "spin":true,
            "icon":["far","check"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "contacts_list":{
      0:[
         {
            "name":"ajouter",
            "action":"goToAddContact",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"modifier",
            "action":"goToEditContact",
            "spin":false,
            "icon":["far","user"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"archive",
            "action":"goToArchiveContact",
            "spin":false,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"archive",
            "action":"goToArchiveContact",
            "spin":false,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "check_deposit_paiement":{
      0:[
         {
            "name":"",
            "action":"",
            "spin":false,
            "icon":[],
            "className":"d-none",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"valider",
            "action":"goToCheckDepositConfig",
            "spin":false,
            "icon":["far","check"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"valider",
            "action":"goToCheckDepositConfig",
            "spin":false,
            "icon":["far","check"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "contract_mare_client_area":{
      0:[
         {
            "name":"ajouter_contrat",
            "action":"goToAddContract",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary btn-lg",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"imprimer",
            "action":"goToPrintContract",
            "spin":false,
            "icon":["fas","download"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"ajouter_contrat",
            "action":"goToAddContract",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary btn-lg",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "embryons_season":{
      0:[
         {
            "name":"",
            "action":"",
            "spin":false,
            "icon":[],
            "className":"d-none",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"modifier",
            "action":"goToEditEmbryon",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"supprimer",
            "action":"goToDeleteEmbryon",
            "spin":false,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "tiers_invoices_dashboard":{
      0:[
         {
            "name":"",
            "action":"",
            "spin":false,
            "icon":[],
            "className":"d-none",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"imprimer",
            "action":"goToPdfInvoice",
            "spin":false,
            "icon":["far","file-pdf"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"envoyer",
            "action":"goToSendInvoice",
            "spin":false,
            "icon":["far","paper-plane"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"reglement",
            "action":"goToAddPayment",
            "spin":false,
            "icon":["far","money-bill-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"export_comptable",
            "action":"goToExportComptable",
            "spin":false,
            "icon":["far","receipt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"avoir",
            "action":"goToModalFactureAvoir",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"accounted",
            "action":"goToAccounted",
            "spin":false,
            "icon":["fal","check"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"no_print",
            "action":"goToNoPrint",
            "spin":false,
            "icon":["fal","times-circle"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"imprimer",
            "action":"goToPdfInvoice",
            "spin":false,
            "icon":["far","file-pdf"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"reglement",
            "action":"goToAddPayment",
            "spin":false,
            "icon":["far","money-bill-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"export_comptable",
            "action":"goToExportComptable",
            "spin":false,
            "icon":["far","receipt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"avoir",
            "action":"goToModalFactureAvoir",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"accounted",
            "action":"goToAccounted",
            "spin":false,
            "icon":["fal","check"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"no_print",
            "action":"goToNoPrint",
            "spin":false,
            "icon":["fal","times-circle"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"envoyer",
            "action":"goToSendInvoice",
            "spin":false,
            "icon":["far","paper-plane"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"telecharger",
            "action":"goToDownloadPdfInvoice",
            "spin":true,
            "icon":["fas","download"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "invoices":{
      0:[
         {
            "name":"",
            "action":"",
            "spin":false,
            "icon":[],
            "className":"d-none",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"imprimer",
            "action":"goToPdfInvoice",
            "spin":true,
            "icon":["far","file-pdf"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"imprimer_avec_acompte",
            "action":"goToPdfInvoiceWithAdvancedPayment",
            "spin":true,
            "icon":["far","file-pdf"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"no_print",
            "action":"goToNoPrint",
            "spin":false,
            "icon":["fal","times-circle"],
            "className":"btn-secondary text-dark text-left",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":"operation"
         },
         {
            "name":"envoyer",
            "action":"goToSendInvoice",
            "spin":true,
            "icon":["far","paper-plane"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"reglement",
            "action":"goToAddPayment",
            "spin":false,
            "icon":["far","money-bill-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"invoice_saillie",
            "action":"goToModifyInvoiceSaillie",
            "spin":false,
            "icon":["fal", "venus-mars"],
            "className":"btn-secondary text-dark text-left",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":"operation"
         },
         {
            "name":"avoir",
            "action":"goToModalFactureAvoir",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary text-dark text-left",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":"operation"
         },
         {
            "name":"edit_qualif",
            "action":"goToEditQualificationTiers",
            "spin":false,
            "icon":["far","user"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"duplicate",
            "action":"goToDuplicateInvoice",
            "spin":true,
            "icon":["far","copy"],
            "className":"btn-secondary text-dark text-left",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":"operation"
         },
         {
            "name":"export_comptable",
            "action":"goToExportComptable",
            "spin":true,
            "icon":["far","receipt"],
            "className":"btn-secondary text-dark text-left",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":"operation"
         },
         {
            "name":"accounted",
            "action":"goToAccounted",
            "spin":false,
            "icon":["fal","check"],
            "className":"btn-secondary text-dark text-left",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":"operation"
         },
         {
            "name":"supprimer",
            "action":"goToDeleteInvoice",
            "spin":false,
            "icon":["far","trash-alt"],
            "className":"btn-secondary text-dark text-left",
            "modal_options":{
               "text_title":"invoice.suppression",
               "text_question":"invoice.confirmation_suppression",
               "text_message":"general.avertissement_suppression"
            },
            "condition":null,
            "rights":"MVP",
            "dropdown_name":"operation"
         },
         {
            "name":"send_ca_invitation",
            "action":"goToSendMailCAInvitation",
            "spin":true,
            "icon":["fal","user-tie"],
            "className":"btn-secondary",
            "modal_options":{
               "text_title":"invoice.invitation_ca",
               "text_question": "invoice.invitation_ca_question",
               "text_message":"invoice.empty"
            },
            "condition":[
               "hasClientArea"
            ],
            "rights":"DUCA",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"imprimer",
            "action":"goToPdfInvoice",
            "spin":true,
            "icon":["far","file-pdf"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"imprimer_avec_acompte",
            "action":"goToPdfInvoiceWithAdvancedPayment",
            "spin":true,
            "icon":["far","file-pdf"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"no_print",
            "action":"goToNoPrint",
            "spin":false,
            "icon":["fal","times-circle"],
            "className":"btn-secondary text-dark text-left",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":"operation"
         },
         {
            "name":"envoyer",
            "action":"goToSendInvoice",
            "spin":true,
            "icon":["far","paper-plane"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"reglement",
            "action":"goToAddPayment",
            "spin":false,
            "icon":["far","money-bill-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"invoice_saillie",
            "action":"goToModifyInvoiceSaillie",
            "spin":false,
            "icon":["fal", "venus-mars"],
            "className":"btn-secondary text-dark text-left",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":"operation"
         },
         {
            "name":"avoir",
            "action":"goToModalFactureAvoir",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary text-dark text-left",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":"operation"
         },
         {
            "name":"edit_qualif",
            "action":"goToEditQualificationTiers",
            "spin":false,
            "icon":["far","user"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"duplicate",
            "action":"goToDuplicateInvoice",
            "spin":true,
            "icon":["far","copy"],
            "className":"btn-secondary text-dark text-left",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":"operation"
         },
         {
            "name":"export_comptable",
            "action":"goToExportComptable",
            "spin":true,
            "icon":["far","receipt"],
            "className":"btn-secondary text-dark text-left",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":"operation"
         },
         {
            "name":"accounted",
            "action":"goToAccounted",
            "spin":false,
            "icon":["fal","check"],
            "className":"btn-secondary text-dark text-left",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":"operation"
         },
         {
            "name":"supprimer",
            "action":"goToDeleteInvoice",
            "spin":false,
            "icon":["far","trash-alt"],
            "className":"btn-secondary text-dark text-left",
            "modal_options":{
               "text_title":"invoice.suppression",
               "text_question":"invoice.confirmation_suppression",
               "text_message":"general.avertissement_suppression"
            },
            "condition":null,
            "rights":"MVP",
            "dropdown_name":"operation"
         },
         {
            "name":"telecharger",
            "action":"goToDownloadPdfInvoice",
            "spin":true,
            "icon":["fas","download"],
            "className":"btn-secondary text-dark text-left",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":"operation"
         },
         {
            "name":"send_ca_invitation",
            "action":"goToSendMailCAInvitation",
            "spin":true,
            "icon":["fal","user-tie"],
            "className":"btn-secondary",
            "modal_options":{
               "text_title":"invoice.invitation_ca",
               "text_question": "invoice.invitation_ca_question",
               "text_message":"invoice.empty"
            },
            "condition":[
               "hasClientArea"
            ],
            "rights":"DUCA",
            "dropdown_name":null
         }
      ]
   },
   "contacts_archive_list":{
      0:[
         {
            "name":"",
            "action":"",
            "spin":false,
            "icon":[],
            "className":"d-none",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"unarchive",
            "action":"goToUnarchiveContact",
            "spin":false,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"unarchive",
            "action":"goToUnarchiveContact",
            "spin":false,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "pension_liste":{
      0:[
         {
            "name":"print_pension",
            "action":"goToPrintPensions",
            "spin":false,
            "icon":["far","file-pdf"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "season_contract_config_modeles":{
      0:[
         {
            "name":"ajouter",
            "action":"goToAddContractConfigModel",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"ajouter",
            "action":"goToAddContractConfigModel",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"modifier",
            "action":"goToEditContractConfigModel",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"distribute",
            "action":"goToDistributeSeasonModel",
            "spin":false,
            "icon":["far","paper-plane"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"supprimer",
            "action":"goToDeleteSeasonModel",
            "spin":true,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":{
               "text_title":"model_season.tilte",
               "text_question":"model_season.confirmation_suppression",
               "text_message":"model_season.avertissement_suppression"
            },
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "season_distribute":{
      0:[
         {
            "name":"ajouter",
            "action":"goToAddStallionToModeleParent",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"ajouter",
            "action":"goToAddStallionToModeleParent",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"synchroniser_parent_enfant",
            "action":"goToExecDistributeSeasonModel",
            "spin":true,
            "icon":["far","paper-plane"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"ajouter",
            "action":"goToAddStallionToModeleParent",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"synchroniser_parent_enfant",
            "action":"goToExecDistributeSeasonModel",
            "spin":true,
            "icon":["far","paper-plane"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "season_contract_config_conditions":{
      0:[
         {
            "name":"ajouter",
            "action":"goToAddConditionsToContractConfigGlobal",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"ajouter",
            "action":"goToAddConditionsToContractConfigGlobal",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"modifier",
            "action":"goToEditConditionsContractConfigGlobal",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"distribute",
            "action":"goToDistributeConditionsContractConfigGlobal",
            "spin":false,
            "icon":["far","paper-plane"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"supprimer",
            "action":"goToDeleteConditionsContractConfigGlobal",
            "spin":true,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":{
               "text_title":"ccc_global.suppression",
               "text_question":"ccc_global.confirmation_suppression",
               "text_message":"ccc_global.avertissement_suppression"
            },
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"ajouter",
            "action":"goToAddConditionsToContractConfigGlobal",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"modifier",
            "action":"goToEditConditionsContractConfigGlobal",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "type_monte_distribute":{
      0:[
         {
            "name":"ajouter",
            "action":"goToAddStallionToConditionsParent",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"ajouter",
            "action":"goToAddStallionToConditionsParent",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"synchroniser_parent_enfant",
            "action":"goToExecDistributeConditions",
            "spin":true,
            "icon":["far","paper-plane"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"archive_unarchive",
            "action":"goToArchiveContractConfigTypeMonte",
            "spin":true,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"ajouter",
            "action":"goToAddStallionToConditionsParent",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"synchroniser_parent_enfant",
            "action":"goToExecDistributeConditions",
            "spin":true,
            "icon":["far","paper-plane"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "contract_config_stallions":{
      0:[
         {
            "name":"ajouter",
            "action":"goToAddStallionToContractConfigGlobal",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"ajouter",
            "action":"goToAddStallionToContractConfigGlobal",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"supprimer",
            "action":"goToDeleteStallionToContractConfigGlobal",
            "spin":true,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"ajouter",
            "action":"goToAddStallionToContractConfigGlobal",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"supprimer",
            "action":"goToDeleteStallionToContractConfigGlobal",
            "spin":true,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "localisation_semence":{
      0:[
         {
            "name":"ajouter",
            "action":"goToAddLocSemence",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"reorganiser",
            "action":"goToReorganizeParent",
            "spin":false,
            "icon":["far","sort"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"modifier",
            "action":"goToEditLocSemence",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"supprimer",
            "action":"goToDeleteLocSemence",
            "spin":false,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":{
               "text_title":"locsemence.suppression",
               "text_question":"locsemence.confirmation_suppression",
               "text_message":"locsemence.avertissement_suppression"
            },
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"reorganiser",
            "action":"goToReorganizeChildren",
            "spin":false,
            "icon":["far","sort"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"supprimer",
            "action":"goToDeleteLocSemence",
            "spin":false,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":{
               "text_title":"locsemence.suppression",
               "text_question":"locsemence.confirmation_suppression_many",
               "text_message":"locsemence.avertissement_suppression"
            },
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "semence_tiers":{
      0:[
         {
            "name":"inventaire",
            "action":"goToInventaireStock",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"retour_stock",
            "action":"goToRetourStocks",
            "spin":false,
            "icon":["far","undo"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1: [
         {
            "name":"history",
            "action":"goToHistoryStock",
            "spin":false,
            "icon":["far","history"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "semence_tiers_history": {
      0: [
         {
            "name":"",
            "action":"",
            "spin":false,
            "icon":[],
            "className":"d-none",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1: [
         {
            "name":"supprimer",
            "action":"goToDeleteStockTiers",
            "spin":true,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":{
               "text_title":"stock.suppression_stock_distant",
               "text_message":"stock.suppression_stock_distant_confirmation"
            },
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "plan_cuve":{
      0:[
         {
            "name":"export_global_cuve",
            "action":"goToExportGlobalCuve",
            "spin":true,
            "icon":["fal","download"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"export_global_cuve",
            "action":"goToExportGlobalCuve",
            "spin":true,
            "icon":["fal","download"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"export_global_cuve",
            "action":"goToExportGlobalCuve",
            "spin":true,
            "icon":["fal","download"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
   },
   "stock_local":{
      0:[
         {
            "name":"import_stock",
            "action":"goToImportStock",
            "spin":false,
            "icon":["fal","download"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"sortie",
            "action":"goToSortieStock",
            "spin":false,
            "icon":["fal","upload"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"import_stock",
            "action":"goToImportStock",
            "spin":false,
            "icon":["fal","download"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"sortie",
            "action":"goToSortieStock",
            "spin":false,
            "icon":["fal","upload"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"import_stock",
            "action":"goToImportStock",
            "spin":false,
            "icon":["fal","download"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"sortie",
            "action":"goToSortieStock",
            "spin":false,
            "icon":["fal","upload"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "stock_stallion":{
      0:[
         {
            "name":"ajouter",
            "action":"goToAddStockStallion",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"destruction",
            "action":"goToDestroyStock",
            "spin":true,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"modifier",
            "action":"goToEditStock",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"destruction",
            "action":"goToDestroyStock",
            "spin":true,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "select_tiers":{
      0:[
         {
            "name":"",
            "action":"",
            "spin":false,
            "icon":[],
            "className":"d-none",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"choisir_lieu",
            "action":"goToSelectLieuStationnement",
            "spin":false,
            "icon":["fal","check"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "payment_regul":{
      0:[
         {
            "name":"",
            "action":"",
            "spin":false,
            "icon":[],
            "className":"d-none",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"mark_as_regulated",
            "action":"goToMarkAsRegulated",
            "spin":false,
            "icon":["fal","check"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"mark_as_regulated",
            "action":"goToMarkAsRegulated",
            "spin":false,
            "icon":["fal","check"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "tiers_group":{
      0:[
         {
            "name":"ajouter",
            "action":"goToAddTiersGroup",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"ajouter",
            "action":"goToAddTiersGroup",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"supprimer",
            "action":"goToDeleteTiersGroup",
            "spin":true,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"fusionner",
            "action":"goToFusionTiers",
            "spin":false,
            "icon":["far","copy"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"ajouter",
            "action":"goToAddTiersGroup",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"supprimer",
            "action":"goToDeleteTiersGroup",
            "spin":true,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "horse_product":{
      0:[
         {
            "name":"import_descendants",
            "action":"goToImportDescendants",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "horse_product_sireless":{
      0:[
         {
            "name":"",
            "action":"",
            "spin":false,
            "icon":[],
            "className":"d-none",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
   },
   "archived_accounting":{
      0:[
         {
            "name":"",
            "action":"",
            "spin":false,
            "icon":[],
            "className":"d-none",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"unarchive",
            "action":"goToUnarchiveAccounting",
            "spin":false,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"unarchive",
            "action":"goToUnarchiveAccounting",
            "spin":false,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "qualif_tiers":{
      0:[
         {
            "name":"",
            "action":"",
            "spin":false,
            "icon":[],
            "className":"d-none",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         
         {
            "name":"print_infos_tiers",
            "action":"goToPrintInfosTiers",
            "spin":false,
            "icon":["far","file-pdf"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"manage_qualifs",
            "action":"goToManageQualifsTiers",
            "spin":false,
            "icon":["fal","tools"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"print_infos_tiers",
            "action":"goToPrintInfosTiers",
            "spin":false,
            "icon":["far","file-pdf"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"manage_qualifs",
            "action":"goToManageQualifsTiers",
            "spin":false,
            "icon":["fal","tools"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "demande_analyse":{
      0:[
         {
            "name":"",
            "action":"",
            "spin":false,
            "icon":[],
            "className":"d-none",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"modifier",
            "action":"goToAddEditDemandeAnalyse",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"modifier",
            "action":"goToAddEditDemandeAnalyse",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "horse_clientarea":{
      0:[
         {
            "name":"ajouter_horse",
            "action":"goToAddHorse",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary btn-lg",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"hide",
            "action":"goToHideHorse",
            "spin":false,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"ajouter_horse",
            "action":"goToAddHorse",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary btn-lg",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"hide",
            "action":"goToHideHorse",
            "spin":false,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"ajouter_horse",
            "action":"goToAddHorse",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary btn-lg",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "embryons_available":{
      0:[
         {
            "name":"ajouter",
            "action":"goToAddEmbryon",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"ajouter",
            "action":"goToAddEmbryon",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"modifier",
            "action":"goToEditEmbryon",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"supprimer",
            "action":"goToDeleteEmbryon",
            "spin":false,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"expedier",
            "action":"goToExpditionEmbryon",
            "spin":false,
            "icon":["fad","truck"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"ajouter",
            "action":"goToAddEmbryon",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"expedier",
            "action":"goToExpditionEmbryon",
            "spin":false,
            "icon":["fad","truck"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "horse_without_act":{
      0:[
         {
            "name":"",
            "action":"",
            "spin":false,
            "icon":[],
            "className":"d-none",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"ajouter_acte",
            "action":"goToAddActe",
            "spin":false,
            "icon":["far","tasks"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"ajouter_acte",
            "action":"goToAddActe",
            "spin":false,
            "icon":["far","tasks"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "expeditions_clientarea":{
      0:[
         {
            "name":"",
            "action":"",
            "spin":false,
            "icon":[],
            "className":"d-none",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"demande_traitee",
            "action":"goToConfirmOpen",
            "spin":true,
            "icon":["fal","envelope"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"demande_traitee",
            "action":"goToConfirmOpen",
            "spin":true,
            "icon":["fal","envelope"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "quotation":{
      0:[
         {
            "name":"ajouter",
            "action":"goToAddQuotation",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"modifier",
            "action":"goToEditQuotation",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"envoyer",
            "action":"goToSendQuotation",
            "spin":false,
            "icon":["far","paper-plane"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"imprimer",
            "action":"goToPdfQuotation",
            "spin":true,
            "icon":["far","file-pdf"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"convertir",
            "action":"goToConvertToBAF",
            "spin":true,
            "icon":["far","check"],
            "className":"btn-secondary",
            "modal_options":{
               "text_title":"devis.convert_title",
               "text_message":"devis.convert_confirmation"
            },
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"dupliquer",
            "action":"goToDuplicateQuotation",
            "spin":false,
            "icon":["far","clone"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "quotation_document":{
      0:[
         {
            "name":"ajouter",
            "action":"goToAddQuotationDocument",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"modifier",
            "action":"goToEditQuotationDocument",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"supprimer",
            "action":"goToDeleteQuotationDocument",
            "spin":true,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":{
               "text_title":"devis.suppression_document",
               "text_message":"devis.suppression_document_confirmation"
            },
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"telecharger",
            "action":"goToDownloadQuotationDocument",
            "spin":true,
            "icon":["fas","download"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"envoyer",
            "action":"goToSendQuotationDocument",
            "spin":true,
            "icon":["far","paper-plane"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "liste_courrier_type_tiers":{
      0:[
         {
            "name":"ajouter",
            "action":"goToAddCourrier",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"modifier",
            "action":"goToEditCourrier",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"imprimer",
            "action":"goToPdfCourrier",
            "spin":true,
            "icon":["far","file-pdf"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"supprimer",
            "action":"goToDeleteCourrier",
            "spin":true,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"envoyer",
            "action":"goToSendCourrierTiers",
            "spin":true,
            "icon":["far","paper-plane"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"supprimer",
            "action":"goToDeleteCourrier",
            "spin":true,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "contract_document":{
      0:[
         {
            "name":"ajouter",
            "action":"goToAddContractDocument",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"modifier",
            "action":"goToEditContractDocument",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"supprimer",
            "action":"goToDeleteContractDocument",
            "spin":true,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":{
               "text_title":"devis.suppression_document",
               "text_message":"devis.suppression_document_confirmation"
            },
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"telecharger",
            "action":"goToDownloadContractDocument",
            "spin":true,
            "icon":["fas","download"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"envoyer",
            "action":"goToSendContractDocument",
            "spin":true,
            "icon":["far","paper-plane"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2: [
         {
            "name":"envoyer",
            "action":"goToSendContractDocuments",
            "spin":true,
            "icon":["far","paper-plane"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "choix_tiers":{
      0:[
         {
            "name":"",
            "action":"",
            "spin":false,
            "icon":[],
            "className":"d-none",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"valider",
            "action":"goToSelectTiers",
            "spin":false,
            "icon":["far","check"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"valider",
            "action":"goToSelectTiers",
            "spin":false,
            "icon":["far","check"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "tiers_note_liste":{
      0:[
         {
            "name":"ajouter",
            "action":"goToAddTiersFicheNote",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"modifier",
            "action":"goToEditNote",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"partager",
            "action":"goToShareNote",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"supprimer",
            "action":"goToDeleteNote",
            "spin":false,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":{
               "text_title":"note.suppression",
               "text_question":"note.confirmation_suppression",
               "text_message":"general.avertissement_suppression"
            },
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"envoyer",
            "action":"goToSendNote",
            "spin":false,
            "icon":["far","paper-plane"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"supprimer",
            "action":"goToDeleteNote",
            "spin":false,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":{
               "text_title":"note.suppression",
               "text_question":"note.confirmation_suppression",
               "text_message":"general.avertissement_suppression"
            },
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"envoyer",
            "action":"goToSendNote",
            "spin":false,
            "icon":["far","paper-plane"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "historique_syndic_part":{
      0:[
         {
            "name":"",
            "action":"",
            "spin":false,
            "icon":[],
            "className":"d-none",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"modifier_date",
            "action":"goToEditDateCession",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "imported_horse":{
      0:[
         {
            "name":"",
            "action":"",
            "spin":false,
            "icon":[],
            "className":"d-none",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"ajouter_acte",
            "action":"goToAddActe",
            "spin":false,
            "icon":["far","tasks"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"ajouter_mouvement",
            "action":"goToAddMouvement",
            "spin":false,
            "icon":["far","route"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"add_pension",
            "action":"goToAddPension",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"ajouter_acte",
            "action":"goToAddActe",
            "spin":false,
            "icon":["far","tasks"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"ajouter_mouvement",
            "action":"goToAddMouvement",
            "spin":false,
            "icon":["far","route"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"add_pension",
            "action":"goToAddPension",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "vehicule":{
      0:[
         {
            "name":"ajouter",
            "action":"goToAddVehicule",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"modifier",
            "action":"goToEditVehicule",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"archive_unarchive",
            "action":"goToArchiveVehicule",
            "spin":false,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
      ]
   },
   "deplacements":{
      0:[
         {
            "name":"ajouter",
            "action":"goToAddDeplacement",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"modifier",
            "action":"goToEditDeplacement",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"imprimer",
            "action":"goToPdfDeplacement",
            "spin":true,
            "icon":["far","file-pdf"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"supprimer",
            "action":"goToDeleteDeplacement",
            "spin":false,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":{
               "text_title":"deplacement.suppression",
               "text_question":"deplacement.confirmation_suppression",
               "text_message":"general.avertissement_suppression"
            },
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"supprimer",
            "action":"goToDeleteDeplacement",
            "spin":false,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":{
               "text_title":"deplacement.suppression",
               "text_question":"deplacement.confirmation_suppression",
               "text_message":"general.avertissement_suppression"
            },
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "suggestion_deplacement":{
      0:[
         {
            "name":"",
            "action":"",
            "spin":false,
            "icon":[],
            "className":"d-none",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"ajouter_deplacement",
            "action":"goToAddDeplacementFromSuggestion",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"ajouter_deplacement",
            "action":"goToAddDeplacementFromSuggestion",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "suggestion_mouvement_saillie_stallion":{
      0:[
         {
            "name":"",
            "action":"",
            "spin":false,
            "icon":[],
            "className":"d-none",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"ajouter_mouvement",
            "action":"goToAddMouvementFromSuggestion",
            "spin":true,
            "icon":["far","route"],
            "className":"btn-secondary",
            "modal_options":{
               "text_title":"suggestion_saillie.title",
               "text_question":"suggestion_saillie.question",
               "text_message": "suggestion_saillie.message"
            },
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"ajouter_mouvement",
            "action":"goToAddMouvementFromSuggestion",
            "spin":true,
            "icon":["far","route"],
            "className":"btn-secondary",
            "modal_options":{
               "text_title":"suggestion_saillie.title",
               "text_question":"suggestion_saillie.question",
               "text_message": "suggestion_saillie.message"
            },
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "suggestion_mouvement_saillie_mare":{
      0:[
         {
            "name":"",
            "action":"",
            "spin":false,
            "icon":[],
            "className":"d-none",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"ajouter_mouvement",
            "action":"goToAddMouvementFromSuggestion",
            "spin":true,
            "icon":["far","route"],
            "className":"btn-secondary",
            "modal_options":{
               "text_title":"suggestion_saillie.title",
               "text_question":"suggestion_saillie.question",
               "text_message": "suggestion_saillie.message"
            },
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"ajouter_mouvement",
            "action":"goToAddMouvementFromSuggestion",
            "spin":true,
            "icon":["far","route"],
            "className":"btn-secondary",
            "modal_options":{
               "text_title":"suggestion_saillie.title",
               "text_question":"suggestion_saillie.question",
               "text_message": "suggestion_saillie.message"
            },
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "invoice_to_solde":{
      0:[
         {
            "name":"",
            "action":"",
            "spin":false,
            "icon":[],
            "className":"d-none",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"solde_facture",
            "action":"goToSoldeInvoice",
            "spin":true,
            "icon":["far","credit-card"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"solde_facture",
            "action":"goToSoldeInvoice",
            "spin":true,
            "icon":["far","credit-card"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "emails_mandrill":{
      0:[
         {
            "name":"",
            "action":"",
            "spin":false,
            "icon":[],
            "className":"d-none",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"archive_tracking",
            "action":"goToArchiveTracking",
            "spin":false,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"archive_tracking",
            "action":"goToArchiveTracking",
            "spin":false,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "stock_local_historique": {
      0:[
         {
            "name":"",
            "action":"",
            "spin":false,
            "icon":[],
            "className":"d-none",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"courrier_stock",
            "action":"goToCourrierStock",
            "spin":true,
            "icon":["far","file-pdf"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"modifier",
            "action":"goToEditStockFlux",
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"courrier_stock",
            "action":"goToCourrierStock",
            "spin":true,
            "icon":["far","file-pdf"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "import_dn_as": {
      0:[
         {
            "name":"",
            "action":"",
            "spin":false,
            "icon":[],
            "className":"d-none",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"importer",
            "action":"goToImportDNAS",
            "spin":true,
            "icon":["fal","download"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"importer",
            "action":"goToImportDNAS",
            "spin":true,
            "icon":["fal","download"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
   },
   "trigger_solde_contract": {
      0:[
         {
            "name":"",
            "action":"",
            "spin":false,
            "icon":[],
            "className":"d-none",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"supprimer",
            "action":"goToDeleteSoldeContract",
            "spin":true,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"supprimer",
            "action":"goToDeleteSoldeContract",
            "spin":true,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
   },
   "task_planning_setting":{
      0:[
         {
            "name":"ajouter",
            "action":"goToAddTaskPlanning",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"modifier",
            "action":"goToEditTaskPlanning",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"archive_unarchive",
            "action":"goToArchiveTaskPlanning",
            "spin":true,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "invoice_suppliers_ocr_control": {
      0:[
         {
            "name":"ajouter",
            "action":"goToAddInvoiceSupplier",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"controler",
            "action":"goToControlInvoiceSupplier",
            "spin":false,
            "icon":["far","check"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":[
               "checkInvoiceSupplierOCR"
            ],
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"supprimer",
            "action":"goToDeleteInvoiceSupplier",
            "spin":true,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options": {
               "text_title": "invoice_suppliers.suppression",
               "text_question": "invoice_suppliers.confirmation_suppression",
               "text_message": "invoice_suppliers.message_suppression"
            },
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "invoice_suppliers_ocr_checked": {
      1:[
         {
            "name":"modifier",
            "action":"goToEditInvoiceSupplier",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"supprimer",
            "action":"goToDeleteInvoiceSupplier",
            "spin":true,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options": {
               "text_title": "invoice_suppliers.suppression",
               "text_question": "invoice_suppliers.confirmation_suppression",
               "text_message": "invoice_suppliers.message_suppression"
            },
            "condition":[
               "checkInvoiceSupplierSolde"
            ],
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"reglement",
            "action":"goToAddPaymentSupplier",
            "spin":false,
            "icon":["far","money-bill-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"ventiler",
            "action":"goToAddChargeAccountSupplier",
            "spin":false,
            "icon":["far","list-ul"],
            "className":"btn-secondary",
            "modal_options":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"export_comptable",
            "action":"goToExportComptableSupplier",
            "spin":true,
            "icon":["far","receipt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":[
               "checkInvoiceSupplierExportReady"
            ],
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"accounted",
            "action":"goToAccountedSupplier",
            "spin":true,
            "icon":["fal","check"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
      ],
      2:[
         {
            "name":"reglement",
            "action":"goToAddPaymentSupplier",
            "spin":false,
            "icon":["far","money-bill-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":[
               "checkInvoiceSupplierSameTiers"
            ],
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"export_comptable",
            "action":"goToExportComptableSupplier",
            "spin":true,
            "icon":["far","receipt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":[
               "checkInvoiceSupplierExportReady"
            ],
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"accounted",
            "action":"goToAccountedSupplier",
            "spin":true,
            "icon":["fal","check"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
      ]
   },
   "payment_supplier":{
      0:[
         {
            "name":"",
            "action":"",
            "spin":false,
            "icon":[],
            "className":"d-none",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"annuler",
            "action":"goToCancelPaymentSupplier",
            "spin":true,
            "icon":["far","trash"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"modifier",
            "action":"goToEditPayment",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"annuler",
            "action":"goToCancelPaymentSupplier",
            "spin":true,
            "icon":["far","trash"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "tiers_payment_supplier":{
      0:[
         {
            "name":"",
            "action":"",
            "spin":false,
            "icon":[],
            "className":"d-none",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"annuler",
            "action":"goToCancelPaymentSupplier",
            "spin":true,
            "icon":["far","trash"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"modifier",
            "action":"goToEditPayment",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"annuler",
            "action":"goToCancelPaymentSupplier",
            "spin":true,
            "icon":["far","trash"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "bridge_transactions": {
      1:[
         {
            "name":"assigner",
            "action":"goToAssignTransaction",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
      ]
   },
   "bridge_transactions_suggestions": {
      1:[
         {
            "name":"assigner",
            "action":"goToAssignTransaction",
            "spin":true,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
      ]
   },
   "semence_not_inventoried": {
      1:[
         {
            "name":"stocker",
            "action":"goToStockSemenceLot",
            "spin":false,
            "icon":["fal","database"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"prestocker",
            "action":"goToPrestockSemenceLot",
            "spin":false,
            "icon":["fal","database"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"detruire",
            "action":"goToDestroyLots",
            "spin":true,
            "icon":["fal","trash-alt"],
            "className":"btn-secondary",
            "modal_options":{
               "text_title":"congelation.confirm_destroy_lots",
               "text_message": "general.avertissement_suppression",
               "text_question":"congelation.confirm_destroy_lots_question",
               "text_button_accept": "action.detruire"
            },
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"stocker",
            "action":"goToStockSemenceLot",
            "spin":false,
            "icon":["fal","database"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"prestocker",
            "action":"goToPrestockSemenceLot",
            "spin":false,
            "icon":["fal","database"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"detruire",
            "action":"goToDestroyLots",
            "spin":true,
            "icon":["fal","trash-alt"],
            "className":"btn-secondary",
            "modal_options":{
               "text_title":"congelation.confirm_destroy_lots",
               "text_message": "general.avertissement_suppression",
               "text_question":"congelation.confirm_destroy_lots_question",
               "text_button_accept": "action.detruire"
            },
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "semence_prestockage": {
      1:[
         {
            "name":"stocker",
            "action":"goToStockSemenceLot",
            "spin":false,
            "icon":["fal","database"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"controle_qualite",
            "action":"goToControlLot",
            "spin":false,
            "icon":["far","check"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"stocker",
            "action":"goToStockSemenceLot",
            "spin":false,
            "icon":["fal","database"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "semence_control_lot": {
      0:[
         {
            "name":"ajouter",
            "action":"goToControlLot",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"modifier",
            "action":"goToEditControl",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "semence_stock_stallion": {
      0:[
         {
            "name":"importer",
            "action":"goToImportPaillettes",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"modifier",
            "action":"goToEditLot",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"detruire",
            "action":"goToDestroyLots",
            "spin":false,
            "icon":["fal","trash-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":[
               "checkLotInventoried"
            ],
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"attribuer",
            "action":"goToAssignLots",
            "spin":false,
            "icon":["far","user"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"detruire",
            "action":"goToDestroyLotsNotInventoried",
            "spin":true,
            "icon":["fal","trash-alt"],
            "className":"btn-secondary",
            "modal_options":{
               "text_title":"congelation.confirm_destroy_lots",
               "text_message": "general.avertissement_suppression",
               "text_question":"congelation.confirm_destroy_lots_question",
               "text_button_accept": "action.detruire"
            },
            "condition":[
               "checkLotsNotInventoried"
            ],
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"stocker",
            "action":"goToStockSemenceLot",
            "spin":false,
            "icon":["fal","database"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":[
               "checkLotsNotInventoried"
            ],
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"prestocker",
            "action":"goToPrestockSemenceLot",
            "spin":false,
            "icon":["fal","database"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":[
               "checkLotsNotInventoried"
            ],
            "rights":"MVP",
            "dropdown_name":null
         },
      ],
      2: [
         {
            "name":"detruire",
            "action":"goToDestroyLots",
            "spin":false,
            "icon":["fal","trash-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":[
               "checkLotInventoried"
            ],
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"attribuer",
            "action":"goToAssignLots",
            "spin":false,
            "icon":["far","user"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"detruire",
            "action":"goToDestroyLotsNotInventoried",
            "spin":true,
            "icon":["fal","trash-alt"],
            "className":"btn-secondary",
            "modal_options":{
               "text_title":"congelation.confirm_destroy_lots",
               "text_message": "general.avertissement_suppression",
               "text_question":"congelation.confirm_destroy_lots_question",
               "text_button_accept": "action.detruire"
            },
            "condition":[
               "checkLotsNotInventoried"
            ],
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"stocker",
            "action":"goToStockSemenceLot",
            "spin":false,
            "icon":["fal","database"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":[
               "checkLotsNotInventoried"
            ],
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"prestocker",
            "action":"goToPrestockSemenceLot",
            "spin":false,
            "icon":["fal","database"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":[
               "checkLotsNotInventoried"
            ],
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "semence_lot_emplacement": {
      0:[
         {
            "name":"ajouter",
            "action":"goToAddEmplacement",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"inventaire",
            "action":"goToInventaireEmplacement",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"detruire",
            "action":"goToDestroyEmplacements",
            "spin":false,
            "icon":["fal","trash-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"controle_qualite",
            "action":"goToControlEmplacement",
            "spin":false,
            "icon":["far","user"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"separer",
            "action":"goToSplitEmplacement",
            "spin":false,
            "icon":["far","layer-plus"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"attribuer",
            "action":"goToAssignEmplacements",
            "spin":false,
            "icon":["far","user"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2: [
         {
            "name":"detruire",
            "action":"goToDestroyEmplacements",
            "spin":false,
            "icon":["fal","trash-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"attribuer",
            "action":"goToAssignEmplacements",
            "spin":false,
            "icon":["far","user"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "semence_commande": {
      0:[
         {
            "name":"ajouter",
            "action":"goToAddExpedition",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"annuler",
            "action":"goToCancelExpedition",
            "spin":false,
            "icon":["far","history"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":[
               "checkStatusCancelExpedition"
            ],
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"modifier",
            "action":"goToEditExpedition",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":[
               "checkStatusExpeditionEditable"
            ],
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"differer_envoi",
            "action":"goToDiffereExpedition",
            "spin":false,
            "icon":["far","undo"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":[
               "checkStatusDiffereExpedition"
            ],
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"preparer",
            "action":"goToPrepareExpedition",
            "spin":true,
            "icon":["far","box"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":[
               "checkStatusPrepareExpedition"
            ],
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"pret_expedition",
            "action":"goToPretExpedition",
            "spin":false,
            "icon":["far","box"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":[
               "checkStatusPretExpedition"
            ],
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"reintegrer",
            "action":"goToReintegrerExpedition",
            "spin":false,
            "icon":["fal","database"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":[
               "checkStatusReintegrerExpedition"
            ],
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"demande_enlevement",
            "action":"goToDemenceEnlevement",
            "spin":false,
            "icon":["far","dolly-empty"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":[
               "checkStatusReintegrerExpedition"
            ],
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"annuler",
            "action":"goToCancelExpedition",
            "spin":false,
            "icon":["far","history"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":[
               "checkStatusCancelExpedition"
            ],
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"differer_envoi",
            "action":"goToDiffereExpedition",
            "spin":false,
            "icon":["far","undo"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":[
               "checkStatusDiffereExpedition"
            ],
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"preparer",
            "action":"goToPrepareExpedition",
            "spin":true,
            "icon":["far","box"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":[
               "checkStatusPrepareExpedition"
            ],
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"pret_expedition",
            "action":"goToPretExpedition",
            "spin":false,
            "icon":["far","box"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":[
               "checkStatusPretExpedition"
            ],
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"reintegrer",
            "action":"goToReintegrerExpedition",
            "spin":false,
            "icon":["fal","database"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":[
               "checkStatusReintegrerExpedition"
            ],
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"demande_enlevement",
            "action":"goToDemenceEnlevement",
            "spin":false,
            "icon":["far","dolly-empty"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":[
               "checkStatusReintegrerExpedition"
            ],
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "semence_expedition": {
      1:[
         {
            "name":"confirmer_expedie",
            "action":"goToConfirmExpedition",
            "spin":false,
            "icon":["fad","truck"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":[
               "checkStatusConfirmExpedition"
            ],
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"imprimer_demande_expedition",
            "action":"goToPdfDemandeExpedition",
            "spin":false,
            "icon":["far","file-pdf"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"bon_livraison",
            "action":"goToPdfBonLivraison",
            "spin":false,
            "icon":["far","file-pdf"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"confirmer_expedie",
            "action":"goToConfirmExpedition",
            "spin":false,
            "icon":["fad","truck"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":[
               "checkStatusConfirmExpedition"
            ],
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"imprimer_demande_expedition",
            "action":"goToPdfDemandeExpedition",
            "spin":false,
            "icon":["far","file-pdf"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"bon_livraison",
            "action":"goToPdfBonLivraison",
            "spin":false,
            "icon":["far","file-pdf"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "semence_cuve_shipped": {
      1:[
         {
            "name":"confirm_retour",
            "action":"goToConfirmCuveRetour",
            "spin":true,
            "icon":["far","undo"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"confirm_retour",
            "action":"goToConfirmCuveRetour",
            "spin":true,
            "icon":["far","undo"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
}