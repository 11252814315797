<template>
	<div>
		<CustomTable
            id_table="semence_stock_tiers"
            ref="table"
            :items="stallions"
            :busy.sync="table_busy"
            primaryKey="horse_id"
            :hide_if_empty="true"
            :hrefsRoutes="config_table_hrefs"
        />
	</div>
</template>

<script type="text/javascript">
	import Congelation from "@/mixins/Congelation.js"

	export default {
		name: 'TiersStocksListe',
		mixins: [Congelation],
		props: ['tiers_id'],
		data () {
			return {
				table_busy: false,
				stallions: [],
				config_table_hrefs: {
					'horse_nom': {
						routeUniqueName: 'StallionFiche',
						routeName: 'horseFiche',
						params: {
							horse_id: 'horse_id'
						}
					},
				}
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				this.table_busy = true
				this.stallions = await this.getStocksByTiers(this.tiers_id)
				this.table_busy = false
			}
		},

		components: {
			CustomTable : () => import('GroomyRoot/components/Table/CustomTable')
		}
	}

</script>