<template>
	<div class="box">
		<div class="row">
			<div class="col-4">
				<label>{{ $t('monte.congelation.centre_congelation') }}</label>
				<e-select
					id="campagne_centre"
					track-by="tiers_id"
					label="tiers_rs"
					:placeholder="$t('monte.congelation.selectionner_centre')"
					:options="all_centres"
					:searchable="true"
					:show-labels="false"
					v-model="centre"
				>
					<template slot="option" slot-scope="{ option }">{{ option.tiers_rs }} <template v-if="option.numero">({{ option.numero }})</template></template>
					<template slot="singleLabel" slot-scope="{ option }">{{ option.tiers_rs }} <template v-if="option.numero">({{ option.numero }})</template></template>
					<template slot="noOptions">{{ $t('global.list_empty') }}</template>
				</e-select>
			</div>
			<div class="col-8">
				<DateRangeV2
	                :start.sync="start_date"
	                :end.sync="end_date"
	                :dateSelect="true"
	                :periodToShow="['personalise','jour','mois','mois_dernier']"
	                @submit="load_statistiques"
	            />
			</div>
		</div>
		<CustomTable
			ref="table"
			id_table="congelation_statistiques"
			:items="statistiques"
			primaryKey="horse_id"
			:busy.sync="table_busy"
            :hrefsRoutes="config_table_hrefs"
		/>
	</div>
</template>

<script type="text/javascript">
	import Congelation from "@/mixins/Congelation.js"

	export default {
		name: 'StatistiquesProduction',
		mixins: [Congelation],
		data () {
			return {
				start_date: new Date(new Date().setMonth(new Date().getMonth() - 3)),
				end_date: new Date(),
				all_centres: [],
				centre: null,
				table_busy: false,
				statistiques: [],
				config_table_hrefs: {
					'horse_nom': {
						routeUniqueName: 'StallionFiche',
						routeName: 'horseFiche',
						params: {
							horse_id: 'horse_id'
						}
					},
				}
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				this.all_centres = await this.loadCentreCollecte()
			},

			async load_statistiques() {
				if(!this.start_date || !this.end_date || !this.centre) {
					return false
				}

				this.table_busy = true
				this.statistiques = await this.getStatistiqueProduction(this.centre.tiers_id, this.start_date.toDateInputValue(), this.end_date.toDateInputValue())
				this.table_busy = false
			}
		},

		components: {
	        DateRangeV2: () => import('GroomyRoot/components/Inputs/DateRangeV2'),
			CustomTable : () => import('GroomyRoot/components/Table/CustomTable')
		}
	}

</script>