import Transformer from './Transformer'
import PhoneTransformer from '@/assets/js/dexie/transformers/PhoneTransformer.js'

/**
 * Retourne une liste de
 * @param {Dexie.Collection} season
 */
export default class ContactTransformer extends Transformer {

    async transform (contact) {
        return {
            'contact_id'                : contact.contact_id,
            'contact_lastname'          : contact.contact_lastname,
            'contact_firstname'         : contact.contact_firstname,
            'contact_civility'          : contact.contact_civility,
            'contact_mail'              : contact.contact_mail,
            'contact_address1'          : contact.contact_address1,
            'contact_address2'          : contact.contact_address2,
            'contact_postalcode'        : contact.contact_postalcode,
            'contact_town'              : contact.contact_town,
            'contact_country'           : contact.contact_country,
            'contact_operateur'         : contact.contact_operateur,
            'contact_archive'           : contact.contact_archive,
            'contact_num_ordre'         : contact.contact_num_ordre,
            'contact_hubspotid'         : contact.contact_hubspotid,
            'contact_label'             : contact.contact_civility + ' ' + contact.contact_firstname + ' ' + contact.contact_lastname,
            'contact_color'             : contact.contact_color,
            'contact_abbreviation'      : contact.contact_abbreviation,
            'contact_datenaissance'     : contact.contact_datenaissance,
            'contact_commentaire'       : contact.contact_commentaire
        }
    }

    async fetchWithPhone (col) {
        return col.with({
            'phone'                     : 'phone'
        })
    }

    async transformWithPhone (contact) {
        const phones = await PhoneTransformer.process(contact.phone)
        let phone_combine = phones.map(phone => phone.phone_combine).join(', ')

        return {
            'contact_id'                : contact.contact_id,
            'contact_lastname'          : contact.contact_lastname,
            'contact_firstname'         : contact.contact_firstname,
            'contact_civility'          : contact.contact_civility,
            'contact_mail'              : contact.contact_mail,
            'contact_address1'          : contact.contact_address1,
            'contact_address2'          : contact.contact_address2,
            'contact_postalcode'        : contact.contact_postalcode,
            'contact_town'              : contact.contact_town,
            'contact_country'           : contact.contact_country,
            'contact_operateur'         : contact.contact_operateur,
            'contact_archive'           : contact.contact_archive,
            'contact_num_ordre'         : contact.contact_num_ordre,
            'contact_hubspotid'         : contact.contact_hubspotid,
            'phones'                    : phones,
            'phone_combine'             : phone_combine,
            'contact_label'             : contact.contact_civility + ' ' + contact.contact_firstname + ' ' + contact.contact_lastname,
            'contact_color'             : contact.contact_color,
            'contact_abbreviation'      : contact.contact_abbreviation,
            'contact_datenaissance'     : contact.contact_datenaissance,
            'contact_commentaire'       : contact.contact_commentaire
        }
    }
}
