<template>
    <div>
        <b-modal ref="modal" hide-footer size="xxl">
            <template v-slot:modal-title>
				{{ $t("action.importer") }}
			</template>
            <div v-if="formError == false">
                <b-form-textarea
                v-if="!importModal"
                id="textarea"
                v-model="text"
                placeholder="Enter something..."
                rows="10"></b-form-textarea>
                <b-button v-if="!importModal" variant="primary" @click="handleData()" class="mb-4">{{ $t("action.importer") }}</b-button>
                <div v-if="importModal">
                    <div class="custom-control custom-checkbox mt-4" v-if="hasWeatherbys">
						<input type="checkbox" id="weatherbys" class="custom-control-input" v-model="weatherbys">
						<label class="custom-control-label ml-n2" for="weatherbys"><h5>{{ $t('horse.import_weatherbys') }}</h5></label>
					</div>
                    <Import :form.sync="form" :numberOfSelect.sync="numberOfSelect" :options="options" @clicked="importData($event)"/>
                </div>
            </div>
            <div v-else>
                <h4>{{$t("horse.error_import_message")}}</h4>
                <table class="table text-center">
                    <thead>
                        <tr>
                            <th scope="col">   
                                {{$t('horse.form.nom')}}
                            </th>
                            <th scope="col">   
                                {{$t('horse.form.transpondeur')}}
                            </th>
                            <th scope="col">
                                {{$t('horse.error')}}
                            </th>
                            <th scope="col">
                                {{$t('horse.avertissement')}}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(ligne, i) in FormToDownload" :key="i">
                            <td scope="col">
                               {{FormToDownload[i].horse_nom}}
                            </td>
                            <td scope="col">
                               {{FormToDownload[i].horse_transpondeur}}
                            </td>
                            <td scope="col">
                                {{FormToDownload[i].error}}
                            </td>
                            <td scope="col" >
                                <ul>
                                    <li v-for="warning in FormToDownload[i].warning" :key="warning">{{warning}}</li>
                                </ul>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <b-button variant="primary" @click="closeModal()">{{$t('horse.compris')}}</b-button>
            </div>
        </b-modal>
        <b-modal ref="horseImportedList" hide-footer size="xl">
            <template v-slot:modal-title>
				{{ $t("action.importer") }}
			</template>
            <div>
                <ListingImportedHorses :horses.sync="allImportedHorses"/>
            </div>
        </b-modal>
    </div>
</template>

<script type="text/javascript">
    import Navigation from "@/mixins/Navigation.js"
    import Tools from "@/mixins/Tools.js"
    import _deepClone from 'lodash/cloneDeep'
    import Horse from '@/mixins/Horse.js'

export default {
        name: "ImportHorse",
        mixins: [Navigation, Horse, Tools], 
        data () {
            return {
                working: false,
                formError: false,
                FormToDownload: {},
                indicatifs: {},
				countries: {},
				countries_iso: {},
                importedHorses: [],
                allImportedHorses:[],
                importModal: false,
                weatherbys: false,
                options: [
                    { value: '0', text: this.getTrad('horse.form.nom'), column: 'horse_nom', requis: true },
                    { value: '1', text: this.getTrad('horse.form.transpondeur'), column: 'horse_transpondeur', requis: false},
                    { value: '2', text: this.getTrad('horse.form.sexe'), column: 'horse_sexe', requis: false },
                    { value: '3', text: this.getTrad('horse.form.datenaissance'), column: 'horse_datenaissance', requis: false },
                    { value: '4', text: this.getTrad('horse.form.sire'), column: 'horse_sire', requis: false },
                    { value: 'null', text: this.getTrad('global.ne_pas_importer'), column: 'null', requis: false}
                ],
                default_form: {
                    horse_nom: '',
                    horse_transpondeur: '',
                    horse_sexe: '',
                    horse_datenaissance: '',
                },
                numberOfSelect: 0,
                form: [],
                text: "",
                events_tab: {
                    'TableAction::goToImportHorses': () => {
                        this.showModal()
                    },
                }
            }
        },
        methods: {
            async showModal() {
                this.$refs['modal'].show()
                this.text = ""
                this.importModal = false
            },
            async handleData() {
                this.form = []
                if(this.text == "" || this.text == null){
                    return
                }
                const wordByspace = this.text.split('\n')
                for (const [i, elements] of wordByspace.entries()) {
                    const words = elements.split('\t')
                    let obj = {}
                    obj.checked = true
                    this.numberOfSelect = 0
                    for (const [j, word] of words.entries()) {
                        this.numberOfSelect++
                        obj[j] = word
                    }
                    this.$set(this.form, i, obj)
                }
                this.text = ""
                this.importModal = true
            },

            async importData(event){
                if(this.working == true){return}
                this.working = true
                this.FormToDownload = {}
                const final_form = []
                let warning = []
                let error = []
                let ID_Horses = 0
                let horseToImport = []
                const sexeM = ["Mâle", "mâle", "Male", "male", "MALE", "m", "M"]
                const sexeF = ["Femelle", "femelle", "FEMELLE", "F", "f"]
                const sexeH = ["Hongre", "hongre", "HONGRE", "H", "h"]
                for (const horse of event) {
                    const form = _deepClone(this.default_form)
                    
                    for (const [key, value] of Object.entries(horse)){
                        form[key] = value
                    }
                    if(form.horse_sexe){
                        if(sexeM.includes(form.horse_sexe)) {
						    form.horse_sexe = 'M'
                        }
                        else if(sexeF.includes(form.horse_sexe)) {
                            form.horse_sexe = 'F'
                        } 
                        else if(sexeH.includes(form.horse_sexe)) {
                            form.horse_sexe = 'H'
                        }
                        else {
                            warning.push('le sexe -> "' + form.horse_sexe + '" n\'est pas reconnu')
                        }
                    }

                    form.horse_datenaissance = this.isValidDate(form.horse_datenaissance)

                    if(form.horse_datenaissance === false){
                        warning.push('la date de naissance -> "' + form.horse_datenaissance + '" n\'est pas reconnu')
                        form.horse_datenaissance = null
                    }

                    if(!form.horse_nom){
                        error.push(this.getTrad('horse.error_name'))
                    } 

                    if (error.length != 0) {
                        this.FormToDownload[ID_Horses] = form
                        this.FormToDownload[ID_Horses].error = error[0]
                        error = []
                        continue
                    }
                    if (warning.length != 0) {
                        this.FormToDownload[ID_Horses] = form
                        this.FormToDownload[ID_Horses].warning = warning
                        warning = []
                    }
                    final_form.push(form)
                    ID_Horses++
                }
                try {
                    let importedBySire = []
                    let importedByTranspondeur = null

                    let transpondeurs = final_form.map(x => x.horse_transpondeur.replaceAll(' ', ''))
                    transpondeurs = transpondeurs.join("\n")
                    if (transpondeurs.length != 0){
                        importedByTranspondeur = await this.addHorseByTranspondeur(transpondeurs)
                    }

                    for(const horse of final_form){
                        if (importedByTranspondeur == null || importedByTranspondeur.retour.errors.includes(horse['horse_transpondeur'])){
                            if(horse['horse_sire'] && horse['horse_sire'] != ""){
                                let sireImport = await this.addHorseBySire(horse['horse_sire'].replaceAll(' ', ''), false, true)
                                if(typeof sireImport.retour == "string"){
                                    horseToImport.push(horse)
                                } else {
                                    if( sireImport.retour.new && sireImport.retour.new == true){
                                        let horseFormatted = {horse_nom: sireImport.retour.birthName, horse_sexe: sireImport.retour.sex, horse_datenaissance: sireImport.retour.birthDate, horse_id: sireImport.retour.id, route_horseFiche: {horse_id:sireImport.retour.id}}
                                        importedBySire.push(horseFormatted)
                                    }
                                }
                            } else {
                                horseToImport.push(horse)
                            }
                        }
                    }
                    let importedHorses = []
                    if (this.weatherbys) {
                        const result = await this.importHorsesWeatherby(horseToImport)
                        if (result.manually && result.manually.length){
                            this.infoToast(result.manually.length . this.getTrad('manual_import'))
                        }
                        importedHorses = result.weatherbys.concat(result.manually)
                    } else {
                         importedHorses = await this.importHorses(horseToImport)
                    }

                    if(importedByTranspondeur){
                        this.allImportedHorses = importedHorses.concat(importedByTranspondeur.retour.horses).concat(importedBySire)
                    }else{
                        this.allImportedHorses = importedHorses.concat(importedBySire)
                    }
                    for (const horse of this.allImportedHorses){
                        if (horse.horse_sexe == ''){
                            horse.horse_sexe = this.getTrad('horse.indetermine')
                        }
                    }
                }
                catch (e) {
                    console.error(e)
                }

                if(Object.keys(this.FormToDownload).length > 0){
                    this.formError = true
                    await this.$sync.force()
                    this.working = false
                    return
                }

                await this.$sync.force()
                this.$refs['modal'].hide()
                this.$refs['horseImportedList'].show()
                this.working = false
            },

            isValidDate(s) {
                var separators = ['\\.', '\\-', '\\/']
                var bits = s.split(new RegExp(separators.join('|'), 'g'))
                var d = new Date(bits[2], bits[1] - 1, bits[0])
                if(d.getFullYear() == bits[2] && d.getMonth() + 1 == bits[1]){
                    return d
                } else {
                    return false
                }
            },

            closeModal(){
                this.$refs['modal'].hide()
                this.formError = false
                this.FormToDownload = {}
                this.$refs['horseImportedList'].show()
            },
        },
        computed: {
            hasWeatherbys(){
                return this.getConfig('api_config').find(conf => conf.apiconfig_type == "WTHBS")
            }
        },
        components: {
            LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
            ErrorAlert: () => import('GroomyRoot/components/Alert/ErrorAlert'),
            Import: () => import('@/components/Utils/Import'),
            ListingImportedHorses: () => import('@/components/Horse/ListingImportedHorses'),
        }
    }
</script>
