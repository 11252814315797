<template>
	<e-select
		track-by="id"
		label="label"
		v-model="searchValue"
		group-label="label"
		group-values="values"
		:group-select="false"
		:options="options"
		:placeholder="$t('global.rechercher')"
		:loading="isLoading"
		:sortable="false"
		:internal-search="false"
		preserve-search
		reset-after
		@search-change="onSearch"
		@select="searchHorseOrTiers"
		ref="input"
		class="mainsearch"
	>    
	    <template slot="option" slot-scope="props">
			<font-awesome-icon v-if="props.option.$groupLabel && props.option.$groupLabel == $t('global.chevaux')" :icon="['fal', 'horse']" class="mr-3"/>
			<font-awesome-icon v-else-if="props.option.$groupLabel && props.option.$groupLabel == $t('tiers.tiers')" :icon="['fal', 'user-tie']" class="mr-3"/>
			<span v-if="props.option.$groupLabel">{{ props.option.$groupLabel }}</span>
			<span class="option__small">{{props.option.label}} <span v-if="props.option.horse_mort">†</span></span>
    	</template>	
		<template slot="singleLabel" slot-scope="{ option }">{{ option.label }}</template>
		<template slot="noOptions">{{ $t('global.list_empty') }}</template>
	</e-select>
</template>

<script>
import _debounce from 'lodash/debounce'
import Constants from '../../../config.local.js'
import Navigation from '@/mixins/Navigation'

export default {
	mixins: [Navigation],
    data() {
        return {
			options: [
				{
					label: this.$t('global.chevaux'),
					values: []
				},
				{
					label: this.$t('tiers.tiers'),
					values: []
				},
				{
					label: '',
					values: []
				}
			],
			searchValue: null,
			isLoading: false,
			events_tab: {
				'SearchInput::focus': this.onFocus
			},
			debouncedSearch: _debounce(this.search, 300),
			search_value: ''
		}
	},
	computed: {
		actualRoute() {
			return this.$route
		}
	},
	methods: {
		onSearch(search_val) {
			this.options[0].values = []
			this.options[1].values = []
			this.options[2].values = [{
				id: -1, 
				type: 'more_results',
				label: this.getTrad('global.more_results')
			}]
			this.search_value = search_val
			this.debouncedSearch(search_val)
		},
		async search(search_val) {
			search_val = search_val.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
			this.isLoading = true

			// Horses
			// const horsePromise = this.$storage.db.t('horse').then(table => {
			// 	return table.where('horse_nom').startsWithIgnoreCase(search_val)
			// 		.or('horse_alias').startsWithIgnoreCase(search_val)
			// 		.or('horse_sire').startsWithIgnoreCase(search_val)
			// 		.or('horse_transpondeur').startsWithIgnoreCase(search_val)
			// 		.or('horse_interne').equals(search_val)
			// 		.and(horse => horse.horse_inactive == 0)
			// 		.limit(20)
			// 		.sortBy('horse_nom')
			// })
			// .then(horses => {
			// 	this.options[0].values = horses.map(horse => {
			// 		return {
			// 			id: horse.horse_id,
			// 			type: 'horse',
			// 			label: horse.horse_nom,
			// 			horse_mort: horse.horse_mort
			// 		}
			// 	})
			// })

			const horsePromise = this.$storage.db.t('horse').then(table => {
				return table.where({
					horse_inactive: 0
				})
				.sortBy('horse_nom')
			})
			.then(col => {
				return col.filter(elem => {
					var regex = new RegExp(search_val, 'gi');
					return (elem.horse_nom && regex.test(elem.horse_nom.normalize('NFD').replace(/[\u0300-\u036f]/g, '')))
					|| (elem.horse_alias && regex.test(elem.horse_alias.normalize('NFD').replace(/[\u0300-\u036f]/g, '')))
					|| (elem.horse_sire && regex.test(elem.horse_sire.normalize('NFD').replace(/[\u0300-\u036f]/g, '')))
					|| (elem.horse_interne && regex.test(elem.horse_interne.normalize('NFD').replace(/[\u0300-\u036f]/g, '')))
					|| (elem.horse_transpondeur && regex.test(elem.horse_transpondeur.normalize('NFD').replace(/[\u0300-\u036f]/g, '')))
				})
			})
			.then(arr => {
				return arr.slice(0, 20)
			})
			.then(horses => {
				this.options[0].values = horses.map(horse => {
					return {
						id: horse.horse_id,
						type: 'horse',
						label: horse.horse_nom,
						horse_mort: horse.horse_mort
					}
				})
			})

			// Tiers
			let tiersPromise
			if(this.$storage.db.tiers) {
				// tiersPromise = this.$storage.db.t('tiers').then(table => {
				// 	return table.where('tiers_rs').startsWithIgnoreCase(search_val)
				// 		.or('tiers_alias').startsWithIgnoreCase(search_val)
				// 		.or('tiers_lastname').startsWithIgnoreCase(search_val)
				// 		.and(tiers => tiers.tiers_archive == 0)
				// 		.limit(20)
				// 		.sortBy('tiers_rs')
				// })
				// .then(tiers => {
				// 	this.options[1].values = tiers.map(tier => {
				// 		return {
				// 			id: tier.tiers_id,
				// 			type: 'tiers',
				// 			label: tier.tiers_rs,
				// 		}
				// 	})
				// })

				tiersPromise = this.$storage.db.t('tiers').then(table => {
					return table.where({
						tiers_archive: 0
					})
					.sortBy('tiers_rs')
				})
				.then(col => {
					return col.filter(elem => {
						var regex = new RegExp(search_val, 'gi');
						return (elem.tiers_rs && regex.test(elem.tiers_rs.normalize('NFD').replace(/[\u0300-\u036f]/g, '')))
						|| (elem.tiers_alias && regex.test(elem.tiers_alias.normalize('NFD').replace(/[\u0300-\u036f]/g, '')))
						|| (elem.tiers_lastname && regex.test(elem.tiers_lastname.normalize('NFD').replace(/[\u0300-\u036f]/g, '')))
						|| (elem.tiers_firstname && regex.test(elem.tiers_firstname.normalize('NFD').replace(/[\u0300-\u036f]/g, '')))
					})
				})
				.then(arr => {
					return arr.slice(0, 20)
				})
				.then(tiers => {
					this.options[1].values = tiers.map(tier => {
						return {
							id: tier.tiers_id,
							type: 'tiers',
							label: tier.tiers_rs,
						}
					})
				})
			}

			this.options[2].values = [{
				id: -1, 
				type: 'more_results',
				label: this.getTrad('global.more_results')
			}]

			// Ajout des résultats au e-select
			await Promise.all([horsePromise, tiersPromise])

			this.isLoading = false
		},
		onFocus() {
			this.$refs.input.activate()
			this.$refs.input.$refs.search.click()
		},
		async searchHorseOrTiers(option) {
			let routeName = ''
			let params = {}

			if(option.type === 'horse') {
				routeName = 'HorseFicheInfo'
				params = {horse_id: option.id}
			}
			else if(option.type === 'tiers') {
				routeName = 'tiersFiche'
				params = {tiers_id: option.id}
			}
			else if(option.type === 'more_results') {
				routeName = 'AdvancedSearch'
				params = {search_value: this.search_value}
			}

			this.$store.commit('nav/close')
			if(!this.checkRedirectAuth(routeName, params)) return
			this.$router.push({ name: routeName, params })
		},
		checkRedirectAuth(routeName, params={}) {
			let paramsCheck = true
			
			// On compare les params nécessaires
			Object.keys(params).forEach(paramName => {
				const paramVal = params[paramName]

				if(this.actualRoute.params[paramName] !== paramVal) {
					paramsCheck = false
					return
				}
			})

			// Si la route et les params sont les mêmes, on retourne false
			return !(this.actualRoute.name == routeName && paramsCheck)
		}
	}
}
</script>

<style scoped>

/* Style pour que la fenêtre de recherche prenne la place nécessaire pour afficher les résultats en entier */
@media screen and (min-width: 700px) {
	.mainsearch /deep/ .multiselect__content-wrapper {
		width: auto;
		min-width: 200px;
		position: fixed;
	}
}

</style>