<template>
    <div v-if="ids && ids.length > 0">
        <div class="col-12">
            <div class="box">
                <CustomTable
                    ref="horse_table"
                    id_table="horse"
                    :items="horses"
                    :busy.sync="table_busy"
                    primaryKey="horse_id"
                    :hrefsRoutes="config_table_hrefs"
                    :hide_if_empty="true"
                    media-field="media_filename"
                    :base-filters="filters"
                    :externSlotColumns="['horse_tags', 'horse_wholesire', 'horse_tiers']"
                    :groupByCustom="customGroupBy"
                >
                    <template v-slot:[`custom-slot-cell(horse_francise)`]="{ data }">
                        <font-awesome-icon class="text-success" v-if="data.horse_francise" :icon="['fal', 'check-circle']" />
                        <font-awesome-icon class="text-danger" v-else :icon="['fal', 'times-circle']" />
                    </template>
                    <template v-slot:[`custom-slot-cell(horse_tags)`]="{ data }">
                        <div class="row">
                            <span v-for="color in data.horse_tags" class="tags" :style="'background-color:'+color" :key="color"></span>
                        </div>
                    </template>
                    <template v-slot:[`custom-slot-cell(horse_wholesire)`]="{ data }">
                        <div class="row">
                            <e-copybox :value="data.horse_wholesire"></e-copybox>
                        </div>
                    </template>
					<template v-slot:[`custom-slot-cell(horse_tiers)`]="{ data }">
						<router-link  v-for="tiers in data.horse_tiers" :key="tiers.tiers_id" :to=" {name: 'tiersFiche', params: { tiers_id: tiers.tiers_id}}">
							{{ tiers.tiers_rs }} <br>
						</router-link>
					</template>
                </CustomTable>
            </div>
        </div>
        <ModalPrintInfosHorse ref="modal_print_infos_horse"/>

        <b-modal ref="modal" hide-footer>
            <template v-slot:modal-title>
                <template>
                    {{ $t("horse.ajouter_pension") }}
                </template>
            </template>
            
            <PensionAjout
                ref="PensionAjout"
                :horse_ids="ids"
                :add-or-edit-ready="add_ready"
            />

            <b-button class="mt-3 btn-block rounded-pill" block @click.prevent="checkForm" variant="primary"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /> {{ $t("global.ajouter") }}</b-button>
        </b-modal>
		<ModalEditQualification ref="modal_edit_qualification"/>

    </div>
</template>

<script type="text/javascript">
import ActesMixin from "@/mixins/Actes.js"
import Horse from '@/mixins/Horse.js'
import Model from "@/mixins/Model.js"
import Navigation from "@/mixins/Navigation.js"

export default {
    name:"ModalPostImportHorse",
    mixins: [ActesMixin, Horse, Model, Navigation],
	props: ['horse_ids'],
    data () {
        return {
            horses: [],
			ids: this.horse_ids,
			processing: false,
			add_ready: false,

			/* Configuration du tableau : */
			table_busy: false,
			config_table_hrefs: {
				'horse_nom': {
					routeUniqueName: 'horseFiche',
					routeName: 'horseFiche',
					params: {
						horse_id: 'horse_id'
					}
				}
			},
			customGroupBy: {'horse_datenaissance': (e) => {return e}},

			events_tab: {
				'TableAction::goToPrintInfosHorse': (params) => {
					this.openModalPrintInfosHorse(params.horse_ids)
				},
				'TableAction::DeleteHorses': this.deleteHorses,
				'TableAction::goToAddPension': (horse_ids) => {
					this.openModalAddPension(horse_ids)
				},
				'TableAction::goToManageQualifsHorse': (horse_ids) => {
					this.openModalQualifs(horse_ids)
				},
				'TableAction::goToArchiveHorse': (horse_ids) => {
					this.archive(horse_ids)
				}
			},
        }
    }, 
	mounted() {
		this.init_component()
	},
    methods:  {
		async init_component() {
			this.table_busy = true
			this.horses = await this.getHorsesByIds(this.ids, "withResidLieu")
			this.table_busy = false
		},
        async deleteHorses(horses) {
			const ids = horses.map(horse => horse.horse_id)
			
			this.devalideHorses(ids)
				.then(() => {
					this.$refs.horse_table.refreshTable()
				})
		},
        openModal(horse_id) {
			this.horse_id = horse_id
			this.$refs.modalImportHorseDescendants.show()
		},
		closeModal() {
			this.$refs.modalImportHorseDescendants.hide()
		},
		openModalPrintInfosHorse(horse_ids) {
			this.$refs.modal_print_infos_horse.openModal(horse_ids)
		},
		openModalAddPension(horse_ids) {
			this.horse_ids = horse_ids
			this.add_ready = true
			this.$refs.modal.show()
		},
		async checkForm() {
			if(!this.processing) {
				this.processing = true
				let result = await this.$refs.PensionAjout.checkForm()
				if(result) {
					this.$refs.modal.hide()
				}

				this.processing = false
			}
		},
		openModalQualifs(horse_ids) {
			this.$refs.modal_edit_qualification.openModalFromHorse(horse_ids)
		},
		archive(horse_ids) {
			this.archiveHorses(horse_ids)
				.then(() => {
					this.$refs.horse_table.refreshTable()
				})
		}
	},
	computed: {
		filters() {
			let filters = {
				horse_not_inactive: {
					column: 'horse_inactive',
					operator: 'isEqualTo',
					value: 0
				}
			}

			return filters
		}
	},
	components: {
		HeaderTab: () => import('@/components/HeaderTab'),
		CustomTable : () => import('GroomyRoot/components/Table/CustomTable'),
		ModalEditQualification: () => import('@/components/Contract/ModalEditQualification'),
		ModalPrintInfosHorse : () => import('@/components/Horse/ModalPrintInfosHorse'),
		PensionAjout : () => import('@/components/Horse/PensionAjout'),
		ImportHorse: () => import('@/components/Horse/Ajout/ImportHorse'),

	}

}

</script>