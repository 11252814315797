<template>
	<div>
		<h3>{{ localisation.localisation_label }}</h3>
        <CustomTable
            id_table="semence_stallion_plan_cuve_detail"
            ref="table"
            :items="plan_cuve"
            :busy.sync="table_busy"
            primaryKey="semencelot_id"
            :hide_if_empty="true"
       	/>
	</div>
</template>

<script type="text/javascript">
	import Congelation from "@/mixins/Congelation.js"

	export default {
		name: 'StallionPlanCuveDetail',
		mixins: [Congelation],
		props: ['horse_id', 'localisation'],
		data () {
			return {
				plan_cuve: [],
				table_busy: false
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				this.table_busy = true
				this.plan_cuve = await this.getStallionPlanCuveDetail(this.horse_id, this.localisation.localisation_id)
				this.table_busy = false
			},
		},

		components: {
			CustomTable : () => import('GroomyRoot/components/Table/CustomTable')
		}
	}

</script>