<template>
    <main id="liste" v-cloak>
        <header-tab :title="$t('compte.autolog_admin_header')" />

        <div id="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="box">
                            <h4><i>Attention, la connexion à un autre compte vous déconnecte de votre session actuelle</i></h4>

                            <b-input-group>
	                            <b-form-input v-model="email" :placeholder="$t('compte.email')" type="email"></b-form-input>
								<b-input-group-append>
	                            <b-button v-if="user && Object.keys(user).length > 0" @click="launchAutoLog(null)">Lancer la session de ce compte</b-button>
								</b-input-group-append>
							</b-input-group>

							<CustomTable
								id_table="admin_autolog_table"
								:items="users"
								:busy.sync="table_busy"
								primaryKey="licence_id"
								:hide_if_empty="true"
								:externSlotColumns="extern_slot_columns"
							>
								<template v-slot:custom-slot-cell(button_autolog)="{ data }">
									<button class="btn btn-primary" type="button" @click.prevent="launchAutoLog(data.email)">{{ $t('global.autolog_connect') }}</button>
								</template>
								<template v-slot:custom-slot-cell(licence_free)="{ data }">
									<div class="custom-control custom-checkbox">
										<b-form-checkbox 
											name="check-button"
				                            switch 
											v-model="data.licence_free" 
											@change="switchLicenceFree(data.licence_id, data.licence_free)"
										>
										</b-form-checkbox>
									</div>
								</template>
								<template v-slot:custom-slot-cell(licence_date_of_validity)="{ data }">
									<e-datepicker v-model="data.date" @input="saveLicenceDate(data.licence_id, data.date)"></e-datepicker>
								</template>
							</CustomTable>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script type="text/javascript">
    import _debounce from 'lodash/debounce';
	import { EventBus } from 'EventBus'
    import { resetRouter } from "@/router/index.js";

	import Tools from '@/mixins/Tools.js'
	import User from '@/mixins/User.js'
	import Navigation from '@/mixins/Navigation.js'

	export default {
		name: "AutoLogAdmin",
		mixins: [
			User
		],
		components: {
			HeaderTab: () => import('@/components/HeaderTab'),
			CustomTable: () => import('GroomyRoot/components/Table/CustomTable')
		},
		data () {
			return {
                email: '',
                loading: false,
                spinner: false,
                user: null,
				users: [],
				table_busy: false,
				extern_slot_columns: ['button_autolog', 'licence_free', 'licence_date_of_validity']
			}
		},
        created() {
            this.debouncedFetchUser = _debounce(this.fetchUser, 1000)
			this.init_component()
        },
		methods: {
			async init_component() {
				this.table_busy = true
				const users = await this.getAllUsers()
				users.forEach(user => {
					user.date = new Date(user.licence_date_of_validity)
				})
				this.users = users
				this.table_busy = false
			},
            async launchAutoLog(email = null) {
				if(email) {
					await this.fetchUser(email)
				}

                // On delog complètement la session actuelle
                await this.delogUser(true)

                // On set la licence + bearer
                await this.persistUserSession(this.user.token, this.user.licence_key, this.user.licence_username, this.user.user_id, this.user.valid_user, this.user.valid_mail, this.user.lang)
				this.setUserLoggedIn()

                // On relance l'appli qui chargera les données user
                window.location.href = "/"
            },
            fetchUser(email) {
                this.loading = true
                this.spinner = true
                this.user = {}

                return this.autologUser(email).then((result)=>{
                    if(result.code_retour !== 0) throw "AutoLogAdmin::fetchUser => bad code response:" + result.code_retour
                    this.user = result.retour
                }).catch((e)=>{
                    this.failureToast("error.IMA")
                    console.error(e)
                }).finally(()=>{
                    this.spinner = false
                    this.loading = false
                })
            },
            async switchLicenceFree(licence_id, free) {
            	await this.saveLicenceFree(licence_id, !free)
            	this.successToast('toast.info_save_succes')
            },
            async saveLicenceDate(licence_id, date) {
            	await this.saveLicenceDateOfValidity(licence_id, date.toDateInputValue())
            	this.successToast('toast.info_save_succes')
            }
		},
        watch: {
            'email' (val) {
                this.debouncedFetchUser(val)
            }
        }
	}
</script>
