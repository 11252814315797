<template>
	<div class="box">
		<CustomTable
            id_table="semence_cuve_shipped"
            ref="table"
            :items="cuves"
            :busy.sync="table_busy"
            primaryKey="semencelocalisation_id"
            :hide_if_empty="true"
            :hrefsRoutes="config_table_hrefs"
        />
	</div>
</template>

<script type="text/javascript">
	import { EventBus } from 'EventBus'
	import Congelation from "@/mixins/Congelation.js"
	import Navigation from "@/mixins/Navigation.js"

	export default {
		name: 'CongelationCuveShipped',
		mixins: [Congelation, Navigation],
		data () {
			return {
				cuves: [],
				table_busy: false,
				config_table_hrefs: {
					'destinataire.tiers_rs': {
						routeUniqueName: 'destinataireFiche',
						routeName: 'tiersFiche',
						params: {
							tiers_id: 'destinataire.tiers_id'
						}
					},
				},
				events_tab: {
					'TableAction::goToConfirmCuveRetour': this.goToConfirmCuveRetour
				}
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				this.table_busy = true
				this.cuves = await this.getCuvesShipped()
				this.table_busy = false
			},

			async goToConfirmCuveRetour(cuves) {
				const cuves_ids = cuves.map(loc => loc.semencelocalisation_id).join(',')
				await this.confirmCuveRetour(cuves_ids)
				EventBus.$emit('TableAction::stopSpin')
				this.init_component()
				this.$refs.table.unselectAll()
			}
		},

		components: {
			CustomTable : () => import('GroomyRoot/components/Table/CustomTable')
		}
	}

</script>