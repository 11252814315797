<template>
    <div class="box">
        <label for="trainer_code" class="col-form-label">{{ $t("compta.trainer_code") }}</label>	
        <input type="text" class="form-control" v-model="trainer_code">

        <div class="form-group mt-2 text-center">
            <button class="btn btn-primary" type="button" @click.prevent="sync_performances">
                <font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse />{{ $t('compta.sync_now') }}
            </button>
        </div> 

        <label>{{ $t("compta.trainer_article") }}</label>
        <e-select
            track-by="articles_id"
            label="articles_label"
            v-model="entraineur_article"
            :options="all_articles"
            :searchable="true"
        >
        </e-select>
        <label class="mt-2">{{ $t("compta.jockey_article") }}</label>
        <e-select
            track-by="articles_id"
            label="articles_label"
            v-model="jockey_article"
            :options="all_articles"
            :searchable="true"
        >
        </e-select> 
        
        <div class="form-group mt-2 text-center">
            <button v-if="trainer_code != current_trainer_code || (jockey_article && jockey_article.articles_id != current_jockey_article) || (entraineur_article && entraineur_article.articles_id != current_entraineur_article)" class="mr-2 btn btn-primary" type="button" @click.prevent="saveForm">
                <font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse />{{ $t('global.enregistrer') }}
            </button>
        </div> 
    </div>
</template>

<script>
    import Accounting from "@/mixins/Accounting.js"
    import Shutter from '@/mixins/Shutter.js'

    export default {
        name: 'AccountingConfig',
        mixins: [Accounting, Shutter],
        props: ['accountingplan_id'],
        data () {
            return {
                current_trainer_code: null,
                current_jockey_article: null,
                current_entraineur_article: null,
                processing: false,
                trainer_code: null,
                jockey_article: null,
                entraineur_article: null,
                all_articles : []
            }
        },
        created() {
            this.init_component()
        },
        methods: {
            async init_component() {
                this.current_trainer_code = await this.getTrainerCode(this.accountingplan_id)
                this.trainer_code = this.current_trainer_code

                this.all_articles = await this.loadAllArticlesByAccounting(this.accountingplan_id)
                const articles = await this.getTrainerJockeyArticles(this.accountingplan_id)
                this.current_entraineur_article = articles['trainer']
                this.entraineur_article = this.all_articles.find(element => element.articles_id == this.current_entraineur_article)
                this.current_jockey_article = articles['jockey']
                this.jockey_article = this.all_articles.find(element => element.articles_id == this.current_jockey_article)
            },

            async saveForm() {
                this.processing = true
                if(this.trainer_code != this.current_trainer_code){
                    await this.saveTrainerCode(this.accountingplan_id, this.trainer_code)
                    .then(res => {
                        this.current_trainer_code = res
                    })
                }
                if(this.entraineur_article != this.current_entraineur_article || this.jockey_article != this.current_jockey_article){
                    await this.saveTrainerJockeyArticles(this.accountingplan_id, this.jockey_article.articles_id, this.entraineur_article.articles_id)
                    .then(res => {
                        this.current_entraineur_article = res['trainer']
                        this.current_jockey_article = res['jockey']
                    })
                }
                this.processing = false
            },

            async sync_performances() {
                this.processing = true
                this.syncPerformances()
                    .then(() => {
                        this.processing = false
                        this.infoToast('toast.long_process')
                    })
            }
        }
    }
</script>