<template>
	<div>
        <CustomTable
            id_table="semence_control_lot"
            ref="table"
            :items="controls"
            :busy.sync="table_busy"
            primaryKey="semencecontrol_id"
            :hide_if_empty="true"
            :externSlotColumns="['semencecontrol_statut']"
        >
			<template v-slot:[`custom-slot-cell(semencecontrol_statut)`]="{ data }">
				<span v-if="data.semencecontrol_statut">{{ $t('monte.congelation.statuts.'+data.semencecontrol_statut) }}</span>
			</template>
	    </CustomTable>
	</div>
</template>

<script type="text/javascript">
	import Congelation from "@/mixins/Congelation.js"
	import Navigation from "@/mixins/Navigation.js"
	import ShutterCongelation from "@/mixins/shutters-manager/Congelation.js"

	export default {
		name: 'ControlsLot',
		mixins: [Congelation, Navigation, ShutterCongelation],
		props: ['lot'],
		data () {
			return {
				table_busy: false,
				controls: [],
				events_tab: {
					'TableAction::goToControlLot': () => {
						this.setUpAddControlLot(this.lot)
					},
					'TableAction::goToEditControl': this.setUpEditControlLot
				},
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				this.table_busy = true
				this.controls = await this.getControlByLot(this.lot.semencelot_id)
				this.table_busy = false
			},

			reload() {
				this.ok()
				this.shutterPanel().goTo('controls-lot')
				this.init_component()
				this.$refs.table.unselectAll()
			},
		},

		components: {
			CustomTable : () => import('GroomyRoot/components/Table/CustomTable'),
		}
	}

</script>