<template>
	<div class="row">
		<div class="col-6 form-group">
			<label for="commentaire">{{ $t("monte.congelation.commentaire") }}</label>
			<input class="form-control" type="text" v-model="form.semenceexpedition_commentaire" id="commentaire">
		</div>
		<div class="col-6 form-group">
			<b-form-checkbox v-model="form.semenceexpedition_invoice_group" id="invoice_group">{{ $t("monte.congelation.invoice_group") }}</b-form-checkbox>
		</div>
		<div class="col-12">
			<h3>{{ $t('compta.articles') }}</h3>
			<div class="d-flex">
				<b-input-group>
					<e-select
						v-model="article_selected"
						id="articles_id"
						track-by="articles_id"
						label="articles_label"
						:placeholder="$t('monte.congelation.selectionner_article')"
						:selectedLabel="$t('global.selected_label')"
						:options="articles"
						:searchable="true"
						:allow-empty="false"
						:show-labels="false"
						:group-select="false"
						:loading="loading_articles"
					>
						<template slot="noOptions">{{ $t('global.list_empty') }}</template>
					</e-select>
					<b-input-group-append>
	            		<b-button @click="addArticle" variant="secondary">{{ $t("global.ajouter") }} <font-awesome-icon :icon="['fal', 'plus']"/></b-button>
					</b-input-group-append>
				</b-input-group>
			</div>
			<div v-if="expedition_articles.length > 0" class="row mt-1">
				<div class="col-3">
					<label for="articles_label">{{ $t('acte.article') }}</label>
				</div>
				<div class="col-1">
					<label for="articles_ht">{{ $t('invoice.invoicedetails_htunit') }}</label>
				</div>
				<div class="col-2">
					<label for="remise">{{ $t('acte.remise') }}</label>
				</div>
				<div class="col-1">
					<label for="qte">{{ $t('acte.quantite') }}</label>
				</div>
				<div class="col-4">
					<label for="commentaire">{{ $t("monte.congelation.commentaire") }}</label>
				</div>
				<div class="col-1">
					<label>{{ $t('global.supprimer') }}</label>
				</div>
			</div>
			<div v-for="(article, index) in expedition_articles" class="row mt-2" :key="index">
				<div class="col-3">
					<b-input type="text" :disabled="true" class="form-control" v-model="article.article.articles_label" />
				</div>
				<div class="col-1">
					<PriceInput v-model="article.expeditionarticles_ht"/>
				</div>
				<div class="col-2">
					<div class="d-flex">
						<b-input type="text" class="form-control" id="remise" v-model="article.expeditionarticles_discount_value" />
						<e-select
							v-model="article.expeditionarticles_discount_type"
							id="discount_type"
							track-by="invoicediscounttype_id"
							label="invoicediscounttype_label"
							:selectedLabel="$t('global.selected_label')"
							:options="discount_type"
							:searchable="true"
							:allow-empty="false"
							:show-labels="false"
						>
							<template slot="singleLabel" slot-scope="{ option }">{{ $t(option.invoicediscounttype_label) }}</template>
							<template slot="noOptions">{{ $t('global.list_empty') }}</template>
						</e-select>
					</div>
				</div>
				<div class="col-1">
					<b-input type="number" class="form-control" v-model="article.expeditionarticles_quantite" />
				</div>
				<div class="col-4">
					<b-input type="text" class="form-control" v-model="article.expeditionarticles_commentaire" />
				</div>
				<div class="col-1">
					<b-button @click.prevent="removeArticle(index)"><font-awesome-icon :icon="['fal', 'trash-alt']" /></b-button> 
				</div>
			</div>
		</div>
		<div class="col-12 text-center">
			<a class="btn btn-primary mt-3" href="#" @click.prevent="checkForm">{{ $t('action.modifier') }} <font-awesome-icon v-if="processing" :icon="['fal', 'spinner']" pulse /><font-awesome-icon v-else :icon="['fal', 'save']" /></a>
		</div>
	</div>
</template>

<script type="text/javascript">
	import Congelation from "@/mixins/Congelation.js"
	import Article from "@/mixins/Article.js"
	import Invoice from "@/mixins/Invoice.js"
	import Shutter from '@/mixins/Shutter.js'

	export default {
		name: 'EditInvoiceExpedition',
		mixins: [Congelation, Article, Invoice, Shutter],
		props: ['expedition'],
		data () {
			return {
				form: {
					semenceexpedition_commentaire: '',
					semenceexpedition_invoice_group: false
				},
				processing: false,
				loading_articles: false,
				articles: [],
				article_selected: null,
				expedition_articles: [],
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				await this.load_discount()
				await this.load_articles()

				this.form.semenceexpedition_commentaire = this.expedition.semenceexpedition_commentaire
				this.form.semenceexpedition_invoice_group = this.expedition.semenceexpedition_invoice_group

				this.expedition_articles = this.expedition.articles.map(article => ({
					expeditionarticles_article: article.article.articles_id,
					article: article.article,
					expeditionarticles_ht: article.expeditionarticles_ht / 100,
					expeditionarticles_quantite: article.expeditionarticles_quantite,
					expeditionarticles_discount_value: article.expeditionarticles_discount_value / 100,
					expeditionarticles_discount_type: this.discount_type.find(discount => discount.invoicediscounttype_id == article.expeditionarticles_discount_type) ?? this.discount_type[0],
					expeditionarticles_commentaire: article.expeditionarticles_commentaire
				}))
			},

			async load_discount() {
				const discount_type = await this.loadDiscountType()
				let tab = []
				tab.push({
					invoicediscounttype_id: 0,
					invoicediscounttype_label: "-",
					invoicediscounttype_type: "none"
				})

				for(let i = 0; i < discount_type.length; i++) {
					let current = discount_type[i]
					current.invoicediscounttype_label = this.getTrad(current.invoicediscounttype_label+"_short")
					tab.push(current)
				}

				this.discount_type = tab
			},

			async load_articles() {
				this.loading_articles = true
				this.articles = await this.loadAllArticles()
				this.loading_articles = false
			},

			addArticle() {
				if(!this.article_selected) {
					return false
				}

				this.expedition_articles.push({
					expeditionarticles_article: this.article_selected.articles_id,
					article: this.article_selected,
					expeditionarticles_ht: this.article_selected.articles_ht,
					expeditionarticles_quantite: 1,
					expeditionarticles_discount_value: 0,
					expeditionarticles_discount_type: this.discount_type[0],
					expeditionarticles_commentaire: ''
				})

				this.article_selected = null
			},

			removeArticle(index) {
				this.expedition_articles.splice(index, 1)
			},

			async checkForm() {
				if(this.processing) {
					return false
				}

				this.processing = true
				const params = {
					...this.expedition,
					semenceexpedition_commentaire: this.form.semenceexpedition_commentaire,
					semenceexpedition_invoice_group: this.form.semenceexpedition_invoice_group,
				}

				await this.editExpedition(this.expedition.semenceexpedition_id, params)

				if(this.expedition_articles.length > 0)
					await this.addExpeditionArticle(this.expedition.semenceexpedition_id, this.expedition_articles)
				this.processing = false
				this.successToast('toast.info_save_succes')
				this.ok()
				this.shutterPanel().close('expedition-invoice-edit')
			},
		},

		components: {
			PriceInput: () => import('GroomyRoot/components/Inputs/PriceInput')
		}
	}

</script>