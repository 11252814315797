<template>
    <div>
        <b-modal ref="modal" hide-footer size="xl">
            <template v-slot:modal-title>
				{{ $t("action.importer") }}
			</template>
            <div v-if="formError == false">
                <b-form-textarea
                v-if="!importModal"
                id="textarea"
                v-model="text"
                placeholder="Enter something..."
                rows="10"></b-form-textarea>
                <b-button v-if="!importModal" variant="primary" @click="handleData()" class="mb-4">{{$t('action.importer')}}</b-button>
                <Import v-if="importModal" :form.sync="form" :numberOfSelect.sync="numberOfSelect" :options="options" @clicked="importData($event)"/>
            </div>
            <div v-else>
                <h4>{{$t("compta.form.error")}} : {{ formErrorLabel }}</h4>
                <b-button variant="primary" @click="closeModal()">{{$t('compta.form.compris')}}</b-button>
            </div>
        </b-modal>
    </div>
</template>

<script type="text/javascript">
    import Navigation from "@/mixins/Navigation.js"
    import Article from "@/mixins/Article.js"
    import Invoice from "@/mixins/Invoice.js"
    import Tools from "@/mixins/Tools.js"
    import _deepClone from 'lodash/cloneDeep'
    import Vue from 'vue'

export default {
        name: "ArticleImport",
        props: ['accountingplan_id'],
        mixins: [Navigation, Article, Tools, Invoice],
        data () {
            return {
                formError: false,
                formErrorLabel: "",
                FormToDownload: {},
                indicatifs: {},
				countries: {},
				countries_iso: {},
                importModal: false,
                options: [
                    { value: '0'    , text: this.getTrad('compta.form.label_article'), column: 'articles_label', requis: false },
                    { value: '1'    , text: this.getTrad('compta.form.code_article'), column: 'articles_code', requis: false },
                    { value: '2'    , text: this.getTrad('compta.form.commentaire'), column: 'articles_commentaire', requis: false },
                    { value: '3'    , text: this.getTrad('compta.form.quantite_defaut'), column: 'articles_quantite', requis: false},
                    { value: '4'    , text: this.getTrad('compta.form.tarif_ht'), column: 'articles_ht', requis: false},
                    { value: '5'    , text: this.getTrad('compta.form.vat'), column: 'articles_vat', requis: false },
                    { value: '6'    , text: this.getTrad('compta.form.articles_type'), column: 'articles_articlestype', requis: false },
                    { value: '7'    , text: this.getTrad('compta.form.compte'), column: 'articles_accountingaccount', requis: false },
                    { value: '8'    , text: this.getTrad('compta.form.articles_accountingaccountfdvxp'), column: 'articles_accountingaccountfdvxp', requis: false },
                    { value: '9'    , text: this.getTrad('compta.form.articles_accountingaccountfdvex'), column: 'articles_accountingaccountfdvex', requis: false },
                    { value: '10'   , text: this.getTrad('compta.form.compte_tva'), column: 'articles_vataccount', requis: false },
                    { value: '11'   , text: this.getTrad('compta.form.articles_vataccountfdvxp'), column: 'articles_vataccountfdvxp', requis: false },
                    { value: '12'   , text: this.getTrad('compta.form.articles_vataccountfdvex'), column: 'articles_vataccountfdvex', requis: false },
                    { value: 'null' , text: this.getTrad('global.ne_pas_importer'), column: 'null', requis: false}
                ],
                default_form: {
                    articles_label: '',
                    articles_ht: '',
                    articles_vat: '0',
                    articles_commentaire: '',
                    articles_quantite: 1,
                    articles_accountingaccount: '',
                    articles_accountingaccountfdvxp: '',
                    articles_accountingaccountfdvex: '',
                    articles_vataccount: '',
                    articles_vataccountfdvxp: '',
                    articles_vataccountfdvex: '',
                    articles_articlestype: '',
                },
                numberOfSelect: 0,
                form: [],
                text: "",
                comptes_comptables: [],
                comptes_comptables_vat: [],
                vat: [],
                accountingplan_id_local: null,
                events_tab: {
                    'TableAction::goToImportArticles': () => {
                        this.showModal()
                    },
                }
            }
        },
        mounted() {
            this.init_component()
        },
        methods: {
            async init_component() {
                // Importer comptes accounting
                // Importer comptes VAT
                this.accountingplan_id_local = this.accountingplan_id
                if(Object.keys(this.comptes_comptables).length == 0) {
                    const comptes_comptables = await this.loadAccountingAccounts(this.accountingplan_id_local)
                    if(comptes_comptables) {
                        for(let i = 0; i < comptes_comptables.length; i++) {
                            // Camille 09/09 => il n’y a que les comptes de TVA qu’il faut “bloquer” du coup
							if(comptes_comptables[i].accountingaccount_vat == null) {
								this.comptes_comptables.push({
                                    name: comptes_comptables[i].accountingaccount_number, 
                                    id: comptes_comptables[i].accountingaccount_id 
                                })
							}
                            else
                            {
                                this.comptes_comptables_vat.push({
                                    name: comptes_comptables[i].accountingaccount_number,
                                    id: comptes_comptables[i].accountingaccount_id,
                                    vat: comptes_comptables[i].accountingaccount_vat
                                })
                            }
                        }
                    }
                }
                if (this.vat.length == 0) {
                    this.vat = await this.getVat()
                }
            },
            async showModal() {
                this.$refs['modal'].show()
                this.text = ""
                this.importModal = false
            },
            async handleData() {
                if(this.text == "" || this.text == null){
                    return
                }
                const wordByspace = this.text.split('\n')
                for (const [i, elements] of wordByspace.entries()) {
                    const words = elements.split('\t')
                    let obj = {}
                    obj.checked = true
                    this.numberOfSelect = 0
                    for (const [j, word] of words.entries()) {
                        this.numberOfSelect++
                        obj[j] = word
                    }
                    this.$set(this.form, i, obj)
                }
                this.importModal = true
            },

            async importData(event){
                const final_form = []
                let ID_Articles = 0
                for (const article of event) {
                    const form = _deepClone(this.default_form)
                    
                    for (const [key, value] of Object.entries(article)){
                        form[key] = value
                    }

                    // Label
                    if (form.articles_label === "") { // Obligatoire
                        this.formError = true
                        this.formErrorLabel = this.getTrad("error.ARTIM_LABEL") 
                        return
                    }

                    // Compte accounting
                    if (form.articles_accountingaccount === "") { // Obligatoire
                        this.formError = true
                        this.formErrorLabel = this.getTrad("error.ARTIM_ACCNT") 
                        return
                    }
                    // ACCOUNTING: On cherche par numéro de compte
                    const compteAccounting = this.comptes_comptables.find(e => e.name == form.articles_accountingaccount)
                    if(!compteAccounting) {
                        console.log(form.articles_accountingaccount);
                        this.formError = true
                        this.formErrorLabel = this.getTrad("error.ARTIM_ACCNT_FND") 
                        return
                    }
                    // Succès: le compte Accounting est trouvé
                    form.articles_accountingaccount = compteAccounting.id
                    // On le met aussi pour les comptes FDVEX et FDVXP si ils sont vides
                    if(form.articles_accountingaccountfdvxp === ""){
                        form.articles_accountingaccountfdvxp = compteAccounting.id
                    } else { // Sinon même traitement
                        const compteFdvxp = this.comptes_comptables.find(e => e.name == form.articles_accountingaccountfdvxp)
                        if(!compteFdvxp) {
                            this.formError = true
                            this.formErrorLabel = this.getTrad("error.ARTIM_ACCNTVXP") 
                            return
                        }
                        form.articles_accountingaccountfdvxp = compteFdvxp.id
                    }
                    if(form.articles_accountingaccountfdvex === ""){
                        form.articles_accountingaccountfdvex = compteAccounting.id
                    } else { // Et re même traitement
                        const compteFdvex = this.comptes_comptables.find(e => e.name == form.articles_accountingaccountfdvex)
                        if(!compteFdvex) {
                            this.formError = true
                            this.formErrorLabel = this.getTrad("error.ARTIM_ACCNTVEX") 
                            return
                        }
                        form.articles_accountingaccountfdvex = compteFdvex.id

                    }

                    // Compte VAT
                    if (form.articles_vataccount === "") { // Obligatoire
                        this.formError = true
                        this.formErrorLabel = this.getTrad("error.ARTIM_VATACCNT") 
                        return
                    }

                    // VAT: On cherche par numéro de compte TVA
                    const compteVat = this.comptes_comptables_vat.find(e => e.name == form.articles_vataccount)
                    if(!compteVat) {
                        this.formError = true
                        this.formErrorLabel = this.getTrad("error.ARTIM_VATACCNTFND") 
                        return
                    }
                    // Succès: le compte VAT est trouvé
                    form.articles_vataccount = compteVat.id
                    // On le met aussi pour les comptes FDVEX et FDVXP si ils sont vides
                    if(form.articles_vataccountfdvxp === ""){
                        form.articles_vataccountfdvxp = compteVat.id
                    } else { // Sinon même traitement
                        const compteVatFdvxp = this.comptes_comptables_vat.find(e => e.name == form.articles_vataccountfdvxp)
                        if(!compteVatFdvxp) {
                            this.formError = true
                            this.formErrorLabel = this.getTrad("error.ARTIM_VATACCNTVXP") 
                            return
                        }
                        form.articles_vataccountfdvxp = compteVatFdvxp.id
                    }
                    if(form.articles_vataccountfdvex === ""){
                        form.articles_vataccountfdvex = compteVat.id
                    } else { // Et re même traitement
                        const compteVatFdvex = this.comptes_comptables_vat.find(e => e.name == form.articles_vataccountfdvex)
                        if(!compteVatFdvex) {
                            this.formError = true
                            this.formErrorLabel = this.getTrad("error.ARTIM_VATACCNTVEX") 
                            return
                        }
                        form.articles_vataccountfdvex = compteVatFdvex.id

                    }

                    // VAT
                    if (!form.articles_vat || form.articles_vat == ""){
                        form.articles_vat = 1 // id 1 = 0% de VAT
                    } else {
                        form.articles_vat = this.vat.find(e => e.vat_label.toFloat() === form.articles_vat.replace(',', '.').toFloat())
                        if(form.articles_vat){ 
                            form.articles_vat = form.articles_vat.vat_id
                        } else {
                            this.formError = true
                            this.formErrorLabel = this.getTrad("error.ARTIM_VAT") 
                            return  
                        }
                    }

                    // Type d'article
                    if (form.articles_articlestype === "") { // Obligatoire
                        this.formError = true
                        this.formErrorLabel = this.getTrad("error.ARTIM_TYPEFND") 
                        return
                    }
                    // D'abord on regarde le string
                    if (form.articles_articlestype.toLowerCase() === "service") {
                        form.articles_articlestype = 1
                    } else if(form.articles_articlestype.toLowerCase() === "produit" || form.articles_articlestype.toLowerCase() === "product") {
                        form.articles_articlestype = 2
                    }
                    // Puis l'integer (1=service, 2=produit)
                    else if(!(form.articles_articlestype === "1" || form.articles_articlestype === "2")) {
                        this.formError = true
                        this.formErrorLabel = this.getTrad("error.ARTIM_TYPE") 
                        return
                    }

                    // Déterminer taux TTC
                    if (form.articles_ht === "") { // Obligatoire
                        this.formError = true
                        this.formErrorLabel = this.getTrad("error.ARTIM_HT") 
                        return
                    }
                    const tva = parseFloat(form.articles_vat)
                    const ht = parseFloat(form.articles_ht.toString().replace(',', '.'))
                    let ttc = (tva * ht) + ht
                    if(ttc.toString().indexOf(".") > -1){
                        ttc = ttc.toString().substr(0, ttc.toString().indexOf(".") + 3).toFloat()
                    }
                    form.articles_ttc = ttc
                    form.articles_ht = ht
                    form.articles_vat = tva

                    if(form.articles_quantite == ""){
                        form.articles_quantite = "1"
                    }

                    // Déterminer code article 
                    if (!form.articles_code || form.articles_code === ""){
                        form.articles_code = this.accentsTidy(form.articles_label).replace(/ /g, "_")
                    } else {
                        form.articles_code = this.accentsTidy(form.articles_code).replace(/ /g, "_")
                    }

                    final_form.push(form)
                    ID_Articles++
                } 
                try {
                    await this.importArticle(final_form)
                }
                catch (e) {
                    console.error(e)
                }

                if(Object.keys(this.FormToDownload).length > 0){
                    this.formError = true
                    this.formErrorLabel = ""
                    this.$parent.loadArticlesListe()
                    return
                }

                this.$parent.loadArticlesListe()
                this.$refs['modal'].hide()
            },

            closeModal(){
                this.$refs['modal'].hide()
                this.formError = false
                this.FormToDownload = {}
            },
        },
        components: {
            LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
            ErrorAlert: () => import('GroomyRoot/components/Alert/ErrorAlert'),
            Import: () => import('@/components/Utils/Import'),
        }
    }
</script>
