<template>
    <div>
        <h2>{{ $t("compta.articles") }}</h2>
        <div class="box">
            <CustomTable
                ref="table"
                id_table="articles"
				:items="articles"
                :busy.sync="table_busy"
                primaryKey="articles_id"
                :hide_if_empty="true"
                :externSlotColumns="['articles_inactive']"
            >
            	<template v-slot:[`custom-slot-cell(articles_inactive)`]="{ data }">
					<div class="custom-control custom-checkbox">
						<b-form-checkbox 
							name="check-button"
                            switch 
							v-model="data.switch" 
							@change="switchArticle(data.articles_id, data.switch)"
						>
							<template v-if="data.switch">
								{{ $t('lieu.registry_enable') }}
							</template>
							<template v-else>
								{{ $t('lieu.registry_disable') }}
							</template>
						</b-form-checkbox>
					</div>
				</template>
            </CustomTable>
            <ArticleAjout :accountingplan_id="accountingplan_id" @submit="addArticle"/>
            <ArticleImport :accountingplan_id="accountingplan_id" />
            <b-modal ref="modalTva" hide-footer @hidden="base64 = null">
                <template v-slot:modal-title>
                    {{ $t("action.modifier_tva") }}
                </template>

                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <label for="articles_vat">{{ $t('compta.form.vat') }} *</label>
                            <e-select
                                id="search_vat"
                                track-by="value"
                                label="name"
                                v-model="articles_vat"
                                :options="vat"
                                :searchable="true"
                                :allow-empty="false"
                                :show-labels="false"
                            >
                                <template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
                                <template slot="noOptions">{{ $t('global.list_empty') }}</template>
                            </e-select>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-group">
                            <label for="articles_vataccount" class="col-form-label">{{ $t("compta.form.vat_compte_comptable") }} *</label>
                            <e-select
                                v-model="articles_vataccount"
                                id="articles_vataccount"
                                track-by="id"
                                label="name"
                                :options="comptes_comptables_vat"
                                :searchable="true"
                                :show-labels="false"
                                :allow-empty="false"
                            >
                                <template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
                                <template slot="noOptions">{{ $t('global.list_empty') }}</template>
                            </e-select>
                            
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-group">
                            <label for="articles_vataccountfdvxp" class="col-form-label">{{ $t("compta.form.articles_vataccountfdvxp") }} *</label>
                            <e-select
                                v-model="articles_vataccountfdvxp"
                                id="articles_vataccountfdvxp"
                                track-by="id"
                                label="name"
                                :options="comptes_comptables_vat"
                                :searchable="true"
                                :show-labels="false"
                                :allow-empty="false"
                            >
                                <template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
                                <template slot="noOptions">{{ $t('global.list_empty') }}</template>
                            </e-select>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-group">
                            <label for="articles_vataccountfdvex" class="col-form-label">{{ $t("compta.form.articles_vataccountfdvex") }} *</label>
                            <e-select
                                v-model="articles_vataccountfdvex"
                                id="articles_vataccountfdvex"
                                track-by="id"
                                label="name"
                                :options="comptes_comptables_vat"
                                :searchable="true"
                                :show-labels="false"
                                :allow-empty="false"
                            >
                                <template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
                                <template slot="noOptions">{{ $t('global.list_empty') }}</template>
                            </e-select>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="text-center mt-2">
                            <button type="submit" @click="saveTva" class="btn btn-primary">
                                {{ $t('global.enregistrer') }} <font-awesome-icon v-if="!processing" :icon="['fal', 'save']" /> <font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse />
                            </button>
                        </div>
                    </div>
                </div>
            </b-modal>
            <b-modal ref="modalCompteComptable" hide-footer @hidden="base64 = null">
                <template v-slot:modal-title>
                    {{ $t("action.modifier_compte_comptable") }}
                </template>

                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <label for="articles_accountingaccount" class="col-form-label">{{ $t("compta.form.compte_comptable") }} *</label>
                            <e-select
                                v-model="articles_accountingaccount"
                                id="articles_accountingaccount"
                                track-by="id"
                                label="name"
                                :options="comptes_comptables"
                                :searchable="true"
                                :show-labels="false"
                                :allow-empty="false"
                            >
                                <template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
                                <template slot="noOptions">{{ $t('global.list_empty') }}</template>
                            </e-select>
                            
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-group">
                            <label for="articles_accountingaccountfdvxp" class="col-form-label">{{ $t("compta.form.articles_accountingaccountfdvxp") }} *</label>
                            <e-select
                                v-model="articles_accountingaccountfdvxp"
                                id="articles_accountingaccountfdvxp"
                                track-by="id"
                                label="name"
                                :options="comptes_comptables"
                                :searchable="true"
                                :show-labels="false"
                                :allow-empty="false"
                            >
                                <template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
                                <template slot="noOptions">{{ $t('global.list_empty') }}</template>
                            </e-select>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-group">
                            <label for="articles_accountingaccountfdvex" class="col-form-label">{{ $t("compta.form.articles_accountingaccountfdvex") }} *</label>
                            <e-select
                                v-model="articles_accountingaccountfdvex"
                                id="articles_accountingaccountfdvex"
                                track-by="id"
                                label="name"
                                :options="comptes_comptables"
                                :searchable="true"
                                :show-labels="false"
                                :allow-empty="false"
                            >
                                <template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
                                <template slot="noOptions">{{ $t('global.list_empty') }}</template>
                            </e-select>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="text-center mt-2">
                            <button type="submit" @click="saveCompteCompable" class="btn btn-primary">
                                {{ $t('global.enregistrer') }} <font-awesome-icon v-if="!processing" :icon="['fal', 'save']" /> <font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse />
                            </button>
                        </div>
                    </div>
                </div>
            </b-modal>
        </div>
    </div>
</template>

<script type="text/javascript">
    import Accounting from "@/mixins/Accounting.js"
    import Article from "@/mixins/Article.js"
    import Invoice from "@/mixins/Invoice.js"
    import Navigation from "@/mixins/Navigation.js"
    import Shutter from '@/mixins/Shutter.js'

    export default {
        name: "Article",
        mixins: [Accounting, Navigation, Article, Invoice, Shutter],
        props: ['accountingplan_id', 'code_onboarding'],
        data () {
            return {
                articles: null,
                table_busy: true,
                events_tab: {
                    'TableAction::goToDeleteArticle': this.deleteArticle,
                    'TableAction::goToEditArticleTva': this.openModelEditTva,
                    'TableAction::goToEditArticleCompteComptable': this.openModelEditCompteComptable
				},
                articles_edit_tva: [],
                articles_edit_compte_comptable: [],
                vat: [],
                articles_vat: {},
                comptes_comptables_vat: [],
                comptes_comptables: [],
                articles_vataccount: {},
                articles_vataccountfdvxp: {},
                articles_vataccountfdvex: {},
                articles_accountingaccount: {},
                articles_accountingaccountfdvxp: {},
                articles_accountingaccountfdvex: {},
                processing: false
			}
        },
        mounted() {
            this.init_component()
        },
        methods: {
            async init_component() {
                this.loadArticlesListe()
                this.loadVat()
            },
            async loadArticlesListe() {
                this.table_busy = true
				const articles = await this.loadAllArticlesByAccounting(this.accountingplan_id)
				this.articles = await this.formatArticles(articles)
                this.table_busy = false
            },
            async loadVat() {
                const vat = await this.getVat()
                for (let i = 0; i < vat.length; i++) {
                    this.vat.push({
                        id: vat[i].vat_id,
                        name: vat[i].vat_label,
                        value: vat[i].vat_value,
                    })
                }

                const comptes_comptables = await this.loadAccountingAccounts(this.accountingplan_id)
                if(comptes_comptables) {
                    for(let i = 0; i < comptes_comptables.length; i++) {
                        if(comptes_comptables[i].accountingaccount_vat != null) {
                            this.comptes_comptables_vat.push({
                                name: comptes_comptables[i].accountingaccount_number + " ("+ comptes_comptables[i].accountingaccount_label +")", 
                                id: comptes_comptables[i].accountingaccount_id,
                                vat: comptes_comptables[i].accountingaccount_vat
                            })
                        }
                        else {
                            this.comptes_comptables.push({
                                name: comptes_comptables[i].accountingaccount_number + " ("+ comptes_comptables[i].accountingaccount_label +")", 
                                id: comptes_comptables[i].accountingaccount_id 
                            })
                        }
                    }
                }
            },
            async deleteArticle(params) {
                let isDeleted = await this.deleteArticles(params.articles_ids.split(","))
                if(isDeleted.length > 0)
                {
                    if(params.articles_ids.split(",").length == 1)
                    {
                        this.failureToast("toast.articles_single_not_deleted")
                    }
                    else
                    {
                        this.failureToast("toast.articles_not_deleted")
                    }
                }
                else
                {
                    this.successToast("toast.articles_deleted")
                }
                this.$refs.table.refreshTable()
                this.loadArticlesListe()
			},
			async formatArticles(articles) {
                let articlesFormated = []

				if(!articles)
                {
                    return []
                }

				for (let i = 0; i < articles.length; i++) {
					const temp = articles[i]
					temp.id = temp.articles_id
					temp.switch = !temp.articles_inactive
					temp.articles_ht_format = await this.priceFormat(temp.articles_ht, temp.tiers_currency)
					temp.articles_ttc_format = await this.priceFormat(temp.articles_ttc, temp.tiers_currency)
                    temp.articles_type.articlestype_label = this.getTrad(temp.articles_type.articlestype_label)
					articlesFormated.push(temp);
				}

                return articlesFormated
            },
			switchArticle(articles_id, val) {
				const article = this.articles.find((a) => (a.articles_id === articles_id))
				this.editArticles(
					articles_id,
					article.articles_accountingaccount,
					article.articles_vat,
					article.articles_label,
					article.articles_code,
					article.articles_ht,
					article.articles_ttc,
					val,
                    article.articles_articlestype,
                    article.articles_accountingaccountfdvex,
                    article.articles_accountingaccountfdvxp,
                    article.articles_vataccount,
                    article.articles_vataccountfdvex,
                    article.articles_vataccountfdvxp,
                    article.articles_doublons
				)
			},
            addArticle() {
                this.$refs.table.unselectAll()

                if(this.code_onboarding) {
                    this.ok()
                }
            },
            openModelEditTva(articles) {
                this.$refs.modalTva.show()
                this.articles_edit_tva = articles
                this.articles_edit_tva = articles
                this.articles_vat = {}
                this.articles_vataccount = {}
                this.articles_vataccountfdvxp = {}
                this.articles_vataccountfdvex = {}
                this.processing = false
            },
            openModelEditCompteComptable(articles){
                this.$refs.modalCompteComptable.show()
                this.articles_edit_compte_comptable = articles
                this.articles_accountingaccount = {}
                this.articles_accountingaccountfdvxp = {}
                this.articles_accountingaccountfdvex = {}
                this.processing = false
            },
            async saveTva() {
                this.processing = true
                const articles_ids = this.articles_edit_tva.map(article => article.articles_id)
                await this.saveArticleTva(articles_ids, this.articles_vat.id, this.articles_vataccount.id, this.articles_vataccountfdvxp.id, this.articles_vataccountfdvex.id)

                this.processing = false
                this.$refs.modalTva.hide()
                this.$refs.table.refreshTable()
                this.loadArticlesListe()
            },
            async saveCompteCompable() {
                this.processing = true
                const articles_ids = this.articles_edit_compte_comptable.map(article => article.articles_id)
                await this.saveArticleCompteComptable(articles_ids, this.articles_accountingaccount.id, this.articles_accountingaccountfdvxp.id, this.articles_accountingaccountfdvex.id)

                this.processing = false
                this.$refs.modalCompteComptable.hide()
                this.$refs.table.refreshTable()
                this.loadArticlesListe()
            }
        },
        components: {
            CustomTable: () => import('GroomyRoot/components/Table/CustomTable'),
            ArticleAjout: () => import('@/components/Accounting/ArticleAjout'),
            ArticleImport: () => import('@/components/Accounting/ArticleImport')
            
        }
    }
</script>
