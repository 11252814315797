<template>
	<div>
		<e-select
            v-model="planning_selected"
            track-by="taskplanning_id"
            label="taskplanning_label"
			:placeholder="$t('task_planning.select_planning')"
            :options="all_planning"
            :searchable="true"
            :allow-empty="false"
            :show-labels="false"
            :group-select="false"
            :loading="loading_all_planning"
            @input="goToLoadPlanning"
        />

        <LoadingSpinner v-if="loading_planning" class="col-12" />
        <div v-if="!loading_planning && planning_selected">
        	<div class="m-0 mt-2">
				<table class="table b-table table-striped table-hover table-borderless table-variante table-task">
					<thead class="sticky">
						<tr class="b-table-details">
							<th>
								<button class="btn btn-secondary" @click="goPrev"><font-awesome-icon :icon="['fal', 'long-arrow-left']" /></button>
								<button class="btn btn-secondary" @click="goToday">{{ $t('global.aujourdhui') }}</button>
								<button class="btn btn-secondary" @click="goNext"><font-awesome-icon :icon="['fal', 'long-arrow-right']" /></button>
							</th>
							<th v-for="day in dates_to_show" class="border-left border-right border-bottom text-center" :key="day">
								{{ getDateLabel(day) }}	{{ formatDate(day, false) }}
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="horse in planning.horses" :key="(typeof horse === 'string' || horse instanceof String) ? horse : horse.horse.horse_id" class="b-table-details">
							<template v-if="(typeof horse === 'string' || horse instanceof String)">
								<td>
									{{ horse }}
								</td>
								<td v-for="day in dates_to_show" class="border-bottom text-center p-0" :key="day"></td>
							</template>
							<template v-else>
								<td><span class="d-flex align-items-center">
									<router-link :to="{ name: 'HorseFicheInfo', params: { horse_id: horse.horse.horse_id }}">{{ horse.horse.horse_nom }}</router-link>
									<span class="tag-manage ml-2" v-if="horse.horse.horse_tags.length > 0">
										<span v-for="tag in horse.horse.horse_tags" :key="'selected'+tag.tags_color" class="tags" :style="'background-color:'+tag.tags_color"></span>
									</span>
								</span></td>
								<td v-for="day in dates_to_show" class="border-left border-right border-bottom text-center p-0" :key="day">
									<div class="text-center justify-content-center align-items-center py-2 || this.planning_selected.taskplanning_groupby === 3">
										<button class="btn btn-secondary" @click="add(horse.horse, day)"><font-awesome-icon :icon="['far', 'plus']" /></button>
									</div>
									<template v-if="horse.task[day] && horse.task[day].length">
										<div v-for="task in horse.task[day]" @click="edit(task, horse.horse, day)" class="border-top" role="button" :style="{'background': task.acte && task.acte.actes_actesstatut ? 'green' : 'unset' }" :key="task.task_id">
											<b-avatar
												v-if="task.contact"
												size="3em"
												:style="'color: white;background-color:'+task.contact.contact_color"
											>
												{{ task.contact.contact_abbreviation }}
											</b-avatar>
											<span class="ml-2 || this.planning_selected.taskplanning_groupby === 3" v-if="task.acte_type && (task.acte_type.active == null || task.acte_type.active.actestypeactive_active)">{{ checkIfTranslationExists('actes_type.'+task.acte_type.actestype_code) ? $t('actes_type.'+task.acte_type.actestype_code) : task.acte_type.actestype_label }}</span>
										</div>
									</template>
								</td>
							</template>
						</tr>
					</tbody>
				</table>
			</div>
        </div>
	</div>
</template>

<script type="text/javascript">
	import TaskPlanning from "@/mixins/TaskPlanning.js"
	import _union from 'lodash/union'
	import _cloneDeep from 'lodash/cloneDeep'
    import Shutter from '@/mixins/Shutter.js'
	import ShutterAct from "@/mixins/shutters-manager/Acts.js"

	export default {
		name: 'TaskPlanning',
		mixins: [TaskPlanning, Shutter, ShutterAct],
		data () {
			return {
				loading_all_planning: false,
				loading_planning: false,
				all_planning: [],
				planning_selected: null,
				planning: {
					days: {},
					horses: {}
				},
				group_by: false,
				dates_to_show: [],
				horse_selected: null
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				this.loading_all_planning = true
				this.all_planning = await this.getAllTaskPlanning(true)
				this.loading_all_planning = false

				if (this.all_planning.length > 0){
					this.planning_selected = this.all_planning[0]
					this.goToLoadPlanning()
				}

				let today = new Date()
				let tomorrow = new Date()
				tomorrow.setDate(tomorrow.getDate() + 1)

				this.dates_to_show = [today.toDateInputValue(), tomorrow.toDateInputValue()]
			},

			goToLoadPlanning() {
				this.planning = {
					days: {},
					horses: {}
				}
				let start = new Date()
				start.setDate(start.getDate() - 5)
				let end = new Date()
				end.setDate(end.getDate() + 5)
				this.loadPlanning(start.toDateInputValue(), end.toDateInputValue())
			},

			async loadPlanning(start, end) {
				this.loading_planning = true
				this.group_by = false
				const planning = await this.getTaskPlanning(this.planning_selected.taskplanning_id, start, end)

				const days = _union(this.planning.days, planning.days)
				this.planning.days = days.sort((a,b) => {
					if(new Date(a).getTime() > new Date(b).getTime()) {
						return 1
					}
					else {
						return -1
					}
				})

				for(let id in planning.horses) {
					if(!Object.prototype.hasOwnProperty.call(this.planning.horses, id)) {
						this.planning.horses[id] = _cloneDeep(planning.horses[id])
					}
					else {
						let days = _cloneDeep(this.planning.horses[id].task)
						for(let day in planning.horses[id].task) {
							if(!Object.prototype.hasOwnProperty.call(days, day)) {
								days[day] = _cloneDeep(planning.horses[id].task[day])
							}
						}
						this.planning.horses[id].task = days
					}
				}

				// Group by
				this.group_by = this.planning_selected.taskplanning_groupby !== 1

				// Tri par date & sexe
				if (this.group_by && (this.planning_selected.taskplanning_groupby === 2 || this.planning_selected.taskplanning_groupby === 3)) {
					const unformatted_planning_horses = this.planning.horses
					const sorted_planning_horses = [
						{label: this.getTrad("task_planning.foal_0yo"),
						horses: []},
						{label: this.getTrad("task_planning.foal_1yo"),
						horses: []},
						{label: this.getTrad("task_planning.horse_2yo"),
						horses: []},
						{label: this.getTrad("task_planning.horse_3yo"),
						horses: []},
						{label: this.getTrad("task_planning.horse_4yo"),
						horses: []},
						{label: this.getTrad("task_planning.horse_5yo"),
						horses: []},
						{label: this.getTrad("task_planning.horse_6yo"),
						horses: []},
						{label: this.getTrad("task_planning.horse_over_7yo"),
						horses: []},
					]
					const today = new Date();
					Object.entries(unformatted_planning_horses).forEach((horse) => {
						if(!horse[1].horse) return
						const age = today.getFullYear() - new Date(horse[1].horse.horse_datenaissance).getFullYear()
						age < 7 ? sorted_planning_horses[age].horses.push(horse) : sorted_planning_horses[7].horses.push(horse)
					})
					
					let formatted_planning_horses = []
					
					sorted_planning_horses.forEach((row) => {
						if (row.horses.length > 0) {

							// Tri alphabetique des chevaux
							row.horses.sort(function(a, b){
								let horse_nomA = a[1].horse.horse_nom.toLowerCase(), horse_nomB = b[1].horse.horse_nom.toLowerCase();
								if (horse_nomA > horse_nomB)
									return 1;
								if (horse_nomA < horse_nomB)
									return -1;
								return 0;
							});

							// Puis on trie par sexe
							row.horses.sort(function(a, b){
								let horse_sexeA = a[1].horse.horse_sexe, horse_sexeB = b[1].horse.horse_sexe;
								if (horse_sexeA < horse_sexeB)
									return 1;
								if (horse_sexeA > horse_sexeB)
									return -1;
								return 0;
							});

							// L'ordre est inversé par odre décroissant
							if (this.planning_selected.taskplanning_groupby === 2)
								row.horses.reverse()

							// Pour le tri par ordre croissant: le label est inséré au début
							if (this.planning_selected.taskplanning_groupby === 3 ) 
								formatted_planning_horses.push(row.label)

							// Les chevaux sont insérés dans le tableau
							row.horses.forEach((horse) => {
								formatted_planning_horses.push(horse[1])
							})

							// Pour le tri par ordre décroissant: à la fin le label est inséré
							if (this.planning_selected.taskplanning_groupby === 2)
							 	formatted_planning_horses.push(row.label)
						}
					})
					// Puis on inverse pour bien retrouver les chevaux les plus vieux en premier (seulement pour le tri décroissant)
					this.planning.horses = this.planning_selected.taskplanning_groupby === 2 ? formatted_planning_horses.reverse() : formatted_planning_horses
				}

				// Tri par localisation
				else if (this.group_by && this.planning_selected.taskplanning_groupby === 4) {
					const unformatted_planning_horses = this.planning.horses
					const sorted_planning_horses = []
					this.planning_selected.lieu.forEach((lieu) => {
						// On prend les chevaux dans ce lieu
						const horses = Object.entries(unformatted_planning_horses).filter((horse) =>{
							if(lieu.lieu && lieu.lieu.lieu_id) {
								return horse[1].horse.residence.horseresidence_lieu == lieu.lieu.lieu_id
							} else if (lieu.taskplanninglieu_intra_location){
								return horse[1].horse.intralocation_id == lieu.taskplanninglieu_intra_location
							}
						})
						// On les trie par ordre alphabétique
						horses.sort(function(a, b){
							let horse_nomA = a[1].horse.horse_nom.toLowerCase(), horse_nomB = b[1].horse.horse_nom.toLowerCase();
							if (horse_nomA > horse_nomB)
								return 1;
							if (horse_nomA < horse_nomB)
								return -1;
							return 0;
						});

						// Puis on trie par sexe
						horses.sort(function(a, b){
							let horse_sexeA = a[1].horse.horse_sexe, horse_sexeB = b[1].horse.horse_sexe;
							if (horse_sexeA < horse_sexeB)
								return 1;
							if (horse_sexeA > horse_sexeB)
								return -1;
							return 0;
						});

						const group = {
							label: lieu.lieu ? lieu.lieu.lieu_label : lieu.intra_location.intralocation_label,
							horses: horses
						}
						sorted_planning_horses.push(group)
					})

					const formatted_planning_horses = []
					sorted_planning_horses.forEach((row) => {
						if(row.horses.length > 0) {
							formatted_planning_horses.push(row.label)
							row.horses.forEach((horse) => {
								formatted_planning_horses.push(horse[1])
							})
						}
					})
					
					this.planning.horses = formatted_planning_horses
				}
				
				this.loading_planning = false
			},
			getDateLabel(date) {
				return this.getTrad("monte.jours."+new Date(date).getDay())
			},

			async goPrev() {
				let current = this.dates_to_show[0]
				let current_in_tab = this.planning.days.indexOf(current)

				if(current_in_tab == 1 || current_in_tab == 0) {
					let start = new Date(current)
					start.setDate(start.getDate() - 5)
					let end = new Date(current)
					end.setDate(end.getDate())
					this.planning = {
						days: {},
						horses: {}
					}
					await this.loadPlanning(start.toDateInputValue(), end.toDateInputValue())
				}

				current_in_tab = this.planning.days.indexOf(current)
				
				let start = this.planning.days[current_in_tab-1]
				let end = this.planning.days[current_in_tab]
				if(current_in_tab == -1) {
					start = this.planning.days[this.planning.days.length-2]
					end = this.planning.days[this.planning.days.length-1]
				}

				this.dates_to_show = [start, end]
			},
			
			async goNext() {
				let current = this.dates_to_show[0]
				let current_in_tab = this.planning.days.indexOf(current)

				if(current_in_tab == this.planning.days.length - 2) {
					let start = new Date(current)
					start.setDate(start.getDate() + 1)
					let end = new Date(current)
					end.setDate(end.getDate() + 6)
					this.planning = {
						days: {},
						horses: {}
					}
					await this.loadPlanning(start.toDateInputValue(), end.toDateInputValue())
				}

				current_in_tab = this.planning.days.indexOf(current)

				let start = this.planning.days[current_in_tab+1]
				let end = this.planning.days[current_in_tab+2]

				this.dates_to_show = [start, end]
			},

			async goToday(){
				let now = new Date()
				const tomorrow = new Date()
				tomorrow.setDate(now.getDate() + 1)
				const hasToday = this.planning.days.includes(now.toDateInputValue())
				const hasTomorrow = this.planning.days.includes(tomorrow.toDateInputValue())
				if(hasToday && hasTomorrow){
					this.dates_to_show = [now.toDateInputValue(), tomorrow.toDateInputValue()]
				} else {
					this.goToLoadPlanning()
				}
			},

			add(horse, day) {
                this.setupAddTaskPlanning(horse, day, this.planning_selected, async (next) => {
                	this.shutterPanel().close('task-planning-add')

                	if(next) {
                		this.next(horse, day)
	                }
	                else {
	                	this.goToLoadPlanning()
	                }
                })
			},

			edit(task, horse, day) {
				this.setupEditTaskPlanning(task, horse, this.planning_selected, async (next) => {
                	this.shutterPanel().close('task-planning-add')

                	if(next) {
                		this.next(horse, day)
                	}
	                else {
	                	this.goToLoadPlanning()
	                }
                })
			},

			next(horse, day) {
				let horse_keys = []
				if(Array.isArray(this.planning.horses)) {
					horse_keys = this.planning.horses.map(item => item.horse ? item.horse.horse_id : undefined)
				} else {
					horse_keys = Object.keys(this.planning.horses)
				}

				// Fonctionnement différent selon si on a des regroupements(groupby > 1) ou si sans regroupement(groupby == 1)
            	let horse_idx = null
				if (this.planning_selected.taskplanning_groupby == 1) {
					horse_idx = horse_keys.indexOf('horse_'+horse.horse_id)
				} else {
					horse_idx = horse_keys.indexOf(horse.horse_id)
				}
				
            	// si je suis arrivé au dernier je ne fais rien
            	if(horse_idx == horse_keys.length - 1) {
					this.goToLoadPlanning()
            		return true
            	}
				let horse_next_index = horse_idx + 1
				
				// Si je n'ai pas de valeur c'est que je suis tombé sur un label de groupe, donc je le skip
				if(horse_keys[horse_next_index] === undefined) {
					horse_next_index++
				}

				// Fonctionnement différent selon si on a des regroupements(groupby > 1) ou si sans regroupement(groupby == 1)
				let horses_objects = null
				if (this.planning_selected.taskplanning_groupby == 1) {
					horses_objects = this.planning.horses[horse_keys[horse_next_index]]
				} else {
					horses_objects = this.planning.horses[horse_next_index]
					// Avec regroupement, possibilité de rencontrer 1 strings
					if (typeof horses_objects === "string") {
						horse_next_index++
						horses_objects = this.planning.horses[horse_next_index]
					}
				}

            	if(horses_objects.task[day].length > 0) {
					this.edit(horses_objects.task[day][0], horses_objects.horse, day)
            	}
            	else {
					this.add(horses_objects.horse, day)
            	}
			},

			checkIfTranslationExists(string) {
				return !(string === this.$t(string))
			}
		},

		components: {
			LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
		}
	}

</script>