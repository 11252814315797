<template>
	<router-view
		:tiers_id="tiers_id"
	></router-view>
</template>

<script>
export default {
	name: 'Tiers',
	props: ['tiers_id']
}
</script>