<template>
	<div>
        <CustomTable
            id_table="semence_stallion_plan_cuve"
            ref="table"
            :items="plan_cuve"
            :busy.sync="table_busy"
            primaryKey="localisation_id"
            :hide_if_empty="true"
            :externSlotColumns="['sum_qte']"
       	>
            <template v-slot:[`custom-slot-cell(sum_qte)`]="{ data }">
                <a href="" class="" @click.prevent="openPlanCuveDetail(data)">
                    {{ data.sum_qte }} <font-awesome-icon :icon="['fas', 'eye']" />
                </a>
            </template>
   		</CustomTable>
	</div>
</template>

<script type="text/javascript">
	import Congelation from "@/mixins/Congelation.js"
	import ShutterCongelation from "@/mixins/shutters-manager/Congelation.js"

	export default {
		name: 'StallionPlanCuveListe',
		mixins: [Congelation, ShutterCongelation],
		props: ['horse_id'],
		data () {
			return {
				plan_cuve: [],
				table_busy: false
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				this.table_busy = true
				this.plan_cuve = await this.getStallionPlanCuve(this.horse_id)
				this.table_busy = false
			},

			openPlanCuveDetail(localisation) {
				this.setUpPlanCuveDetail(this.horse_id, localisation)
			}
		},

		components: {
			CustomTable : () => import('GroomyRoot/components/Table/CustomTable')
		}
	}

</script>