<template>
	<div>
		<h2>{{ $t("monte.stock_semence.add_stock_stallion") }} {{ horse.horse_nom }}</h2>
		<div class="row">
			<div class="col-6">
                <div class="form-group">
                    <label for="lot" class="col-form-label">{{ $t("monte.stock_semence.numero_lot") }}</label>
                    <input type="text" id="lot" class="form-control" v-model="lot" required>
                </div>
            </div>

			<div class="col-6">
                <div class="form-group">
                    <label for="qte" class="col-form-label">{{ $t("monte.stock_semence.qte") }}</label>
                    <input type="number" id="qte" class="form-control" v-model="qte" required>
                </div>
            </div>

            <div class="col-6">
                <div class="form-group">
                    <label for="couleur" class="col-form-label">{{ $t("monte.stock_semence.couleur") }}</label>
                    <e-select
	                    v-model="couleur"
	                    id="couleur"
	                    track-by="semencecouleur_code"
	                    :placeholder="$t('monte.stock_semence.selectionnez_couleur')"
	                    :selectedLabel="$t('global.selected_label')"
	                    :options="all_couleurs"
	                    :searchable="true"
	                    :allow-empty="false"
	                    :show-labels="false"
						:loading="loadingCouleur"
	                >
						<template slot="option" slot-scope="{ option }">{{ $t('monte.couleur.'+option.semencecouleur_code) }}</template>
		                <template slot="singleLabel" slot-scope="{ option }">{{ $t('monte.couleur.'+option.semencecouleur_code) }}</template>
		                <template slot="noOptions">{{ $t('global.list_empty') }}</template>
					</e-select>
                </div>
            </div>

			<div class="col-6">
                <div class="form-group">
                   <label for="localisation" class="col-form-label">{{ $t("monte.stock_semence.localisation") }}</label>
                   <e-select
						v-model="localisation"
						id="localisation"
						track-by="full_name"
						label="full_name"
						:placeholder="$t('monte.stock_semence.rechercher_categorie')"
						:selectedLabel="$t('global.selected_label')"
						:options="all_localisations"
						:searchable="true"
						:show-labels="false"
						:allow-empty="true"
						:sortable="false"
						:loading="loadingLoc"
					>
						<template slot="option" slot-scope="props"><span :style="`padding-left: ${25 * (props.option.indent || 0) }px;`">{{ props.option.full_name }}</span></template>
					</e-select>
                </div>
            </div>

            <div class="col-12">
                <div class="form-group">
                    <label for="tiers" class="col-form-label">{{ $t("monte.stock_semence.tiers") }}</label>
	                   <e-select
							v-model="tiers_selected"
                            id="tiers"
                            track-by="tiers_rs"
                            label="tiers_rs"
                            :placeholder="$t('tiers.search_tiers')"
                            :selectedLabel="$t('global.selected_label')"
                            :options="all_tiers"
                            :searchable="true"
                            :show-labels="false"
							:loading="loadingTiers"
                        />
                </div>
            </div>

			<div class="col-12 mb-4">
				<label for="commentaire" class="col-form-label">{{ $t("monte.stock_semence.commentaire") }}</label>
				<textarea id="commentaire" class="form-control" :placeholder="$t('formulaire.votre_commentaire')" v-model="commentaire"></textarea>
			</div>

            <div class="col-12 text-center">
            	<button @click="submitForm" class="btn btn-primary rounded-pill">
            		<template v-if="stock && stock.uid">
            			<font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse />
                    	<font-awesome-icon v-else :icon="['far', 'pencil-alt']" /> {{ $t('global.modifier') }}
            		</template>
            		<template v-else>
            			<font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse />
                    	<font-awesome-icon v-else :icon="['far', 'plus']" /> {{ $t('global.ajouter') }}
            		</template>
                </button>
            </div>
		</div>
	</div>
</template>

<script type="text/javascript">
import Shutter from '@/mixins/Shutter'
import ShutterSemence from '@/mixins/shutters-manager/Semence'
import StockSemence from '@/mixins/StockSemence'
import Tiers from '@/mixins/Tiers'

	export default {
		name: 'AjoutStockStallion',
		mixins: [Shutter, ShutterSemence, StockSemence, Tiers],
		props: ['horse', 'tiers', 'fiche', 'stock'],
		data () {
			return {
				all_couleurs: [],
				all_localisations: [],
				all_tiers: [],
				lot: '',
				qte: 0,
				couleur: null,
				localisation: null,
				tiers_selected: null,
				commentaire: "",
				loadingCouleur: false,
				loadingLoc: false,
				loadingTiers: false,
				processing: false
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				this.loadingCouleur = true
				this.all_couleurs = await this.getAllSemenceCouleur()
				this.loadingCouleur = false

				this.loadingLoc = true
				const all_localisations = await this.getLocalisationSemenceFullName()
				this.all_localisations = all_localisations.filter(loc => !loc.semencelocalisation_transport)
				this.loadingLoc = false

				this.loadingTiers = true
				this.all_tiers = await this.loadTiersEntity()
				this.loadingTiers = false

				if(this.stock) {
					this.lot = this.stock.lot
					this.qte = this.stock.stock
					this.couleur = this.all_couleurs.find(couleur => couleur.semencecouleur_label == this.stock.couleur)
					this.localisation = this.all_localisations.find(loc => loc.semencelocalisation_id == this.stock.localisation_id)
					this.tiers_selected = this.stock.to
					this.commentaire = this.stock.commentaire
				}
				else {
					if(this.tiers) {
						this.tiers_selected = this.all_tiers.find(t => t.tiers_id == this.tiers.tiers_id)
					}
					else if(this.all_tiers.length == 1) {
						this.tiers_selected = this.all_tiers[0]
					}
				}
			},

			async submitForm() {
				this.processing = true

				if(this.stock && this.stock.uid) {
					await this.editStock(this.stock.uid, {
						lot: this.lot,
						qte: this.qte,
						couleur: this.couleur.semencecouleur_code,
						localisation: this.localisation ? this.localisation.semencelocalisation_id : null,
						to: this.tiers_selected.tiers_id,
						commentaire: this.commentaire ? this.commentaire : ''
					})
				}
				else {
					await this.addNewStockStallion(this.horse.horse_id, {
						lot: this.lot,
						qte: this.qte,
						couleur: this.couleur.semencecouleur_code,
						localisation: this.localisation ? this.localisation.semencelocalisation_id : null,
						to: this.tiers_selected.tiers_id,
						commentaire: this.commentaire
					})
				}

				this.processing = false

				this.ok({horse: this.horse, fiche: this.fiche})
				this.shutterPanel().close('ajout-stock-stallion')
			}
		}
	}

</script>