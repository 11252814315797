<template>
	<div>
		<div v-if="loading" class="row">
			<LoadingSpinner class="col-12" />
		</div>
		<div v-else class="row">
			<div class="col-12 form-group">
				<label for="cuve_transport">{{ $t("monte.stock_semence.cuve_transport") }} *</label>
				<e-select
					id="cuve_transport"
					v-model="localisation_selected"
					track-by="semencelocalisation_id"
					label="full_name"
					:placeholder="$t('monte.stock_semence.rechercher_categorie')"
					:selectedLabel="$t('global.selected_label')"
					:options="all_localisations"
					:searchable="true"
					:show-labels="false"
					:allow-empty="true"
					:sortable="false"
				>
					<template slot="option" slot-scope="props"><span :style="`padding-left: ${25 * (props.option.indent || 0) }px;`">{{ props.option.full_name }}</span></template>
				</e-select>
			</div>

			<div class="col-12">
				<div v-for="(stallion, index) in emplacements" class="row" :key="stallion.uuid">
					<div class="col-12">
						<h3 class="d-inline">{{ stallion.horse_nom }}</h3> - <h5 class="d-inline">{{ stallion.demandeur_rs }}</h5> - {{ stallion.qte_total }} {{ $t('monte.paillettes') }}<br>
						{{ stallion.norme }}
					</div>

					<div class="col-12 mt-2">
						<table class="table table-hover table-sm mb-0">
							<thead>
								<tr>
									<th>{{ $t('monte.congelation.qte_sortir') }}</th>
									<th>{{ $t('monte.congelation.qte_dispo') }}</th>
									<th>{{ $t('monte.congelation.semencelot_quantieme') }}</th>
									<th>{{ $t('monte.stock_semence.localisation') }}</th>
									<th>{{ $t('monte.congelation.norme') }}</th>
								</tr>
							</thead>
							<tbody v-if="stallion.emplacements.length > 0 && force_reload">
								<tr v-for="emplacement in stallion.emplacements.filter(emp => emp.show)" :key="emplacement.emplacementcongelation_id">
									<td>
										<b-input type="number" class="form-control" v-model="emplacement.qte_expedition"/>
									</td>
									<td>{{ emplacement.emplacementcongelation_qte }}</td>
									<td>{{ emplacement.lot_quantieme }}</td>
									<td>{{ emplacement.localisation_label }}</td>
									<td>{{ emplacement.normes_label }}</td>
								</tr>
								<tr v-if="stallion.emplacements.filter(emp => !emp.show).length > 0">
									<td colspan="5" class="text-center">
										<a href="#" @click.prevent="showMore(index)"><font-awesome-icon :icon="['fal', 'angle-down']"/> {{ $t('monte.congelation.voir_plus') }} <font-awesome-icon :icon="['fal', 'angle-down']"/></a>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>

			<div class="col-12 text-center">
				<a class="btn btn-primary mt-3" href="#" @click.prevent="checkForm">{{ $t('global.enregistrer') }} <font-awesome-icon v-if="processing" :icon="['fal', 'spinner']" pulse /><font-awesome-icon v-else :icon="['fal', 'save']" /></a>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import Congelation from "@/mixins/Congelation.js"
	import StockSemence from "@/mixins/StockSemence.js"
	import ShutterCongelation from "@/mixins/shutters-manager/Congelation.js"
	import Shutter from '@/mixins/Shutter.js'

	export default {
		name: 'PreparationExpedition',
		mixins: [Congelation, StockSemence, Shutter, ShutterCongelation],
		props: ['expeditions'],
		data () {
			return {
				loading: false,
				all_localisations: [],
				localisation_selected: null,
				processing: false,
				emplacements: [],
				force_reload: true
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				this.loading = true
				const all_localisations = await this.getLocalisationSemenceFullName()
				// je récupère uniquement les cuves de transports qui ne sont pas parties et qui n'ont pas de tiers ou le même tiers que mon expédition
				this.all_localisations = all_localisations.filter(loc => loc.semencelocalisation_transport && !loc.semencelocalisation_shipped && (!loc.semencelocalisation_destinataire || loc.semencelocalisation_destinataire == this.expeditions[0].semenceexpedition_destinataire))
				this.localisation_selected = this.all_localisations.find(loc => loc.semencelocalisation_destinataire == this.expeditions[0].semenceexpedition_destinataire)

				this.emplacements = await this.getLotsSuggestion(this.expeditions.map(exp => exp.semenceexpedition_id).join(','))
				this.loading = false
			},

			async checkForm() {
				if(!this.localisation_selected || this.processing) {
					return false
				}

				this.processing = true

				const expeditions = this.emplacements.map(stallion => {
					return stallion.emplacements.filter(empl => empl.qte_expedition > 0).map(empl => ({
						qte_expedition: empl.qte_expedition,
						emplacement_id: empl.emplacementcongelation_id,
					}))
				})

				const floors_ids = await this.pretExpedition(this.expeditions.map(exp => exp.semenceexpedition_id).join(','), expeditions, this.localisation_selected.semencelocalisation_id)

				this.processing = false
				this.successToast('toast.info_save_succes')
				this.ok()
				if(floors_ids.length > 0) {
					this.setUpDeleteFloors(floors_ids)
				}
				this.shutterPanel().close('expedition-preparation')
			},

			reload() {
				this.ok()
			},

			showMore(index) {
				this.emplacements[index].emplacements.forEach(empl => {
					empl.show = true
				})

				this.force_reload = false
				this.$nextTick(()=> {
					this.force_reload = true
				})
			}
		},

		components: {
			LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
		}
	}

</script>