<template>
	<div>
		<LoadingSpinner v-if="loading" />
		<div v-else class="table-responsive m-0 mt-4" style="height: 70vh;">
			<table class="table b-table table-striped table-hover table-borderless">
				<thead class="sticky">
					<tr>
						<td v-for="(day, key) in calendar" :class="{'border text-center': true, 'weekend': day.weekend}" :key="key">{{ day.label }}</td>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(days, test) in tests" :key="test">
						<td v-for="(day, index) in days" :key="index" :class="{'weekend': day.weekend}">
							<span v-if="day.acte_id" @click="openActeEdit(day.acte_id)" role="button" class="p-2" :style="'background-color:'+day.color">{{ day.label }}</span>
							<span v-else class="p-2">{{ day.label }}</span>
						</td>
					</tr>
					<tr>
						<td v-for="(day, index) in lots" :key="index" :class="{'weekend': day.weekend}">
							<template v-if="day.count">{{ day.count }}</template>
							<div v-else class="d-flex">
								<div v-for="lot in day.lots" :key="lot.semencelot_id" class="ml-1">
									<font-awesome-icon v-if="lot.statut == 'apte'" style="color:green" :icon="['far', 'check']" role="button" @click="setUpEditLot(lot)" />
									<font-awesome-icon v-else-if="lot.statut == 'inapte'" style="color:red" :icon="['fal', 'times']" role="button" @click="setUpEditLot(lot)" />
									<font-awesome-icon v-else :icon="['fal', 'question']" role="button" @click="setUpEditLot(lot)" />
								</div>
							</div>
						</td>
					</tr>
					<tr v-for="protocole in campagne.protocoles" :key="protocole.campagneprotocole_id">
						<td v-if="protocole.start > 0" :colspan="protocole.start"></td>
						<td :colspan="protocole.colspan" class="text-center" :style="'background-color:'+protocole.protocole.protocole_color+'; color:'+protocole.protocole.protocole_color_police">
							<b>{{ protocole.protocole.protocole_code }}</b>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>

<script type="text/javascript">
	import Vue from 'vue'
	import Congelation from "@/mixins/Congelation.js"
	import Navigation from '@/mixins/Navigation.js'
	import Shutter from '@/mixins/Shutter.js'
	import ShutterActs from "@/mixins/shutters-manager/Acts.js"
	import ShutterCongelation from "@/mixins/shutters-manager/Congelation.js"

	export default {
		name: 'CampagnePreview',
		mixins: [Congelation, Navigation, Shutter, ShutterActs, ShutterCongelation],
		props: ['campagne'],
		data () {
			return {
				loading: false,
				calendar: [],
				tests: [],
				lots: [],
				events_tab: {
                    'SanitaireHorse::refresh': this.onShuttersSubmit
                },
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				this.loading = true
				const data = await this.getCampagne(this.campagne.campagne_horse, this.campagne.campagne_id)

				let end = null
				if(this.campagne.campagne_end) {
					end = new Date(this.campagne.campagne_end)
				}
				else {
					// date de fin correspond au dernier lot + 30j ou date de début de campagne + 30j
					end = data.last_lot ? new Date(data.last_lot) : new Date(this.campagne.campagne_start)
					end.setDate(end.getDate() + 30)
				}

				this.generateDatesArray(this.campagne.campagne_start, end)

				const lots = []
				let last_lot = 0
				// pour chaque jour j'ajoute les lots si existants
				this.calendar.forEach((date, index) => {
					let lots_date = data.lots.filter(lot => lot.semencelot_date == date.date)
					if(lots_date.length > 0) {
						lots.push({
							lots: lots_date,
							weekend: date.weekend
						})
						last_lot = index
					}
					else if(data.last_lot && new Date(data.last_lot).getTime() < new Date(date.date).getTime()) {
						lots.push({
							count: parseInt((new Date(date.date).getTime() - new Date(data.last_lot).getTime()) / (1000 * 3600 * 24)),
							weekend: date.weekend
						})
					}
					else {
						lots.push({
							weekend: date.weekend
						})
					}
				})
				this.lots = lots

				let tests = {
		            "TEST_NEW2_MTRITE_CONTAGIEUSE_QUINE_MEC_POUR_TALON": [],
		            "TEST_NEW2_ARTRITE_VIRALE_QUINE_EVA_POUR_TALON": [],
		            "TEST_NEW2_ANMIE_INFCTIEUSE_DES_EQUIDS_AIE": [],
		            "TEST_DOURINE": [],
            		"TEST_MORVE": []
				}

				// pour chaque test et chaque jour j'ajoute soit le test, soit le nb de jours soit rien
				for(let i in tests) {
					let last = 0
					this.calendar.forEach(date => {
						let actes_of_type = data.tests.find(acte => acte.actes_type.actestype_code == i && acte.actes_date == date.date)
						if(actes_of_type) {
							tests[i].push({
								label: this.getTrad('monte.congelation.tests.'+i),
								acte_id: actes_of_type.actes_id,
								color: actes_of_type.color,
								weekend: date.weekend
							})
							last = 1
						}
						else if(!actes_of_type && last > 0) {
							tests[i].push({
								label: last,
								acte_id: null,
								color: null,
								weekend: date.weekend
							})
							last++
						}
						else {
							tests[i].push({
								label: '',
								acte_id: null,
								color: null,
								weekend: date.weekend
							})
						}
					})
				}
				this.tests = tests

				// je calcule la taille de chaque ligne de protocole dans le tableau
				this.campagne.protocoles.forEach(protocole => {
					protocole.start = parseInt((new Date(protocole.campagneprotocole_start) - new Date(this.campagne.campagne_start)) / (1000 * 60 * 60 * 24), 10)
					if(protocole.campagneprotocole_end) {
						const delay = parseInt((new Date(this.campagne.campagne_end) - new Date(this.campagne.campagne_start)) / (1000 * 60 * 60 * 24), 10)
						const end = parseInt((new Date(this.campagne.campagne_end) - new Date(protocole.campagneprotocole_end)) / (1000 * 60 * 60 * 24), 10)
						protocole.colspan = delay - protocole.start - end + 1
					}
					else {
						protocole.colspan = last_lot - protocole.start + 1
					}
				})

				this.loading = false
			},

			reload() {
				this.shutterPanel().goTo('campagne-preview')
				this.shutterPanel().close('controls-lot')
				this.shutterPanel().close('add-control-lot')
				this.shutterPanel().close('edit-lot')
				this.shutterPanel().close('act-edit')
				this.init_component()
			},

			generateDatesArray(start, end) {
				let arr = []
				for(let dt = new Date(start); dt <= new Date(end); dt.setDate(dt.getDate()+1)){
					const date = new Date(dt)
			        arr.push({
			        	date: this.formatDate(date),
			        	label: date.toLocaleDateString(Vue.i18n.locale(), {day: "numeric", month: "numeric"}).padStart(2, "0"),
			        	weekend: date.getDay() == 6 || date.getDay() == 0
			        });
			    }
			    this.calendar = arr
			},

			formatDate(date) {
				var d = new Date(date),
				month = '' + (d.getMonth() + 1),
				day = '' + d.getDate(),
				year = d.getFullYear();

				if (month.length < 2) 
					month = '0' + month;
				if (day.length < 2) 
					day = '0' + day;

				return [year, month, day].join('-')+' 00:00:00';
			},

			openActeEdit(acte_id) {
				this.setupActEdit(acte_id.toString(), false)
			},

			onShuttersSubmit() {
				this.init_component()
			}
		},

		watch: {
			'campagne.campagne_id'() {
				this.init_component()
			}
		},

		components: {
            LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35')
		}
	}

</script>