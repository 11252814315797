<template>
	<div id="content">
		<shutter-panel ref="shutterPanel" />
	</div>
</template>

<script type="text/javascript">
	import ShutterCongelation from "@/mixins/shutters-manager/Congelation.js"

	export default {
		name: 'Commande',
		mixins: [ShutterCongelation],
		props: ['horse_id'],
		mounted() {
			this.setUpCommandeList(this.horse_id)
		},
	}
</script>