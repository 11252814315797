<template>
	<div class="box">
        <CustomTable
            id_table="semence_not_inventoried"
            ref="table"
            :items="lots"
            :busy.sync="table_busy"
            primaryKey="semencelot_id"
            :hide_if_empty="true"
            :hrefsRoutes="config_table_hrefs"
        />

        <b-modal ref="modal-stock" hide-footer size="xl">
			<template v-if="!prestock" v-slot:modal-title>
				{{ $t('monte.congelation.stockage_paillettes') }}
			</template>
			<template v-else v-slot:modal-title>
				{{ $t('monte.congelation.prestockage_paillettes') }}
			</template>

			<table v-if="!hide_lot" class="table table-hover table-sm mb-0">
				<tr>
					<td>{{ $t('monte.stock_semence.numero_lot') }}</td>
					<td>{{ $t('monte.congelation.nb_paillettes') }}</td>
					<td v-if="!prestock">{{ $t('monte.congelation.cuve_stockage') }}</td>
					<td v-else>{{ $t('monte.congelation.cuve_prestockage') }}</td>
				</tr>
				<template v-for="stallion in lots_selected">
					<tr :key="stallion.horse.horse_id">
						<td colspan="3"><b>{{ stallion.horse.horse_nom }}</b></td>
					</tr>
					<tr v-for="lot in stallion.lots" :key="lot.semencelot_id">
						<td>{{ lot.semencelot_quantieme }}</td>
						<td>{{ lot.semencelot_qte }}</td>
						<td v-if="!prestock">
							<e-select
								v-model="lot.stockage"
								id="stockage"
								track-by="full_name"
								label="full_name"
								:placeholder="$t('monte.stock_semence.rechercher_categorie')"
								:selectedLabel="$t('global.selected_label')"
								:options="all_localisations_stockage"
								:searchable="true"
								:show-labels="false"
								:allow-empty="true"
								:sortable="false"
								@input="reloadLot"
							>
								<template slot="option" slot-scope="props"><span :style="`padding-left: ${25 * (props.option.indent || 0) }px;`">{{ props.option.full_name }}</span></template>
							</e-select>
						</td>
						<td v-else>
							<e-select
								v-model="lot.prestockage"
								id="prestockage"
								track-by="full_name"
								label="full_name"
								:placeholder="$t('monte.stock_semence.rechercher_categorie')"
								:selectedLabel="$t('global.selected_label')"
								:options="all_localisations_prestockage"
								:searchable="true"
								:show-labels="false"
								:allow-empty="true"
								:sortable="false"
								@input="reloadLot"
							>
								<template slot="option" slot-scope="props"><span :style="`padding-left: ${25 * (props.option.indent || 0) }px;`">{{ props.option.full_name }}</span></template>
							</e-select>
						</td>
					</tr>
				</template>
			</table>

			<div class="text-center">
				<a class="btn btn-primary mt-3" href="#" @click.prevent="checkForm">{{ $t('global.enregistrer') }} <font-awesome-icon v-if="processing" :icon="['fal', 'spinner']" pulse /><font-awesome-icon v-else :icon="['fal', 'save']" /></a>
			</div>
		</b-modal>
	</div>
</template>

<script type="text/javascript">
	import Congelation from "@/mixins/Congelation.js"
	import StockSemence from "@/mixins/StockSemence.js"
	import Navigation from "@/mixins/Navigation.js"
	import _groupBy from 'lodash/groupBy'
	import { EventBus } from 'EventBus'

	export default {
		name: 'NotInventoried',
		mixins: [Congelation, StockSemence, Navigation],
		data () {
			return {
				table_busy: false,
				lots: [],
				config_table_hrefs: {
					'horse.horse_nom': {
						routeUniqueName: 'StallionFiche',
						routeName: 'horseFiche',
						params: {
							horse_id: 'horse.horse_id'
						}
					}
				},
				events_tab: {
					'TableAction::goToStockSemenceLot': (lots) => {
						this.openStockModal(lots)
					},
					'TableAction::goToPrestockSemenceLot': (lots) => {
						this.openStockModal(lots, true)
					},
					'TableAction::goToDestroyLots': this.confirmDestroyLot
				},
				lots_selected: [],
				prestock: false,
				processing: false,
				all_localisations_stockage: [],
				all_localisations_prestockage: [],
				hide_lot: false
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				this.table_busy = true
				this.lots = await this.getNotInventoried()
				this.table_busy = false

				const all_localisations = await this.getLocalisationSemenceFullName()
				this.all_localisations_stockage = all_localisations.filter(loc => !loc.semencelocalisation_prestockage && !loc.semencelocalisation_transport && loc.semencelocalisation_is_floor)
				this.all_localisations_prestockage = all_localisations.filter(loc => loc.semencelocalisation_prestockage && !loc.semencelocalisation_transport && loc.semencelocalisation_is_floor)
			},

			openStockModal(lots, prestock = false) {
				const lots_group = _groupBy(lots, 'horse.horse_id')
				const lots_selected = []
				for(let i in lots_group) {
					lots_group[i].forEach(lot => {
						lot.stockage = this.all_localisations_stockage.find(loc => loc.semencelocalisation_id == lot.stockage_id)
						lot.prestockage = this.all_localisations_prestockage.find(loc => loc.semencelocalisation_id == lot.prestockage_id)
					})
					lots_selected.push({
						horse: lots_group[i][0].horse,
						lots: lots_group[i]
					})
				}
				this.lots_selected = lots_selected
				this.prestock = prestock
				this.$refs['modal-stock'].show()
			},

			reloadLot() {
				this.hide_lot = true
				this.$nextTick(()=> {
					this.hide_lot = false
				})
			},

			async checkForm() {
				let lots = []
				this.lots_selected.forEach(lot_stallion => {
					const ll = lot_stallion.lots.map(lot => {
						return {
							semencelot_id: lot.semencelot_id,
							semencelot_date: new Date(),
							semencelot_qte: lot.semencelot_qte,
							semencelot_commentaire: lot.semencelot_commentaire,
							stockage: lot.stockage ? lot.stockage.semencelocalisation_id : null,
							prestockage: lot.prestockage ? lot.prestockage.semencelocalisation_id : null
						}
					})
					lots = lots.concat(ll)
				})

				this.processing = true
				await this.stockLots(lots, this.prestock)
				this.processing = false
				this.successToast('toast.info_save_succes')
				this.$refs['modal-stock'].hide()
				this.$refs.table.unselectAll()
				this.init_component()
			},

			async confirmDestroyLot(lots) {
				const lots_ids = lots.map(lot => lot.semencelot_id)
				await this.destroyLotsNotInventoried(lots_ids)
				EventBus.$emit('TableAction::stopSpin')
				this.successToast('toast.info_save_succes')
				this.$refs.table.unselectAll()
				this.init_component()
			}
		},

		components: {
			CustomTable : () => import('GroomyRoot/components/Table/CustomTable'),
		}
	}

</script>