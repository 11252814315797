<template>
	<div class="row">
		<div class="col-12">
			<div class="box">
				<h2>{{ $t('monte.congelation.campagnes') }}</h2>
				<LoadingSpinner v-if="loading" />
				<div class="row">
					<div v-for="campagne in campagnes" :key="campagne.campagne.campagne_id" class="col-12">
						<h3>{{ $t('monte.congelation.campagne_start') }} : {{ formatDate(campagne.campagne.campagne_start) }} - {{ $t('monte.congelation.campagne_end') }} : {{ formatDate(campagne.campagne.campagne_end) }}</h3>
						<h3>{{ $t('monte.congelation.campagne_entree') }} : {{ formatDate(campagne.campagne.campagne_entree) }} - {{ $t('monte.congelation.campagne_centre') }} : {{ campagne.campagne.centre.tiers_rs }} ({{ campagne.campagne.centre.numero }})</h3>
						<div v-for="(month, index) in campagne.months" :key="index">
							<h5>{{ formatMonth(index) }}</h5>
							<table class="table table-hover table-sm mb-0">
								<thead>
									<tr>
										<th>{{ $t('monte.congelation.date_recolte') }}</th>
										<th>{{ $t('monte.congelation.semencelot_quantieme') }}</th>
										<th>{{ $t('monte.congelation.qte') }}</th>
										<th>{{ $t('monte.congelation.controle_qualite') }}</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="lot in month" :key="lot.semencelot_id">
										<th>{{ formatDate(lot.semencelot_date) }}</th>
										<th>{{ lot.semencelot_quantieme }}</th>
										<th>{{ lot.semencelot_qte }}</th>
										<th>{{ $t('monte.congelation.statuts.'+lot.last_control) }}</th>
									</tr>
								</tbody>
							</table>
						</div>
						<hr>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import Congelation from "@/mixins/Congelation.js"

	export default {
		name: 'StallionDashboard',
		mixins: [Congelation],
		props: ['horse_id'],
		data () {
			return {
				loading: false,
				campagnes: []
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				this.loading = true
				this.campagnes = await this.getLotsCampagneByStallion(this.horse_id)
				this.loading = false
			},

			formatMonth(month) {
				const split = month.split('_')
				return this.getTrad("mois."+split[0]) +" "+ split[1]
			}
		},

		components: {
			LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35')
		}
	}

</script>