<template>
	<div>
		<div class="row">
			<div class="col-3 form-group">
				<b-form-checkbox v-model="params.with_stock" id="with_stock"><b>{{ $t("monte.congelation.with_stock") }}</b></b-form-checkbox>
			</div>
			<div class="col-3 form-group">
				<b-form-checkbox v-model="params.empty_stock" id="empty_stock"><b>{{ $t("monte.congelation.empty_stock") }}</b></b-form-checkbox>
			</div>
			<div class="col-3 form-group">
				<b-form-checkbox v-model="params.lot_prestockage" id="lot_prestockage"><b>{{ $t("monte.congelation.lot_prestockage") }}</b></b-form-checkbox>
			</div>
			<div class="col-3 form-group">
				<b-form-checkbox v-model="params.non_inventories" id="non_inventories"><b>{{ $t("monte.congelation.non_inventories") }}</b></b-form-checkbox>
			</div>
			<div class="col-5 form-group">
				<e-select
					id="protocole_id"
					track-by="protocole_id"
					label="protocole_label"
					:placeholder="$t('monte.congelation.selectionner_protocole')"
					:selectedLabel="$t('global.selected_label')"
					:options="protocoles"
					:searchable="true"
					:allow-empty="true"
					:show-labels="false"
					:group-select="false"
					:multiple="true"
					v-model="protocoles_selected"
					:loading="loading"
				>
					<template slot="noOptions">{{ $t('global.list_empty') }}</template>
				</e-select>
			</div>
			<div class="col-5 form-group">
				<e-select
					id="tiers"
					track-by="tiers_id"
					label="tiers_rs"
					:placeholder="$t('monte.congelation.selectionner_tiers')"
					:options="tiers"
					:searchable="true"
					:show-labels="false"
					v-model="tiers_selected"
					:loading="loading"
				>
					<template slot="option" slot-scope="{ option }">{{ option.tiers_rs }}</template>
					<template slot="singleLabel" slot-scope="{ option }">{{ option.tiers_rs }}</template>
					<template slot="noOptions">{{ $t('global.list_empty') }}</template>
				</e-select>
			</div>
			<div class="col-2">
				<b-button variant="primary" block @click="load_stocks">{{ $t('global.rechercher') }}</b-button>
			</div>
		</div>
		<CustomTable
            id_table="semence_stock_stallion"
            ref="table"
            :items="lots"
            :busy.sync="table_busy"
            primaryKey="semencelot_id"
            :hide_if_empty="true"
            :externSlotColumns="['control_statut', 'semencelot_quantieme', 'proprietaires', 'normes']"
        >
			<template v-slot:[`custom-slot-cell(control_statut)`]="{ data }">
				<a href="#" v-if="data.control_statut" @click.prevent="setUpControlsLot(data)">{{ $t('monte.congelation.statuts.'+data.control_statut) }}</a>
				<a href="#" v-else @click.prevent="setUpAddControlLot(data)">{{ $t('global.ajouter') }}</a>
			</template>
			<template v-slot:[`custom-slot-cell(semencelot_quantieme)`]="{ data }">
				<a href="#" @click.prevent="setUpEmplacementLot(data)">{{ data.semencelot_quantieme }}</a>
			</template>
			<template v-slot:[`custom-slot-cell(proprietaires)`]="{ data }">
				<router-link v-for="tiers in data.proprietaires" :key="tiers.id" :to="{ name: 'tiersFiche', params: { tiers_id: tiers.id }}">
                    {{ tiers.tiers_rs }} ({{ tiers.qte }})<br>
                </router-link>
			</template>
			<template v-slot:[`custom-slot-cell(normes)`]="{ data }">
				<div v-if="data.qte_verrou > 0">{{ data.qte_verrou }} {{ $t('monte.paillettes') }} {{ $t('monte.congelation.paillette_verrou') }}</div>
				<div v-if="data.qte_normes > 0">{{ data.qte_normes }} {{ $t('monte.paillettes') }} ({{ data.normes_label }})</div>
			</template>
	    </CustomTable>

		<b-modal ref="modal-destroy-lots" hide-footer>
			<template v-slot:modal-title>
				{{ $t('monte.congelation.confirm_destroy_lots') }}
			</template>

			 <template slot="default">
			 	<div class="row">
					<div class="col-12 form-group">
						<label for="commentaire">{{ $t("monte.congelation.commentaire") }} *</label>
						<input class="form-control" type="text" v-model="commentaire" id="commentaire">
					</div>
					<div class="col-12 text-center">
			            <b-button variant="primary" @click="confirmDestroyLot">
			                <font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse class="mr-1"/>
			                <font-awesome-icon v-else :icon="['fal', 'trash-alt']" class="mr-1"/>{{ $t('action.detruire') }}
			            </b-button>
					</div>
			 	</div>
	        </template>
		</b-modal>

		<b-modal ref="modal-assign-lots" hide-footer>
			<template v-slot:modal-title>
				{{ $t('monte.congelation.assign_lots') }}
			</template>

			 <template slot="default">
			 	<div class="row">
					<div class="col-12 form-group">
						<label for="tiers">{{ $t("tiers.tiers") }} *</label>
						<SearchTiers 
							id="tiers"
							:tiers_selected.sync="tiers_assigned"
						/>
					</div>
					<div class="col-12 form-group">
						<label for="date">{{ $t("monte.date") }} *</label>
						<e-datepicker id="date" v-model="date" mode="date"></e-datepicker>
					</div>
					<div class="col-12 text-center">
			            <b-button variant="primary" @click="confirmAssignLots">
			                <font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse class="mr-1"/>
			                <font-awesome-icon v-else :icon="['far', 'user']" class="mr-1"/>{{ $t('action.attribuer') }}
			            </b-button>
					</div>
			 	</div>
	        </template>
		</b-modal>

        <b-modal ref="modal-stock" hide-footer size="xl">
			<template v-if="!prestock" v-slot:modal-title>
				{{ $t('monte.congelation.stockage_paillettes') }}
			</template>
			<template v-else v-slot:modal-title>
				{{ $t('monte.congelation.prestockage_paillettes') }}
			</template>

			<table v-if="!hide_lot" class="table table-hover table-sm mb-0">
				<tr>
					<td>{{ $t('monte.stock_semence.numero_lot') }}</td>
					<td>{{ $t('monte.congelation.nb_paillettes') }}</td>
					<td v-if="!prestock">{{ $t('monte.congelation.cuve_stockage') }}</td>
					<td v-else>{{ $t('monte.congelation.cuve_prestockage') }}</td>
				</tr>
				<template v-for="stallion in lots_selected">
					<tr :key="stallion.horse.horse_id">
						<td colspan="3"><b>{{ stallion.horse.horse_nom }}</b></td>
					</tr>
					<tr v-for="lot in stallion.lots" :key="lot.semencelot_id">
						<td>{{ lot.semencelot_quantieme }}</td>
						<td>{{ lot.semencelot_qte }}</td>
						<td v-if="!prestock">
							<e-select
								v-model="lot.stockage"
								id="stockage"
								track-by="full_name"
								label="full_name"
								:placeholder="$t('monte.stock_semence.rechercher_categorie')"
								:selectedLabel="$t('global.selected_label')"
								:options="all_localisations_stockage"
								:searchable="true"
								:show-labels="false"
								:allow-empty="true"
								:sortable="false"
								@input="reloadLot"
							>
								<template slot="option" slot-scope="props"><span :style="`padding-left: ${25 * (props.option.indent || 0) }px;`">{{ props.option.full_name }}</span></template>
							</e-select>
						</td>
						<td v-else>
							<e-select
								v-model="lot.prestockage"
								id="prestockage"
								track-by="full_name"
								label="full_name"
								:placeholder="$t('monte.stock_semence.rechercher_categorie')"
								:selectedLabel="$t('global.selected_label')"
								:options="all_localisations_prestockage"
								:searchable="true"
								:show-labels="false"
								:allow-empty="true"
								:sortable="false"
								@input="reloadLot"
							>
								<template slot="option" slot-scope="props"><span :style="`padding-left: ${25 * (props.option.indent || 0) }px;`">{{ props.option.full_name }}</span></template>
							</e-select>
						</td>
					</tr>
				</template>
			</table>

			<div class="text-center">
				<a class="btn btn-primary mt-3" href="#" @click.prevent="checkForm">{{ $t('global.enregistrer') }} <font-awesome-icon v-if="processing" :icon="['fal', 'spinner']" pulse /><font-awesome-icon v-else :icon="['fal', 'save']" /></a>
			</div>
		</b-modal>
	</div>
</template>

<script type="text/javascript">
	import Congelation from "@/mixins/Congelation.js"
	import Navigation from "@/mixins/Navigation.js"
	import StockSemence from "@/mixins/StockSemence.js"
	import ShutterCongelation from "@/mixins/shutters-manager/Congelation.js"
	import _groupBy from 'lodash/groupBy'
	import { EventBus } from 'EventBus'

	export default {
		name: 'StallionStocksListe',
		mixins: [Congelation, Navigation, StockSemence, ShutterCongelation],
		props: ['horse_id'],
		data () {
			return {
				table_busy: false,
				lots: [],
				events_tab: {
					'TableAction::goToEditLot': this.setUpEditLot,
					'TableAction::goToDestroyLots': this.openConfirmDestroyLots,
					'TableAction::goToAssignLots': this.openAssignLots,
					'TableAction::goToImportPaillettes': () => {
						this.setUpImportPaillettes(this.horse_id)
					},
					'TableAction::goToDestroyLotsNotInventoried': this.confirmDestroyLotNotInventoried,
					'TableAction::goToStockSemenceLot': (lots) => {
						this.openStockModal(lots)
					},
					'TableAction::goToPrestockSemenceLot': (lots) => {
						this.openStockModal(lots, true)
					},
				},
				params: {
					with_stock: true,
					stock_empty: false,
					lot_prestockage: false,
					non_inventories: false
				},
				protocoles: [],
				protocoles_selected: [],
				tiers: [],
				tiers_selected: [],
				lots_selected: [],
				commentaire: '',
				tiers_assigned: null,
				date: new Date(),
				processing: false,
				loading: false,
				prestock: false,
				all_localisations_stockage: [],
				all_localisations_prestockage: [],
				hide_lot: false
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				this.loading = true
				this.protocoles = await this.getAllProtocoles()
				this.tiers = await this.getHorseStockTiers(this.horse_id)
				this.loading = false
				this.load_stocks()

				const all_localisations = await this.getLocalisationSemenceFullName()
				this.all_localisations_stockage = all_localisations.filter(loc => !loc.semencelocalisation_prestockage && !loc.semencelocalisation_transport && loc.semencelocalisation_is_floor)
				this.all_localisations_prestockage = all_localisations.filter(loc => loc.semencelocalisation_prestockage && !loc.semencelocalisation_transport && loc.semencelocalisation_is_floor)
			},

			async load_stocks() {
				if(Object.values(this.params).every(param => !param)) {
					return false
				}
				this.table_busy = true
				this.params.protocoles = this.protocoles_selected.length > 0 ? this.protocoles_selected.map(p => p.protocole_id) : null
				this.params.tiers_id = this.tiers_selected ? this.tiers_selected.tiers_id : null
				this.lots = await this.getStocksByStallion(this.horse_id, this.params)
				this.table_busy = false
			},

			async reload() {
				this.shutterPanel().close('edit-lot')
				this.$refs.table.unselectAll()
				this.tiers = await this.getHorseStockTiers(this.horse_id)
				this.load_stocks()
			},

			openConfirmDestroyLots(lots) {
				this.lots_selected = lots.map(lot => lot.semencelot_id)
				this.commentaire = ''
				this.$refs['modal-destroy-lots'].show()
			},

			async confirmDestroyLot() {
				this.processing = true
				const floors_ids = await this.destroyLots(this.lots_selected, this.commentaire)
				this.processing = false
				this.$refs['modal-destroy-lots'].hide()
				this.reload()
				if(floors_ids.length > 0) {
					this.setUpDeleteFloors(floors_ids)
				}
			},

			openAssignLots(lots) {
				this.lots_selected = lots.map(lot => lot.semencelot_id)
				this.tiers_assigned = null
				this.date = new Date()
				this.$refs['modal-assign-lots'].show()
			},

			async confirmAssignLots() {
				if(!this.tiers_assigned || !this.date) 
					return false

				this.processing = true
				await this.assignLots(this.lots_selected, this.tiers_assigned.tiers_id, this.date)
				this.processing = false
				this.$refs['modal-assign-lots'].hide()
				this.reload()
			},

			async confirmDestroyLotNotInventoried(lots) {
				const lots_ids = lots.map(lot => lot.semencelot_id)
				await this.destroyLotsNotInventoried(lots_ids)
				EventBus.$emit('TableAction::stopSpin')
				this.successToast('toast.info_save_succes')
				this.$refs.table.unselectAll()
				this.reload()
			},

			openStockModal(lots, prestock = false) {
				const lots_group = _groupBy(lots, 'horse.horse_id')
				const lots_selected = []
				for(let i in lots_group) {
					lots_group[i].forEach(lot => {
						lot.stockage = this.all_localisations_stockage.find(loc => loc.semencelocalisation_id == lot.stockage_id)
						lot.prestockage = this.all_localisations_prestockage.find(loc => loc.semencelocalisation_id == lot.prestockage_id)
					})
					lots_selected.push({
						horse: lots_group[i][0].horse,
						lots: lots_group[i]
					})
				}
				this.lots_selected = lots_selected
				this.prestock = prestock
				this.$refs['modal-stock'].show()
			},

			async checkForm() {
				let lots = []
				this.lots_selected.forEach(lot_stallion => {
					const ll = lot_stallion.lots.map(lot => {
						return {
							semencelot_id: lot.semencelot_id,
							semencelot_date: new Date(),
							semencelot_qte: lot.semencelot_qte,
							semencelot_commentaire: lot.semencelot_commentaire,
							stockage: lot.stockage ? lot.stockage.semencelocalisation_id : null,
							prestockage: lot.prestockage ? lot.prestockage.semencelocalisation_id : null
						}
					})
					lots = lots.concat(ll)
				})

				this.processing = true
				await this.stockLots(lots, this.prestock)
				this.processing = false
				this.lots_selected = []
				this.$refs['modal-stock'].hide()
				this.successToast('toast.info_save_succes')
				this.$refs.table.unselectAll()
				this.reload()
			},

			reloadLot() {
				this.hide_lot = true
				this.$nextTick(()=> {
					this.hide_lot = false
				})
			}
		},

		components: {
			CustomTable : () => import('GroomyRoot/components/Table/CustomTable'),
			SearchTiers : () => import('@/components/Contract/SearchTiers')
		}
	}

</script>