import Vue from 'vue'
import Transformer from './Transformer'
import PedigreeTransformer from './PedigreeTransformer'
import TierTransformer from './TierTransformer'
import HorseContactTransformer from '@/assets/js/dexie/transformers/HorseContactTransformer.js'
import TiersHorseTransformer from './TiersHorseTransformer'
import HorseResidenceTransformer from '@/assets/js/dexie/transformers/HorseResidenceTransformer.js'
import HorsePensionTransformer from '@/assets/js/dexie/transformers/HorsePensionTransformer.js'
import MouvementTransformer from '@/assets/js/dexie/transformers/MouvementTransformer.js'
import ActeTransformer from '@/assets/js/dexie/transformers/ActeTransformer.js'
import ActeTypeTransformer from '@/assets/js/dexie/transformers/ActeTypeTransformer.js'

import Countries from 'GroomyRoot/assets/lang/countries/countries'
import _orderBy from 'lodash/orderBy'

/**
 * Retourne une liste de
 * @param {Dexie.Collection} horses
 */
export class HorseTransformer extends Transformer {

	table = 'horse'
	transactionTables = [
		'horse_sexe',
		'horse_race',
		'horse_robe',
		'horse_residence',
		'horse_pedigree',
		'horse_mouvement',
		'horse_media',
		'horse_media_playlist',
		'horse_categorie',
		'horse_categorie_lien',
		'tiers',
		'tiers_horse',
		'tiers_horse_part',
		'lieu'
	]
	needAccessRights = true

	async stringified(item) {
		let stringified = await super.stringified(item)
		let pedigree = await this.pedigree(item)
		pedigree = await super.stringified(pedigree)
		return `${stringified}|${pedigree}`
	}

    main_media(item) {
        return this.db().t('horse_media')
        .then(table => {
            return table
			.where('media_horse')
			.equals(item.horse_id)
            .with({
                playlist: 'media_playlist'
            })
        }).then(col => {
			return col
			.filter(media => {
				return media.playlist.mediaplaylist_libelle === 'Photo de profil'
			})
            .reverse()
        }).then(col => {
            return col.length ? col[0] : {}
        })
    }

	async media_filename(item) {
		const main_media = await this.fetchAdditionalColumn(item, 'main_media')
		return main_media.media_filename
	}

	main_residence(item) {
		return this.db().t('horse_residence')
		.then(table => {
			return table.where({
				horseresidence_horse: parseInt(item.horse_id)
			})
		})
		.then(col => {
			return col.transform(new HorseResidenceTransformer('withLieu'))
		})
		.then(res => {
			if(!res || res.length === 0) return null
			return _orderBy(res, 'horseresidence_id', 'desc')[0]
		})
	}

	async horse_residence_item(item) {
		const main_residence = await this.main_residence(item)
		return {
			"lieu_label": main_residence ? main_residence.lieu.lieu_label : null,
			"lieu_cp":main_residence ? main_residence.lieu.lieu_cp: null
		}
	}

	last_mouvement(item) {
		return this.db().t('horse_mouvement')
            .then(table => {
				return table.where({
					mouvement_horse: parseInt(item.horse_id)
                })
            })
			.then(res => {
				return res.filter(mouv => mouv.mouvement_type != 5)
			})
			.then(col => {
                return col.transform(new MouvementTransformer())
			})
			.then(res => {
				return _orderBy(res, ['mouvement_date', 'mouvement_id'], ['desc', 'desc'])
			})
			.then(res => {
				if(res.length > 0) return res[0]
				return null
			})
	}

	async horse_last_mouv_label(item) {
		const last_mouvement = await this.fetchAdditionalColumn(item, 'last_mouvement')
		return last_mouvement ? last_mouvement.mouvement_label : null
	}

	intra_location(item) {
		return this.db().t('horse_mouvement')
		.then(table => {
			return table.where({
				mouvement_horse: parseInt(item.horse_id)
			})
		})
		.then(col => {
			// On exclue les date en 0000-00-00 qui trompent le résultat du sort du transformer
			return col.filter(m => m.mouvement_date != '0000-00-00')
		})
		.then(col => {
			return col.transform(new MouvementTransformer('lite'))
		})
		.then(res => {
			if(res.length > 0 && res[0].mouvement_type == 5) return res[0]
			return null
		})
	}

	async horse_intraloc_label(item) {
		const last_mouvement = await this.fetchAdditionalColumn(item, 'last_mouvement')

		if(!last_mouvement) {
			return null
		}

		// si le dernier mouvement est une entrée ou une loc interne je retourne une loc interne
		if(last_mouvement.mouvement_type == 1 || last_mouvement.mouvement_type == 5) {
			const intra_location = await this.fetchAdditionalColumn(item, 'intra_location')
			return intra_location ? intra_location.mouvement_label : null	
		}

		// sinon je retourne rien car il s'agit d'une sortie
		return null
	}

	actual_pension(item) {
		if (!this.hasRight('PENS')) {
			return []
		}

		return this.db().t('horse_pension')
		.then(table => {
			return table.where({
				horsepension_horse: parseInt(item.horse_id)
			})
		})
		.then(col => {
			const actual_date = new Date()
			return col.filter(p => p.horsepension_start <= actual_date
				&& (p.horsepension_end >= actual_date || p.horsepension_end == null))
		})
		.then(col => {
			return col.transform(new HorsePensionTransformer())
		})
	}

	async horse_actual_pension(item) {
		const actual_pension = await this.fetchAdditionalColumn(item, 'actual_pension')
		return actual_pension ? actual_pension : null
	}

	async horse_last_vermifuge(item) {
		let actes_types_vermifuge = await this.db().t('horse_actes_type')
			.then(table => {
				return table.where({
					actestype_codegroupe: 'VERM'
				})
			})
			.then(col => {
                return col.transform(new ActeTypeTransformer())
			})

		return this.db().t('horse_actes')
            .then(table => {
				return table.where({
					actes_horse: parseInt(item.horse_id),
					actes_actesstatut: 1
                })
				.filter(acte => actes_types_vermifuge.findIndex(at => at.actestype_id == acte.actes_actestype) != -1)
            })
			.then(col => {
                return col.transform(new ActeTransformer('LiteWithNotes'))
			})
			.then(res => {
				return _orderBy(res, ['actes_date'], ['desc'])
			})
			.then(res => {
				if(res.length > 0) return res[0]
				return null
			})
	}

	async horse_last_repro(item) {
		let actes_types_repro = await this.db().t('horse_actes_type')
			.then(table => {
				return table.where({
					actestype_codegroupe: 'REPRO'
				})
			})
			.then(col => {
                return col.transform(new ActeTypeTransformer())
			})

		return this.db().t('horse_actes')
            .then(table => {
				return table.where({
					actes_horse: parseInt(item.horse_id),
					actes_actesstatut: 1
                })
				.filter(acte => actes_types_repro.findIndex(at => at.actestype_id == acte.actes_actestype) != -1)
            })
			.then(col => {
                return col.transform(new ActeTransformer('LiteWithNotes'))
			})
			.then(res => {
				return _orderBy(res, ['actes_date'], ['desc'])
			})
			.then(res => {
				if(res.length > 0) return res[0]
				return null
			})
	}

	async horse_last_echo(item) {
		let actes_types_repro = await this.db().t('horse_actes_type')
			.then(table => {
				return table.where({
					actestype_code: 'REPRO_ECHOGRAPHIE'
				})
			})
			.then(col => {
                return col.transform(new ActeTypeTransformer())
			})

		return this.db().t('horse_actes')
            .then(table => {
				return table.where({
					actes_horse: parseInt(item.horse_id),
					actes_actesstatut: 1
                })
				.filter(acte => actes_types_repro.findIndex(at => at.actestype_id == acte.actes_actestype) != -1)
            })
			.then(col => {
                return col.transform(new ActeTransformer('LiteWithNotes'))
			})
			.then(res => {
				return _orderBy(res, ['actes_date'], ['desc'])
			})
			.then(res => {
				if(res.length > 0) return res[0]
				return null
			})
	}

	async horse_last_vermifuge_date(item) {
		const horse_last_vermifuge = await this.fetchAdditionalColumn(item, 'horse_last_vermifuge')
		return horse_last_vermifuge ? horse_last_vermifuge.actes_date : null
	}

	async horse_last_vermifuge_label(item) {
		const horse_last_vermifuge = await this.fetchAdditionalColumn(item, 'horse_last_vermifuge')
		return horse_last_vermifuge ? horse_last_vermifuge.reponse.reponse_formatted_data : null
	}

	async horse_last_echographie_label(item) {
		const horse_last_echo = await this.fetchAdditionalColumn(item, 'horse_last_echo')

		return horse_last_echo ? {
			actes_date: horse_last_echo.actes_date,
			og: horse_last_echo.reponse.og,
			od: horse_last_echo.reponse.od,
			uterus: horse_last_echo.reponse.uterus
		} : null
	}

	async horse_last_repro_act(item) {
		const horse_last_repro = await this.fetchAdditionalColumn(item, 'horse_last_repro')

		return horse_last_repro ? {
			actes_actestype: horse_last_repro.actetype.actestype_label,
			actes_date: horse_last_repro.actes_date,
			reponse: horse_last_repro.reponse.reponse_formatted_data,
		} : null
	}

	horse_wholesire(item) {
		return item.horse_sire + item.horse_cle
	}

	// Ces getters sont là pour que le CustomTable ne fetch que ces relations spécifiques
	// sans exécuter le transformer en entier lors du tri et des filtres
    race_label(item) {
        return this.fetchRelationship(item, 'horse_race')
        .then(race => {
            return race ? race.race_label : null
        })
    }

    race_type(item) {
        return this.fetchRelationship(item, 'horse_race')
        .then(race => {
            return race ? race.race_type : null
        })
    }

    robe_label(item) {
        return this.fetchRelationship(item, 'horse_robe')
        .then(robe => {
            return robe ? robe.robe_label : null
        })
    }

    sexe_label(item) {
        return item.horse_sexe
    }

    country_label(item) {
        return Countries[Vue.i18n.locale()].iso_3[item.horse_country]
    }

    horse_categories(item) {
        return this.db().t('horse_categorie_lien')
        .then(table => {
            return table
            .where({
                lien_horse: item.horse_id
            })
            .with({
                categorie: 'lien_categorie'
            })
        })
        .then(liens => {
            const categories = []
            liens.forEach((lien) => {
                categories.push(lien.categorie)
            })
            return categories.map((c) => {
            	if(c.categorie_libelle && Array.isArray(c.categorie_libelle))
					return c.categorie_libelle ? c.categorie_libelle.filter(cl => cl !== "").join(', ') : null
				return c.categorie_libelle
			})
        })
    }

    async horse_categorie(item) {
        return (await this.fetchAdditionalColumn(item, 'horse_categories'))
	}

	horse_stallion_label(item) {
		return item.horse_stallion ? Vue.i18n.translate('global.oui') : Vue.i18n.translate('global.non')
	}

	horse_age(item) {
		const now = new Date()
		const date_naissance = Date.parseTz(item.horse_datenaissance)
		return now.getFullYear() - date_naissance.getFullYear()
	}

    async tiers(item) {
		if (!this.hasRight('FACT') && !this.hasRight('REPRO')) {
			return []
		}

		const tiersIds = []
		const tiersHorse = await this.tiers_horse(item)
		const hasSaillie = this.hasRight('SAILL')
        tiersHorse.forEach(tierHorse => {
			if (!tierHorse.tiers_horse_part) return // si lien horsepart supprimé
			let sum = tierHorse.tiers_horse_part.tiershorsepart_pension + tierHorse.tiers_horse_part.tiershorsepart_frais + tierHorse.tiers_horse_part.tiershorsepart_contract + tierHorse.tiers_horse_part.tiershorsepart_propriete
			if(!hasSaillie) {
				sum = tierHorse.tiers_horse_part.tiershorsepart_pension + tierHorse.tiers_horse_part.tiershorsepart_frais + tierHorse.tiers_horse_part.tiershorsepart_propriete
			}
            if(sum > 0 && tierHorse.tiers /* si tiers supprimé alors que horsepart toujours présent */) {
                tiersIds.push(tierHorse.tiers.tiers_id)
            }
        })

        return this.db().t('tiers')
        .then(table => {
            return table
            .where('tiers_id').anyOf(tiersIds)
        })
        .then(col => {
            return col.transform(new TierTransformer('light'))
        })
	}

	async horse_tiers(item) {
		const tiers = await this.fetchAdditionalColumn(item, 'tiers')
		return tiers.map(c => c.tiers_rs).join(', ')
	}

	async horse_tiers_objects(item){

		const tiers = await this.fetchAdditionalColumn(item, 'tiers')
		const res = tiers.map((c) => {
			return {
				"tiers_id": c.tiers_id,
				"tiers_rs": c.tiers_rs
			}
		})
		return res
	}

	tiers_horse(item) {
		if (!this.hasRight('FACT') && !this.hasRight('REPRO')) {
			return []
		}

		return this.db().t('tiers_horse')
		.then(table => {
            return table.where({
				tiershorse_horse: item.horse_id,
				tiershorse_valide: 1
			})
			.and(tiershorse => tiershorse.tiershorse_fonction == 'PROPRIO')
		})
		.then(col => {
			return col.transform(new TiersHorseTransformer('withTiersHorseCurrentPartAndTiers'))
		})
	}

	async pedigree(item) {
        return this.db().t('horse_pedigree')
        .then(table => {
            return table
            .get({
				'pedigree_horse': item.horse_id
			})
        })
        .then(pedigree => {
            return PedigreeTransformer.process(pedigree, 'light')
		})
	}

	async 'pedigree.pere' (item) {
		const pedigree = await this.fetchAdditionalColumn(item, 'pedigree')
		return pedigree.pere
	}

	async 'pedigree.mere' (item) {
		const pedigree = await this.fetchAdditionalColumn(item, 'pedigree')
		return pedigree.mere
	}

	async lieu_stationnement(item) {
		if (!this.hasRight('FACT') && !this.hasRight('REPRO')) {
			return []
		}

		if(item.horse_lieustationnement) {
			return this.db().t('tiers')
			.then(table => {
				return table.get(item.horse_lieustationnement)
			})
			.then(tiers => {
				return TierTransformer.process(tiers, 'withPhone')
			})
		}
		else {
			return []
		}
	}

	async horse_lieu_stationnement(item) {
		const stationnement = await this.fetchAdditionalColumn(item, 'lieu_stationnement')
		return stationnement
	}

	async horse_tags(item) {
        return this.db().t('tags')
			.then(table => {
				return table.where({
					tags_type: 'horse',
					tags_fk: item.horse_id
				})
			})
			.then(col => {
				return col.toArray()
			})
	}

	async transform(horse) {
        return {
            'horse_id'              : horse.horse_id,
            'horse_sire'            : horse.horse_sire,
			'horse_cle'             : horse.horse_cle,
            'horse_wholesire'       : horse.horse_sire+horse.horse_cle,
            'horse_ueln'            : horse.horse_ueln,
			'horse_interne'			: horse.horse_interne,
            'horse_transpondeur'    : horse.horse_transpondeur,
            'horse_passeport'   	: horse.horse_passeport,
            'horse_nom'             : horse.horse_nom,
            'horse_lieunaissance'   : horse.horse_lieunaissance,
            'horse_datenaissance'   : horse.horse_datenaissance ? Date.parseTz(horse.horse_datenaissance) : null,
            'horse_stallion'        : horse.horse_stallion,
			'horse_inactive'		: horse.horse_inactive,
			'horse_sexe'			: horse.horse_sexe
        }
	}

	async resolveHeavy(horses) {
		horses = await horses.toArray()

		const horse_ids = horses.map(h => h.horse_id)

		// Média
		const medias = await this.db().t('horse_media')
		.then(table => {
			return table
			.where('media_horse')
			.anyOf(horse_ids)
			.with({
				playlist: 'media_playlist'
			})
		}).then(col => {
			return col
			.filter(media => {
				return media.playlist.mediaplaylist_libelle === 'Photo de profil'
			})
			.reverse()
		})

		// Pedigree
		const pedigrees = await this.db().t('horse_pedigree')
		.then(table => {
			return table.where('pedigree_horse').anyOf(horse_ids).transform(new PedigreeTransformer('light'))
		})

		const robe_codes = []
		const race_codes = []
		const sexe_codes = []
		horses.forEach(horse => {
			if(horse.horse_robe) {
				robe_codes.push(horse.horse_robe)
			}

			if(horse.horse_race) {
				race_codes.push(horse.horse_race && horse.horse_race !== "" ? Vue.i18n.translate('race.' + horse.horse_race) : "")
			}

			if(horse.horse_sexe) {
				sexe_codes.push(horse.horse_sexe)
			}
		})

		// Robes
		const robes = await this.db().t('horse_robe')
		.then(table => {
			return table.where('robe_code').anyOf(robe_codes).toArray()
		})

		// Races
		const races = await this.db().t('horse_race')
		.then(table => {
			return table.where('race_code').anyOf(race_codes).toArray()
		})

		// Sexes
		const sexes = await this.db().t('horse_sexe')
		.then(table => {
			return table.where('sexe_code').anyOf(sexe_codes).toArray()
		})

		// Catégories
		const categorie_liens = await this.db().t('horse_categorie_lien')
		.then(table => {
			return table.where('lien_horse').anyOf(horse_ids).toArray()
		})

		const categorie_ids = categorie_liens.map(cl => (cl.lien_categorie))
		const categories = await this.db().t('horse_categorie')
		.then(table => {
			return table.where('categorie_id').anyOf(categorie_ids).toArray()
		})

		// Tiers
		let tiers = []
		let tiers_horses = []
		if(this.hasRight('FACT') || this.hasRight('TIERS')) {
			tiers_horses = await this.db().t('tiers_horse')
			.then(table => {
				return table.where('tiershorse_horse').anyOf(horse_ids)
				.and(th => (th.tiershorse_valide === 1))
				.toArray()
			})

			const tiers_ids = tiers_horses.map(th => (th.tiershorse_tiers))

			tiers = await this.db().t('tiers')
			.then(table => {
				return table.where('tiers_id').anyOf(tiers_ids).toArray()
			})
		}

		return horses.map(horse => {
			const main_media = medias.find(m => (m.media_horse === horse.horse_id)) || {}
			const pedigree = pedigrees.find(p => (p.pedigree_horse === horse.horse_id)) || {}
			const robe = robes.find(r => (r.robe_code === horse.horse_robe))
			const race = races.find(r => (r.race_code === horse.horse_race))
			const sexe = sexes.find(r => (r.sexe_code === horse.horse_sexe))

			const horse_liens = categorie_liens.filter(cl => (cl.lien_horse === horse.horse_id))
			const horse_categories = horse_liens.map(cl => {
				return categories.find(c => (c.categorie_id === cl.lien_categorie))
			})

			const horse_tiers_horses = tiers_horses.filter(th => (th.tiershorse_horse === horse.horse_id))
			const horse_tiers = horse_tiers_horses.map(th => {
				return tiers.find(t => (t.tiers_id === th.tiershorse_tiers))
			})
			.filter(tier => (!!tier))

			return {
				horse_id                : horse.horse_id,
				horse_licencekey        : horse.horse_licencekey,
				horse_sire               : horse.horse_sire,
				horse_sireencode		: horse.horse_sireencode,
				horse_cle               : horse.horse_cle,
				horse_wholesire         : horse.horse_sire+horse.horse_cle,
				horse_nom               : horse.horse_nom,
				horse_valide            : horse.horse_valide,
				horse_lieunaissance     : horse.horse_lieunaissance,
				horse_datenaissance     : horse.horse_datenaissance ? Date.parseTz(horse.horse_datenaissance) : null,
				horse_age               : this.horse_age(horse),
				horse_race              : horse.horse_race,
				horse_robe              : horse.horse_robe,
				horse_sexe              : horse.horse_sexe,
				horse_taille            : horse.horse_taille,
				horse_ueln              : horse.horse_ueln,
				horse_uesa              : horse.horse_uesa,
				horse_assujetitva       : horse.horse_assujetitva,
				horse_memo              : horse.horse_memo,
				horse_pursang           : horse.horse_pursang,
				horse_mort              : horse.horse_mort,
				horse_datemort          : horse.horse_datemort ? Date.parseTz(horse.horse_datemort) : null,
				horse_localisationlat   : horse.horse_localisationlat,
				horse_localisationlng   : horse.horse_localisationlng,
				horse_pleine            : horse.horse_pleine,
				horse_transpondeur      : horse.horse_transpondeur,
				horse_passeport      	: horse.horse_passeport,
				horse_interne           : horse.horse_interne,
				horse_francegalop       : horse.horse_francegalop,
				horse_stallion          : horse.horse_stallion,
				horse_stallion_label    : horse.horse_stallion ? Vue.i18n.translate('global.oui') : Vue.i18n.translate('global.non'),
				horse_alias       	   	: horse.horse_alias,

				pedigree                : pedigree,

				media_filename          : main_media.media_filename,

				race_label              : race ? race.race_label : null,
				race_type				: race ? race.race_type : null,
				robe_label              : robe ? robe.robe_label : null,
				sexe_label              : sexe ? sexe.sexe_label : null,

				categories              : horse_categories,
				horse_categorie         : horse_categories.map(c => (c.categorie_libelle)).join(', '),

				tiers                   : horse_tiers,
				horse_tiers             : horse_tiers.map(c => (c.tiers_rs)).join(', '),

				horse_country           : this.country_label(horse),
				horse_country_code      : horse.horse_country,
				horse_localisationlivret: horse.horse_localisationlivret,
				horse_carte				: horse.horse_carte,
				horse_commentaire		: horse.horse_commentaire,
				horse_display_ca		: horse.horse_display_ca,
				horse_residence_label	: horse.horse_residence_label,
				horse_inactive			: horse.horse_inactive,
				horse_weatherbys		: horse.horse_weatherbys
			}
		})
	}

    async fetchHeavy(col) {
        this.additionalColumns({
            'main_media': this.main_media,
            'categories': this.categories,
            'horse_categorie': this.horse_categorie,
			'horse_age': this.horse_age,
            'tiers': this.tiers,
            'race_label': this.race_label,
            'race_type': this.race_type,
            'robe_label': this.robe_label,
            'sexe_label': this.sexe_label,
            'country_label': this.country_label,
			'pedigree': this.pedigree
		})

        return col
    }

    async transformHeavy (horse) {
        return {
            horse_id                : horse.horse_id,
            horse_licencekey        : horse.horse_licencekey,
            horse_sire              : horse.horse_sire,
			horse_sireencode		: horse.horse_sireencode,
            horse_cle               : horse.horse_cle,
            horse_wholesire         : horse.horse_sire+horse.horse_cle,
            horse_nom               : horse.horse_nom,
            horse_valide            : horse.horse_valide,
            horse_lieunaissance     : horse.horse_lieunaissance,
            horse_datenaissance     : horse.horse_datenaissance ? Date.parseTz(horse.horse_datenaissance) : null,
            horse_age               : horse.horse_age,
            horse_race              : horse.horse_race,
            horse_robe              : horse.horse_robe,
            horse_sexe              : horse.horse_sexe,
            horse_taille            : horse.horse_taille,
            horse_ueln              : horse.horse_ueln,
			horse_uesa              : horse.horse_uesa,
            horse_assujetitva       : horse.horse_assujetitva,
            horse_memo              : horse.horse_memo,
            horse_pursang           : horse.horse_pursang,
            horse_mort              : horse.horse_mort,
            horse_datemort          : horse.horse_datemort ? Date.parseTz(horse.horse_datemort) : null,
            horse_localisationlat   : horse.horse_localisationlat,
            horse_localisationlng   : horse.horse_localisationlng,
            horse_pleine            : horse.horse_pleine,
            horse_transpondeur      : horse.horse_transpondeur,
            horse_interne           : horse.horse_interne,
            horse_francegalop       : horse.horse_francegalop,
            horse_stallion          : horse.horse_stallion,
            horse_stallion_label    : horse.horse_stallion ? Vue.i18n.translate('global.oui') : Vue.i18n.translate('global.non'),
            horse_alias          	: horse.horse_alias,

            pedigree                : horse.pedigree,

            media_filename          : horse.main_media.media_filename,

            race_label              : horse.race_label,
            race_type             	: horse.race_type,
            robe_label              : horse.robe_label,
            sexe_label              : horse.sexe_label,

            categories              : horse.categories,
            horse_categorie         : horse.horse_categorie,

            tiers                   : horse.tiers,
            horse_tiers             : horse.tiers.map(c => c.tiers_rs).join(', '),

            horse_country           : horse.country_label,
            horse_country_code      : horse.horse_country,
			horse_localisationlivret: horse.horse_localisationlivret,
			horse_carte				: horse.horse_carte,
			horse_commentaire		: horse.horse_commentaire,
			horse_display_ca		: horse.horse_display_ca,
        }
	}

	async fetchWithResidLieu(col) {
        this.additionalColumns({
            'media_filename': this.media_filename,
            'horse_categorie': this.horse_categorie,
			'horse_age': this.horse_age,
            'horse_tiers_objects': this.horse_tiers_objects,
            'race_label': this.race_label,
            'robe_label': this.robe_label,
            'sexe_label': this.sexe_label,
            'country_label': this.country_label,
			'pedigree': this.pedigree,
			'horse_wholesire': this.horse_wholesire,
			'horse_residence_item': this.horse_residence_item,
			'horse_last_mouv_label': this.horse_last_mouv_label,
			'horse_intraloc_label': this.horse_intraloc_label,
			'horse_actual_pension': this.horse_actual_pension,
			'horse_last_vermifuge_date': this.horse_last_vermifuge_date,
			'horse_last_vermifuge_label': this.horse_last_vermifuge_label,
			'horse_lieu_stationnement': this.horse_lieu_stationnement,
			'horse_tags': this.horse_tags
		})

        return col
    }

    async transformWithResidLieu(horse) {
    	let horse_actual_pension = horse.horse_actual_pension

    	if(typeof horse_actual_pension == 'string') {
    		horse_actual_pension = [{pension:{pension_label:horse_actual_pension}}]
    	}

    	if(horse.horse_last_vermifuge_date) {
    		let date = 'Invalid Date'
    		if(typeof horse.horse_last_vermifuge_date == 'string') {
				let split = horse.horse_last_vermifuge_date.split('/')
				date = new Date(split[1]+'/'+split[0]+'/'+split[2])
			}
			else if (typeof horse.horse_last_vermifuge_date == 'object'){
				date = horse.horse_last_vermifuge_date
			}
			if(date != 'Invalid Date') {
				horse.horse_last_vermifuge_date = this.formatDate(date)
			}
    	}

		return {
            horse_id                	: horse.horse_id,
            horse_licencekey        	: horse.horse_licencekey,
            horse_sire              	: horse.horse_sire,
            horse_cle               	: horse.horse_cle,
            horse_wholesire         	: horse.horse_wholesire,
            horse_nom               	: horse.horse_nom,
            horse_valide            	: horse.horse_valide,
            horse_lieunaissance     	: horse.horse_lieunaissance,
            horse_datenaissance     	: horse.horse_datenaissance ? Date.parseTz(horse.horse_datenaissance) : null,
            horse_age               	: horse.horse_age,
            horse_race              	: horse.horse_race && horse.horse_race !== "" ? Vue.i18n.translate('race.' + horse.horse_race) : "",
            horse_robe              	: horse.horse_robe,
            horse_sexe              	: horse.horse_sexe,
            horse_taille            	: horse.horse_taille,
            horse_ueln              	: horse.horse_ueln,
            horse_assujetitva       	: horse.horse_assujetitva,
            horse_memo              	: horse.horse_memo,
            horse_pursang           	: horse.horse_pursang,
            horse_mort              	: horse.horse_mort,
            horse_datemort          	: horse.horse_datemort,
            horse_localisationlat   	: horse.horse_localisationlat,
            horse_localisationlng   	: horse.horse_localisationlng,
            horse_pleine            	: horse.horse_pleine,
            horse_transpondeur      	: horse.horse_transpondeur,
            horse_interne           	: horse.horse_interne,
            horse_stallion          	: horse.horse_stallion,
            horse_stallion_label    	: horse.horse_stallion == 1 ? Vue.i18n.translate('global.oui') : Vue.i18n.translate('global.non'),
            pedigree                	: horse.pedigree,
            media_filename          	: horse.media_filename,
            race_label              	: horse.race_label,
            robe_label              	: horse.robe_label,
            sexe_label              	: horse.sexe_label,
            horse_categorie         	: horse.horse_categorie,
            horse_tiers             	: horse.horse_tiers_objects,
            horse_country           	: horse.country_label,
			horse_livret      			: horse.horse_localisationlivret,
			horse_commentaire      		: horse.horse_commentaire,
			horse_carte      			: horse.horse_carte,
			horse_country_code      	: horse.horse_country,
			horse_residence_label   	: horse.horse_residence_item.lieu_label,
			horse_residence_cp			: horse.horse_residence_item.lieu_cp,
			horse_last_mouv_label		: horse.horse_last_mouv_label,
			horse_intraloc_label		: horse.horse_intraloc_label,
			horse_actual_pension		: horse_actual_pension[0],
			horse_last_vermifuge_date   : horse.horse_last_vermifuge_date,
			horse_last_vermifuge_label  : horse.horse_last_vermifuge_label,
			lieustationnement			: horse.horse_lieu_stationnement.tiers_rs,
			lieustationnement_cp		: horse.horse_lieu_stationnement.tiers_postalcode,
			lieustationnement_town		: horse.horse_lieu_stationnement.tiers_town,
			lieustationnement_phone		: horse.horse_lieu_stationnement.phones_number,
			horse_sireencode			:  horse.horse_sireencode,
			horse_francise				: (horse.horse_sire != "" && horse.horse_sire) ? true : (horse.horse_sireencode && horse.horse_sireencode.indexOf("_") > -1) ? false : true,
			horse_inactive				: horse.horse_inactive,
			// horse_display_web			: horse.horse_display_web,
			horse_tags 					: horse.horse_tags.map(tag => tag.tags_color),
			horse_weatherbys			: horse.horse_weatherbys
        }
    }

    fetchWithPedigree (col) {
		this.additionalColumns({
			horse_categorie: this.horse_categorie
		})

		return col.with({
			pedigree: 'horse_pedigree'
		})
    }

    async transformWithPedigree (horse) {
        return {
            'horse_id'              : horse.horse_id,
            'horse_sire'            : horse.horse_sire,
			'horse_cle'             : horse.horse_cle,
            'horse_wholesire'       : horse.horse_sire+horse.horse_cle,
            'horse_ueln'            : horse.horse_ueln,
			'horse_interne'			: horse.horse_interne,
            'horse_transpondeur'    : horse.horse_transpondeur,
            'horse_nom'             : horse.horse_nom,
            'horse_lieunaissance'   : horse.horse_lieunaissance,
            'horse_datenaissance'   : horse.horse_datenaissance ? Date.parseTz(horse.horse_datenaissance) : null,
            'horse_stallion'        : horse.horse_stallion,
            'horse_pedigree'        : await PedigreeTransformer.process(horse.pedigree, 'light'),
			'horse_categorie'		: horse.horse_categorie
        }
	}

	fetchWithPedigreeAndMainResidence (col) {
		this.additionalColumns({
			horse_categorie: this.horse_categorie,
			'horse_residence_item': this.horse_residence_item,
			'horse_last_vermifuge_date': this.horse_last_vermifuge_date,
			'horse_last_vermifuge_label': this.horse_last_vermifuge_label,
			'horse_last_repro_act': this.horse_last_repro_act,
			'horse_last_echographie_label': this.horse_last_echographie_label,
			'horse_intraloc_label': this.horse_intraloc_label
		})

		return col.with({
			pedigree: 'horse_pedigree'
		})
    }

    async transformWithPedigreeAndMainResidence (horse) {
    	let horse_last_repro = ""
    	if(horse.horse_last_repro_act) {
    		horse_last_repro = horse.horse_last_repro_act.actes_actestype
			let date_lang_format = Vue.prototype.$i18n.locale()
			horse_last_repro += " (" + Date.parseTz(horse.horse_last_repro_act.actes_date).toLocaleDateString(date_lang_format) + ")"
			horse_last_repro += " " + horse.horse_last_repro_act.reponse
    	}
    	return {
            'horse_id'              	: horse.horse_id,
            'horse_sire'            	: horse.horse_sire,
			'horse_cle'             	: horse.horse_cle,
            'horse_wholesire'       	: horse.horse_sire+horse.horse_cle,
            'horse_ueln'            	: horse.horse_ueln,
			'horse_interne'				: horse.horse_interne,
            'horse_transpondeur'    	: horse.horse_transpondeur,
            'horse_nom'             	: horse.horse_nom,
            'horse_lieunaissance'   	: horse.horse_lieunaissance,
            'horse_datenaissance'   	: horse.horse_datenaissance ? Date.parseTz(horse.horse_datenaissance) : null,
            'horse_stallion'        	: horse.horse_stallion,
            'horse_pedigree'        	: await PedigreeTransformer.process(horse.pedigree, 'light'),
			'horse_categorie'			: horse.horse_categorie,
			'horse_residence_label'		: horse.horse_residence_item.lieu_label,
			'horse_last_vermifuge_date' : horse.horse_last_vermifuge_date,
			'horse_last_vermifuge_label': horse.horse_last_vermifuge_label,
			'horse_last_repro'			: horse_last_repro,
			'horse_sexe'				: horse.horse_sexe,
			'horse_last_echo_og'		: horse.horse_last_echographie_label && horse.horse_last_echographie_label.og ? horse.horse_last_echographie_label.og : '',
			'horse_last_echo_od'		: horse.horse_last_echographie_label && horse.horse_last_echographie_label.od ? horse.horse_last_echographie_label.od : '',
			'horse_last_echo_uterus'	: horse.horse_last_echographie_label && horse.horse_last_echographie_label.uterus ? horse.horse_last_echographie_label.uterus : '',
			'horse_inactive'			: horse.horse_inactive,
			'horse_intraloc_label' 		: horse.horse_intraloc_label
        }
    }

    fetchWithPedigreeAndContact (col) {
		return col
		.with({
            'pedigree'              : 'horse_pedigree',
            'contact'               : 'horse_contact'
        })
    }

    async transformWithPedigreeAndContact (horse) {
        return {
            'horse_id'              : horse.horse_id,
            'horse_sire'            : horse.horse_sire,
            'horse_cle'             : horse.horse_cle,
            'horse_wholesire'       : horse.horse_sire+horse.horse_cle,
            'horse_ueln'            : horse.horse_ueln,
            'horse_transpondeur'    : horse.horse_transpondeur,
            'horse_nom'             : horse.horse_nom,
            'horse_lieunaissance'   : horse.horse_lieunaissance,
            'horse_datenaissance'   : horse.horse_datenaissance ? Date.parseTz(horse.horse_datenaissance) : null,
            'horse_stallion'        : horse.horse_stallion,
            'horse_pedigree'        : horse.pedigree != undefined && horse.pedigree.length > 0 ? await PedigreeTransformer.process(horse.pedigree[0], 'light') : [],
            'contact'               : await HorseContactTransformer.process(horse.contact, 'withContactAndFonction')
        }
	}

    fetchLight (col) {
		this.additionalColumns({
			horse_age: this.horse_age,
			pedigree: this.pedigree,
			'country_label': this.country_label,
			race_label: this.race_label,
			'horse_lieu_stationnement': this.horse_lieu_stationnement
		})

		return col
	}

	// ATTENTION Colonne indéxée : Vérifier sur groomy_api (model/Contract.php::avenant()) si update
    async transformLight (horse) {
        return {
            'horse_id'              : horse.horse_id,
            'horse_sire'            : horse.horse_sire,
            'horse_cle'             : horse.horse_cle,
            'horse_wholesire'       : horse.horse_sire+horse.horse_cle,
            'horse_ueln'            : horse.horse_ueln,
            'horse_transpondeur'    : horse.horse_transpondeur,
			'horse_nom'             : horse.horse_nom,
			'horse_age'				: horse.horse_age,
			'horse_sexe'            : horse.horse_sexe,
			'horse_mere'			: horse.pedigree != undefined && horse.pedigree.length > 0 ? horse.pedigree.mere : null,
			'horse_pmere'			: horse.pedigree != undefined && horse.pedigree.length > 0 ? horse.pedigree.pmere : null,
			'horse_country'			: horse.country_label,
			'horse_display_ca'		: horse.horse_display_ca,
			'race_label'            : horse.race_label,
			'horse_race'            : horse.horse_race && horse.horse_race !== "" ? Vue.i18n.translate('race.' + horse.horse_race) : "",
			'lieustationnement'		: horse.horse_lieu_stationnement.tiers_rs,
			'lieustationnement_cp'	: horse.horse_lieu_stationnement.tiers_postalcode,
			'lieustationnement_town': horse.horse_lieu_stationnement.town,
			'lieustationnement_phone': horse.horse_lieu_stationnement.phones_number,
			'horse_inactive'		: horse.horse_inactive
        }
	}

	fetchWithTiersAndCurrentPart(col) {
		this.additionalColumns({
			'tiers_horse': this.tiers_horse
		})

		return col
	}

	async transformWithTiersAndCurrentPart (horse) {
		return {
			'horse_id': horse.horse_id,
			'horse_sire': horse.horse_sire,
			'horse_cle': horse.horse_cle,
			'horse_ueln': horse.horse_ueln,
			'horse_nom': horse.horse_nom,
			'horse_interne': horse.horse_interne,
			'horse_transpondeur': horse.horse_transpondeur,
			'horse_lieunaissance': horse.horse_lieunaissance,
			'horse_datenaissance': horse.horse_datenaissance ? Date.parseTz(horse.horse_datenaissance) : null,
			'horse_race': horse.horse_race && horse.horse_race !== "" ? Vue.i18n.translate('race.' + horse.horse_race) : "",
			'horse_robe': horse.horse_robe,
			'horse_sexe': horse.horse_sexe,
			'tiers_horse': horse.tiers_horse
		}
	}

	tiers_horse_with_phone(item) {
		if (!this.hasRight('FACT') && !this.hasRight('REPRO')) {
			return []
		}

		return this.db().t('tiers_horse')
		.then(table => {
            return table.where({
				tiershorse_horse: item.horse_id,
				tiershorse_valide: 1
			})
			.and(tiershorse => tiershorse.tiershorse_fonction == 'PROPRIO')
		})
		.then(col => {
			return col.transform(new TiersHorseTransformer('withTiersHorseCurrentPartAndTiersHeavy'))
		})
	}

	fetchWithTiersAndPhoneAndCurrentPart(col) {
		this.additionalColumns({
			'tiers_horse_with_phone': this.tiers_horse_with_phone
		})

		return col
	}

	async transformWithTiersAndPhoneAndCurrentPart (horse) {
		return {
			'horse_id': horse.horse_id,
			'horse_sire': horse.horse_sire,
			'horse_cle': horse.horse_cle,
			'horse_ueln': horse.horse_ueln,
			'horse_nom': horse.horse_nom,
			'horse_interne': horse.horse_interne,
			'horse_transpondeur': horse.horse_transpondeur,
			'horse_lieunaissance': horse.horse_lieunaissance,
			'horse_datenaissance': horse.horse_datenaissance ? Date.parseTz(horse.horse_datenaissance) : null,
			'horse_race': horse.horse_race && horse.horse_race !== "" ? Vue.i18n.translate('race.' + horse.horse_race) : "",
			'horse_robe': horse.horse_robe,
			'horse_sexe': horse.horse_sexe,
			'tiers_horse': horse.tiers_horse_with_phone
		}
	}

	tiers_horse_all(item) {
		return this.fetchRelationship(item, 'tiers_horse')
		.then(tiers_horses => {
			return TiersHorseTransformer.process(tiers_horses, 'withTiersHorsePartAndTiers')
		})
	}

	fetchWithTiersAndPart(col) {
		this.additionalColumns({
			'tiers_horse_all': this.tiers_horse_all
		})

		return col
	}

	async transformWithTiersAndPart (horse) {
		return {
			'horse_id': horse.horse_id,
			'horse_sire': horse.horse_sire,
			'horse_cle': horse.horse_cle,
			'horse_ueln': horse.horse_ueln,
			'horse_nom': horse.horse_nom,
			'horse_interne': horse.horse_interne,
			'horse_transpondeur': horse.horse_transpondeur,
			'horse_lieunaissance': horse.horse_lieunaissance,
			'horse_datenaissance': horse.horse_datenaissance ? Date.parseTz(horse.horse_datenaissance) : null,
			'horse_race': horse.horse_race && horse.horse_race !== "" ? Vue.i18n.translate('race.' + horse.horse_race) : "",
			'horse_robe': horse.horse_robe,
			'horse_sexe': horse.horse_sexe,
			'tiers_horse': horse.tiers_horse_all
		}
	}

	fetchLightWithPedigree(col) {
		this.additionalColumns({
			'horse_wholesire': this.horse_wholesire,
			'pedigree': this.pedigree,
			'horse_lieu_stationnement': this.horse_lieu_stationnement
		})

		return col
	}

	async transformLightWithPedigree (horse) {
		return {
			'horse_id': horse.horse_id,
			'horse_sire': horse.horse_sire,
			'horse_cle': horse.horse_cle,
			'horse_wholesire': horse.horse_wholesire,
			'horse_ueln': horse.horse_ueln,
			'horse_interne': horse.horse_interne,
			'horse_transpondeur': horse.horse_transpondeur,
			'horse_nom': horse.horse_nom,
			'horse_lieunaissance': horse.horse_lieunaissance,
			'horse_datenaissance': horse.horse_datenaissance ? Date.parseTz(horse.horse_datenaissance) : null,
			'horse_race': horse.horse_race && horse.horse_race !== "" ? Vue.i18n.translate('race.' + horse.horse_race) : "",
			'horse_robe': horse.horse_robe,
			'horse_sexe': horse.horse_sexe,
			'pedigree': horse.pedigree,
			'lieustationnement': horse.horse_lieu_stationnement.tiers_rs,
			'lieustationnement_cp': horse.horse_lieu_stationnement.tiers_postalcode,
			'lieustationnement_town': horse.horse_lieu_stationnement.town,
			'lieustationnement_phone': horse.horse_lieu_stationnement.phones_number
		}
	}

	fetchWithResidenceAndLastMouvement(col) {
		this.additionalColumns({
			'horse_residence_item': this.horse_residence_item,
			'horse_last_mouv_label': this.horse_last_mouv_label,
			'horse_categorie': this.horse_categorie,
		})

		return col
	}

	async transformWithResidenceAndLastMouvement (horse) {
        return {
            'horse_id'              : horse.horse_id,
            'horse_sire'            : horse.horse_sire,
            'horse_cle'             : horse.horse_cle,
            'horse_wholesire'       : horse.horse_sire+horse.horse_cle,
            'horse_ueln'            : horse.horse_ueln,
            'horse_transpondeur'    : horse.horse_transpondeur,
			'horse_nom'             : horse.horse_nom,
			'horse_sexe'            : horse.horse_sexe,
			'horse_residence_label' : horse.horse_residence_label,
			'horse_last_mouv_label'	: horse.horse_last_mouv_label,
			'horse_display_ca'		: horse.horse_display_ca,
			'horse_categorie'		: horse.horse_categorie,
			'horse_inactive'		: horse.horse_inactive
        }
	}

    fetchUltraLight (col) {
		this.additionalColumns({
			'country_label': this.country_label,

		})

		return col
	}

	async transformUltraLight (horse) {
		return {
            'horse_id'              : horse.horse_id,
			'horse_nom'             : horse.horse_nom,
			'horse_country'			: horse.country_label
        }
	}

	fetchWithTiers (col) {
        this.additionalColumns({
			'horse_age'	: this.horse_age,
            'tiers'		: this.tiers,
			'pedigree'	: this.pedigree
		})

		return col
	}

	async transformWithTiers (horse) {
		return {
			'horse_id'		: horse.horse_id,
			'horse_nom'		: horse.horse_nom,
			'horse_age'		: horse.horse_age,
			'pedigree'		: horse.pedigree,
			'horse_tiers'	: horse.tiers.map(c => c.tiers_rs).join(', ')
		}
	}
}

export default HorseTransformer
