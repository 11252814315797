<template>
	<div>
		<LoadingSpinner v-show="isWorking" class="col-12"/>

		<b-alert show variant="warning" class="mt-2" v-if="warningDeadHorse != '' ">
			{{ warningDeadHorse }}
		</b-alert>

		<div class="text-left text-truncate">
			<router-link v-if="singleAddHorse" :to="{ name: 'horseFiche', params: { horse_id: horses_ids[0] }}">
				<font-awesome-icon :icon="['fas', 'horse-head']" /> {{ horses_names }}
			</router-link>
			<router-link v-else id="horse_names" :to="{ name: 'horseListe'}">
				<font-awesome-icon :icon="['fas', 'horse-head']" /> {{ horses_names }}
			</router-link>
		</div>

		<div class="row mt-4">
			<div class="col-12">
				 <e-select
                    v-model="type_selected"
                    id="type_mouvement"
                    :placeholder="$t('mouvement.type_mouvement')"
                    :selectedLabel="$t('global.selected_label')"
                    :options="mouvement_types"
                    :show-labels="false"
                    :group-select="false"
                    @input="resetLieux"
                >
					<template slot="option" slot-scope="{ option }">{{ $t('mouvement.'+option) }}</template>
					<template slot="singleLabel" slot-scope="{ option }">{{ $t('mouvement.'+option) }}</template>
                    <template slot="noOptions">{{ $t('global.list_empty') }}</template>
            	</e-select>
			</div>
		</div>

		<div class="row mt-4" v-if="ready">
			<div class="col-12">
				<WriteEntree 
					v-if="type_selected == 'entree' && mare_ready"
					:horses_ids="horses_ids" 
					:provenance_lieu.sync="provenance_lieu" 
					:destination_lieu.sync="destination_lieu"
					:mouvement_date.sync="mouvement_date"
					:selected_contact.sync="selected_contact"
					:mouvement_raison.sync="mouvement_raison"
				/>

				<WriteSortie
					v-if="type_selected == 'sortie' && mare_ready"
					:horses_ids="horses_ids" 
					:destination_lieu.sync="destination_lieu"
					:mouvement_date.sync="mouvement_date"
					:selected_contact.sync="selected_contact"
					:mouvement_raison.sync="mouvement_raison"
					:date_retour.sync="date_retour"
					:sortie_definitive.sync="sortie_definitive"
					:new_stationnement.sync="new_stationnement"
					:date_retour_checked="date_retour_checked"
				/>

				<WriteInterne
					v-if="type_selected == 'interne'"
					:horses_ids="horses_ids" 
					:residence_id="lieu.lieu_id"
					:mouvement_date.sync="mouvement_date"
					:default_location_id="selected_intra_location_id"
					:selected_location.sync="intra_location" 
					:selected_contact.sync="selected_contact"
				/>
			</div>
		</div>

        <!-- Sub route - carnet d'adresse -->
        <!-- <div v-if="state == 'carnet_adresse'">
            <router-view></router-view>
        </div> -->

		<!-- ajout et sauvegarde - écran principal -->
		<!-- <form v-show="!isWorking && state == 'main_form'" @submit.prevent="validForm"> -->

			<!-- Permet de renseigner l'origine du cheval -->
			<!--div v-if="!isOnlyIntraLocation && (isAjoutChevalTunnel || isFirstMouvement)" class="form-group">
				<label>{{ $t("mouvement.lieu_origine_cheval") }}<sup>*</sup></label>
				<input class="form-control" type="text" id="horse_origine" :placeholder="$t('mouvement.lieu_origine_cheval')" v-model="origine_cheval" required>
				<hr>
			</div> -->

            <!-- Si on veut ajouter un nouveau lieu directement -->
			<!-- <div class="form-group">
				<label>{{ $t("mouvement.date_mouvement") }}<sup>*</sup></label>
                <e-datepicker id="date_ouverture" v-model="mouvement_date"></e-datepicker>
			</div>

			<div class="form-group">
	            <label class="col-form-label">{{ $t('acte.choix_contact') }}</label>
                <e-select
                    v-model="selected_contact"
                    id="contact_id"
                    track-by="contact_id"
                    :placeholder="$t('acte.selectionnez_contact')"
                    :selectedLabel="$t('global.selected_label')"
                    :options="contacts"
                    :searchable="true"
                    :allow-empty="true"
                    :loading="isLoadingContact"
                    :show-labels="false"
                    :group-select="false"
                >
                    <template slot="option" slot-scope="{ option }">{{ option.contact_civility }} {{ option.contact_firstname }} {{ option.contact_lastname }}</template>
                    <template slot="singleLabel" slot-scope="{ option }">{{ option.contact_civility }} {{ option.contact_firstname }} {{ option.contact_lastname }}</template>
                    <template slot="noOptions">{{ $t('global.list_empty') }}</template>
                </e-select>
	        </div> -->

            <!-- Carnet d'adresse pour choisir un lieu - au click, on affiche le composant CarnetAdresse -->
			<!-- <span v-if="!isOnlyIntraLocation">
				<label>{{ $t("mouvement.lieu_mouvement") }}<sup>*</sup></label>
				<span class="btn-link float-right" v-on:click.stop.prevent="displayCarnetAdresse" title="">
					{{ $t("mouvement.carnet_adresse") }} <font-awesome-icon :icon="['fal', 'chevron-circle-right']" />
				</span> -->

				<!-- Formulaire ajout lieu -->
				<!-- <div class="row" id="lieu">
					<div class="col-12"> -->
						<!-- Si les infos sont déjà renseignées -->
						<!-- <div @click="editProposedLieu" class="form-group" v-if="(lieu.lieu_adresse && lieu.lieu_cp && lieu.lieu_ville) || lieu_from_ca">
							<div class="form-control">
								<b>{{ lieu.lieu_label }}: </b>
								<span>{{ lieu.lieu_adresse }}, {{ lieu.lieu_cp }} {{ lieu.lieu_ville | capitalize }}</span>
							</div>
						</div> -->

						<!-- Sinon, on affiche le formulaire -->
						<!-- <div v-if="edit_lieu_ca || !lieu_from_ca">
							<div class="form-group">
								<input class="form-control chooseplace" :placeholder="$t('lieu.label')" v-model="lieu.lieu_label" required>
							</div>
							<div class="form-group">
								<div class="row">
									<div class="col-4">
										<input class="form-control" :placeholder="$t('lieu.adresse')" v-model="lieu.lieu_adresse">
									</div>
									<div class="col-4">
										<input class="form-control" type="number" :placeholder="$t('lieu.cp')" id="cp" v-model="lieu.lieu_cp">
									</div>
									<div class="col-4">
										<input class="form-control" :placeholder="$t('lieu.ville')" v-model="lieu.lieu_ville">
									</div>
								</div>
							</div>
						</div> -->

						<!-- Raison du mouvement -->
						<!-- <div class="form-group">
							<label>{{ $t("mouvement.raison_mouvement") }}<sup>*</sup></label>
							<input class="form-control" type="text" id="mouvement_raison" :placeholder="$t('mouvement.raison_mouvement')" v-model="mouvement_raison" required>
						</div> -->

						<!-- Voir le computed can_define_retour pour les cas -->
						<!-- <div class="form-group" v-if="can_define_retour">
							<div class="custom-control custom-checkbox">
								<input type="checkbox" class="custom-control-input" id="realise" v-model="date_retour_checked">
								<label class="custom-control-label" for="realise">{{ $t("mouvement.definir_date_retour") }}</label>
							</div>
						</div>

						<div class="form-group" v-if="date_retour_checked">
							<label>{{ $t("mouvement.date_retour") }} <sup>*</sup></label>
							<div class="input-group">
								<e-datepicker v-model="date_retour" required />
								<div class="input-group-append">
									<span class="input-group-text"><font-awesome-icon :icon="['fal', 'calendar-alt']" /></span>
								</div>
							</div>
						</div>

						<div class="form-group" v-if="lieu.lieu_type == 3">
							<div class="custom-control custom-checkbox">
								<input type="checkbox" class="custom-control-input" id="sortie_def" v-model="sortie_definitive">
								<label class="custom-control-label" for="sortie_def">{{ $t("mouvement.sortie_definitive") }}</label>
							</div>
						</div>
					</div>
				</div> -->

				<!-- Provenance à préciser si le cheval entre sur la structure -->
				<!-- <div v-if="askProvenance">
					<AskProvenance ref="askProvenance" :provenance.sync="provenance_lieu" :displayCarnetAdresse="displayCarnetAdresseProvenance" />
				</div>
			</span>

			<div v-if="askIntraLocation || isOnlyIntraLocation">
				<AskIntraLocation :default_location_id="selected_intra_location_id" :selected_location.sync="intra_location" :residence_id="mouvLieuId" />
			</div> -->

            <!-- Bas de page -->
            <!-- Message d'erreur si besoin -->
            <ErrorAlert v-if="message_erreur_code !== ''" :messageI18n="message_erreur_code" />
            <div class="text-center mt-3" v-if="can_edit">
				<button title="Ajouter" class="btn btn-primary rounded-pill" v-if="mouvement_id == null" v-on:click="validForm">
                    {{ $t("global.ajouter") }} <font-awesome-icon class="ml-2" :icon="['fal', 'plus-circle']" />
                </button>
				<button title="Modifier" class="btn btn-primary rounded-pill" v-else v-on:click="validForm">
                    {{ $t("global.modifier") }} <font-awesome-icon class="ml-2" :icon="['fal', 'plus-circle']" />
                </button>

                <br/>

				<router-link
                    v-if="isAjoutChevalTunnel"
                    :to="horses_ids.length === 1 ? { name: 'horseFiche', params: { horse_id: horses_ids[0] } } : { name: 'horseListe' }"
                    class="btn btn-link mt-4"
                >
                    {{ $t("mouvement.passer_ajout") }}
                </router-link>
            </div>
		<!-- </form> -->
	</div>
</template>

<script type="text/javascript">
    import Common from '@/assets/js/common.js'
    import Mouvement from "@/mixins/Mouvements.js"
	import Lieux from "@/mixins/Lieux.js"
    import Horse from "@/mixins/Horse.js"
    import Navigation from "@/mixins/Navigation.js"
    import Shutter from "@/mixins/Shutter.js"
    import ShutterMouvements from '@/mixins/shutters-manager/Mouvements.js'
    import ShutterTriggers from '@/mixins/shutters-manager/Triggers.js'
    import IntraLocation from "@/mixins/IntraLocation.js"
    import Contact from "@/mixins/Contact.js"
	import _cloneDeep from 'lodash/cloneDeep'


	export default {
		name: "WriteMouvement",
        mixins: [Mouvement, Lieux, Horse, Navigation, IntraLocation, Shutter, ShutterMouvements, ShutterTriggers, Contact],
        props: ['horses_ids', 'mare_id', 'date_acte', 'next', 'type_mvt', 'code_onboarding'],
		data () {
			return {
				warningDeadHorse: '',
                state: 'main_form',
                lieu_from_ca: false, // passe à true si on a séléctionné une adresse dans le carnet d'adresse, ou si on edit un mouvement
				edit_lieu_ca: false,
                message_erreur_code: '',
                from_asset: '', // Vue précédente

                /* obj mouvement */
                mouvement: {},
                mouvement_id: 0,
                mouvement_date: new Date(),
                date_retour: null,
                date_retour_checked: false,
                actual_date: new Date(),
                mouvement_raison: '',
				origine_cheval: '', // si on est dans le tunnel d'ajout de cheval

                /* Nav et formulaires */
                state_index: 0,
                tab_states: {
					'main_form': 'mouvement.ajouter_mouvement',
					'carnet_adresse': 'mouvement.carnet_adresse',
				},
                mouv_modif_title: 'mouvement.modifier_mouvement',
                ready : false,
				last_mouvement: null,
				previous_mouv: null, // mouvement précédent l'actuel (cas d'une édition)
				destination_lieu: { lieu_id: 0, lieu_label: '', lieu_adresse: '', lieu_cp: '', lieu_ville: '', lieu_type: '3' }, // lieu de destination du mouvement
				lieu: { lieu_id: 0, lieu_label: '', lieu_adresse: '', lieu_cp: '', lieu_ville: '', lieu_type: '3' }, // lieu de destination du mouvement
				lieu_bak: '',
				provenance_lieu: { lieu_id: 0, lieu_label: '', lieu_adresse: '', lieu_cp: '', lieu_ville: '', lieu_type: '' },
				provenance_lieu_bak: '',
				provenance_mouv: { lieu_id: 0, lieu_label: '', lieu_adresse: '', lieu_cp: '', lieu_ville: '', lieu_type: '' },
				provenance_mouv_bak: '',
				provenance_edit: false,

				intra_location: null,
				only_intra_location: null,
				selected_intra_location_id: 0,

				ca_origine: '', // origine du clic, qui a ouvert le carnet d'adresse. Peut être lieu_mouvement ou provenance
				first_mouv: false, // passe à true si aucun mouvement n'est encore enregistré
				events_tab: {
					'WriteMouvement::backFromCarnetAdresse': this.backFromCarnetAdresse
				},
				prev_mouv_working: true, // booléens du statut de chargement des données
				last_mouv_working: true,
				horse_resid_working: true,

                can_edit: true,

                triggered_list: [],

                selected_contact: null,
                contacts: [] ,
                isLoadingContact: true,
				horses_names: null,
				sortie_definitive: false,
				type_selected: null,
				mouvement_types: [
					"entree",
					"sortie",
					"interne"
				],
				mare_ready: true,
				new_stationnement: true
			}
		},
		mounted() {
            this.init_component()
		},
		methods: {
            async init_component() {
				this.horses_names = await this.getHorsesNames()
				let horse_alive = await this.checkHorseAlive(this.horses_ids)
				if(!horse_alive) {
					if(this.horses_ids.length > 1) {
						this.warningDeadHorse = this.getTrad('toast.horse_multiple_dead')
					}
					else {
						this.warningDeadHorse = this.getTrad('toast.horse_unique_dead')
					}
				} else {
					this.warningDeadHorse = ''
				}

				if(this.type_selected == 'interne') {
					this.checkActualResidence()
				}

            	if(this.mare_id) {
            		this.mare_ready = false
            		const mouvement_mare = await this.getHorseLastResidence(this.mare_id)
            		if(mouvement_mare) {
	            		this.destination_lieu = {
	            			lieu_id: mouvement_mare[0].mouvement_lieu,
	            			lieu_label: mouvement_mare[0].mouvement_label,
	            			lieu_adresse: mouvement_mare[0].mouvement_adresse,
							lieu_cp: mouvement_mare[0].mouvement_cp,
							lieu_ville: mouvement_mare[0].mouvement_ville,
							lieu_type: 1
	            		}
	            	}

            		this.origine_cheval = this.getTrad("horse.naissance")
					this.mouvement_raison = this.getTrad("horse.naissance")
					this.mare_ready = true
            	}
            	if(this.next) this.triggered_list = this.next

                this.from_asset = this.$route.params.from
            	if(!this.type_mvt) {
	                this.mouvement_id = this.$route.params.mouvement_id
	            }
                this.mouvement_date = this.actual_date

                if(this.type_mvt) {
                	this.type_selected = this.type_mvt
                }
                else {
               		this.type_selected = this.$route.params.type
               	}

                this.contacts = await this.getAllContact(true)
                this.isLoadingContact = false

	            if(this.date_acte) this.mouvement_date = this.date_acte

	           	await this.preloadProvenance()

				// Si on modifie un mouvement, on preload le form
                if(this.mouvement_id) {
					// this.last_mouv_working = false
     //                this.tab_states["main_form"] = this.mouv_modif_title
     //                this.lieu_from_ca = true

                    // On va chercher les infos du mouvement, pour auto complete le formulaire
					const raw = await this.getMouvementById(this.mouvement_id)

					if(raw.mouvement_contact) {
	                    this.selected_contact = this.contacts.find(contact => contact.contact_id == raw.mouvement_contact)
	                }

	                this.extractMouvData(raw, 'lieu', true)
	                this.extractMouvData(raw, 'destination_lieu', true)

	                this.mouvement_date = raw.mouvement_date
					this.mouvement_raison = raw.mouvement_raison

					if(raw.mouvement_type == 1) {
            			this.type_selected = 'entree'
					}
					else if(raw.mouvement_type == 5) {
						this.type_selected = 'interne'
					}
					else {
						this.type_selected = 'sortie'
					}
				// 	// On regarde s'il s'agit d'un déplacement interne ou non
					if(raw.mouvement_intra_location) {
						this.only_intra_location = true
						this.intra_location = { 'raison': raw.mouvement_raison }
						this.mouvement_date = raw.mouvement_date
						
						const actual_location = await this.getIntraLocationsWithAllParents(raw.mouvement_intra_location)

						// On récuprère le lieu_id
						// const locations_flatten = Common.flattenBy(actual_location, 'parent')
						// const root_location = locations_flatten.find(loc => loc.intralocation_parent_type == 'lieu')
						// this.lieu = await this.getLieuById(root_location.intralocation_parent)

						this.selected_intra_location_id = raw.mouvement_intra_location
						this.prev_mouv_working = false
					}
				}
				// 	else {
				// 		// On va également récupérer en arrière plan, l'éventuel mouvement précédent
				// 		this.getPreviousMouvement(raw.mouvement_horse, raw.mouvement_id).then(async (result) => {
				// 			if(result) {
				// 				this.previous_mouv = Array.isArray(result) ? result[0] : result
				// 			}
				// 			else {
				// 				const init_prov = await this.getInitialProvenance(raw.mouvement_horse)

				// 				if(init_prov && init_prov.mouvement_label) {
				// 					this.origine_cheval = init_prov.mouvement_label
				// 				}
				// 			}
				// 		}).catch(e => {
				// 			console.log("WriteMouvement::init_component => error while executing getPreviousMouvement", e)
				// 		})
				// 		.finally(() => {
				// 			this.prev_mouv_working = false
				// 		})

				// 		this.extractMouvData(raw, 'lieu', true)
				// 		this.backupLieuResidence()
				// 	}
    //             }
				// // Si on est sur un cas d'ajout classique
				// else if(this.normalCase) {
				// 	this.prev_mouv_working = false
				// 	this.backupLieuResidence()

				// 	// S'il s'agit du tout premier mouvement
    //                 if(this.isFirstMouvement) {
    //                     this.lieu.lieu_type = 1
    //                 }

				// 	// Si on a un seul cheval
				// 	if(this.singleAddHorse) {
				// 		this.getLastMouvement(this.horses_ids[0])
				// 		.then(async (result) => {
				// 			if(!result) {
				// 				await this.preloadDestinationResidence()
				// 				return
				// 			}

				// 			if(Array.isArray(result)) {
				// 				result = result[0]
				// 			}
				// 			this.last_mouvement = result

    //                         // Dans le cas d'un ajout de mouvement, le mouvement précédent est le dernier enregistré
    //                         this.previous_mouv = result

				// 			// Si le dernier mouvement est une entrée, la proposition de la provenance n'est pas nécessaire puisque le mouvement saisit s'agira d'une sortie
				// 			if(result.mouvement_type == 1) return

				// 			// En revanche, si le mouvement précédent est une sortie, il s'agit alors actuellement d'une entrée. On preload la destination qui sera la résidence
    //                         await this.preloadDestinationResidence()

				// 			// On autocomplete la provenance par le mouvement précédent
				// 			this.extractMouvData(result, 'provenance_mouv')
    //                         this.extractMouvData(result, 'provenance_lieu')
				// 			this.backupMouvProvenance()
				// 			this.backupLieuProvenance()
				// 		})
				// 		.catch(e => {
				// 			console.log("WriteMouvement::init_component => ", e)
				// 		})
				// 		.finally(() => {
    //                         this.last_mouv_working = false
    //                     })
				// 	}
				// 	else {
				// 		this.last_mouv_working = false
				// 	}
				// }

				// if(!this.isOnlyIntraLocation) {
	   //              this.prepare_form()
				// }
				this.ready = true
            },

            async prepare_form() {
                // Si on vient de l'ajout de cheval, le lieu où se trouvera le cheval sera toujours une résidence. Donc lieu_type et mouvement_type à 1
                if(this.isAjoutChevalTunnel) {
                    this.lieu.lieu_type = 1
					await this.preloadDestinationResidence()

					this.prev_mouv_working = false
					this.last_mouv_working = false
                }
                else {
                    this.set_actual_status()
                }
            },

			async checkIfHorsesHaveSameMainResidence(currentHorseLocation) {
				let idToCompare = 0
				if (currentHorseLocation) {
					idToCompare = currentHorseLocation.horseresidence_lieu
				}
				for(const horseId of this.horses_ids) {
					const thisLocation = await this.getHorsePlaceAtDate(horseId, this.mouvement_date);
						if ( !(!thisLocation && idToCompare === 0) && (thisLocation && (thisLocation.horseresidence_lieu != idToCompare) || (idToCompare !== 0 && !thisLocation))) {
							return false
						} else if(!thisLocation && idToCompare === 0){
							return false
						}
					}
					return true;
			},

			async preloadDestinationResidence() {
				let done = false

				// Si on est sur un ajout simple, on preload la dernière résidence ayant servie d'entrée pour ce cheval
				if(this.singleAddHorse) {
					const last_resid = await this.getHorseLastResidence(this.horses_ids[0])
					
					if(last_resid && last_resid.length > 0) {
						this.lieu_from_ca = true
						this.ca_origine = 'lieu_mouvement'
						this.extractMouvData(last_resid[0], 'lieu')
						this.extractMouvData(last_resid[0], 'destination_lieu')
						done = true
					}
				}

				if(!done) {
					const default_lieu = await this.getDefaultLieu()

					if(default_lieu.length > 0) {
						this.lieu_from_ca = true
						this.ca_origine = 'lieu_mouvement'
						this.setObjectVars(default_lieu[0])
						done = true
					}
				}

				// Si pas d'entrée en résidence déjà effectuée, ou si plusieurs chevaux
				// On regarde si on a déjà des résidences, si oui, on auto complete avec la dernière saisie
				if(!done) {
					const last_resid = await this.getLastResidence()
					.catch(e => {
						console.log("WriteMouvement::prepare_form => error on getLastResidence:", e)
					})

					if(last_resid && last_resid.lieu_id != 0) {
						this.lieu_from_ca = true
						this.ca_origine = 'lieu_mouvement'
						this.setObjectVars(last_resid)
					}
				}
            
				this.backupLieuResidence()
			},

			async preloadProvenance() {
				if(this.singleAddHorse) {
					this.getLastMouvementSortie(this.horses_ids[0])
					.then(async (result) => {
						if(!result) return false
						this.extractMouvData(result, 'provenance_mouv')
			           	this.backupMouvProvenance()
						this.extractMouvData(result, 'provenance_lieu')
			           	this.backupLieuProvenance()
					})
				}
			},

			async checkActualResidence() {
				this.message_erreur_code = ""

				if(this.horses_ids.length === 1) {
					const actualResidence = await this.getHorsePlaceAtDate(this.horses_ids[0], this.mouvement_date)
            		if(actualResidence) {
	            		this.lieu = {
	            			lieu_id: actualResidence.mouvement_lieu,
	            			lieu_label: actualResidence.mouvement_label,
	            			lieu_adresse: actualResidence.mouvement_adresse,
							lieu_cp: actualResidence.mouvement_cp,
							lieu_ville: actualResidence.mouvement_ville,
							lieu_type: 1
	            		}
	            	} else {
						if(this.type_selected == "interne") {
							this.message_erreur_code = "mouvement.erreur_no_residence"						
						}
					}
				} else {
					const currentHorseLocation = await this.getHorsePlaceAtDate(this.horses_ids[0], this.mouvement_date)
					const sameResidence = await this.checkIfHorsesHaveSameMainResidence(currentHorseLocation);
					if(sameResidence && currentHorseLocation) {
						this.lieu = {
							lieu_id: currentHorseLocation.mouvement_lieu,
							lieu_label: currentHorseLocation.mouvement_label,
							lieu_adresse: currentHorseLocation.mouvement_adresse,
							lieu_cp: currentHorseLocation.mouvement_cp,
							lieu_ville: currentHorseLocation.mouvement_ville,
							lieu_type: 1
						}	
					} else {
						if(this.type_selected == "interne") {
							this.message_erreur_code = "mouvement.erreur_diff_residence"
						}
					}
				}
			},

			async validForm() {

				let formError = ""

				// Si on est dans un mouvement d'entrée et que pas de résidence (ou qu'on a que la résidence par défaut), pas de raison ou pas de date: message d'erreur
				if(this.type_selected == 'entree' && (!this.destination_lieu || this.destination_lieu.lieu_id == 0 || !this.mouvement_raison || !this.mouvement_date)) {
					formError = "formulaire.erreur_champs_non_remplis"
				}
				// Si message d'erreur, on ne valide rien
				if (this.message_erreur_code && this.message_erreur_code != "") {
					this.failureToast(this.message_erreur_code)
					return
				} else if (formError != "") {
					this.failureToast(formError)
					return
				}
                // On retourne un tableau d'objets avec les nouvelles valeurs
                let result = []

                // Si on vient de la fiche cheval et qu'on a l'origine du cheval
                if(this.isAjoutChevalTunnel && this.type_selected != 'interne') {
                    result.push({
                        type: 'provenance',
                        mouvement_label: this.provenance_mouv.lieu_label,
                        mouvement_date: '0000-00-00',
                        mouvement_raison: this.getTrad('mouvement.lieu_origine_cheval'),
                        mouvement_cp: this.provenance_mouv.lieu_cp,
                        mouvement_ville: this.provenance_mouv.lieu_ville,
                        mouvement_adresse: this.provenance_mouv.lieu_adresse,
                        mouvement_type: 3,
                        mouvement_lieu: null, // pas de lieu id sur une provenance
                        mouvement_contact: this.selected_contact ? this.selected_contact.contact_id : null,
                        mouvement_tiers: this.provenance_mouv.tiers_id,
                        horse_id: this.horse_id
                    })
                }

                // Si la provenance par défaut a été édité, cela signifie que le cheval a eu une autre sortie, entre la sortie enregistrée et l'entrée saisie
                if(this.askProvenance && this.hasChangeMouvProvenance && this.type_selected != 'interne') {
                    let provenance = {
                        type: 'provenance',
                        mouvement_label: this.provenance_mouv.lieu_label,
                        mouvement_date: this.mouvement_date,
                        mouvement_raison: this.getTrad('mouvement.lieu_origine_cheval'),
                        mouvement_cp: this.provenance_mouv.lieu_cp,
                        mouvement_ville: this.provenance_mouv.lieu_ville,
                        mouvement_adresse: this.provenance_mouv.lieu_adresse,
                        mouvement_type: 3,
                        mouvement_contact: this.selected_contact ? this.selected_contact.contact_id : null,
                        mouvement_tiers: this.provenance_mouv.tiers_id,
                        horse_id: this.horse_id
                    }

					// Si le LIEU renseigné pour la provenance ne vient pas du carnet d'adresse, on enregistre un nouveau lieu
					if(this.askProvenance && this.hasChangeLieuProvenance) {
						result.push({
							type: 'lieu',
							lieu_label: this.provenance_lieu.lieu_label,
							lieu_adresse: this.provenance_lieu.lieu_adresse,
							lieu_cp: this.provenance_lieu.lieu_cp,
							lieu_ville: this.provenance_lieu.lieu_ville,
                            lieu_type: 3,
                            lieu_tag: 'provenance'
						})
                        provenance.lieu_tag = 'provenance'
                    }

                    result.push(provenance)
				}

                // Si la résidence proposée, lors d'une entrée sur la résidence, a été modifié, on l'enregistre comme un nouveau lieu typé résidence
                if(this.hasChangeResidenceLieu) {
                    result.push({
                        type: 'lieu',
                        lieu_label: this.lieu.lieu_label,
                        lieu_adresse: this.lieu.lieu_adresse,
                        lieu_cp: this.lieu.lieu_cp,
                        lieu_ville: this.lieu.lieu_ville,
                        lieu_type: this.lieu.lieu_type ? this.lieu.lieu_type : 4,
                        lieu_tag: 'mouvement'
                    })
                }

                // Si on a une date retour
                if(this.date_retour) {
                    result.push({
                        type: 'mouvement_retour',
                        date_retour: this.date_retour,
                    })
				}

				// Le mouvement principal
				const mouv_to_save = this.compileMouvData()

				// Si on sauvegarde une modif de mouvement interne
				if(this.type_selected == 'interne') {
					const mouv_intra_update = this.compileIntraMouvData()
					result.push(mouv_intra_update)
				}
				// Si on a un déplacement interne (ajout uniquement)
				// else if(this.intra_location && !this.isOnlyIntraLocation) {
				// 	const intra_to_save = this.compileIntraMouvData()
				// 	result.push(intra_to_save)
				// }
				// Sinon c'est un mouvement classique - ajout / modif
				else {
					result.push(mouv_to_save)
				}

                result.push({
                    type: 'horses',
                    horses: this.horses_ids
                })

				this.can_edit = false
                this.valid_form(result)
            },

            displayCarnetAdresse() {
				this.ca_origine = 'lieu_mouvement'
                this.openCarnetAdresse()
            },

			displayCarnetAdresseProvenance() {
				this.ca_origine = 'provenance'
                this.openCarnetAdresse()
            },

			backFromCarnetAdresse(data) {
                this.lieu_from_ca = true
                this.setObjectVars(data, true)
            },

			backupLieuResidence() {
                // On garde une copie du lieu, pour savoir s'il a été modifié
                this.lieu_bak = JSON.stringify(this.lieu)
            },

			backupLieuProvenance() {
				// On garde une copie de la provenance (lieu), pour savoir si elle a été édité
				this.provenance_lieu_bak = JSON.stringify(this.provenance_lieu)
			},

			backupMouvProvenance() {
				// On garde une copie de la provenance (mouvement), pour savoir si elle a été édité
				this.provenance_mouv_bak = JSON.stringify(this.provenance_mouv)
			},

			editProposedLieu() {
                this.edit_lieu_ca = true
            },

			setObjectVars(data, from_ca=false) {
	            if(this.ca_origine == 'provenance') {
					this.extractLieuData(data, 'provenance_mouv')
					this.extractLieuData(data, 'provenance_lieu')
					if (from_ca) this.backupLieuProvenance()
				}
				else if(this.ca_origine == 'lieu_mouvement') {
	                this.lieu.lieu_id    = data.lieu_id
					this.lieu.lieu_label = data.lieu_label
	                this.lieu.lieu_adresse = data.lieu_adresse
	                this.lieu.lieu_cp      = data.lieu_cp
	                this.lieu.lieu_ville   = data.lieu_ville
	                this.lieu.lieu_type    = data.lieu_type
	                this.backupLieuResidence() // pour le formulaire de mouvement normal, on viendra toujours du CA
				}
				this.ca_origine = ''
	        },

			extractMouvData(mouvement, type, with_mouv_infos=false) {
                this[type].lieu_id      = mouvement.mouvement_lieu
                this[type].lieu_label   = mouvement.mouvement_label
                this[type].lieu_adresse = mouvement.mouvement_adresse
                this[type].lieu_cp     = mouvement.mouvement_cp
                this[type].lieu_ville  = mouvement.mouvement_ville
                this[type].lieu_type   = mouvement.mouvement_type
                this[type].tiers_id 	= mouvement.mouvement_tiers

                if(with_mouv_infos) {
                    this.mouvement_date   = mouvement.mouvement_date
                    this.mouvement_raison = mouvement.mouvement_raison
                }
            },

			extractLieuData(lieu, type) {
				this[type].lieu_id      = lieu.lieu_id
				this[type].lieu_label   = lieu.lieu_label
				this[type].lieu_adresse = lieu.lieu_adresse
				this[type].lieu_cp 	  	= lieu.lieu_cp
				this[type].lieu_ville   = lieu.lieu_ville
				this[type].lieu_type    = lieu.lieu_type
			},

			compileMouvData() {
                return {
                    'mouvement_date'  : this.mouvement_date,
                    'mouvement_raison': this.mouvement_raison,
                    'mouvement_label' : this.lieu.lieu_label,
                    'mouvement_adresse': this.lieu.lieu_adresse,
                    'mouvement_cp'     : this.lieu.lieu_cp,
                    'mouvement_ville'  : this.lieu.lieu_ville,
                    'mouvement_type': this.lieu.lieu_type,
                    'mouvement_lieu': this.lieu.lieu_id,
                    'mouvement_contact': this.selected_contact ? this.selected_contact.contact_id : null,
                    'mouvement_tiers': this.lieu.tiers_id,
                    'lieu_tag'      : 'mouvement',
                    'type'          : 'mouvement'
                }
			},
			
			compileIntraMouvData() {
				return {
					'mouvement_date': this.mouvement_date,
					'mouvement_raison': this.intra_location.raison,
					'mouvement_label': this.intra_location.intralocation_label,
					'mouvement_adresse': this.intra_location.mouvement_adresse,
					'mouvement_cp': this.intra_location.mouvement_cp,
					'mouvement_ville': this.intra_location.mouvement_ville,
                    'mouvement_type': 5,
                    'mouvement_lieu': this.lieu.lieu_id,
					'mouvement_intra_location': this.intra_location.intralocation_id, 
					'mouvement_contact': this.selected_contact ? this.selected_contact.contact_id : null,
					'type': this.mouvement_id ? 'mouvement' : 'intra_location' // si update, typé mouvement
				}
            },

            lieuChoosed(lieu) {
                this.lieu_from_ca = true
                this.setObjectVars(lieu, true)
            },

            async valid_form(data) {
				const horses = data.find(data => data.type == 'horses')
				if(this.sortie_definitive) {
					await Common.asyncForEach(horses.horses, async (id) => {
						await this.deleteHorseResidence(id)
					});
					if(this.new_stationnement){
						await Common.asyncForEach(horses.horses, async (id) => {
							await this.assignLieuStationnement(id, this.lieu.tiers_id)
						});
					}
				}
				
				const success = await this.processMouvOperations(data, horses.horses, this.mouvement_id)

				if(!success) {
					this.failureToast('toast.info_save_failed')
					return
				}

				this.successToast('toast.info_save_succes')
				this.getTrigger(data)
			},

			getTrigger(data) {
				const mouvement = data.find(data => data.type == 'mouvement' || data.type == 'intra_location')
				const horses = data.find(data => data.type == 'horses')

				if(this.type_selected == 'interne') {
					this.nextTrigger()
					return false
				}

				this.setupActPropositionMouvement(horses.horses, mouvement.mouvement_date, this.type_selected, this.date_retour, this.from_asset, (result) => {
					result.forEach(triggered => {
                        this.triggered_list.push({
                            type: triggered.type,
                            horses: triggered.horses,
                            date: triggered.date
                        })
                    })

                    this.shutterPanel().close('proposition-acte')
                    this.shutterPanel().close('horse-selection')
                    this.nextTrigger()
                },() => {
                	this.nextTrigger()
                })
			},

			nextTrigger() {
				if(this.code_onboarding) {
                    this.shutterPanel().close(this.code_onboarding + '_select')
                    this.shutterPanel().close(this.code_onboarding)
                }
				else {
					if(this.triggered_list.length > 0) {
						const triggered = this.triggered_list.shift()
						if(triggered.type == "pension_reouverture") {
							this.setupNewTriggered(triggered.horses, this.date_retour, triggered.type, this.horses_ids[0], this.triggered_list)
						} 
						else {
							this.setupNewTriggered(triggered.horses, triggered.date, triggered.type, this.horses_ids[0], this.triggered_list)
						}
					} else {
						if(this.horses_ids.length > 1) {
							this.$router.push({ name: 'mouvementListe' })
						}
						else {
							this.$router.push({ name: 'horseFiche', params: {horse_id: this.horses_ids[0]}})
						}
					}
				}
			},

			async getHorsesNames() {
				let horses_names = []
				await Common.asyncForEach(this.horses_ids, async(horse_id) => {
					let horse = await this.getHorseById(horse_id)
					horses_names.push(horse.horse_nom)
				})

				return horses_names.join(', ')
			},

			async resetLieux () {
				this.destination_lieu = { lieu_id: 0, lieu_label: '', lieu_adresse: '', lieu_cp: '', lieu_ville: '', lieu_type: '3' }
				this.lieu = { lieu_id: 0, lieu_label: '', lieu_adresse: '', lieu_cp: '', lieu_ville: '', lieu_type: '3' }
				this.lieu_bak = ''
				this.provenance_lieu = { lieu_id: 0, lieu_label: '', lieu_adresse: '', lieu_cp: '', lieu_ville: '', lieu_type: '' }
				this.provenance_lieu_bak = ''
				this.provenance_mouv = { lieu_id: 0, lieu_label: '', lieu_adresse: '', lieu_cp: '', lieu_ville: '', lieu_type: '' }
				this.provenance_mouv_bak = ''

				if(this.mare_id) {
					this.mare_ready = false
            		const mouvement_mare = await this.getHorseLastResidence(this.mare_id)
            		if(mouvement_mare) {
	            		this.destination_lieu = {
	            			lieu_id: mouvement_mare[0].mouvement_lieu,
	            			lieu_label: mouvement_mare[0].mouvement_label,
	            			lieu_adresse: mouvement_mare[0].mouvement_adresse,
							lieu_cp: mouvement_mare[0].mouvement_cp,
							lieu_ville: mouvement_mare[0].mouvement_ville,
							lieu_type: 1
	            		}
	            	}

            		this.origine_cheval = this.getTrad("horse.naissance")
					this.mouvement_raison = this.getTrad("horse.naissance")
					this.mare_ready = true
            	}

				if(this.type_selected == 'interne') {
					await this.checkActualResidence()
				}

            	await this.preloadProvenance()
			}
		},
		computed: {
            isAjoutChevalTunnel: function() {
                return this.from_asset == "horseAjout" || this.from_asset == "HorseCreation" || this.from_asset == "SearchSire" || this.from_asset == "SearchWeatherbys"
            },
			normalCase() {
				return !this.isAjoutChevalTunnel// && !this.mouvement_id
			},
			singleAddHorse() {
				return this.horses_ids.length == 1 && this.horses_ids[0] !== -1
			},
			isOnlyIntraLocation() {
				return this.only_intra_location === true
			},
			askProvenance() {
				const cond_prev_mouv = this.provenance_lieu && this.provenance_lieu.lieu_type != '' && this.provenance_lieu.lieu_type !== 1
				return this.lieu.lieu_type == 1 && this.normalCase && this.singleAddHorse && cond_prev_mouv
			},
			askIntraLocation() {
				// lieu type 1 (résidence), en dehors de la modif de mouvement, et si le lieu n'a pas été modifié
				return this.lieu.lieu_type == 1 && !this.mouvement_id && !this.hasChangeResidenceLieu
			},
			hasChangeMouvProvenance() {
				// if(this.provenance_mouv_bak == '') return false
				if(this.provenance_mouv_bak != JSON.stringify(this.provenance_mouv)) return true

				return false
			},
			hasChangeLieuProvenance() {
				if(this.provenance_lieu_bak == '') return false
				if(this.provenance_lieu_bak != JSON.stringify(this.provenance_lieu) && !this.provenance_lieu.tiers_id) return true

				return false
			},
			hasChangeResidenceLieu() {
                if(this.lieu_bak == '') return false
				if(this.lieu_bak != JSON.stringify(this.lieu)) return true

                return false
            },
            isFirstMouvement: function() { // S'il s'agit du premier mouvement du cheval (en dehors de la provenance)
				return !this.previous_mouv && this.singleAddHorse
			},
			isWorking() {
				return false
				// return this.prev_mouv_working || this.last_mouv_working || this.horse_resid_working
			},
			// mouvLieuId() {
			// 	return this.lieu ? this.lieu.lieu_id : 0
			// }
		},
        asyncComputed: {
            can_define_retour: async function() {
                // Si on définit un mouvement vers une résidence, pas de date de retour possible
                if(this.lieu.lieu_type == 1) return false

                // Si on édit un mouvement, on ne peut pas définir de date de retour
                if(this.mouvement_id) return false

                // Si on est sur une séléction de plusieurs chevaux, on ne peut pas définir de date de retour (chaque cheval peut avoir un lieu actuel différent)
                // On regarde si le cheval est déjà dans une résidence. Si non, pas de date de retour possible pour le mouvement à insérer (le cheval ne peut pas avoir de date retour si le mouvement précédent est un concours, par ex)
                if(this.$route.params.horse_id != undefined) {
					if(this.horses_ids.length != 1) {
						return false
					}

					// Si plusieurs chevaux de sélectionnés
					if(this.horses_ids[0] !== -1) {
						// const horse_residence = await this.getHorseMainResidence(this.horses_ids[0])
						const horse_residence = await this.getHorseLastResidence(this.horses_ids[0])
						if(!horse_residence) {
							return false
						}
					}
                }
                return true
            }
        },
        watch: {
            'state' (val) {
                // Si l'état actuel correspond au formulaire d'ajout de mouvement, on push la route
                if(val == 'carnet_adresse') {
					const only_lieux = this.ca_origine == 'provenance'
					const only_resid = this.isAjoutChevalTunnel
                    this.$router.push({ name: 'lieu_choice_mv', params: { lieu_id: this.lieu_id, only_lieux: only_lieux, only_residence: only_resid } })
                }
            },
            '$route' (to, from) {
                // Si on revient dans la vue mère (via le goBack())
                if(to.name == this.$options.name) {
                    this.set_last_state()
                }
            },
            'can_define_retour' (val, prev) {
                if(!val) {
                    this.date_retour_checked = false
                }
				this.horse_resid_working = false
            },
			'isFirstMouvement' (val) {
                // Si on est sur une modification de mouvement, on garde le type du lieu
                if(this.mouvement_id) return

                // S'il s'agit du tout premier mouvement
                if(val) {
                    this.lieu.lieu_type = 1
                }
                else {
                    // Si le mouvement précédent est une entrée, l'actuel sera donc une sortie
                    if(this.previous_mouv && this.previous_mouv.mouvement_type == 1) {
                        this.lieu.lieu_type = 3
                    }
                    else if(this.singleAddHorse) {
                        this.lieu.lieu_type = 1
                    }
                }
            },
			'provenance_lieu': {
				handler(val) {
					this.provenance_mouv = val
				},
				deep: true
			},
			'destination_lieu': {
				handler(val) {
					this.lieu = val
				},
				deep: true
			},
			'mouvement_date':{
				handler(val) {
					if(this.type_selected == 'interne') {
						this.checkActualResidence()
					}
				}
			}
        },
        components: {
			ErrorAlert: () => import('GroomyRoot/components/Alert/ErrorAlert'),
            LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
			AskProvenance: () => import('@/components/Mouvements/AskProvenance'),
			AskIntraLocation: () => import('@/components/Mouvements/AskIntraLocation'),
			WriteEntree: () => import('@/components/Mouvements/WriteEntree'),
			WriteSortie: () => import('@/components/Mouvements/WriteSortie'),
			WriteInterne: () => import('@/components/Mouvements/WriteInterne')
        }
	};
</script>