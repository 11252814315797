<template>
	<div>
		<LoadingSpinner v-if="loading" />
		<table v-else class="table table-hover table-sm mb-0 w-100">
			<tr>
				<th>{{ $t('monte.congelation.protocole_label') }}</th>
				<th>{{ $t("monte.congelation.campagne_start") }}</th>
				<th>{{ $t('monte.congelation.campagne_end') }}</th>
				<th>{{ $t('monte.congelation.campagne_resident') }}</th>
				<th>{{ $t("monte.congelation.campagne_centre") }}</th>
				<th>{{ $t("monte.congelation.campagne_entree") }}</th>
				<th>{{ $t("monte.congelation.campagne_sortie") }}</th>
				<th>{{ $t("monte.congelation.campagne_closed") }}</th>
				<th class="text-right"><b-button variant="primary" @click.prevent="openAddCampagne">{{ $t('global.ajouter') }} <font-awesome-icon :icon="['fal', 'plus']" /></b-button></th>
			</tr>
			<tr v-for="campagne in campagnes" :key="campagne.campagne_id">
				<td>{{ campagne.protocoles_label }}</td>
				<td>{{ formatDate(campagne.campagne_start) }}</td>
				<td><template v-if="campagne.campagne_end">{{ formatDate(campagne.campagne_end) }}</template></td>
				<td>{{ campagne.campagne_resident_label }}</td>
				<td><router-link v-if="campagne.centre" :to="{ name: 'tiersFiche', params: { tiers_id: campagne.centre.tiers_id }}">{{ campagne.centre.tiers_rs }} <template v-if="campagne.centre.numero">({{ campagne.centre.numero }})</template></router-link></td>
				<td><template v-if="campagne.campagne_entree">{{ formatDate(campagne.campagne_entree) }}</template></td>
				<td><template v-if="campagne.campagne_sortie">{{ formatDate(campagne.campagne_sortie) }}</template></td>
				<td>{{ campagne.campagne_closed_label }}</td>
				<td class="text-right">
					<b-button v-if="!campagne.campagne_closed" variant="secondary" class="mr-1" @click.prevent="openModalCloture(campagne)">{{ $t('action.cloturer') }} <font-awesome-icon :icon="['fal', 'times']" /></b-button>
					<b-button variant="secondary" class="mr-1" @click.prevent="previewCampagne(campagne)">{{ $t('action.voir') }} <font-awesome-icon :icon="['fal', 'eye']" /></b-button>
					<b-button variant="secondary" class="mr-1" @click.prevent="openModalCourrier(campagne)">{{ $t('action.courrier_only') }} <font-awesome-icon :icon="['fal', 'file-alt']" /></b-button>
					<b-button variant="secondary" @click.prevent="openEditcampagne(campagne)">{{ $t('global.modifier') }} <font-awesome-icon :icon="['fal', 'angle-right']" /></b-button>
				</td>
			</tr>
		</table>

		<b-modal ref="modal-campagne" hide-footer size="xl">
			<template v-if="!form.campagne_id" v-slot:modal-title>
				{{ $t('monte.congelation.add_campagne') }}
			</template>
			<template v-else v-slot:modal-title>
				{{ $t('monte.congelation.edit_campagne') }}
			</template>

			<div class="row">
				<div class="col-6 form-group">
					<label for="campagne_start">{{ $t("monte.congelation.campagne_start") }} *</label>
					<e-datepicker v-model="form.campagne_start" id="campagne_start" />
				</div>
				<div class="col-6 form-group">
					<label for="campagne_end">{{ $t("monte.congelation.campagne_end") }}</label>
					<e-datepicker v-model="form.campagne_end" id="campagne_end" />
				</div>
				<div class="col-6 form-group">
					<label for="campagne_entree">{{ $t("monte.congelation.campagne_entree") }}</label>
					<e-datepicker type="number" v-model="form.campagne_entree" id="campagne_entree" />
				</div>
				<div class="col-6 form-group">
					<label for="campagne_sortie">{{ $t("monte.congelation.campagne_sortie") }}</label>
					<e-datepicker type="number" v-model="form.campagne_sortie" id="campagne_sortie" />
				</div>
				<div class="col-6 form-group">
					<label for="campagne_resident">{{ $t("monte.congelation.campagne_resident") }}</label>
					<b-form-checkbox v-model="form.campagne_resident" id="campagne_resident" />
				</div>
				<div class="col-6 form-group">
					<label for="campagne_centre">{{ $t("monte.congelation.campagne_centre") }} *</label>
					<e-select
						id="campagne_centre"
						track-by="tiers_id"
						label="tiers_rs"
						:placeholder="$t('monte.congelation.selectionner_centre')"
						:options="tiers_centre"
						:searchable="true"
						:show-labels="false"
						v-model="form.campagne_centre"
					>
						<template slot="option" slot-scope="{ option }">{{ option.tiers_rs }} <template v-if="option.numero">({{ option.numero }})</template></template>
						<template slot="singleLabel" slot-scope="{ option }">{{ option.tiers_rs }} <template v-if="option.numero">({{ option.numero }})</template></template>
						<template slot="noOptions">{{ $t('global.list_empty') }}</template>
					</e-select>
				</div>
			</div>
			<table class="table table-hover table-sm mb-0">
				<tr>
					<td>{{ $t('monte.congelation.protocole_label') }}</td>
					<td>{{ $t('monte.congelation.campagne_start') }}</td>
					<td>{{ $t('monte.congelation.campagne_end') }}</td>
					<td class="text-right"><b-button @click.prevent="addProtocole"><font-awesome-icon :icon="['fal', 'plus']" /></b-button></td>
				</tr>
				<tr v-for="(protocole, index) in protocole_campagne" :key="index">
					<td>
						<e-select
							v-model="protocole.protocole"
							id="protocole_id"
							track-by="protocole_id"
							label="protocole_label"
							:placeholder="$t('monte.congelation.selectionner_protocole')"
							:selectedLabel="$t('global.selected_label')"
							:options="protocoles_list"
							:searchable="true"
							:allow-empty="false"
							:show-labels="false"
							:group-select="false"
							@input="updateProtocole(index)"
						>
							<template slot="noOptions">{{ $t('global.list_empty') }}</template>
						</e-select>
					</td>
					<td><e-datepicker v-model="protocole.start" /></td>
					<td><e-datepicker v-model="protocole.end" /></td>
					<td class="text-right"><b-button @click.prevent="removeProtocole"><font-awesome-icon :icon="['fal', 'trash-alt']" /></b-button></td>
				</tr>
			</table>

			<div class="text-center mt-3">
				<b-button variant="primary" @click.prevent="checkForm">{{ $t('global.enregistrer') }} <font-awesome-icon v-if="processing" :icon="['fal', 'spinner']" pulse /><font-awesome-icon v-else :icon="['fal', 'save']" /></b-button>
				<b-button v-if="form && form.campagne_id" variant="secondary" class="ml-2" @click.prevent="openConfirmDelete">{{ $t('global.supprimer') }} <font-awesome-icon :icon="['fal', 'trash-alt']" /></b-button>
			</div>
		</b-modal>

		<b-modal ref="modal-close-campagne" hide-footer>
			<template v-slot:modal-title>
				{{ $t('monte.congelation.confirm_close_campagne') }}
			</template>

			 <template slot="default">
			 	<div class="row" v-if="campagne">
					<div class="col-6 form-group">
						<label for="campagne_end">{{ $t("monte.congelation.campagne_end") }} *</label>
						<e-datepicker v-model="campagne.campagne_end" id="campagne_end" />
					</div>
					<div class="col-6 form-group">
						<label for="delete_acte">{{ $t("monte.congelation.delete_acte") }}</label>
						<b-form-checkbox v-model="campagne.delete_acte" id="delete_acte" />
					</div>
					<div class="col-12">{{ text_message }}</div>
					<div class="col-12 text-center">
			            <b-button variant="primary" @click="confirmCloseCampagne">
			                <font-awesome-icon v-if="processing_close" :icon="['fas', 'spinner']" pulse />
			                <font-awesome-icon v-else :icon="['fal', 'times']" class="mr-1"/>{{ $t('action.cloturer') }}
			            </b-button>
					</div>
			 	</div>
	        </template>
		</b-modal>

		<b-modal ref="modal-courrier-campagne" hide-footer>
			<template v-slot:modal-title>
				<template>
					{{ $t("monte.congelation.courrier_campagne") }}
				</template>
			</template>

			<div v-if="campagne" class="row">
				<div class="col-12">
					<div class="form-group">
						<label for="modeltype_label">{{ $t('model.modeltype_label') }} *</label>
						<e-select
							v-model="template"
							id="model_id"
							track-by="model_label"
							label="model_label"
							:placeholder="$t('horse.search_doc')"
							:deselectLabel="$t('global.press_enter_to_remove')"
							:selectedLabel="$t('global.selected_label')"
							:options="templates"
							:searchable="true"
							:allow-empty="false"
							:show-labels="false"
						/>
					</div>

					<div class="form-group">
						<label for="protocole">{{ $t('monte.congelation.protocole') }}</label>
						<e-select
							id="protocole"
							track-by="campagneprotocole_id"
							label="campagneprotocole_id"
							:placeholder="$t('monte.congelation.selectionner_protocole')"
							:options="campagne.protocoles"
							:searchable="true"
							:show-labels="false"
							v-model="protocole"
						>
							<template slot="option" slot-scope="{ option }">{{ option.protocole.protocole_label }}</template>
							<template slot="singleLabel" slot-scope="{ option }">{{ option.protocole.protocole_label }}</template>
							<template slot="noOptions">{{ $t('global.list_empty') }}</template>
						</e-select>
					</div>

					<div class="form-group">
						<label for="with_notes">{{ $t('monte.congelation.courrier_with_notes') }}</label>
						<b-form-checkbox id="with_notes" v-model="with_notes"></b-form-checkbox>
					</div>
				</div>
				<div class="col-12 text-center">
					<b-button class="mt-1 rounded-pill" variant="primary" @click="printCourrier">{{ $t("global.print") }} <font-awesome-icon v-if="processing_courrier" :icon="['fas', 'spinner']" pulse  class="mr-1"/></b-button>
				</div>
			</div>
		</b-modal>
	</div>
</template>

<script type="text/javascript">
	import Congelation from "@/mixins/Congelation.js"
	import Shutter from '@/mixins/Shutter.js'
	import ShutterCongelation from "@/mixins/shutters-manager/Congelation.js"
	import Model from "@/mixins/Model.js"
	import _cloneDeep from 'lodash/cloneDeep'

	export default {
		name: 'StallionCampagneListe',
		mixins: [Congelation, Shutter, ShutterCongelation, Model],
		props: ['horse_id'],
		data () {
			return {
				loading: false,
				processing: false,
				campagnes: [],
				protocoles: [],
				protocoles_list: [],
				protocole_campagne: [],
				tiers_centre: [],
				form: {
					campagne_id: null,
					campagne_start: new Date(),
					campagne_horse: null,
					campagne_end: null,
					campagne_entree: null,
					campagne_sortie: null,
					campagne_resident: false,
					campagne_centre: null
				},
				campagne: null,
				processing_close: false,
				templates: [],
				template: null,
				protocole: null,
				processing_courrier: false,
				with_notes: false
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				await this.load_campagnes()
				this.protocoles = await this.getAllProtocoles()
				this.tiers_centre = await this.loadCentreCollecte()
			},

			async load_campagnes() {
				this.loading = true
				const campagnes = await this.getCampagnesByHorse(this.horse_id)
				campagnes.forEach(campagne => {
					campagne.campagne_resident_label = campagne.campagne_resident ? this.getTrad('global.oui') : this.getTrad('global.non')
					campagne.campagne_closed_label = campagne.campagne_closed ? this.getTrad('global.oui') : this.getTrad('global.non')
				})
				this.campagnes = campagnes
				this.loading = false
			},
 
			openAddCampagne() {
				this.form = {
					campagne_id: null,
					campagne_start: new Date(),
					campagne_end: null,
					campagne_entree: null,
					campagne_sortie: null,
					campagne_resident: false,
					campagne_centre: null
				}
				this.protocole_campagne = []
				this.protocoles_list = this.protocoles.filter(pro => !pro.protocole_archive)
				this.$refs['modal-campagne'].show()
			},

			openEditcampagne(campagne) {
				this.form = {
					campagne_id: campagne.campagne_id,
					campagne_start: new Date(campagne.campagne_start),
					campagne_end: campagne.campagne_end ? new Date(campagne.campagne_end) : null,
					campagne_entree: campagne.campagne_entree ? new Date(campagne.campagne_entree) : null,
					campagne_sortie: campagne.campagne_sortie ? new Date(campagne.campagne_sortie) : null,
					campagne_resident: campagne.campagne_resident,
					campagne_horse: campagne.campagne_horse,
					campagne_centre: null
				}

				if(campagne.campagne_centre) {
					this.form.campagne_centre = this.tiers_centre.find(tiers => tiers.tiers_id == campagne.campagne_centre)
				}

				this.protocole_campagne = campagne.protocoles.map(p => ({
					start: new Date(p.campagneprotocole_start),
					end: p.campagneprotocole_end ? new Date(p.campagneprotocole_end) : null,
					protocole: this.protocoles.find(pro => pro.protocole_id == p.campagneprotocole_protocole)
				}))
				this.protocoles_list = this.protocoles

				this.$refs['modal-campagne'].show()
			},

			addProtocole() {
				this.protocole_campagne.push({
					start: this.form.campagne_start,
					end: null,
					protocole: null
				})
			},

			removeProtocole(protocole) {
				this.protocole_campagne.splice(this.protocole_campagne.findIndex(pro => pro == protocole), 1)
			},

			updateProtocole(index) {
				const date = _cloneDeep(this.form.campagne_start)
				this.protocole_campagne[index].start = new Date(date.setDate(date.getDate() + this.protocole_campagne[index].protocole.protocole_start))
			},

			async checkForm() {
				if(!this.form.campagne_centre) {
					this.failureToast('formulaire.erreur_champs_non_remplis')
					return false
				}
				this.processing = true
				const protocoles = this.protocole_campagne.map(a => ({protocole: a.protocole.protocole_id, start: a.start, end: a.end}))
				const form = {...this.form, campagne_centre: this.form.campagne_centre ? this.form.campagne_centre.tiers_id : null}
				if(this.form.campagne_id) {
					await this.editCampagne(this.horse_id, this.form.campagne_id, form, protocoles)
				}
				else {
					await this.createCampagne(this.horse_id, form, protocoles)
				}
				this.processing = false
				this.$refs['modal-campagne'].hide()
				this.load_campagnes()
				this.shutterPanel().keepFirst()
			},

			previewCampagne(campagne) {
				this.setUpCampagneStallion(campagne)
			},

			openModalCloture(campagne) {
				this.campagne = campagne
				if(!this.campagne.campagne_end) {
					this.campagne.campagne_end = new Date()
				}
				this.campagne.delete_acte = false
				this.$refs['modal-close-campagne'].show()
			},

			async confirmCloseCampagne() {
				this.processing_close = true
				await this.closeCampagne(this.campagne)
				this.processing_close = false
				this.$refs['modal-close-campagne'].hide()
				this.load_campagnes()
			},

			async openModalCourrier(campagne) {
				if(this.templates.length == 0) {
					this.templates = await this.loadAllModel(0, ['semence_campagne'])
				}
				this.campagne = campagne
				this.$refs['modal-courrier-campagne'].show()
			},

			async printCourrier() {
				if(!this.template) {
					this.failureToast('formulaire.erreur_champs_non_remplis')
					return false
				}
				const campagneprotocole_id = this.protocole ? this.protocole.campagneprotocole_id : null
				const protocole_label = this.protocole ? this.protocole.protocole.protocole_label : this.getTrad('monte.congelation.campagne')

				this.processing_courrier = true
				await this.printCourrierCampagne(this.campagne.campagne_horse, this.campagne.campagne_id, this.template.model_id, campagneprotocole_id, protocole_label, this.with_notes)
				this.processing_courrier = false
				this.$refs['modal-courrier-campagne'].hide()
				this.template = null
				this.protocole = null
				this.campagne = null
			},

			openConfirmDelete() {
				this.setUpDeleteCapagne(this.form)
				this.$refs['modal-campagne'].hide()
			}
		},

		computed: {
			text_message() {
				if(!this.campagne.last_lot) return ''

				let date1 = new Date(this.campagne.last_lot.semencelot_date)
				let date2 = new Date(this.campagne.campagne_end)
                date1.setHours(0,0,0,0);
                date2.setHours(0,0,0,0);
                let Difference_In_Time = date2.getTime() - date1.getTime(); 
                let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24); 
				return this.getTrad("monte.congelation.close_campagne_day", { nb_jours: Math.round(Difference_In_Days) })
			}
		},

		components: {
            LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
            CampagnePreview: () => import('@/components/Congelation/CampagnePreview'),
			ModalConfirm: () => import('GroomyRoot/components/Modals/ModalConfirm')
		}
	}

</script>