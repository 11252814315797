<template>
	<div class="row">
		<div class="col-6 form-group">
			<label for="qualite">{{ $t("monte.congelation.qualite") }}</label>
			<input class="form-control" type="text" v-model="form.qualite" id="qualite">
		</div>
		<div class="col-6 form-group">
			<label for="motilite">{{ $t("monte.congelation.motilite") }}</label>
			<input class="form-control" type="text" v-model="form.motilite" id="motilite">
		</div>
		<div class="col-6 form-group">
			<label for="survie">{{ $t("monte.congelation.survie") }}</label>
			<input class="form-control" type="text" v-model="form.survie" id="survie">
		</div>
		<div class="col-6 form-group">
			<label for="comptage">{{ $t("monte.congelation.comptage") }}</label>
			<input class="form-control" type="text" v-model="form.comptage" id="comptage">
		</div>
		<div class="col-6 form-group">
			<label for="statut">{{ $t("monte.congelation.statut") }} *</label>
			<e-select
				v-model="form.statut"
				id="statut"
				:placeholder="$t('monte.congelation.selectionnez_statut')"
				:selectedLabel="$t('global.selected_label')"
				:options="all_statut"
				:searchable="true"
				:allow-empty="false"
				:show-labels="false"
			>
				<template slot="option" slot-scope="{ option }">{{ $t('monte.congelation.statuts.'+option) }}</template>
				<template slot="singleLabel" slot-scope="{ option }">{{ $t('monte.congelation.statuts.'+option) }}</template>
				<template slot="noOptions">{{ $t('global.list_empty') }}</template>
			</e-select>
		</div>
		<div class="col-6 form-group">
			<label for="commentaire">{{ $t("monte.congelation.commentaire") }}</label>
			<input class="form-control" type="text" v-model="form.commentaire" id="commentaire">
		</div>

		<div class="col-12 text-center">
			<a class="btn btn-primary mt-3" href="#" @click.prevent="checkForm">{{ $t('global.enregistrer') }} <font-awesome-icon v-if="processing" :icon="['fal', 'spinner']" pulse /><font-awesome-icon v-else :icon="['fal', 'save']" /></a>
		</div>
	</div>
</template>

<script type="text/javascript">
	import Congelation from "@/mixins/Congelation.js"
	import Shutter from '@/mixins/Shutter.js'

	export default {
		name: 'EditControlLot',
		mixins: [Congelation, Shutter],
		props: ['control'],
		data () {
			return {
				form: {
					qualite: '',
					motilite: '',
					survie: '',
					comptage: '',
					commentaire: '',
					statut: null
				},
				all_statut: ['apte', 'inapte', 'doute', 'detruit'],
				processing: false,
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				this.form = {
					qualite: this.control.semencecontrol_qualite,
					motilite: this.control.semencecontrol_motilite,
					survie: this.control.semencecontrol_survie,
					comptage: this.control.semencecontrol_comptage,
					commentaire: this.control.semencecontrol_commentaire,
					statut: this.control.semencecontrol_statut
				}
			},

			async checkForm() {
				this.processing = true
				await this.editControlLot(this.control.semencecontrol_id, this.form)
				this.processing = false
				this.ok()
			}
		}
	}

</script>