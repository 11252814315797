<template>
	<div>
		<h3>{{ $t('monte.congelation.reintegration_cuve') }} {{ localisation.semencelocalisation_libelle }}</h3>

		<div class="row">
			<LoadingSpinner v-if="loading" class="col-12" />
			<div v-else class="col-12">
				<table class="table table-hover table-sm mb-0">
					<tr>
						<td>{{ $t('monte.stock_semence.numero_lot') }}</td>
						<td>{{ $t('horse.etalon') }}</td>
						<td>{{ $t('monte.congelation.nb_paillettes') }}</td>
						<td>{{ $t('monte.congelation.cuve_stockage') }}</td>
					</tr>
					<tr v-for="emplacement in emplacements" :key="emplacement.emplacementcongelation_id">
						<td>{{ emplacement.lot.semencelot_quantieme }}</td>
						<td>{{ emplacement.lot.horse.horse_nom }}</td>
						<td>{{ emplacement.emplacementcongelation_qte }}</td>
						<td>
							<e-select
								id="stockage"
								v-model="emplacement.localisation"
								track-by="full_name"
								label="full_name"
								:placeholder="$t('monte.stock_semence.rechercher_categorie')"
								:selectedLabel="$t('global.selected_label')"
								:options="all_localisations"
								:searchable="true"
								:show-labels="false"
								:sortable="false"
								@input="reloadEmplacements"
							>
								<template slot="option" slot-scope="props"><span :style="`padding-left: ${25 * (props.option.indent || 0) }px;`">{{ props.option.full_name }}</span></template>
							</e-select>
						</td>
					</tr>
				</table>

				<div class="text-center">
					<a class="btn btn-primary mt-3" href="#" @click.prevent="checkForm">{{ $t('global.enregistrer') }} <font-awesome-icon v-if="processing" :icon="['fal', 'spinner']" pulse /><font-awesome-icon v-else :icon="['fal', 'save']" /></a>
				</div>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import Congelation from "@/mixins/Congelation.js"
	import StockSemence from "@/mixins/StockSemence.js"
	import Shutter from '@/mixins/Shutter.js'

	export default {
		name: 'ReintegrerExpedition',
		mixins: [Congelation, StockSemence, Shutter],
		props: ['expeditions'],
		data () {
			return {
				localisation: {},
				emplacements: [],
				loading: false,
				all_localisations: [],
				processing: false
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				const localisation = await this.getLocalisationSemenceById(this.expeditions[0].semenceexpedition_cuve)
				this.localisation = localisation[0]

				const all_localisations = await this.getLocalisationSemenceFullName()
				this.all_localisations = all_localisations.filter(loc => !loc.semencelocalisation_prestockage && !loc.semencelocalisation_transport && loc.semencelocalisation_is_floor)

				this.loading = true
				this.emplacements = await this.getReintegrationStocks(this.expeditions[0].semenceexpedition_cuve)
				this.emplacements.forEach(empl => {
					const loc = this.all_localisations.find(loc => loc.semencelocalisation_id == empl.emplacementcongelation_from_localisation)
					empl.localisation = loc ?? {}
				})
				this.loading = false
			},

			reloadEmplacements() {
				this.loading = true
				this.$nextTick(()=> {
					this.loading = false
				})
			},

			async checkForm() {
				this.processing = true
				const emplacements = this.emplacements.map(empl => ({
					emplacement_id: empl.emplacementcongelation_id,
					localisation_id: empl.localisation.semencelocalisation_id
				}))
				await this.reintegreStocks(this.expeditions[0].semenceexpedition_cuve, emplacements)
				this.processing = false
				this.successToast('toast.info_save_succes')
				this.ok()
				this.shutterPanel().close('expedition-reintegrer')
			}
		},

		components: {
			LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35')
		}
	}

</script>