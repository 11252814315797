import Shutter from '@/mixins/Shutter.js'

export default {
    mixins: [Shutter],
    methods: {
        getCongelationShutters(props) {
            return {
                'prestockage': {
                    name: 'prestockage',
                    title: this.$t('menu.congelation.prestockage'),
                    cmpPath: 'components/Congelation/PrestockageListe',
                    props
                },
                'add-control-lot': {
                    name: 'add-control-lot',
                    title: this.$t('monte.congelation.add_controle_qualite'),
                    cmpPath: 'components/Congelation/AddControlLot',
                    props,
                    onOk: this.reload,
                },
                'controls-lot': {
                    name: 'controls-lot',
                    title: this.$t('monte.congelation.controle_qualite'),
                    cmpPath: 'components/Congelation/ControlsLot',
                    props,
                    onOk: this.reload,
                },
                'stallion-campagne-liste': {
                    name: 'stallion-campagne-liste',
                    title: this.$t('monte.congelation.liste_campagnes'),
                    cmpPath: 'components/Congelation/StallionCampagneListe',
                    props
                },
                'campagne-preview': {
                    name: 'campagne-preview',
                    title: this.$t('monte.congelation.campagne'),
                    cmpPath: 'components/Congelation/CampagnePreview',
                    props
                },
                'edit-lot': {
                    name: 'edit-lot',
                    title: this.$t('monte.congelation.edit_lot'),
                    cmpPath: 'components/Congelation/EditLot',
                    props,
                    onOk: this.reload
                },
                'delete-campagne': {
                    name: 'delete-campagne',
                    title: this.$t('monte.congelation.delete_campagne'),
                    cmpPath: 'components/Congelation/DeleteCampagne',
                    props,
                    onOk: this.load_campagnes
                },
                'stallion-stock-liste': {
                    name: 'stallion-stock-liste',
                    title: this.$t('monte.congelation.stocks'),
                    cmpPath: 'components/Congelation/StallionStocksListe',
                    props
                },
                'emplacement-lot': {
                    name: 'emplacement-lot',
                    title: this.$t('monte.congelation.emplacements'),
                    cmpPath: 'components/Congelation/EmplacementLot',
                    props,
                    onOk: this.reload
                },
                'emplacement-detail': {
                    name: 'emplacement-detail',
                    title: this.$t('monte.congelation.emplacement_detail'),
                    cmpPath: 'components/Congelation/EmplacementDetail',
                    props,
                    onOk: this.reload
                },
                'add-emplacement': {
                    name: 'add-emplacement',
                    title: this.$t('monte.congelation.emplacement_add'),
                    cmpPath: 'components/Congelation/AddEmplacement',
                    props,
                    onOk: this.reload
                },
                'stallion-plan-cuve': {
                    name: 'stallion-plan-cuve',
                    title: this.$t('monte.congelation.plan_cuve'),
                    cmpPath: 'components/Congelation/StallionPlanCuveListe',
                    props
                },
                'stallion-plan-cuve-detail': {
                    name: 'stallion-plan-cuve-detail',
                    title: this.$t('monte.congelation.plan_cuve_detail'),
                    cmpPath: 'components/Congelation/StallionPlanCuveDetail',
                    props
                },
                'import-paillettes': {
                    name: 'import-paillettes',
                    title: this.$t('monte.congelation.import_paillettes'),
                    cmpPath: 'components/Congelation/ImportPaillettes',
                    props,
                    onOk: this.load_stocks
                },
                'commande-list': {
                    name: 'commande-list',
                    title: this.$t('monte.congelation.expedition'),
                    cmpPath: 'components/Congelation/CommandeList',
                    props
                },
                'commande-add': {
                    name: 'commande-add',
                    title: this.$t('monte.congelation.add_expedition'),
                    cmpPath: 'components/Congelation/AddCommande',
                    props,
                    onOk: this.load_expeditions
                },
                'expedition-edit': {
                    name: 'expedition-edit',
                    title: this.$t('monte.congelation.edit_expedition'),
                    cmpPath: 'components/Congelation/AddCommande',
                    props,
                    onOk: this.load_expeditions
                },
                'expedition-preparation': {
                    name: 'expedition-preparation',
                    title: this.$t('monte.congelation.preparation_expedition'),
                    cmpPath: 'components/Congelation/PreparationExpedition',
                    props,
                    onOk: this.load_expeditions
                },
                'expedition-reintegrer': {
                    name: 'expedition-reintegrer',
                    title: this.$t('monte.congelation.reintegrer_expedition'),
                    cmpPath: 'components/Congelation/ReintegrerExpedition',
                    props,
                    onOk: this.load_expeditions
                },
                'expedition-list': {
                    name: 'expedition-list',
                    title: this.$t('monte.congelation.expedition'),
                    cmpPath: 'components/Congelation/ExpeditionList',
                    props
                },
                'delete-floors': {
                    name: 'delete-floors',
                    title: this.$t('monte.congelation.delete_floors'),
                    cmpPath: 'components/Congelation/DeleteFloors',
                    props,
                    onOk: this.reload
                },
                'edit-control-lot': {
                    name: 'edit-control-lot',
                    title: this.$t('monte.congelation.edit_controle_qualite'),
                    cmpPath: 'components/Congelation/EditControlLot',
                    props,
                    onOk: this.reload,
                },
                'expedition-invoice-edit': {
                    name: 'expedition-invoice-edit',
                    title: this.$t('monte.congelation.edit_expedition'),
                    cmpPath: 'components/Congelation/EditInvoiceExpedition',
                    props,
                    onOk: this.load_expeditions
                },
                'tiers-stock-liste': {
                    name: 'tiers-stock-liste',
                    title: this.$t('monte.congelation.stocks'),
                    cmpPath: 'components/Congelation/TiersStocksListe',
                    props
                }
            }
        },

        setUpPrestockage() {
            const shutters = this.getCongelationShutters()
            this.shutterPanel().open(shutters['prestockage'])
        },

        setUpAddControlLot(lot) {
            const shutters = this.getCongelationShutters({lot})
            this.shutterPanel().open(shutters['add-control-lot'])
        },

        setUpAddControlEmplacement(emplacement) {
            const shutters = this.getCongelationShutters({emplacement})
            this.shutterPanel().open(shutters['add-control-lot'])
        },

        setUpControlsLot(lot) {
            const shutters = this.getCongelationShutters({lot})
            this.shutterPanel().open(shutters['controls-lot'])
        },

        setUpCampagneStallionListe(horse_id) {
            const shutters = this.getCongelationShutters({horse_id})
            this.shutterPanel().open(shutters['stallion-campagne-liste'])
        },

        setUpCampagneStallion(campagne) {
            const shutters = this.getCongelationShutters({campagne})
            this.shutterPanel().open(shutters['campagne-preview'])
        },

        setUpEditLot(lot) {
            const shutters = this.getCongelationShutters({lot})
            this.shutterPanel().open(shutters['edit-lot'])
        },

        setUpDeleteCapagne(campagne) {
            const shutters = this.getCongelationShutters({campagne})
            this.shutterPanel().open(shutters['delete-campagne'])
        },

        setUpStallionStocksListe(horse_id) {
            const shutters = this.getCongelationShutters({horse_id})
            this.shutterPanel().open(shutters['stallion-stock-liste'])
        },

        setUpEmplacementLot(lot) {
            const shutters = this.getCongelationShutters({lot})
            this.shutterPanel().open(shutters['emplacement-lot'])
        },

        setUpEmplacementDetail(emplacement) {
            const shutters = this.getCongelationShutters({emplacement})
            this.shutterPanel().open(shutters['emplacement-detail'])
        },

        setUpAddEmplacement(lot) {
            const shutters = this.getCongelationShutters({lot})
            this.shutterPanel().open(shutters['add-emplacement'])
        },

        setUpPlanCuveListe(horse_id) {
            const shutters = this.getCongelationShutters({horse_id})
            this.shutterPanel().open(shutters['stallion-plan-cuve'])
        },

        setUpPlanCuveDetail(horse_id, localisation) {
            const shutters = this.getCongelationShutters({horse_id, localisation})
            this.shutterPanel().open(shutters['stallion-plan-cuve-detail'])
        },

        setUpImportPaillettes(horse_id) {
            const shutters = this.getCongelationShutters({horse_id})
            this.shutterPanel().open(shutters['import-paillettes'])
        },

        setUpCommandeList(horse_id) {
            const shutters = this.getCongelationShutters({horse_id})
            this.shutterPanel().open(shutters['commande-list'])
        },

        setUpAddCommande(horse_id) {
            const shutters = this.getCongelationShutters({horse_id})
            this.shutterPanel().open(shutters['commande-add'])
        },

        setUpPreparationExpedition(expeditions) {
            const shutters = this.getCongelationShutters({expeditions})
            this.shutterPanel().open(shutters['expedition-preparation'])
        },

        setUpReintegrerExpedition(expeditions) {
            const shutters = this.getCongelationShutters({expeditions})
            this.shutterPanel().open(shutters['expedition-reintegrer'])
        },

        setUpEditExpedition(expedition) {
            const shutters = this.getCongelationShutters({expedition})
            this.shutterPanel().open(shutters['expedition-edit'])
        },

        setUpExpeditionList(horse_id) {
            const shutters = this.getCongelationShutters({horse_id})
            this.shutterPanel().open(shutters['expedition-list'])
        },

        setUpDeleteFloors(floors_ids) {
            const shutters = this.getCongelationShutters({floors_ids})
            this.shutterPanel().open(shutters['delete-floors'])
        },

        setUpEditControlLot(control) {
            const shutters = this.getCongelationShutters({control})
            this.shutterPanel().open(shutters['edit-control-lot'])
        },

        setUpEditInvoiceExpedition(expedition) {
            const shutters = this.getCongelationShutters({expedition})
            this.shutterPanel().open(shutters['expedition-invoice-edit'])
        },

        setUpTiersStocksListe(tiers_id) {
            const shutters = this.getCongelationShutters({tiers_id})
            this.shutterPanel().open(shutters['tiers-stock-liste'])
        },
    }
}
