<template>
	<div>
		<h3>{{ lot.semencelot_quantieme }}</h3>
		<div class="row">
			<div class="col-6 form-group">
				<label for="qte">{{ $t("monte.congelation.qte") }} *</label>
				<input class="form-control" type="number" v-model="qte" id="qte">
			</div>
			<div class="col-6 form-group">
				<label for="commentaire">{{ $t("monte.congelation.commentaire") }}</label>
				<input class="form-control" type="text" v-model="commentaire" id="commentaire">
			</div>
			<div class="col-6 form-group">
				<label for="tiers">{{ $t("tiers.tiers") }}</label>
				<SearchTiers 
					id="tiers"
					:tiers_selected.sync="tiers"
				/>
			</div>
			<div class="col-6 form-group">
				<label for="date">{{ $t("monte.date") }} *</label>
				<e-datepicker id="date" v-model="date" mode="date"></e-datepicker>
			</div>
			<div class="col-6 form-group">
           		<label for="stockage">{{ $t("monte.stock_semence.localisation") }} *</label>
				<e-select
					v-model="localisation_selected"
					id="stockage"
					track-by="full_name"
					label="full_name"
					:placeholder="$t('monte.stock_semence.rechercher_categorie')"
					:selectedLabel="$t('global.selected_label')"
					:options="all_localisations"
					:searchable="true"
					:show-labels="false"
					:allow-empty="true"
					:sortable="false"
				>
					<template slot="option" slot-scope="props"><span :style="`padding-left: ${25 * (props.option.indent || 0) }px;`">{{ props.option.full_name }}</span></template>
				</e-select>
			</div>
			<div class="col-6 form-group">
				<label for="norme_lock">{{ $t("monte.congelation.norme_lock") }}</label>
				<b-form-checkbox v-model="norme_lock" id="norme_lock" />
			</div>
		</div>
		<div class="col-12 text-center">
			<a class="btn btn-primary mt-3" href="#" @click.prevent="checkForm">{{ $t('global.enregistrer') }} <font-awesome-icon v-if="processing" :icon="['fal', 'spinner']" pulse /><font-awesome-icon v-else :icon="['fal', 'save']" /></a>
		</div>
	</div>
</template>

<script type="text/javascript">
	import Congelation from "@/mixins/Congelation.js"
	import StockSemence from "@/mixins/StockSemence.js"
	import Shutter from '@/mixins/Shutter.js'

	export default {
		name: 'AddEmplacement',
		mixins: [Congelation, StockSemence, Shutter],
		props: ['lot'],
		data () {
			return {
				all_localisations: [],
				qte: 1,
				commentaire: '',
				tiers: null,
				localisation_selected: '',
				date: new Date(),
				norme_lock: false,
				processing: false
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				const all_localisations = await this.getLocalisationSemenceFullName()
				this.all_localisations = all_localisations.filter(loc => !loc.semencelocalisation_transport && loc.semencelocalisation_is_floor)
				const setting = await this.getStallionSettings(this.lot.semencelot_horse)
				if(setting) {
					this.localisation_selected = this.all_localisations.find(loc => loc.semencelocalisation_id == setting.semencestallion_stockage)
				}
			},

			async checkForm() {
				if(!this.qte || !this.localisation_selected || !this.date) {
					return false
				}

				const tiers_id = this.tiers ? this.tiers.tiers_id : null

				this.processing = true
				await this.addEmplacement(this.lot.semencelot_id, this.qte, this.commentaire, tiers_id, this.localisation_selected.semencelocalisation_id, this.date, this.norme_lock)
				this.processing = false
				this.shutterPanel().goTo('emplacement-lot')
				this.ok()
			}
		},

		components: {
			SearchTiers : () => import('@/components/Contract/SearchTiers')
		}
	}

</script>